import { pairwise } from "core/utils";

export function buildSegmentedChartConfig({
  colours,
  normalisedRange,
  terminology,
  interpretation
}) {
  const range = [normalisedRange?.minimum, ...normalisedRange?.range, normalisedRange?.maximum];
  const segments = pairwise(range).map((points, index) => index);

  return {
    terms:
      segments?.length && segments.length === terminology?.length
        ? segments.map(index => ({
            bg: "transparent",
            term: terminology?.[index]
          }))
        : null,
    discreteValues:
      segments?.length && segments.length === colours?.length
        ? segments.map(index => ({ bg: colours?.[index] || "transparent" }))
        : null,
    value: segments?.map(index => ({ showValueIcon: terminology?.[index] === interpretation }))
  };
}

export function buildSegmentedProgressChartConfig({
  colours: allColours,
  value,
  fill,
  defaultColor = "haze",
  backgroundColor = "white"
}) {
  // TODO change "colours" to "color" to be consistent

  const colours = allColours?.length
    ? allColours.filter((colour, index) => index * 10 <= value * 100)
    : [];

  return {
    colours,
    fill,
    defaultColor,
    backgroundColor,
    interpretationColor: colours.slice(-1)?.[0]
  };
}
