import Modal from "./Modal";

function ChartModal({ children, show, close, bg }) {
  return (
    <Modal
      mode="centered"
      maxWidth={1020}
      closeButton
      bg={bg}
      headerProps={{
        pr: 20,
        pt: 20,
        pb: [40, 40, 20]
      }}
      show={show}
      close={close}
    >
      {children}
    </Modal>
  );
}

ChartModal.defaultProps = {
  bg: "white"
};

export default ChartModal;
