import Text from "components/Text";

import Group from "./Group";
import Stack from "./Stack";

export default function Currency({
  align,
  fontSize,
  symbol,
  value = 0,
  color,
  mb,
  display,
  sx,
  prefix,
  strikethrough,
  decimalFontSize,
  showTrailingZeros = false,
  stacked,
  prefixFontSize,
  prefixFontFamily,
  "data-testid": dataTestId
}) {
  /**
   *
   * I had wanted to get rid of the Text component since the gilroy font in the designs
   * is now like gilroy in the rendered HTML.  So no need for the margin correction stuff.
   * However the dinBold font has a lot more white space under the character than the designs
   * so the old Text component is useful here.
   *
   * Usage: If you use this component in a flexbox you'll probably need to wrap the Currency
   * element with a div element so that the fractional units are level with the integer units
   * (if both are the same size).
   *
   */

  value = value || 0;
  let stringifiedValue = typeof value !== "string" ? value.toString() : value;

  let integerPart = stringifiedValue.includes(".")
    ? stringifiedValue.split(".")[0]
    : stringifiedValue;
  let fractionalPart = stringifiedValue.includes(".") ? stringifiedValue.split(".")[1] : undefined;

  if (!showTrailingZeros && fractionalPart === "00") {
    fractionalPart = undefined;
  }

  const justifyContent = align === "right" ? "flex-end" : "flex-start";

  const content = (
    <>
      {!!prefix && (
        <Text
          as="span"
          color="inherit"
          fontFamily={prefixFontFamily}
          fontSize={prefixFontSize || fontSize}
          data-testid="currency-prefix"
        >
          {prefix}
          {stacked ? "" : ":"}
        </Text>
      )}
      <Group color={color}>
        <Text
          as="span"
          color="inherit"
          fontFamily="dinBold"
          fontSize={fontSize}
          textDecorationColor={color}
          data-testid="currency-symbol"
          style={{
            ...(strikethrough
              ? {
                  textDecoration: "line-through",
                  textDecorationThickness: "2px"
                }
              : {})
          }}
        >
          {symbol}
        </Text>
        {integerPart !== undefined && (
          <Text
            as="span"
            color="inherit"
            fontFamily="dinBold"
            fontSize={fontSize}
            textDecorationColor={color}
            data-testid="currency-integer-part"
            style={{
              ...(strikethrough
                ? {
                    textDecoration: "line-through",
                    textDecorationThickness: "2px"
                  }
                : {})
            }}
          >
            {integerPart}
          </Text>
        )}
        {fractionalPart !== undefined && (
          <Text
            as="span"
            color="inherit"
            fontFamily="dinBold"
            fontSize={decimalFontSize || fontSize}
            textDecorationColor={color}
            alignSelf="flex-end"
            style={{
              ...(strikethrough
                ? {
                    textDecoration: "line-through",
                    textDecorationThickness: "2px"
                  }
                : {})
            }}
          >
            .
          </Text>
        )}
        {fractionalPart !== undefined && (
          <Text
            as="span"
            color="inherit"
            fontFamily="dinBold"
            fontSize={decimalFontSize || fontSize}
            textDecorationColor={color}
            alignSelf="flex-end"
            data-testid="currency-fractional-part"
            style={{
              ...(strikethrough
                ? {
                    textDecoration: "line-through",
                    textDecorationThickness: "2px"
                  }
                : {})
            }}
          >
            {fractionalPart.substring(0, 2).padEnd(2, "0")}
          </Text>
        )}
      </Group>
    </>
  );

  if (stacked) {
    return (
      <Stack
        data-component-name="Currency"
        data-testid={dataTestId}
        as="span"
        color={color}
        mb={mb}
        display={display}
        gap={5}
        alignItems={justifyContent}
        {...sx}
      >
        {content}
      </Stack>
    );
  }

  return (
    <Group
      data-component-name="Currency"
      data-testid={dataTestId}
      as="span"
      color={color}
      mb={mb}
      display={display}
      gap={5}
      justifyContent={justifyContent}
      flexWrap="wrap"
      {...sx}
    >
      {content}
    </Group>
  );
}

Currency.defaultProps = {
  symbol: "£",
  fontSize: 28,
  prefixFontFamily: "dinBold"
};
