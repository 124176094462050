import { Fragment, useCallback, useState } from "react";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import Divider from "components/Divider";
import Grid from "components/Grid";
import { useActionRequired } from "contexts/ActionRequiredContext";
import {
  ARCHIVE_CALLBACK_REQUEST_MUTATION,
  CALLBACK_REQUESTS_QUERY,
  TOGGLE_RESPONDED_TO_CALLBACK_REQUEST_MUTATION
} from "graphql/accounts";
import { useDataLoader } from "hooks/useDataLoader";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import { BaseCheckbox } from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import { List, ListContext } from "tpo/List";
import SimpleTable from "tpo/SimpleTable";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { useTableControls } from "tpo/Table";
import ButtonV2 from "v2/Buttons";

import PatientsTemplate from "./ui/Template";

const SORT_OPTIONS = [
  {
    label: "Date (Newest - Oldest)",
    value: "-created"
  },
  {
    label: "Date (Oldest - Newest)",
    value: "created"
  }
];

function HideShowMessage({ message }) {
  const [show, setShow] = useState(false);

  return (
    <Box>
      {show && (
        <Box fontFamily="gilroyMedium" fontSize={16}>
          {message}
        </Box>
      )}
      <Group justifyContent="flex-end">
        <ButtonV2
          variant="transparent"
          rightIcon={<ChevronComponent direction={show ? "top" : "bottom"} fill="black" />}
          onClick={() => setShow(!show)}
          p={0}
          sx={{
            fontFamily: "gilroyBold",
            fontSize: 10
          }}
        >
          message detail
        </ButtonV2>
      </Group>
    </Box>
  );
}

export default function CallbackRequests() {
  const { refetch: refetchActionsRequired } = useActionRequired();

  const {
    page,
    sort,
    debouncedSearch,
    search,
    setSearch,
    setPage,
    setSort,
    sortMenuOpen,
    setSortMenuOpen
  } = useTableControls({
    sortOptions: SORT_OPTIONS
  });

  const { data, loading, refetch } = useDataLoader({
    query: CALLBACK_REQUESTS_QUERY,
    variables: {
      page,
      orderBy: sort,
      search: debouncedSearch
    }
  });

  // NB: refetch appears to keep loading = false, and data as not undefined
  // Shouldn't look a problem though for this table after they've clicked archive

  const [archiveMutation] = useMutation(ARCHIVE_CALLBACK_REQUEST_MUTATION);
  const [toggleRespondedMutation] = useMutation(TOGGLE_RESPONDED_TO_CALLBACK_REQUEST_MUTATION);

  const archive = useCallback(
    id => {
      return archiveMutation({
        variables: {
          id
        }
      }).catch(e => console.log("Error encountered archiving", e));
    },
    [archiveMutation]
  );

  const toggleResponded = useCallback(
    id => {
      return toggleRespondedMutation({
        variables: {
          id
        }
      }).catch(e => console.log("Error encountered toggling responded", e));
    },
    [toggleRespondedMutation]
  );

  const items = data?.contactExpertsPagination?.items;
  const pageInfo = data?.contactExpertsPagination?.pageInfo;

  return (
    <PatientsTemplate active="callback_requests">
      <PanelBoxV2 maxWidth={1280}>
        <PanelBoxV2
          maxWidth={1020}
          outer={{
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            bg: "white",
            px: 20,
            borderRadius: 5
          }}
        >
          <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
            Patient call back request
          </Box>
          <Spacer py={[2, 2, 20]} />
          <Box fontFamily="gilroyMedium" fontSize={16} lineHeight="150%">
            Below are requests from potential new patients who have requested a call back via the
            Omnos platform.
          </Box>
          <Spacer py={[2, 2, 20]} />
          <SimpleTable
            search={search}
            setSearch={setSearch}
            sort={sort}
            setSort={setSort}
            sortMenuOpen={sortMenuOpen}
            setSortMenuOpen={setSortMenuOpen}
            pageInfo={pageInfo}
            setPage={setPage}
            sortingOptions={SORT_OPTIONS}
            table={
              <>
                <List items={items} loading={loading}>
                  <Grid
                    display={["none", "none", "grid"]}
                    gridTemplateColumns={["1fr", "1fr 1fr 1fr 1fr"]}
                    style={{
                      gridRowGap: 0,
                      gridColumnGap: 0
                    }}
                    pb={2}
                  >
                    <Box fontFamily="gilroyBold" fontSize={18} pl={40}>
                      Contact details
                    </Box>
                    <Box fontFamily="gilroyBold" fontSize={18} textAlign="center">
                      Date
                    </Box>
                    <Box fontFamily="gilroyBold" fontSize={18}>
                      Appointment
                    </Box>
                    <Box fontFamily="gilroyBold" fontSize={18} pr={40} textAlign="right">
                      Responded
                    </Box>
                  </Grid>
                  <Divider
                    style={{
                      gridColumn: "1 / -1"
                    }}
                  />
                  <ListContext.Consumer>
                    {items =>
                      items?.map((item, idx) => (
                        <Fragment key={item.id}>
                          <Grid
                            gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr 1fr"]}
                            style={{
                              gridColumnGap: 0
                            }}
                            bg={idx % 2 === 1 ? "haze" : null}
                            px={[20, 20, 40]}
                            py={[20, 20, 40]}
                            fontSize={[14, 14, 16]}
                            gridRowGap={[20, 20, 0]}
                            data-component-name="CallbackRequest"
                            data-testid={item.id}
                          >
                            <Stack fontSize="inherit" gap={5}>
                              <Box fontSize="inherit" fontFamily="gilroyBold">
                                {`${item.firstName} ${item.lastName}`}
                              </Box>
                              {item.email && <Box fontSize="inherit">{item.email}</Box>}
                              {item.phoneNumber && <Box fontSize="inherit">{item.phoneNumber}</Box>}
                            </Stack>
                            <Box fontSize="inherit" textAlign={["left", "left", "center"]}>
                              {new Date(item.created).toLocaleDateString()}
                            </Box>
                            <Group fontSize="inherit" gap={20} alignSelf="flex-start">
                              {item.consultationType.map(ct => (
                                <Badge key={ct} bg="green" color="white" px={16} py="8px">
                                  {ct}
                                </Badge>
                              ))}
                            </Group>
                            <Group
                              gap={10}
                              alignItems="center"
                              justifySelf={["start", "start", "end"]}
                              fontSize="inherit"
                            >
                              <Box
                                as="label"
                                htmlFor={`responded-${item.id}`}
                                display={["block", "none", "none"]}
                                fontFamily="gilroyBold"
                                fontSize="inherit"
                              >
                                Contacted
                              </Box>
                              <BaseCheckbox
                                checked={item.responded}
                                styles={{
                                  inputWrapperProps: {
                                    ml: "auto"
                                  }
                                }}
                                onChange={() =>
                                  toggleResponded(item.id).then(() => refetchActionsRequired())
                                }
                                id={`responded-${item.id}`}
                                data-component-name="RespondedCheckbox"
                                name="responded"
                              />
                            </Group>
                            <Divider
                              my={[0, 0, 20]}
                              style={{
                                gridColumn: "1 / -1"
                              }}
                            />
                            <Stack
                              gap={20}
                              style={{
                                gridColumn: "1 / -1"
                              }}
                            >
                              <HideShowMessage message={item.message} />
                              <ButtonV2
                                color="red"
                                onClick={() =>
                                  archive(item.id).then(() => {
                                    refetch();
                                  })
                                }
                                mr="auto"
                                data-component-name="ArchiveButton"
                              >
                                Archive
                              </ButtonV2>
                            </Stack>
                          </Grid>
                          <Divider
                            style={{
                              gridColumn: "1 / -1"
                            }}
                          />
                        </Fragment>
                      ))
                    }
                  </ListContext.Consumer>
                </List>
              </>
            }
          />
        </PanelBoxV2>
      </PanelBoxV2>
    </PatientsTemplate>
  );
}
