import { getTestProductQuestionnaireQuestionsUrl } from "../core/urls";
import { useTestProduct, useTestProductSlug } from "./QuestionnaireHooks";
import Intro, { useIntro } from "./QuestionnaireIntro";
import { LoadingPage } from "./QuestionnairePages";

function TestProductQuestionnaireIntro() {
  const testProductSlug = useTestProductSlug();
  const { handleClick, submission } = useIntro(
    getTestProductQuestionnaireQuestionsUrl(testProductSlug, "symptoms")
  );
  const testProductData = useTestProduct();
  const truncatedName = testProductData?.testProduct?.truncatedName;
  if (!submission || !truncatedName) {
    return <LoadingPage />;
  }
  return (
    <Intro
      h1="Omnos Test matching"
      h2={truncatedName}
      h3="Welcome"
      bodyCopy="The following questions will help us to identify how suitable this test is for your current symptoms."
      buttonText={submission.complete ? "Edit" : "Get Started"}
      handleClick={handleClick}
    />
  );
}

export default TestProductQuestionnaireIntro;
