import { useEffect, useMemo, useState } from "react";

import { gql, useQuery } from "@apollo/client";
import Box from "components/Box";
import { ExternalTextLink, InternalTextLink } from "components/Links";
import Page from "components/Page";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { getAPIBase } from "core/config";
import { ORGANISATION_STOCK_LIST_QUERY } from "graphql/organisations/queries/stock";
import { useDataLoader } from "hooks/useDataLoader";
import useListControls from "hooks/useListControls";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Modal from "tpo/Modal";
import SimpleTable from "tpo/SimpleTable";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import Table from "tpo/Table";
import ButtonV2 from "v2/Buttons";

import OrganisationTabs from "../OrganisationTabs";
import { REGENERUS } from "../constants";
import OrderIcon from "./ui/OrderIcon";
import RegisterStock from "./ui/RegisterStock";
import Switcher from "./ui/Switcher";

export function OrganisationStock({ statusFilters = [] }) {
  const [filterTab, setFilterTab] = useState("all");

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedStockId, setSelectedStockId] = useState();
  const [page, setPage] = useState(1);

  // use the url state to determine if we should preselect a stock id
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stockId = urlParams.get("stock");
    if (stockId) {
      setSelectedStockId(stockId);
    }
  }, []);

  const controls = useListControls({
    defaultSort: "-created_from_order__checkout_date",
    sortOptions: [
      {
        label: "A - Z",
        value: "test_product__name"
      },
      {
        label: "Z - A",
        value: "-test_product__name"
      },
      {
        label: "Oldest To Newest",
        value: "created_from_order__checkout_date"
      },
      {
        label: "Newest To Oldest",
        value: "-created_from_order__checkout_date"
      }
    ]
  });

  const { organisation } = useOrganisationContext();
  const { data, loading } = useDataLoader(
    {
      query: ORGANISATION_STOCK_LIST_QUERY,
      variables: {
        page,
        organisation: parseInt(organisation?.id),
        orderBy: controls.sort,
        search: controls.debouncedSearch,
        filter: filterTab
      },
      cachePolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !organisation
    },
    [organisation, page, controls.sort, controls.debouncedSearch, filterTab]
  );

  const orders = data?.organisationStockPagination?.items;
  const pageInfo = data?.organisationStockPagination?.pageInfo;

  const columns = useMemo(
    () => [
      {
        id: "kit",
        label: "Kit",
        accessor: ({
          id,
          omnosBarcodeId,
          testProduct: product,
          createdFromOrder: order,
          orderTestItem
        }) => {
          return (
            <Stack gap={5}>
              <Box fontFamily="gilroyBold" fontSize={16}>
                {product.name}
              </Box>
              <InternalTextLink href={`/partners/dashboard/order/${order.id}`}>
                Order No: {order.id}
              </InternalTextLink>
              {(orderTestItem?.providerTestId || omnosBarcodeId) && (
                <Box fontFamily="gilroyMedium" fontSize={14}>
                  Kit registration ID: {orderTestItem?.providerTestId || omnosBarcodeId}
                </Box>
              )}
            </Stack>
          );
        }
      },
      {
        id: "orderDate",
        accessor: stock =>
          new Date(stock.createdFromOrder.checkoutDate).toLocaleDateString("en-GB"),
        label: "Ordered"
      },
      {
        id: "expires",
        accessor: stock => {
          // TODO Replace with real expiry calculations
          const date = new Date(stock.createdFromOrder.checkoutDate);
          date.setMonth(date.getMonth() + 3);
          return date.toLocaleDateString("en-GB");
        },
        label: "Expires"
      },
      {
        id: "lastColumn",
        accessor: ({ id, registeredUser, orderTestItem, testProduct }) => {
          if (registeredUser) {
            return (
              <Stack gap={5}>
                <Badge bg="green" color="white" ml="auto" py="8px" px="16px" fontSize={10}>
                  Registered
                </Badge>
                <Box fontFamily="gilroyBold" fontSize={16}>
                  {registeredUser.firstName} {registeredUser.lastName}
                </Box>
                {testProduct.supplier.name == REGENERUS && (
                  <ExternalTextLink
                    href={`${getAPIBase()}/requisition/download/${orderTestItem.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonV2 color="green" rightIcon={<ChevronComponent />} ml="auto" mt={2}>
                      Lab form
                    </ButtonV2>
                  </ExternalTextLink>
                )}
              </Stack>
            );
          } else {
            return (
              <ButtonV2
                color="dark"
                rightIcon={<ChevronComponent />}
                onClick={() => setSelectedStockId(id)}
              >
                Register
              </ButtonV2>
            );
          }
        },
        align: "right"
      }
    ],
    []
  );

  const statusTabs = [
    {
      label: "all",
      slug: "all"
    },
    {
      label: "registered",
      slug: "registered"
    },
    {
      label: "not registered",
      slug: "not_registered"
    }
  ];

  return (
    <>
      <OrganisationTabs selectedTab="orders" />
      <Stack
        maxWidth={1538}
        mx="auto"
        pt={[30, 30, 60]}
        pb={[50, 50, 80]}
        px={[20, 20, "5.5vw"]}
        gap={[20, 20, 40]}
      >
        <Center>
          <Switcher />
        </Center>
        <Box
          bg="white"
          borderRadius={5}
          maxWidth={1280}
          pt={[30, 30, 60]}
          pb={[50, 50, 80]}
          px={[20, 20, 40]}
        >
          <Box maxWidth={1020} mx="auto">
            <Group gap={20}>
              <OrderIcon width={[30, 30, 40]} />
              <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                Organisation Stock
              </Box>
            </Group>
            <Spacer py={[2, 2, 20]} />
            <SimpleTable
              controls={
                <SortMenu
                  value={filterTab || null}
                  setValue={v => setFilterTab(v)}
                  open={showFilterMenu}
                  setOpen={setShowFilterMenu}
                  options={statusTabs.map(({ label, slug }) => ({
                    label: label.toUpperCase(),
                    value: slug
                  }))}
                  styles={{
                    button: {
                      color: "dark",
                      marginRight: "auto"
                    }
                  }}
                  placeholder="Filter by status"
                />
              }
              search={controls.search}
              setSearch={controls.setSearch}
              sort={controls.sort}
              setSort={controls.setSort}
              sortMenuOpen={controls.sortMenuOpen}
              setSortMenuOpen={controls.setSortMenuOpen}
              pageInfo={pageInfo}
              setPage={setPage}
              sortingOptions={controls.sortOptions}
              table={
                <>
                  <Box display={["none", "none", "block"]}>
                    <Table columns={columns} loading={loading} rows={orders} />
                  </Box>
                  <Box display={["block", "block", "none"]}>todo</Box>
                </>
              }
            />
          </Box>
        </Box>
      </Stack>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [2, 2, 20],
          pb: 20
        }}
        bg="white"
        show={!!selectedStockId}
        close={() => setSelectedStockId()}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "white",
            px: [20, 20, 40],
            pt: [20, 20, 40],
            pb: [50, 50, 80],
            borderRadius: 5
          }}
        >
          <RegisterStock stockId={selectedStockId} />
        </PanelBoxV2>
      </Modal>
    </>
  );
}
