import { useContext, useEffect, useMemo, useState } from "react";

import { isEmpty, isEqual } from "lodash";

import { AnswersContext, DownloadQuestionsContext } from "./QuestionnaireContexts";

function initAnswers(answers) {
  return (answers || []).reduce(
    (acc, userAnswer) => ({ ...acc, [userAnswer.question.id]: +userAnswer.answer[2] }),
    /** userAnswer.answer is of form "A_2".  We want the number.  */
    {}
  );
}

function Answers({ children }) {
  const { answers } = useContext(DownloadQuestionsContext);
  const [answered, setAnswered] = useState(initAnswers(answers));
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (answers && isEmpty(answered)) {
      const newAnswers = initAnswers(answers);
      if (!isEqual(answered, newAnswers)) {
        setAnswered(newAnswers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, answered, setAnswered]);

  const api = useMemo(
    () => ({
      answered,
      setAnswered,
      setShowErrors,
      showErrors
    }),
    [answered, setAnswered, showErrors, setShowErrors]
  );

  return <AnswersContext.Provider value={api}>{children}</AnswersContext.Provider>;
}

export default Answers;
