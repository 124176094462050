import React from "react";

import Box from "./Box";
import Text from "./Text";

function Errors({ children }) {
  return (
    <Box py={30}>
      <Text textAlign="center" color="red" data-component-name="Errors">
        {children === "" ? "\u00a0" : children}
      </Text>
    </Box>
  );
}

export default Errors;
