import React, { createContext, forwardRef, useCallback, useMemo, useState } from "react";

import Box from "components/Box";
import { SpanText } from "components/Text";
import { theme } from "core/theme";
import { ReactComponent as Pencil } from "images/pencil.svg";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { color, position } from "styled-system";

import Input from "./Input";
import InputWrapper, { Error } from "./InputWrapper";

const Label = styled.label`
  ${color}
  ${position}
  pointer-events: none;
  transform-origin: top left;
  transition: transform 300ms;
  ${props => {
    return props.minimised && "transform: scale(0.65) translate3d(0, -120%, 0);";
  }}
`;

const FloatingLabelContext = createContext();

export const FloatingLabel = forwardRef(
  (
    { label, value, error, required, name, "data-component-name": dataComponentName, input },
    ref
  ) => {
    let borderColor = theme.colors.carbon;
    if (error) borderColor = theme.colors.red;

    const [focused, setFocused] = useState(false);

    const onFocus = useCallback(
      e => {
        setFocused(true);
      },
      [setFocused]
    );

    const onBlur = useCallback(
      e => {
        setFocused(false);
      },
      [setFocused]
    );

    const topGap = "13px";

    const api = useMemo(
      () => ({
        onBlur,
        onFocus,
        focused
      }),
      [onBlur, onFocus, focused]
    );

    return (
      <FloatingLabelContext.Provider value={api}>
        <Box
          borderBottomColor={borderColor}
          borderBottomStyle="solid"
          borderBottomWidth={"1px"}
          display="flex"
          alignItems="center"
          position="relative"
          pt={topGap}
          pb="5px"
          css={`
            & > input:-webkit-autofill ~ label {
              transform: scale(0.65) translate3d(0, -110%, 0);
            }
          `}
          data-component-name={dataComponentName}
        >
          {input}
          <Label
            position="absolute"
            top={topGap}
            left={0}
            htmlFor={name}
            color={error ? "red" : "slate"}
            minimised={focused || value}
            ref={ref}
          >
            {label}
            {required && <SpanText color="red">*</SpanText>}
          </Label>
        </Box>
      </FloatingLabelContext.Provider>
    );
  }
);

const FloatingLabelInput = forwardRef(
  (
    {
      label,
      name,
      onChange,
      error,
      value,
      errorPosition,
      InputComponent,
      rightIcon,
      type,
      color,
      editable = true,
      inputProps = {},
      styles,
      showBorder,
      ...props
    },
    ref
  ) => (
    <InputWrapper
      input={
        <FloatingLabel
          label={label}
          value={value}
          error={error}
          showBorder={showBorder}
          input={
            <Box flexGrow={1}>
              {error && errorPosition === "top" && (
                <Error error={error} position="relative" top={-40} />
              )}
              <FloatingLabelContext.Consumer>
                {({ onBlur, onFocus }) => (
                  <InputComponent
                    name={name}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    type={type}
                    disabled={!editable}
                    color={color}
                    style={{
                      paddingRight: rightIcon || editable ? 24 : 0
                    }}
                    rightIcon={
                      rightIcon ||
                      (editable ? (
                        <Pencil fill={error ? "red" : theme.colors.slate} width={16} />
                      ) : null)
                    }
                    {...inputProps}
                  />
                )}
              </FloatingLabelContext.Consumer>
            </Box>
          }
        />
      }
      error={errorPosition === "bottom" ? error : undefined}
      {...styles?.wrapper}
      {...props}
    />
  )
);

FloatingLabelInput.defaultProps = {
  errorPosition: "bottom",
  type: "text",
  InputComponent: Input
};

export default FloatingLabelInput;
