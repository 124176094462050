import { useCallback, useMemo } from "react";

import { gql, useMutation } from "@apollo/client";
import STATUS_BADGES from "components/organisations/shared/ui/StatusBadges";
import { CONNECTED_USERS_QUERY } from "graphql/organisations/queries/patients";
import ButtonV2 from "v2/Buttons";

import Box from "../components/Box";
import { InternalTextLink } from "../components/Links";
import Text from "../components/Text";
import { getClientManagementTabUrl } from "../core/urls";
import { useDataLoader } from "../hooks/useDataLoader";
import Badge from "./Badge";
import { PanelBoxV2 } from "./Boxes";
import ChevronComponent from "./Chevron";
import Group from "./Group";
import { List, ListContext } from "./List";
import SimpleTable from "./SimpleTable";
import Spacer from "./Spacer";
import Table, { useTableControls } from "./Table";

const RESEND_MUTATION = gql`
  mutation ResendInvitationToPatientMutation($input: ResendInvitationToPatientMutationInput!) {
    resendInvitationToPatientMutation(input: $input) {
      pk
    }
  }
`;

function ResendInvite({ id }) {
  const [resendInviteMutation] = useMutation(RESEND_MUTATION, {
    variables: {
      input: {
        pk: id
      }
    }
  });

  const resendInvite = useCallback(() => {
    resendInviteMutation().catch(e => console.log("Error encountered resending invite", e));
  }, [resendInviteMutation]);

  return (
    <ButtonV2 color="dark" rightIcon={<ChevronComponent />} onClick={resendInvite}>
      Resend
    </ButtonV2>
  );
}

function StatusBasedButton({ id, platformUserProfile, granted }) {
  if (granted && platformUserProfile?.user?.id) {
    if (platformUserProfile?.user?.pageAvailability?.testResultsAvailable) {
      return (
        <ButtonV2
          as={InternalTextLink}
          rightIcon={<ChevronComponent />}
          href={getClientManagementTabUrl(platformUserProfile?.user?.id)}
          color="green"
          underline={false}
          newTab
        >
          profile
        </ButtonV2>
      );
    } else {
      return (
        <ButtonV2 as={InternalTextLink} color="grey" underline={false} disabled={true} newTab>
          No results
        </ButtonV2>
      );
    }
  }

  return <ResendInvite id={id} />;
}

function ConnectedUserCard({
  createdDateFormatted,
  platformUserProfile,
  email,
  granted,
  id,
  status
}) {
  return (
    <Box
      pt={2}
      pb={20}
      display="flex"
      flexDirection="column"
      gap={20}
      borderColor="haze"
      borderBottomStyle="solid"
      borderWidth={1}
    >
      <Box display="flex" flexDirection="column" gap={10}>
        {!!(platformUserProfile?.user?.firstName && platformUserProfile?.user?.lastName) && (
          <Text
            fontFamily="gilroyBold"
            fontSize={16}
          >{`${platformUserProfile?.user?.firstName}, ${platformUserProfile?.user?.lastName}`}</Text>
        )}
        {!!email && <Text fontSize={16}>{email}</Text>}
      </Box>
      <Group alignItems="center" justifyContent="space-between">
        <Box fontFamily="gilroyMedium" fontSize={16} lineHeight="150%">
          {createdDateFormatted}
        </Box>
        {STATUS_BADGES[status]}
      </Group>
      <Box display="flex" justifyContent="flex-start">
        <StatusBasedButton granted={granted} platformUserProfile={platformUserProfile} id={id} />
      </Box>
    </Box>
  );
}

const SORT_OPTIONS = [
  {
    label: "Date order (Newest - Oldest)",
    value: "-created"
  },
  {
    label: "Date order (Oldest - Newest)",
    value: "created"
  },
  {
    label: "Status (Ascending)",
    value: "status"
  },
  {
    label: "Status (Descending)",
    value: "-status"
  }
];

function ConnectedUsers() {
  const {
    page,
    sort,
    debouncedSearch,
    search,
    setSearch,
    setPage,
    setSort,
    sortMenuOpen,
    setSortMenuOpen
  } = useTableControls({
    sortOptions: SORT_OPTIONS
  });

  const { data, loading } = useDataLoader({
    query: CONNECTED_USERS_QUERY,
    variables: {
      page,
      orderBy: sort,
      search: debouncedSearch
    }
  });

  const columns = useMemo(
    () => [
      {
        id: "email",
        label: "Name / Email",
        accessor: ({ platformUserProfile, email }) => {
          return (
            <Box display="flex" flexDirection="column" gap={10}>
              {!!(platformUserProfile?.user?.firstName && platformUserProfile?.user?.lastName) && (
                <Text
                  fontFamily="gilroyBold"
                  fontSize={16}
                >{`${platformUserProfile?.user?.firstName}, ${platformUserProfile?.user?.lastName}`}</Text>
              )}
              {!!email && <Text fontSize={16}>{email}</Text>}
            </Box>
          );
        }
      },
      {
        id: "createdDateFormatted",
        accessor: "createdDateFormatted",
        label: ""
      },
      {
        id: "status",
        accessor: ({ status }) => STATUS_BADGES[status],
        label: "Status"
      },
      {
        id: "results",
        accessor: row => (
          <Group justifyContent="flex-end">
            <StatusBasedButton {...row} />
          </Group>
        ),
        label: "",
        align: "center",
        width: "25%"
      }
    ],
    []
  );

  const items = data?.connectedUsersPagination?.items;
  const pageInfo = data?.connectedUsersPagination?.pageInfo;

  return (
    <PanelBoxV2 maxWidth={1280}>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "white",
          px: 20,
          borderRadius: 5
        }}
      >
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
          Connected users
        </Box>
        <Spacer py={[2, 2, 20]} />
        <SimpleTable
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          sortMenuOpen={sortMenuOpen}
          setSortMenuOpen={setSortMenuOpen}
          pageInfo={pageInfo}
          setPage={setPage}
          sortingOptions={SORT_OPTIONS}
          table={
            <>
              <Box display={["none", "none", "block"]}>
                <Table columns={columns} loading={loading} rows={items} />
              </Box>
              <List items={items} loading={loading} display={["block", "block", "none"]}>
                <ListContext.Consumer>
                  {items => items?.map(item => <ConnectedUserCard key={item.id} {...item} />)}
                </ListContext.Consumer>
              </List>
            </>
          }
        />
      </PanelBoxV2>
    </PanelBoxV2>
  );
}

export default ConnectedUsers;
