import { useCallback, useMemo } from "react";

import { useHistory, useLocation } from "react-router-dom";

export default function useQueryParams({ DEFAULT_FILTERS, ALL_FILTERS }) {
  /**
   * We want arrays to be comma separated values.
   *
   * {
   *    gender: 'Male',
   *    fruits: ['apple', 'pear']
   * }
   *
   * Should become ?gender=Male&fruits=apple,pear
   *
   * So it knows whether to replace or add a new param
   * to a filter use the following defaults:
   *
   * {
   *  gender: undefined,
   *  fruits: []
   * }
   *
   * This way if a param is to added to "gender" it knows to replace
   * what it has already, otherwise if it's a list it knows to add.
   *
   */

  const history = useHistory();
  const location = useLocation();

  const applyFilter = useCallback(
    (key, value) => {
      if (Array.isArray(DEFAULT_FILTERS[key])) {
        const params = new URLSearchParams(location.search);
        params.append(key, value);
        return history.push({
          path: location.path,
          search: params.toString()
        });
      } else {
        const params = new URLSearchParams(location.search);
        params.set(key, value);
        return history.push({
          path: location.path,
          search: params.toString()
        });
      }
    },
    [history, location, DEFAULT_FILTERS]
  );

  const removeFilter = useCallback(
    (key, value) => {
      if (Array.isArray(DEFAULT_FILTERS[key])) {
        const params = new URLSearchParams(location.search);
        params.delete(key, value);
        return history.push({
          path: location.path,
          search: params.toString()
        });
      } else {
        const params = new URLSearchParams(location.search);
        params.delete(key);
        return history.push({
          path: location.path,
          search: params.toString()
        });
      }
    },
    [history, location, DEFAULT_FILTERS]
  );

  const toggleFilter = useCallback(
    (group, value) => {
      const params = new URLSearchParams(location.search);
      if (params.has(group, value)) {
        return removeFilter(group, value);
      }
      return applyFilter(group, value);
    },
    [applyFilter, removeFilter, location]
  );

  const resetFilters = useCallback(() => {
    return history.push({
      path: location.path
    });
  }, [history, location]);

  const validatedFilters = [...new URLSearchParams(location.search).entries()]
    .filter(([k]) => k in ALL_FILTERS)
    .map(([k, values]) => [k, values.split(",")])
    .filter(([k, values]) => values.every(v => ALL_FILTERS[k].includes(v)))
    .reduce(
      (mergedObj, [k, v]) => {
        if (Array.isArray(DEFAULT_FILTERS[k])) {
          if (k in mergedObj) {
            return {
              ...mergedObj,
              [k]: [...mergedObj[k], ...v]
            };
          } else {
            return {
              ...mergedObj,
              [k]: v
            };
          }
        } else {
          return {
            ...mergedObj,
            [k]: v
          };
        }
      },
      {
        ...Object.entries(DEFAULT_FILTERS).reduce(
          (obj, [k, v]) => ({ ...obj, [k]: Array.isArray(v) ? v : [v] }),
          {}
        )
      }
    );

  const api = useMemo(
    () => ({
      applyFilter,
      removeFilter,
      toggleFilter,
      resetFilters,
      validatedFilters
    }),
    [applyFilter, removeFilter, toggleFilter, resetFilters, validatedFilters]
  );

  return api;
}
