import PercentageBar from "./PercentageBar";
import { usePercentageAnswers } from "./QuestionnaireHooks";

function SectionProgressBar() {
  const percentage = usePercentageAnswers();

  return (
    <>
      <PercentageBar
        value={+percentage}
        foregroundColor="brand"
        enclosed={false}
        height={5}
        showChevron={false}
        showText={false}
      />
    </>
  );
}

export default SectionProgressBar;
