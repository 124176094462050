import { useCallback, useEffect, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";
import Lottie from "lottie-react";

import { useAppState } from "../components/AppStateProvider";
import Box from "../components/Box";
import Text from "../components/Text";
import { DASHBOARD_HOME_URL } from "../core/urls";
import SpinnerJSON from "../data/spinner";
import { PanelBox } from "../deprecated/Boxes";
import Section from "../deprecated/Section";
import { HeadingExtraExtraSmall } from "./Headings";
import { useSubmission } from "./QuestionnaireHooks";
import Intro from "./QuestionnaireIntro";
import Page from "./QuestionnairePages";

const COMPLETE_USER_SUBMISSION_MUTATION = gql`
  mutation CompleteUserSubmissionMutation($input: CompleteUserSubmissionMutationInput!) {
    completeUserSubmissionMutation(input: $input) {
      userTestRecommendations {
        id
        product {
          id
          name
          slug
          productFamily {
            id
            slug
          }
          content {
            id
            image1
          }
        }
        symptoms {
          id
          name
          points
          maxPoints
          score
        }
        score
        points
        maxPoints
      }
      errors {
        field
        messages
      }
    }
  }
`;

export function useOutro(product, partial) {
  const submission = useSubmission();
  const history = useHistory();
  const location = useLocation();
  const { setQuestionnaireProcessing } = useAppState();
  const [completeUserSubmissionMutation] = useMutation(COMPLETE_USER_SUBMISSION_MUTATION);
  const [bestMatchedTest, setBestMatchedTest] = useState();
  const redirectToDashboard = useCallback(() => {
    history.push(DASHBOARD_HOME_URL);
  }, [history]);

  const shouldUpdate = useRef(true);
  shouldUpdate.current = true;

  useEffect(() => {
    if (submission) {
      if (location.state?.fromSection && history.action === "PUSH") {
        completeUserSubmissionMutation({
          variables: {
            input: {
              submission: submission.id,
              complete: true,
              products: product,
              partial
            }
          }
        })
          .then(resp => {
            if (shouldUpdate.current) {
              // if user doesn't wait to get to this point but has by now moved to different page
              // when react gets to here it will warn that it tried to update a mounted component
              // so this flag checks to see if we should update
              setQuestionnaireProcessing(true);
              setBestMatchedTest({
                show: true,
                test: resp.data?.completeUserSubmissionMutation?.userTestRecommendations?.[0]
              });
            }
          })
          .catch(error => {
            // TODO - what should we do here?
            console.log("Error marking the user submission as complete", error);
          });
      } else {
        redirectToDashboard();
      }
    }

    return () => {
      shouldUpdate.current = false;
    };
  }, [
    partial,
    product,
    history,
    redirectToDashboard,
    submission,
    location,
    setQuestionnaireProcessing,
    completeUserSubmissionMutation,
    setBestMatchedTest
  ]);

  return {
    bestMatchedTest
  };
}

export function LoadingResults() {
  return (
    <Page
      header="Processing results..."
      Main={
        <PanelBox medium>
          <PanelBox bg="white" nested small verticalPadding>
            <Section.Header>
              <HeadingExtraExtraSmall fontSize={[18]}>
                This should take a moment
              </HeadingExtraExtraSmall>
            </Section.Header>
            <Section.BodyCopy>
              <Text fontSize={[15, 16]}>
                Just a moment, we’re analysing your answers and will shortly have your wellness
                scores, recommendations and matched tests available for you.
              </Text>
            </Section.BodyCopy>
            <Section.Content>
              <Box display="flex" justifyContent="center">
                <Box height={50} width={50}>
                  <Lottie animationData={SpinnerJSON} loop />
                </Box>
              </Box>
            </Section.Content>
          </PanelBox>
        </PanelBox>
      }
    />
  );
}

function NoTestRecommendationsAvailable() {
  return (
    <Intro
      h2="self-assessment"
      h3="No test recommendations available"
      bodyCopy={`We need further clarification before we can give you a sufficient test recommendation.
      Please book your free 15 min consultation here.`}
      buttonText="book a free consultation"
      linkText="Continue to dashboard"
      linkUrl="/dashboard"
      handleClick={() => {
        window.location.href =
          "mailto:support@omnos.me?subject=Consultation&body=I've just completed the symptom questionnaire and have been advised to get in touch for a free 15 min consultation.";
      }}
    />
  );
}

export function Results({ Component, userTestRecommendation, threshold = 0.189999 }) {
  if (userTestRecommendation) {
    if (userTestRecommendation.score > threshold) {
      return <Component userTestRecommendation={userTestRecommendation} />;
    }
  }
  return <NoTestRecommendationsAvailable />;
}
