import ReactSlider from "react-slider";

import { theme } from "core/theme";
import styled from "styled-components";

import Center from "./Center";
import ChevronComponent from "./Chevron";

const HEIGHT = 10;
const THUMB_HEIGHT = 30;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: ${HEIGHT}px;
  max-width: ${props => props.maxWidth};
`;

const StyledThumb = styled.div`
  height: ${THUMB_HEIGHT}px;
  line-height: ${THUMB_HEIGHT}px;
  width: ${30}px;
  text-align: center;
  background-color: black;
  color: red;
  border-radius: 50%;
  cursor: grab;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
`;

const Thumb = (props, state) => (
  <StyledThumb {...props}>
    <Center height="100%">
      <ChevronComponent
        width={10}
        height={10}
        direction="left"
        style={{
          margin: 0
        }}
      />
      <ChevronComponent
        width={10}
        height={10}
        direction="right"
        style={{
          margin: 0
        }}
      />
    </Center>
  </StyledThumb>
);

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${props => (props.index === 0 ? theme.colors.green : theme.colors.haze)};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

export default function Slider({ maxWidth, value, onChange }) {
  return (
    <StyledSlider
      maxWidth={maxWidth}
      renderTrack={Track}
      renderThumb={Thumb}
      onChange={onChange}
      value={value}
    />
  );
}

Slider.defaultProps = {
  value: 0
};
