import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { ReactComponent as StripeLogo } from "images/stripe-grey.svg";

export default function StripeCalloutBanner({ fontSize, mr, updateLink }) {
  return (
    <Box
      borderRadius={5}
      borderColor="lightGrey"
      borderWidth={2}
      borderStyle="solid"
      bg="white"
      p={5}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Box fontFamily="gilroyMedium" fontSize={fontSize} lineHeight={1.5} color="#525F7F" mr={mr}>
        We use Stripe to make sure you get paid on time and to keep your personal bank and details
        secure.{" "}
        {updateLink && (
          <ExternalTextLink target="_self" href={updateLink}>
            Update your Stripe account details
          </ExternalTextLink>
        )}
        .
      </Box>
      <StripeLogo width={150} />
    </Box>
  );
}

StripeCalloutBanner.defaultProps = {
  fontSize: 16,
  mr: 3
};
