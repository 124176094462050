import Box from "components/Box";
import { InternalTextLink } from "components/Links";
import Danger from "images/Danger";
import { ReactComponent as ExpiredMarkersToggleIcon } from "images/expired-markers-toggle.svg";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import Spacer from "tpo/Spacer";

export default function ExpiredMarkers({ learnMore }) {
  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        px: 40,
        pb: 80
      }}
    >
      <Center gap={20}>
        <Danger size={40} />
        <Box as="h2" fontFamily="gilroyBold" fontSize={44}>
          Expired markers
        </Box>
      </Center>
      <Spacer py={[20]} />
      <Center fontFamily="gilroyRegular" fontSize={16}>
        <Box>
          We've introduced a new feature that enables you to switch between two modes for your
          wellness scores. With this update, you can choose to display a wellness score that takes
          into account expiration markers, or opt for a score that disregards them. This toggle
          gives you the flexibility to customise how you view your wellness progress.{" "}
          {/* TODO - add the link */}
          <InternalTextLink>Learn more</InternalTextLink>
        </Box>
      </Center>
      <Spacer py={[20]} />
      <Center>
        <ExpiredMarkersToggleIcon />
      </Center>
    </PanelBoxV2>
  );
}
