import { Fragment } from "react";

import PropTypes from "prop-types";

import Box from "../components/Box";

export default function Stack({ children, itemBoxProps, divider }) {
  return children.map((child, index) => {
    const isFirstItem = index === 0;
    const isLastItem = index === children.length - 1;

    let { pt, pb } = itemBoxProps;

    if (pt === undefined) pt = 20;
    if (pb === undefined) pb = 20;

    if (child.length === 0) return undefined;

    return (
      <Fragment key={index}>
        {!isFirstItem && divider}
        <Box {...itemBoxProps} pt={isFirstItem ? undefined : pt} pb={isLastItem ? undefined : pb}>
          {child}
        </Box>
      </Fragment>
    );
  });
}

Stack.defaultProps = {
  itemBoxProps: {}
};

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  itemBoxProps: PropTypes.object,
  divider: PropTypes.elementType
};
