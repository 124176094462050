import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import { useBasketContext } from "contexts/BasketContext";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useTestProductOptionContext } from "contexts/TestProductOptionContext";
import { GET_CONSUMER_CLINICS } from "graphql/shop";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Stack from "tpo/Stack";

import BaseTestProductModal, { ChooseClinic } from "./BaseTestProductModal";
import HowItWorksPanel from "./HowItWorksPanel";
import TestProductDetailContent from "./ProductDetail";
import { getCarouselImages } from "./TestProductDetailPage";
import TestProductOptions from "./TestProductOptions";
import TruncatedTestProductDetailContent from "./TruncatedProductDetail";
import {
  DISPLAY_CONTEXT_ALWAYS_VISIBLE,
  DISPLAY_CONTEXT_B2B_ONLY,
  DISPLAY_CONTEXT_B2C_ONLY
} from "./constants";
import showFullDetails from "./utils/showFullDetails ";

function ProductDetailView() {
  const { testProduct, userTestRecommendation, selectTestProductId } = useTestProductModalContext();
  const { selectTestProductOptionId } = useTestProductOptionContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  if (!testProduct) return null;

  const testDataFilesWithSamples = testProduct.testDataFiles
    .filter(tdf => tdf.sampleFile)
    .map(tdf => ({
      id: tdf.id,
      name: tdf.name,
      url: tdf.sampleFile,
      extension: tdf.fileType.extension,
      contentType: tdf.fileType.contentType
    }));

  const carouselImages = getCarouselImages(testProduct);

  const fullView = showFullDetails(testProduct);

  if (fullView) {
    return (
      <TestProductDetailContent
        sampleReports={
          testDataFilesWithSamples.length
            ? testDataFilesWithSamples
            : testProduct.reportPdf
            ? [
                {
                  id: "reportPdf",
                  name: "Report Pdf",
                  url: testProduct.reportPdf,
                  extension: "pdf",
                  contentType: "application/pdf"
                }
              ]
            : []
        }
        status={testProduct.status}
        compositeId={testProduct.compositeId}
        practitionerSupportRequired={testProduct.productAudience === "B2B"}
        productAudience={testProduct.productAudience}
        carouselImages={carouselImages}
        name={testProduct.name}
        description={testProduct.content.description}
        shortDescription={testProduct.content.shortDescription}
        categories={testProduct.content.categories.map(cat => cat.name)}
        sampleTypes={testProduct.sampleTypes}
        numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
        processingTime={testProduct.content.processingTime}
        options={
          testProduct.options.length ? (
            <TestProductOptions>
              {testProduct.options.map((option, index) => {
                const hasClinics = !!option.consumerClinicsInfo?.clinics?.length;
                return (
                  <TestProductOptions.Option
                    key={option.id}
                    id={option.id}
                    status={testProduct.status}
                    compositeId={option.compositeId}
                    name={option.testProductOptionFamily.name}
                    description={option.testProductOptionFamily.description}
                    price={
                      hasClinics ? (
                        <Stack gap={0}>
                          <Currency
                            prefix="FROM"
                            prefixFontFamily="gilroyBold"
                            prefixFontSize={16}
                            value={option.consumerClinicsInfo.fromPrice}
                            stacked
                            fontSize={44}
                          />
                          <Box fontFamily="gilroyMedium" fontSize={14}>
                            Includes clinic fee
                          </Box>
                        </Stack>
                      ) : (
                        option.consumerCurrentPrice
                      )
                    }
                    practitionerSupportRequired={testProduct.productAudience === "B2B"}
                    lastOption={index === testProduct.options.length - 1}
                    hasClinics={hasClinics}
                    onFindNearestClinic={() => {
                      selectTestProductOptionId(option.id);
                    }}
                    onAddToBasket={() => {
                      setBasketOpen(true);
                      selectTestProductId(undefined);
                      addItemToBasket({
                        compositeId: option.compositeId
                      });
                    }}
                  />
                );
              })}
            </TestProductOptions>
          ) : null
        }
        howItWorksPanel={
          testProduct.content.howItWorksPanel &&
          (testProduct.content.collectingYourSampleImage ||
            testProduct.content.collectingYourSampleVideo) ? (
            <PanelBoxV2
              maxWidth={600}
              bg="white"
              outer={{
                pt: [60],
                pb: [80],
                px: [20, 20, 80]
              }}
              stacked
              gap={40}
            >
              <HowItWorksPanel
                title={testProduct.content.howItWorksPanel.title}
                body={testProduct.content.howItWorksPanel.body}
                collectingYourSampleImage={testProduct.content.collectingYourSampleImage}
                collectingYourSampleVideo={testProduct.content.collectingYourSampleVideo}
              />
            </PanelBoxV2>
          ) : null
        }
        consumerCurrentPrice={testProduct.consumerCurrentPrice}
        consumerFullPrice={testProduct.consumerFullPrice}
        userTestRecommendation={userTestRecommendation}
        slug={testProduct.slug}
        informationPanels={testProduct.content.informationPanels.filter(
          panel =>
            panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
            panel.displayContext === DISPLAY_CONTEXT_B2C_ONLY
        )}
        biomarkersTested={testProduct.content.biomarkersTested}
        relatedSymptoms={testProduct.content.relatedSymptoms}
        testType={testProduct.content.testType}
        onAddToBasket={() => {
          addItemToBasket({
            compositeId: testProduct.compositeId
          });
          setBasketOpen(true);
          selectTestProductId(undefined);
        }}
        dimensions={{
          yourResultsPanel: {
            panelBox: {
              maxWidth: 600
            },
            title: {
              maxWidth: 600
            },
            cardContainer: {
              gridTemplateColumns: ["1fr"]
            },
            resultsCard: {
              maxHeight: 230
            },
            recommendationsCard: {
              maxHeight: 230
            },
            supportCard: {
              maxHeight: 230
            }
          },
          securityPanel: {
            panelBox: {
              maxWidth: 760
            },
            dataSecurity: {
              gridTemplateColumns: ["1fr"]
            },
            labsYouCanTrust: {
              gridTemplateColumns: ["1fr"]
            }
          }
        }}
      />
    );
  }
  return (
    <TruncatedTestProductDetailContent
      sampleReports={
        testDataFilesWithSamples.length
          ? testDataFilesWithSamples
          : testProduct.reportPdf
          ? [
              {
                id: "reportPdf",
                name: "Report Pdf",
                url: testProduct.reportPdf,
                extension: "pdf",
                contentType: "application/pdf"
              }
            ]
          : []
      }
      carouselImages={carouselImages}
      status={testProduct.status}
      compositeId={testProduct.compositeId}
      practitionerSupportRequired={testProduct.productAudience === "B2B"}
      productAudience={testProduct.productAudience}
      name={testProduct.name}
      description={testProduct.content.description}
      shortDescription={testProduct.content.shortDescription}
      categories={testProduct.content.categories.map(cat => cat.name)}
      sampleTypes={testProduct.sampleTypes}
      numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
      processingTime={testProduct.content.processingTime}
      options={
        testProduct.options.length ? (
          <TestProductOptions>
            {testProduct.options.map((option, index) => {
              const hasClinics = !!option.consumerClinicsInfo?.clinics?.length;
              return (
                <TestProductOptions.Option
                  key={option.id}
                  id={option.id}
                  status={testProduct.status}
                  compositeId={option.compositeId}
                  name={option.testProductOptionFamily.name}
                  description={option.testProductOptionFamily.description}
                  price={
                    hasClinics ? (
                      <Stack gap={0}>
                        <Currency
                          prefix="FROM"
                          prefixFontFamily="gilroyBold"
                          prefixFontSize={16}
                          value={option.consumerClinicsInfo.fromPrice}
                          stacked
                          fontSize={44}
                        />
                        <Box fontFamily="gilroyMedium" fontSize={14}>
                          Includes clinic fee
                        </Box>
                      </Stack>
                    ) : (
                      option.consumerCurrentPrice
                    )
                  }
                  practitionerSupportRequired={testProduct.productAudience === "B2B"}
                  lastOption={index === testProduct.options.length - 1}
                  hasClinics={hasClinics}
                  onFindNearestClinic={() => {
                    selectTestProductOptionId(option.id);
                  }}
                  onAddToBasket={() => {
                    setBasketOpen(true);
                    selectTestProductId(undefined);
                    addItemToBasket({
                      compositeId: option.compositeId
                    });
                  }}
                />
              );
            })}
          </TestProductOptions>
        ) : null
      }
      consumerCurrentPrice={testProduct.consumerCurrentPrice}
      consumerFullPrice={testProduct.consumerFullPrice}
      slug={testProduct.slug}
      informationPanels={testProduct.content.informationPanels.filter(
        panel =>
          panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
          panel.displayContext === DISPLAY_CONTEXT_B2B_ONLY
      )}
      biomarkersTested={testProduct.content.biomarkersTested}
      relatedSymptoms={testProduct.content.relatedSymptoms}
      testType={testProduct.content.testType}
      onAddToBasket={() => {
        addItemToBasket({
          compositeId: testProduct.compositeId
        });
        setBasketOpen(true);
        selectTestProductId(undefined);
      }}
    />
  );
}

function ChooseClinicView() {
  const {
    testProductOption,
    selectTestProductId,
    selectTestProductOptionId
  } = useTestProductModalContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  return (
    <ChooseClinic
      onAddToBasket={({ clinic }) => {
        addItemToBasket({
          compositeId: testProductOption.compositeId,
          clinicLocationId: clinic.id
        });
        setBasketOpen(true);
        selectTestProductId(undefined);
        selectTestProductOptionId(undefined);
      }}
      query={GET_CONSUMER_CLINICS}
      testProductOptionPriceField="consumerCurrentPrice"
      clinicLocationsField="consumerClinicLocations"
    />
  );
}

export default function TestProductModal({ bg }) {
  return (
    <BaseTestProductModal
      bg={bg}
      clinicsInfoField="consumerClinicsInfo"
      chooseClinicView={<ChooseClinicView />}
      productDetailView={<ProductDetailView />}
    />
  );
}
