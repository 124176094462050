import Box from "../components/Box";
import ProgressBarCircle from "./ProgressBarCircle";
import Stack from "./Stack";

function WellnessScore({ wellnessScore, scoreInterpretations }) {
  const interpretationFound = scoreInterpretations.find(({ score }) => wellnessScore <= score);

  const message = interpretationFound ? interpretationFound.name : "";

  return (
    <Box width={280}>
      <ProgressBarCircle percentValue={wellnessScore}>
        <Stack gap={10}>
          <Box as="h2" color="white" fontFamily="gilroyBold" fontSize={["28px"]}>
            Wellness Score
          </Box>
          <Box
            as="h3"
            color="white"
            textAlign="center"
            textTransform="uppercase"
            fontFamily="gilroyBold"
            fontSize="18px"
          >
            {message}
          </Box>
        </Stack>
      </ProgressBarCircle>
    </Box>
  );
}

export default WellnessScore;
