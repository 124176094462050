import { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import { Spinner } from "components/Buttons";
import Divider from "components/Divider";
import { InternalTextLink } from "components/Links";
import { FadeTransition } from "components/animations/FadeTransition";
import CreatePatientForm from "components/organisations/checkout/forms/CreatePatientForm";
import { BasketTotals } from "components/organisations/checkout/ui/BasketTotals";
import PatientDetails from "components/organisations/checkout/ui/PatientDetails";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import RefetchEvent from "events/refetch";
import {
  CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION,
  CREATE_STOCK_PAYMENT_INVOICE_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import { ORGANISATION_ORDERS_LIST_QUERY } from "graphql/organisations/queries/orders";
import {
  ORGANISATION_STOCK_DETAILS_QUERY,
  ORGANISATION_STOCK_LIST_QUERY
} from "graphql/organisations/queries/stock";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Modal from "tpo/Modal";
import SelectPatient from "tpo/SelectPatient";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function RegisterStockPanel({ stock, onComplete }) {
  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);
  const { organisation } = useOrganisationContext();
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [registerMutationQuery, setRegisterMutationQuery] = useState(
    CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION
  );

  const patient = organisation.patients.find(p => p.id === selectedPatientId);
  const requiresCheckout = !organisation?.automaticInvoicingEnabled;

  const [registerMutation, { data, loading, error }] = useMutation(registerMutationQuery, {
    variables: {
      input: {
        stock: stock?.id,
        patient: patient?.platformUserProfile?.pk,
        organisation: organisation?.id
      }
    },
    refetchQueries: [
      {
        query: ORGANISATION_STOCK_DETAILS_QUERY,
        variables: {
          id: stock?.id,
          organisation: parseInt(organisation?.id)
        }
      },
      {
        query: ORGANISATION_STOCK_LIST_QUERY,
        variables: {
          organisation: parseInt(organisation?.id),
          page: 1,
          orderBy: "-created_from_order__checkout_date"
        }
      },
      {
        query: ORGANISATION_ORDERS_LIST_QUERY,
        variables: {
          organisation: parseInt(organisation?.id),
          page: 1,
          orderBy: "-checkout_date"
        }
      }
    ]
  });

  useEffect(() => {
    if (organisation?.automaticInvoicingEnabled) {
      setRegisterMutationQuery(CREATE_STOCK_PAYMENT_INVOICE_MUTATION);
    } else {
      setRegisterMutationQuery(CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION);
    }
  }, [organisation]);

  useEffect(() => {
    if (data?.createStockPaymentCheckoutSessionMutation?.paymentCheckoutSession?.url) {
      RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
      window.location.href =
        data.createStockPaymentCheckoutSessionMutation.paymentCheckoutSession.url;
    }
  }, [data]);

  const handleSubmit = () => {
    registerMutation();
  };

  return (
    <Stack gap={40}>
      <Box>
        <Box fontFamily="gilroyBold" fontSize={28}>
          Register this kit to a patient
        </Box>
        <Spacer py={2} />
        <Box fontFamily="gilroyMedium" fontSize={16}>
          Once you register this kit to a patient your business will be invoiced for the remaining
          balance of the test.
        </Box>
      </Box>
      <SelectPatient
        patients={organisation.patients}
        value={selectedPatientId}
        onChange={setSelectedPatientId}
      />
      <ButtonV2
        color="green"
        onClick={() => setShowCreatePatientModal(true)}
        rightIcon={<ChevronComponent />}
        maxWidth={310}
      >
        create a new patient
      </ButtonV2>
      <FadeTransition in={patient}>
        <PatientDetails patient={patient} />
      </FadeTransition>
      <Divider color="#1A1A1A" />
      <Box fontFamily="gilroyBold" fontSize={28}>
        Remaining Balance
      </Box>
      {requiresCheckout && (
        <Box>
          <Box fontFamily="gilroyMedium" fontSize={16}>
            {/* not sure where "automatic invoicing enabled" should link to */}
            You don’t currently have{" "}
            <InternalTextLink>automatic invoicing enabled</InternalTextLink>. Activate in your
            organisation settings to differ payment via invoice in 30 days. Alternatively you can
            pay with a card to complete this order now.
          </Box>
        </Box>
      )}
      <Stack width="100%" alignItems="flex-end" gap={0}>
        <Box>
          <Currency prefix={"TEST TOTAL"} value={stock.remainingCost} fontSize={28} />
          <Spacer py={1} />
        </Box>
        <Box>
          <Currency prefix={"RETURN SHIPPING"} value={stock.returnShippingCost} fontSize={28} />
          <Spacer py={1} />
        </Box>
        <Box>
          <Currency
            prefix={"TOTAL"}
            value={stock.remainingCost + stock.returnShippingCost}
            fontSize={44}
          />
          <Spacer py={1} />
        </Box>
      </Stack>
      {loading ? (
        <Center width="150px" height="57px">
          <Spinner margin="auto" />
        </Center>
      ) : (
        <Box width="100%" flexDirection="row" display="flex">
          <Box flex={1} />
          <ButtonV2
            color="green"
            rightIcon={loading ? null : <ChevronComponent />}
            mr="auto"
            onClick={() => handleSubmit()}
            disabled={!patient || loading}
          >
            {requiresCheckout ? "pay & register" : "register & pay"}
          </ButtonV2>
        </Box>
      )}
      <FadeTransition in={error}>
        <Box color="red" fontSize={16}>
          {error?.message}
        </Box>
      </FadeTransition>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [2, 2, 20],
          pb: 20
        }}
        bg="white"
        show={showCreatePatientModal}
        close={() => setShowCreatePatientModal(false)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <Box maxWidth={760} mx="auto">
          <CreatePatientForm
            handleSuccess={({ data: { id } }) => {
              setSelectedPatientId(id);
              setShowCreatePatientModal(false);
            }}
          />
        </Box>
      </Modal>
    </Stack>
  );
}
