import { createContext, useContext } from "react";

// for passing along stuff for the variables to be used in executing gql queries
export const ExecutionContext = createContext();

export function useExecutionContext() {
  return useContext(ExecutionContext);
}

export function ExecutionProvider({ children, context }) {
  return <ExecutionContext.Provider value={context}>{children}</ExecutionContext.Provider>;
}
