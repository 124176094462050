import { useContext } from "react";

import Box from "components/Box";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import { ModalInstanceContext } from "tpo/Modal";

import BiomarkersAndSymptomsList from "./BiomarkersAndSymptomsList";
import Carousel from "./Carousel";
import MainDetails from "./MainDetails";
import SampleReports from "./SampleReports";
import TestProductInformationPanels from "./TestProductInformationPanels";

// TODO - change Component name to the file name
export default function TruncatedTestProductDetailContent({
  practitionerSupportRequired,
  name,
  description,
  categories,
  sampleTypes,
  numOfBiomarkersTested,
  processingTime,
  options,
  consumerCurrentPrice,
  consumerFullPrice,
  relatedSymptoms,
  biomarkersTested,
  sampleReports,
  informationPanels,
  onProductOptionSelection,
  testType,
  dimensions = {},
  onAddToBasket,
  status,
  carouselImages
}) {
  const modalInstanceContext = useContext(ModalInstanceContext);
  const withInModal = !!modalInstanceContext;

  return (
    <>
      <Box display="none" data-component-name="TruncatedProductDetail" />
      {!!carouselImages.length && (
        <PanelBoxV2
          maxWidth={1020}
          outer={{
            bg: "white",
            pt: withInModal ? [50, 50, 0] : [50, 50, 80],
            pb: [60],
            px: [20, 20, "5.5vw"]
          }}
        >
          <Box bg="haze" p={20}>
            <Carousel images={carouselImages} bg="haze" />
          </Box>
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "haze",
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, "5.5vw"]
        }}
      >
        <Box>
          <MainDetails
            status={status}
            practitionerSupportRequired={practitionerSupportRequired}
            name={name}
            description={description}
            categories={categories}
            sampleTypes={sampleTypes}
            numOfBiomarkersTested={numOfBiomarkersTested}
            processingTime={processingTime}
            options={options}
            priceElement={
              <Group gap={10}>
                {consumerFullPrice !== undefined && consumerFullPrice > consumerCurrentPrice && (
                  <Currency fontSize={[44]} value={consumerFullPrice} color="red" strikethrough />
                )}
                <Currency fontSize={[44]} value={consumerCurrentPrice} />
              </Group>
            }
            onProductOptionSelection={onProductOptionSelection}
            productOptionCardStackBg="white"
            testType={testType}
            onAddToBasket={onAddToBasket}
          />
        </Box>
      </PanelBoxV2>
      {!!(relatedSymptoms?.length || biomarkersTested?.length) && (
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "partners",
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            px: [20, 20, "5.5vw"]
          }}
        >
          <BiomarkersAndSymptomsList
            relatedSymptoms={relatedSymptoms}
            biomarkersTested={biomarkersTested}
          />
        </PanelBoxV2>
      )}
      {!!sampleReports?.length && (
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "white",
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            px: [20, 20, "5.5vw"]
          }}
        >
          <SampleReports sampleReports={sampleReports} />
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "haze",
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, "5.5vw"]
        }}
        stacked
        gap={40}
      >
        <TestProductInformationPanels panels={informationPanels} />
      </PanelBoxV2>
    </>
  );
}
