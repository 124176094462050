import React from "react";

import PropTypes from "prop-types";
// eslint-disable-next-line
import styled from "styled-components/macro";

import { theme } from "../core/theme";
import Box from "./Box";
import Stack from "./Stack";

function RadioButton({ checked, handleClick, label, error }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={e => {
        e.preventDefault();
        handleClick();
      }}
      as="button"
      data-component-name="Radio button"
    >
      <Box position="relative" mr="10px" flexShrink={0}>
        <Box
          display="block"
          borderRadius="50%"
          width={40}
          height={40}
          borderWidth="2px"
          borderStyle="solid"
          borderColor={theme.colors.green}
          checked={checked}
        />
        <Box
          position="absolute"
          top="5px"
          left="5px"
          width={30}
          height={30}
          backgroundColor={theme.colors.green}
          borderRadius="50%"
          opacity={checked ? 1 : 0}
          style={{ pointerEvents: "none", transition: "opacity 250ms" }}
        />
      </Box>
      <span style={{ textAlign: "left", color: error && theme.colors.red }}>{label}</span>
    </Box>
  );
}

function RadioGroup({
  options,
  value,
  handleChange,
  error,
  multiSelect,
  maxSelect,
  initialValue,
  fieldName,
  label,
  labelProps,
  boxProps = {}
}) {
  return (
    <Box id={fieldName} {...boxProps}>
      <Box {...labelProps}>{label}</Box>
      <Stack
        itemBoxProps={{
          pt: "5px",
          pb: "5px"
        }}
        id={fieldName}
      >
        {options.map((option, optionIndex) => {
          let checked;
          if (multiSelect) {
            checked = value !== null && value.indexOf(option.value) !== -1;
          } else {
            checked = option.value === value;
          }
          return (
            <RadioButton
              key={optionIndex}
              checked={checked}
              label={option.name}
              error={error}
              handleClick={() => {
                if (multiSelect) {
                  let newValue;
                  if (checked) {
                    newValue = value.filter(item => {
                      return item !== option.value;
                    });
                  } else {
                    newValue = [...value, option.value];
                  }
                  if (!maxSelect | (newValue.length <= maxSelect)) {
                    handleChange(newValue);
                  }
                } else {
                  if (value === option.value) {
                    handleChange(initialValue);
                  } else {
                    handleChange(option.value);
                  }
                }
              }}
            />
          );
        })}
      </Stack>
    </Box>
  );
}

const valuePropTypes = [PropTypes.string, PropTypes.bool, PropTypes.number];

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType(valuePropTypes).isRequired,
      requiresDetails: PropTypes.bool
    })
  ).isRequired,
  value: PropTypes.oneOfType([...valuePropTypes, PropTypes.array]),
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  multiSelect: PropTypes.bool,
  maxSelect: PropTypes.number,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number
  ])
};

RadioGroup.defaultProps = {
  multiSelect: false
};

export default RadioGroup;
