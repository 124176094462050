import Circle from "components/Circle";
import Group from "tpo/Group";

export default function OrganisationBadge({ fullName, initials, ...props }) {
  return (
    <Group
      bg="disabled"
      alignItems="center"
      fontFamily="gilroyMedium"
      fontSize={14}
      p={1}
      pl="16px"
      borderRadius="100px"
      gap={10}
      data-component-name="OrganisationBadge"
      {...props}
    >
      <span data-testid="fullName">{fullName}</span>
      <Circle
        background="linear-gradient(123deg, #5220DD 39.48%, #9747FF 100%)"
        size={30}
        fontFamily="gilroyBold"
        fontSize={12}
        color="white"
        data-testid="initials"
      >
        {initials}
      </Circle>
    </Group>
  );
}
