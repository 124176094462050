import { useState } from "react";

import { useDebounce } from "./useDebounce";

export default function useListControls({ sortOptions, defaultSort }) {
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [sort, setSort] = useState(defaultSort || sortOptions[0].value);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 200);

  return {
    sortMenuOpen,
    setSortMenuOpen,
    sort,
    setSort,
    search,
    setSearch,
    debouncedSearch,
    sortOptions
  };
}
