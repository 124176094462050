import { useCallback, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color, layout, space, typography, system } from "styled-system";

import Box from "./Box";

export const ExternalTextLink = styled.a`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${({ underline }) => {
    if (underline === true) return "text-decoration: underline;";
  }}
  /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  ${({ noWrap }) => {
    if (noWrap === true) return "white-space: nowrap";
  }}
`;

ExternalTextLink.defaultProps = {
  underline: true,
  color: "purple",
  rel: "noopener noreferrer",
  target: "_blank"
};

/**
 * TODO -
 * Perhaps change this BaseInternalTextLink
 * so it just uses a styled-system Box component.
 * An anchor element can be without a href attribute
 * so we don't need to use <a> like this (I don't think so anyway)
 */

export function BaseInternalTextLink({
  className,
  children,
  href,
  fontFamily,
  fontSize,
  newTab,
  download
}) {
  const history = useHistory();

  let props = { download, href };
  if (newTab) {
    props = {
      ...props,
      target: "_blank",
      rel: "noopener noreferrer"
    };
  } else {
    props = {
      ...props,
      onClick: e => {
        e.preventDefault();
        history.push(href);
      }
    };
  }

  return (
    <a className={className} fontFamily={fontFamily} fontSize={fontSize} {...props}>
      {children}
    </a>
  );
}

BaseInternalTextLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string
};

export const InternalTextLink = styled(BaseInternalTextLink)`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${system({
    textTransform: {
      property: "text-transform"
    }
  })}
  ${({ underline }) => {
    if (underline === true) return "text-decoration: underline";
  }}
`;

InternalTextLink.defaultProps = {
  underline: true,
  color: "purple"
};

export function DownloadCrossOriginFile({
  trigger, // use a component which doesn't link to a new page
  fileUrl,
  contentType,
  fileName,
  containerProps
}) {
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState({});
  const aRef = useRef();

  const startDownload = useCallback(() => {
    if (loading || !fileUrl || !isEmpty(download)) return;
    setLoading(true);
    fetch(fileUrl, {
      method: "GET",
      headers: {
        "Content-Type": contentType
      }
    })
      .then(response => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        setLoading(false);
        setDownload({
          download: fileName,
          href: url,
          style: {
            display: "none"
          }
        });
      })
      .catch(error => console.log("Error encountered downloading the file", fileUrl, error));
  }, [fileUrl, setLoading, loading, contentType, fileName, setDownload, download]);

  useEffect(() => {
    if (download && aRef.current) {
      aRef.current.click();
    }
  }, [download]);

  return (
    <Box
      {...containerProps}
      onClick={() => {
        if (loading) return;
        if (isEmpty(download)) {
          startDownload();
          return;
        }
        if (aRef.current) {
          aRef.current.click();
        }
      }}
    >
      {trigger}
      {!isEmpty(download) && (
        <a ref={aRef} {...download}>
          download file link
        </a>
      )}
    </Box>
  );
}

DownloadCrossOriginFile.defaultProps = {
  contentType: "application/pdf"
};
