import { forwardRef } from "react";

import InputWrapper from "tpo/InputWrapper";

const Textarea = forwardRef(
  ({ name, error, label, value, onChange, rows, placeholder, style, maxLength }, ref) => (
    <InputWrapper
      label={label}
      input={
        <textarea
          ref={ref}
          name={name}
          onChange={onChange}
          value={value}
          rows={rows}
          placeholder={placeholder}
          style={style}
          maxLength={maxLength}
        />
      }
      error={error}
    />
  )
);

export default Textarea;
