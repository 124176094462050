import { createContext, useContext } from "react";

import useOrganisation from "hooks/organisations/useOrganisation";

const OrganisationContext = createContext();

export function OrganisationProvider({ children }) {
  const api = useOrganisation();
  return <OrganisationContext.Provider value={api}>{children}</OrganisationContext.Provider>;
}

export function useOrganisationContext() {
  return useContext(OrganisationContext);
}
