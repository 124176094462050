import React from "react";

import { LOGIN_URL } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import Box from "./Box";
import { InternalTextLink } from "./Links";

function ResetPasswordCompletePage() {
  useDocTitle("Reset password complete");
  return (
    <AccountPage heading="Password reset complete">
      <Box pt={40}>
        Your password has been successfully reset. Please click{" "}
        <InternalTextLink href={LOGIN_URL}>here</InternalTextLink> to login.
      </Box>
    </AccountPage>
  );
}

export default ResetPasswordCompletePage;
