import React from "react";

import useDocTitle from "../hooks/use-doc-title";
import IFramePage from "./IFramePage";

function TermsOfUsePage() {
  useDocTitle("Terms of use");
  return <IFramePage title="Terms of  Use" src="/terms-of-use.html" />;
}

export default TermsOfUsePage;
