import styled from "styled-components";
import { space } from "styled-system";

const Divider = styled.div`
  ${space}
  height: 1px;
  width: ${props => {
    return props.width;
  }};
  ${props => {
    if (props.lineStyle === "dotted") {
      return `
        background-image: linear-gradient(
          90deg,
          ${props.color},
          ${props.color} 60%,
          transparent 60%,
          transparent 100%
        );
        background-position: center;
        background-repeat: repeat-x;
        background-size: 7px 1px;
      `;
    } else {
      return `background-color: ${props.color};`;
    }
  }}
`;

Divider.defaultProps = {
  color: "rgba(0,0,0,0.1)",
  width: "100%"
};

export default Divider;
