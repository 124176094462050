import { createContext, useContext, useEffect, useMemo, useState } from "react";

import * as Sentry from "@sentry/browser";

export const LocalStorageContext = createContext();

export function LocalStorageContextProvider({ children }) {
  const api = useMemo(
    () => ({
      getStorage: key => {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
      },
      setStorage: (key, value) => {
        const json = JSON.stringify(value);
        return localStorage.setItem(key, json);
      }
    }),
    []
  );

  return <LocalStorageContext.Provider value={api}>{children}</LocalStorageContext.Provider>;
}

export function useLocalStorageContext() {
  return useContext(LocalStorageContext);
}

export function usePersistentState(key, initialValue = null) {
  const { getStorage, setStorage } = useLocalStorageContext();

  const [state, setState] = useState(() => {
    try {
      const value = getStorage(key);
      return value !== null ? value : initialValue;
    } catch (error) {
      console.error("Problem getting value from local storage", error);
      Sentry.captureException(error);
      return initialValue;
    }
  });

  useEffect(() => {
    setStorage(key, state);
  }, [key, state, setStorage]);

  return [state, setState];
}
