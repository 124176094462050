import { useCallback, useEffect, useState } from "react";

import { FormProvider } from "react-hook-form";

import Box from "components/Box";
import { FadeTransition } from "components/animations/FadeTransition";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { UPDATE_ORGANISATION_DETAILS_MUTATION } from "graphql/organisations/mutations";
import { ORGANISATION_LIST_QUERY } from "graphql/organisations/queries/organisation";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import NativeSelect from "tpo/NativeSelect";
import ShippingCountrySelect from "tpo/ShippingCountrySelect";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function UpdateOrganisationDetailsForm({
  handleSuccess,
  handleFailure,
  mutationOptions,
  canEdit,
  ...props
}) {
  const { organisation } = useOrganisationContext();

  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [detailsChanged, setDetailsChanged] = useState(false);

  useEffect(() => {
    if (!organisation) return;

    setOnboardingComplete(organisation?.onboardingStatus === "complete");
  }, [organisation]);

  const { methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: UPDATE_ORGANISATION_DETAILS_MUTATION,
    mutationName: "updateOrganisationDetailsMutation",
    mode: "onBlur",
    onSuccess: handleSuccess,
    onFailure: handleFailure,
    mutationOptions: {
      refetchQueries: [
        {
          query: ORGANISATION_LIST_QUERY
        }
      ],
      ...mutationOptions
    },
    defaultValues: {
      name: organisation?.name || "",
      line1: organisation?.line1 || "",
      line2: organisation?.line2 || "",
      city: organisation?.city || "",
      postcode: organisation?.postcode || "",
      country: organisation?.country?.isoCode || "",
      website: organisation?.website || "",
      vatNumber: organisation?.vatNumber || "",
      companyNumber: organisation?.companyNumber || "",
      contactNumber: organisation?.contactNumber || "",
      contactEmail: organisation?.contactEmail || ""
    },
    ...props
  });

  const { reset } = methods;

  useEffect(() => {
    if (!organisation) {
      return;
    }
    reset({
      name: organisation?.name || "",
      line1: organisation?.line1 || "",
      line2: organisation?.line2 || "",
      city: organisation?.city || "",
      postcode: organisation?.postcode || "",
      country: organisation?.country?.isoCode || "",
      website: organisation?.website || "",
      vatNumber: organisation?.vatNumber || "",
      companyNumber: organisation?.companyNumber || "",
      contactNumber: organisation?.contactNumber || "",
      contactEmail: organisation?.contactEmail || ""
    });
  }, [organisation, reset]);

  const handleSubmitOrganisationDetails = useCallback(
    input => {
      if (!organisation) {
        return;
      }
      setDetailsChanged(false);
      onSubmit({
        id: parseInt(organisation?.id),
        ...input
      });
    },
    [organisation, onSubmit]
  );

  const watchCountry = methods.watch("country");
  useEffect(() => {
    if (!organisation || !watchCountry) {
      return;
    }
    if (watchCountry === organisation?.country?.isoCode) {
      return;
    }
    setDetailsChanged(true);
  }, [watchCountry]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitOrganisationDetails)}>
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          Business details
        </Box>
        <Spacer py={40 / 2} />
        <Stack gap={40}>
          <Box fontFamily="gilroyBold" fontSize={18}>
            Business address
          </Box>
          <ControlledFormField
            name="name"
            Component={FloatingLabelInput}
            label="Business name"
            onFocus={() => setDetailsChanged(true)}
          />
          <ControlledFormField
            name="line1"
            Component={FloatingLabelInput}
            label="Business address line 1"
            onFocus={() => setDetailsChanged(true)}
            editable={!onboardingComplete && canEdit}
            inputProps={{
              color: onboardingComplete ? "grey" : "black"
            }}
          />
          <ControlledFormField
            name="line2"
            Component={FloatingLabelInput}
            label="Business address line 2"
            onFocus={() => setDetailsChanged(true)}
            editable={!onboardingComplete && canEdit}
            inputProps={{
              color: onboardingComplete ? "grey" : "black"
            }}
          />
          <ControlledFormField
            name="city"
            Component={FloatingLabelInput}
            label="City"
            onFocus={() => setDetailsChanged(true)}
            editable={!onboardingComplete && canEdit}
            inputProps={{
              color: onboardingComplete ? "grey" : "black"
            }}
          />
          <ControlledFormField
            name="postcode"
            Component={FloatingLabelInput}
            label="Postcode"
            onFocus={() => setDetailsChanged(true)}
            editable={!onboardingComplete && canEdit}
            inputProps={{
              color: onboardingComplete ? "grey" : "black"
            }}
          />
          {onboardingComplete || canEdit ? (
            <ControlledFormField
              Component={FloatingLabelInput}
              name="country"
              label="Country"
              editable={false}
              value={organisation?.country?.name}
              inputProps={{
                color: "grey"
              }}
            />
          ) : (
            <ControlledFormField
              name="country"
              Component={ShippingCountrySelect}
              getOptions={countries => {
                const all = countries.map(item => ({
                  value: item.isoCode,
                  label: item.name
                }));
                const ukOnly = all.filter(item => item.value === "GB");
                const rest = all.filter(item => item.value !== "GB");
                return [
                  ...ukOnly,
                  ...rest.sort(({ value: v1 }, { value: v2 }) => v1.localeCompare(v2))
                ];
              }}
            />
          )}
        </Stack>
        {onboardingComplete && canEdit && (
          <>
            <Box fontFamily="gilroyBold" fontSize={18} my={3} mt={7}>
              Business details
            </Box>
            <Stack gap={40}>
              <ControlledFormField
                name="website"
                Component={FloatingLabelInput}
                label="Website"
                onFocus={() => setDetailsChanged(true)}
                editable={!onboardingComplete}
                inputProps={{
                  color: onboardingComplete ? "grey" : "black"
                }}
              />
              <ControlledFormField
                name="contactNumber"
                Component={FloatingLabelInput}
                label="Contact Number"
                onFocus={() => setDetailsChanged(true)}
                editable={!onboardingComplete}
                inputProps={{
                  color: onboardingComplete ? "grey" : "black"
                }}
              />
              <ControlledFormField
                name="contactEmail"
                Component={FloatingLabelInput}
                label="Contact Email"
                onFocus={() => setDetailsChanged(true)}
                editable={!onboardingComplete}
                inputProps={{
                  color: onboardingComplete ? "grey" : "black"
                }}
              />
              <ControlledFormField
                name="vatNumber"
                Component={FloatingLabelInput}
                label="VAT Number"
                onFocus={() => setDetailsChanged(true)}
              />
              <ControlledFormField
                name="companyNumber"
                Component={FloatingLabelInput}
                label="Company Number"
                onFocus={() => setDetailsChanged(true)}
              />
            </Stack>
          </>
        )}
        <FadeTransition in={detailsChanged}>
          <Group mt={40} justifyContent="center">
            <ButtonV2
              color="red"
              type="button"
              onClick={() => {
                methods.reset({
                  name: organisation?.name || "",
                  line1: organisation?.line1 || "",
                  line2: organisation?.line2 || "",
                  city: organisation?.city || "",
                  postcode: organisation?.postcode || "",
                  country: organisation?.country?.isoCode || "",
                  website: organisation?.website || "",
                  vatNumber: organisation?.vatNumber || "",
                  companyNumber: organisation?.companyNumber || "",
                  contactNumber: organisation?.contactNumber || "",
                  contactEmail: organisation?.contactEmail || ""
                });
                setDetailsChanged(false);
              }}
              mr={20}
            >
              Cancel
            </ButtonV2>
            <ButtonV2 color="green" type="submit" loading={methods.formState.isSubmitting}>
              Save
            </ButtonV2>
          </Group>
        </FadeTransition>
      </form>
    </FormProvider>
  );
}
