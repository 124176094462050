import React from "react";

import { useHistory } from "react-router-dom";

import { LOGIN_URL, REQUEST_PASSWORD_RESET_DONE, SIGNUP_URL } from "../core/urls";
import { REQUEST_PASSWORD_RESET_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import Box from "./Box";
import Form from "./Form";
import Input from "./Input";
import { AuthAction } from "./LoginPage";
import ResendVerificationEmailButton from "./ResendVerificationEmailButton";

function RequestPasswordResetPage() {
  useDocTitle("Request password reset");
  const history = useHistory();
  return (
    <AccountPage heading="Password reset">
      <Box pt={40}>
        <Form
          mutation={REQUEST_PASSWORD_RESET_MUTATION}
          handleSubmitted={() => {
            history.push(REQUEST_PASSWORD_RESET_DONE);
          }}
          data={[
            {
              fields: [
                {
                  name: "email",
                  initialValue: "",
                  widget: {
                    component: Input,
                    props: {
                      type: "email",
                      autoComplete: "email",
                      label: "Email address"
                    }
                  }
                }
              ]
            }
          ]}
          Button={ResendVerificationEmailButton}
          formWrapperProps={{
            mb: 30
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <AuthAction url={LOGIN_URL}>Click here to log in</AuthAction>
        <AuthAction url={SIGNUP_URL}>Click here to sign up</AuthAction>
      </Box>
    </AccountPage>
  );
}

export default RequestPasswordResetPage;
