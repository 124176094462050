import { gql } from "@apollo/client";
import Box from "components/Box";
import Loading from "components/Loading";
import { TESTS_URL, getTestUrl } from "core/urls";
import useWindowSize from "hooks/use-window-size";
import { useDataLoader } from "hooks/useDataLoader";
import styled from "styled-components";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import LinkWrapper from "tpo/LinkWrapper";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

import TestRecommendationBadge from "./TestRecommendationBadge";

function Card({
  className,
  name,
  shortDescription,
  price,
  slug,
  productFamily,
  dataComponentName,
  image,
  badge
}) {
  const url = getTestUrl(slug, productFamily.slug);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 645;

  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      dataComponentName={dataComponentName}
    >
      <Box bg="haze" display="flex" flexDirection="column" flex={1}>
        <Box
          height={isMobile ? "226px" : "306px"}
          width={isMobile ? "310px" : "420px"}
          position="relative"
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain"
          }}
        >
          {!!badge && (
            <Box position="absolute" zIndex={1}>
              {badge}
            </Box>
          )}
        </Box>
        <Box flex={1} display="flex" flexDirection="column" p={20} borderTop="1px solid #fff">
          <Box fontFamily="gilroyBold" fontSize={18}>
            {name}
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={14}>
            {shortDescription}
          </Box>
          <Box mt="auto">
            <Box display="flex" alignItems="end" justifyContent="space-between" mt={20}>
              <Currency value={price} fontSize={28} />
              <ButtonV2 as={LinkWrapper} color="dark" to={url} py={2} px={20}>
                learn more
              </ButtonV2>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const OMNOS_TEST_PRODUCTS_QUERY = gql`
  query OmnosTestProductsQuery($productAudience: String, $orderBy: String) {
    consumerTestProducts(productAudience: $productAudience, orderBy: $orderBy) {
      id
      name
      slug
      userTestRecommendationRank
      productFamily {
        id
        slug
      }
      consumerCurrentPrice
      content {
        id
        image1
      }
    }
  }
`;

const Cards = styled(Group)`
  .card:last-child {
    margin-right: 20px;
  }
`;

export default function DiscoverOtherTestsPanel({ title, subtitle }) {
  const { data, loading } = useDataLoader({
    query: OMNOS_TEST_PRODUCTS_QUERY,
    variables: {
      productAudience: "B2C",
      orderBy: "user_test_recommendation_rank"
    }
  });

  const testProducts = data?.consumerTestProducts;
  const orderedTestProducts = testProducts;

  const showLoadingIcon = !data || loading;

  return (
    <>
      <Box maxWidth={760} mx="auto" px={[20, 20, 0]}>
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          {title}
        </Box>
        <Spacer py={[2, 2, 15]} />
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          {subtitle}
        </Box>
      </Box>
      {showLoadingIcon ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <Cards gap={20} overflowX="auto" pl={20}>
          {orderedTestProducts
            .filter(tp => tp.productFamily)
            .map(tp => (
              <Card
                data-component-name="DiscoverTests:TestProductCard"
                key={tp.id}
                className="card"
                name={tp.name}
                shortDescription={tp.content.shortDescription}
                price={tp.consumerCurrentPrice}
                slug={tp.slug}
                productFamily={tp.productFamily}
                image={tp.content.image1}
                badge={
                  tp.userTestRecommendationRank !== null && tp.userTestRecommendationRank < 6 ? (
                    <TestRecommendationBadge
                      bg="brand"
                      color="white"
                      rank={tp.userTestRecommendationRank}
                    />
                  ) : null
                }
              />
            ))}
        </Cards>
      )}
      <ButtonV2
        as={LinkWrapper}
        color="dark"
        rightIcon={<ChevronComponent />}
        mx="auto"
        to={TESTS_URL}
      >
        see all omnos tests
      </ButtonV2>
    </>
  );
}

DiscoverOtherTestsPanel.defaultProps = {
  title: "Discover our other tests",
  subtitle:
    "Our series of specialised tests are designed to cover the key areas of health and wellbeing."
};
