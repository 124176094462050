import { gql } from "@apollo/client";

import { USER_DATAPOINT_FIELDS_FOR_COT, USER_RESULT_FIELDS_FOR_COT } from "./tpo/results/types";

export const USER_DATAPOINTS_QUERY = gql`
  query UserDatapointsQuery($datapointId: ID!, $cot: Boolean, $userId: ID) {
    userDatapoints(datapointId: $datapointId, cot: $cot, userId: $userId) {
      ...UserDatapointFieldsForCot
    }
  }
  ${USER_DATAPOINT_FIELDS_FOR_COT}
`;

export const USER_RESULTS_QUERY = gql`
  query UserResultsQuery($resultId: ID!, $cot: Boolean, $userId: ID) {
    userResults(resultId: $resultId, cot: $cot, userId: $userId) {
      ...UserResultFieldsForCot
    }
  }
  ${USER_RESULT_FIELDS_FOR_COT}
`;
