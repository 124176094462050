import Box from "components/Box";
import { DownloadCrossOriginFile } from "components/Links";
import ChevronComponent from "tpo/Chevron";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function SampleReports({ sampleReports }) {
  return (
    <>
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
        Sample Reports
      </Box>
      <Stack gap={10}>
        {sampleReports.map(sr => (
          <Stack
            alignItems={[null, "center"]}
            flexDirection={["column", "row"]}
            justifyContent="space-between"
            key={sr.id}
          >
            <Box fontFamily="gilroyBold">{sr.name}</Box>
            <DownloadCrossOriginFile
              fileUrl={sr.url}
              fileName={`${sr.name}.${sr.extension}`}
              contentType={sr.contentType}
              trigger={
                <ButtonV2 color="green" rightIcon={<ChevronComponent direction="bottom" />}>
                  Sample File
                </ButtonV2>
              }
            />
          </Stack>
        ))}
      </Stack>
    </>
  );
}
