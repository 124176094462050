import { useHistory } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import BaseOrganisationBadge from "components/organisations/shared/ui/OrganisationBadge";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { AFFILIATE, PRACTITIONER } from "core/constants";
import { theme } from "core/theme";
import { DASHBOARD_HOME_URL, PARTNERS_DASHBOARD_URL } from "core/urls";
import RegenerusLogo from "images/RegenerusLogo";
import styled from "styled-components";

import Center from "./Center";
import Jumbotron from "./Jumbotron";
import SegmentedControl from "./SegmentedControl";
import Stack from "./Stack";

const FullRegenerusLogo = styled(Stack)`
  margin-bottom: 50px;

  @media (min-width: 960px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    margin-bottom: 0;
  }

  @media (min-width: 1280px) {
    left: 100px;
  }
`;

const OrganisationBadge = styled(BaseOrganisationBadge)`
  margin-top: 16px;
  margin-left: auto;
  margin-bottom: 20px;

  @media (min-width: 960px) {
    position: absolute;
    top: 16px;
    right: 50px;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
  }

  @media (min-width: 1280px) {
    right: 100px;
  }
`;

function getInitials(string) {
  // Source: https://stackoverflow.com/a/33076323
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export default function Header({ bg, mt, isPartnerPage }) {
  const history = useHistory();
  const {
    organisationId,
    setOrganisationId,
    availableOrganisations,
    organisation
  } = useOrganisationContext();

  const { userInfo } = useAppState();

  if (!userInfo || !userInfo?.user) return null;

  if (
    !userInfo.user.partnerUserProfile?.id ||
    userInfo.user.partnerUserProfile?.tier === AFFILIATE
  ) {
    return <Jumbotron title="Dashboard" mt={mt} />;
  }

  return (
    <Stack
      bg={bg}
      px={[20, 20, 0]}
      mt={mt}
      data-component-name="DashboardSwitcher"
      position="relative"
      minHeight={140}
      height={["auto", "auto", 140]}
      justifyContent={[null, null, "center"]}
      gap={0}
      pb={[40, 40, 0]}
    >
      {isPartnerPage && organisation && (
        <OrganisationBadge fullName={organisation.name} initials={getInitials(organisation.name)} />
      )}
      {isPartnerPage && userInfo.user.partnerUserProfile.tier === PRACTITIONER && (
        <FullRegenerusLogo>
          <Box color="dark" fontFamily="gilroyRegular" fontSize={18}>
            Powered by
          </Box>
          <RegenerusLogo color={theme.colors.dark} />
        </FullRegenerusLogo>
      )}
      <Center>
        <SegmentedControl
          data={[
            {
              label: "My Omnos",
              value: DASHBOARD_HOME_URL
            },
            {
              label: "Partners",
              value: PARTNERS_DASHBOARD_URL
            }
          ]}
          onChange={val => {
            if (val === DASHBOARD_HOME_URL) {
              setOrganisationId(null);
            } else {
              if (!organisationId && availableOrganisations?.length) {
                setOrganisationId(availableOrganisations?.[0]?.id);
              }
            }
            return history.push(val);
          }}
          value={isPartnerPage ? PARTNERS_DASHBOARD_URL : DASHBOARD_HOME_URL}
          height={55}
        />
      </Center>
    </Stack>
  );
}

Header.defaultProps = {
  bg: "purple"
};
