import Box from "../components/Box";

function SelectCircle({ borderColor, borderRadius, cursor, selected, onClick, width, ...rest }) {
  return (
    <Box
      borderColor={borderColor}
      borderRadius={borderRadius}
      cursor={cursor}
      padding={selected ? 2 : 0}
      onClick={onClick}
      width={width}
      {...rest}
    >
      {selected && (
        <Box
          position="absolute"
          bg={borderColor}
          borderRadius={borderRadius}
          height={width - 10}
          width={width - 10}
          cursor={cursor}
        ></Box>
      )}
    </Box>
  );
}

const diameter = 45;

SelectCircle.defaultProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  borderColor: "green",
  borderStyle: "solid",
  borderWidth: 2,
  height: diameter,
  width: diameter,
  minHeight: diameter,
  minWidth: diameter,
  maxHeight: diameter,
  maxWidth: diameter,
  mr: 3,
  cursor: "pointer",
  position: "relative"
};

export default SelectCircle;
