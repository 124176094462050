import styled from "styled-components";
import { color, layout, position, space } from "styled-system";

const StyledCloseButton = styled.button`
  ${space}
  ${layout}
  background-color: inherit;
  ${color}
  ${position}
  border: 2px solid
    ${props => {
    return props.color || props.theme.colors.blue;
  }};
  border-radius: 50%;
  height: ${props => {
    return props.height;
  }};
  position: ${props => {
    return props.position;
  }};
  width: ${props => {
    return props.width;
  }};
  &::after,
  &::before {
    background-color: ${props => {
      return props.color || props.theme.colors.blue;
    }};
    content: "";
    height: 18px;
    left: 50%;
    margin-left: -1px;
    margin-top: -9px;
    position: absolute;
    top: 50%;
    width: 2px;
  }

  &::before {
    transform: rotateZ(-45deg);
  }

  &::after {
    transform: rotateZ(45deg);
  }
`;

StyledCloseButton.defaultProps = {
  position: "relative",
  height: "45px",
  width: "45px"
};

export default StyledCloseButton;
