import { forwardRef, useEffect, useState } from "react";

import FloatingLabelInput from "./FloatingLabelInput";
import Group from "./Group";
import InputWrapper from "./InputWrapper";

function PaddedNumberField({ value, minChars = 2, onChange, ...props }) {
  const padValue = e => {
    const newValue = `${parseInt(e.target.value)}`.padStart(minChars, "0");
    onChange(newValue);
  };

  return (
    <FloatingLabelInput
      {...props}
      type="number"
      value={value}
      onChange={e => {
        onChange(e.target.value);
      }}
      onBlur={padValue}
    />
  );
}

function BaseDateInput({ name, value, onChange, getDay, getMonth, getYear, constructDate }) {
  return (
    <Group gap={20}>
      <PaddedNumberField
        flexGrow={1}
        label="Day"
        value={getDay(value) || ""}
        name={`${name}-day`}
        inputProps={{
          min: 1,
          max: 31,
          step: 1
        }}
        width="33%"
        onChange={newValue =>
          onChange(
            constructDate({
              day: newValue,
              month: getMonth(value),
              year: getYear(value)
            })
          )
        }
      />
      <PaddedNumberField
        flexGrow={1}
        label="Month"
        value={getMonth(value) || ""}
        name={`${name}-month`}
        inputProps={{
          min: 1,
          max: 12,
          step: 1
        }}
        width="33%"
        onChange={newValue =>
          onChange(
            constructDate({
              day: getDay(value),
              month: newValue,
              year: getYear(value)
            })
          )
        }
      />
      <PaddedNumberField
        flexGrow={1}
        minChars={4}
        label="Year"
        value={getYear(value)}
        name={`${name}-year` || ""}
        inputProps={{
          min: 1800,
          max: 9999,
          step: 1
        }}
        width="33%"
        onChange={newValue =>
          onChange(
            constructDate({
              day: getDay(value),
              month: getMonth(value),
              year: newValue
            })
          )
        }
      />
    </Group>
  );
}

const DateInput = forwardRef(
  (
    { value, onChange, getDay, getMonth, getYear, constructDate, error, label, name, labelProps },
    ref
  ) => (
    <InputWrapper
      ref={ref}
      label={label}
      labelProps={labelProps}
      input={
        <BaseDateInput
          value={value}
          onChange={onChange}
          getDay={getDay}
          getMonth={getMonth}
          getYear={getYear}
          constructDate={constructDate}
          name={name}
        />
      }
      error={error}
    />
  )
);

export default DateInput;
