import { useHistory } from "react-router-dom";

import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import "core/constants";
import { getPartnerDashboardTabUrl } from "core/urls";
import { PanelBoxV2 } from "tpo/Boxes";
import Tab from "tpo/Tab";

import { CMP_PERMISSION_PLACE_ORDERS, CMP_PERMISSION_VIEW_RESULTS } from "./constants";

export default function OrganisationTabs({ selectedTab, ...props }) {
  const { permissions } = useOrganisationContext();

  // NB: permissions here is only just for styling the tabs
  // the routes take care of checking permissions

  const history = useHistory();
  const tabs = [
    {
      label: "profile",
      slug: "profile",
      permissions: []
    },
    {
      label: "organisation",
      slug: "business",
      permissions: []
    },
    {
      label: "patients",
      slug: "patients",
      permissions: [CMP_PERMISSION_VIEW_RESULTS]
    },
    {
      label: "orders",
      slug: "orders",
      permissions: [CMP_PERMISSION_PLACE_ORDERS]
    },
    {
      label: "commissions",
      slug: "commissions",
      permissions: [CMP_PERMISSION_PLACE_ORDERS]
    },
    {
      label: "shop",
      slug: "shop",
      permissions: [CMP_PERMISSION_PLACE_ORDERS]
    }
  ];

  return (
    <PanelBoxV2
      outer={{
        bg: "haze"
      }}
      inner={{
        display: "flex",
        overflowX: "auto",
        pl: 20
      }}
      {...props}
    >
      {tabs.map(tab => {
        return (
          <Tab
            data-component-name={`OrganisationTab:${tab.label}`}
            // disabled if none of the permissions are in the user's permissions
            disabled={
              !tab.permissions ||
              (tab.permissions.length > 0 && !tab.permissions.some(p => permissions.includes(p)))
            }
            key={tab.slug}
            borderRadiusBottom
            borderRadiusTop={false}
            tab={tab}
            selectedTab={selectedTab}
            tabs={tabs}
            onClick={() => {
              history.push(getPartnerDashboardTabUrl(tab.slug));
            }}
          />
        );
      })}
    </PanelBoxV2>
  );
}
