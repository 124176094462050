import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { GET_PRACTITIONER_CLINICS } from "graphql/shop";
import Currency from "tpo/Currency";
import Stack from "tpo/Stack";
import BaseTestProductModal, { ChooseClinic } from "tpo/shop/BaseTestProductModal";
import { getCarouselImages } from "tpo/shop/TestProductDetailPage";
import TestProductOptions from "tpo/shop/TestProductOptions";
import { DISPLAY_CONTEXT_ALWAYS_VISIBLE, DISPLAY_CONTEXT_B2B_ONLY } from "tpo/shop/constants";

import TruncatedTestProductDetailContent from "./TruncatedTestProductDetailContent";

function ProductDetailView() {
  const {
    testProduct,
    selectTestProductId,
    selectTestProductOptionId
  } = useTestProductModalContext();
  const { basket, addProductToBasket } = useOrganisationBasketContext();
  const { setBasketOpen } = useAppState();

  if (!testProduct) {
    return null;
  }

  const testDataFilesWithSamples = testProduct.testDataFiles
    .filter(tdf => tdf.sampleFile)
    .map(tdf => ({
      id: tdf.id,
      name: tdf.name,
      url: tdf.sampleFile,
      extension: tdf.fileType.extension,
      contentType: tdf.fileType.contentType
    }));

  const carouselImages = getCarouselImages(testProduct);

  const showOptions = !basket.purchaseAsStock;

  const handleAddToBasket = (product, option) => {
    addProductToBasket(product.id, option?.id);
    setBasketOpen(true);
    selectTestProductId(undefined);
  };

  return (
    <TruncatedTestProductDetailContent
      sampleReports={
        testDataFilesWithSamples.length
          ? testDataFilesWithSamples
          : testProduct.reportPdf
          ? [
              {
                id: "reportPdf",
                name: "Report Pdf",
                url: testProduct.reportPdf,
                extension: "pdf",
                contentType: "application/pdf"
              }
            ]
          : []
      }
      status={testProduct.status}
      practitionerSupportRequired={false}
      carouselImages={carouselImages}
      productAudience={testProduct.productAudience}
      name={testProduct.name}
      productId={testProduct.id}
      description={testProduct.content.description}
      shortDescription={testProduct.content.shortDescription}
      categories={testProduct.content.categories.map(cat => cat.name)}
      sampleTypes={testProduct.sampleTypes}
      numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
      processingTime={testProduct.content.processingTime}
      options={
        testProduct.options.length ? (
          <TestProductOptions>
            {testProduct.options.map((option, index) => {
              const hasClinics = !!option.practitionerClinicsInfo?.clinics?.length;
              return (
                <TestProductOptions.Option
                  key={option.id}
                  status={testProduct.status}
                  id={option.id}
                  compositeId={option.compositeId}
                  name={option.testProductOptionFamily.name}
                  description={option.testProductOptionFamily.description}
                  price={
                    hasClinics ? (
                      <Stack gap={0}>
                        <Currency
                          prefix="FROM"
                          prefixFontFamily="gilroyBold"
                          prefixFontSize={16}
                          value={option.practitionerClinicsInfo.fromPrice}
                          stacked
                          fontSize={44}
                        />
                        <Box fontFamily="gilroyMedium" fontSize={14}>
                          Includes clinic fee
                        </Box>
                      </Stack>
                    ) : (
                      option.tradeCurrentPrice
                    )
                  }
                  previousPrice={option.retailFullPrice}
                  practitionerSupportRequired={false}
                  lastOption={index === testProduct.options.length - 1}
                  hasClinics={hasClinics}
                  disabled={hasClinics && !showOptions}
                  disabledMessage={showOptions ? "" : "Cannot purchase as stock"}
                  onFindNearestClinic={() => {
                    selectTestProductOptionId(option.id);
                  }}
                  onAddToBasket={() => {
                    handleAddToBasket(testProduct, option);
                  }}
                />
              );
            })}
          </TestProductOptions>
        ) : null
      }
      tradeCurrentPrice={testProduct.tradeCurrentPrice}
      tradeFullPrice={testProduct.tradeFullPrice}
      retailFullPrice={testProduct.retailFullPrice}
      slug={testProduct.slug}
      informationPanels={testProduct.content.informationPanels.filter(
        panel =>
          panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
          panel.displayContext === DISPLAY_CONTEXT_B2B_ONLY
      )}
      biomarkersTested={testProduct.content.biomarkersTested}
      relatedSymptoms={testProduct.content.relatedSymptoms}
      testType={testProduct.content.testType}
      onAddToBasket={() => {
        handleAddToBasket(testProduct);
      }}
    />
  );
}

function ChooseClinicView() {
  const {
    testProduct,
    testProductOption,
    selectTestProductId,
    selectTestProductOptionId
  } = useTestProductModalContext();

  const { setBasketOpen } = useAppState();
  const { addProductToBasket } = useOrganisationBasketContext();

  return (
    <ChooseClinic
      query={GET_PRACTITIONER_CLINICS}
      testProductOptionPriceField="tradeCurrentPrice"
      clinicLocationsField="practitionerClinicLocations"
      onAddToBasket={({ clinic }) => {
        addProductToBasket(testProduct.id, testProductOption.id, clinic.id);
        setBasketOpen(true);
        selectTestProductId(undefined);
        selectTestProductOptionId(undefined);
      }}
    />
  );
}

export default function TestProductModal({ bg }) {
  return (
    <BaseTestProductModal
      chooseClinicView={<ChooseClinicView />}
      productDetailView={<ProductDetailView />}
      clinicsInfoField="practitionerClinicsInfo"
      organisationShop={true}
      bg={bg}
    />
  );
}
