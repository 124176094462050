import { theme } from "../core/theme";
import useWindowSize from "./use-window-size";

function useBreakPointBasedPropIndex() {
  const { width } = useWindowSize();
  const { breakpoints } = theme;
  const idx = breakpoints.findIndex(breakpoint => width < breakpoint.match(/\d+/)?.[0]);
  return idx === -1 ? breakpoints.length - 1 : idx;
}

export default useBreakPointBasedPropIndex;
