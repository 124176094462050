export const RefetchEventName = "refetchGraphQL";

export default class RefetchEvent extends CustomEvent {
  constructor(queryString) {
    super(RefetchEventName, {
      detail: {
        queryString
      }
    });
  }

  static dispatch(queryString) {
    document.dispatchEvent(new RefetchEvent(queryString));
  }

  static listen(callback) {
    document.addEventListener(RefetchEventName, event => {
      callback(event.detail.queryString);
    });
  }

  static unlisten(callback) {
    document.removeEventListener(RefetchEventName, callback);
  }
}
