import { gql } from "@apollo/client";

export const PAGE_INFO_FIELDS = gql`
  fragment PageInfoFields on PageInfoType {
    totalPages
    currentPage
    hasNextPage
    hasPreviousPage
  }
`;
