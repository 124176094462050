import Text from "./Text";

export function AutoPaddingHeading(props) {
  let { pt, pb, padding, ...forwardedProps } = props;

  if (typeof pt === "boolean") {
    pt = padding;
  }
  if (typeof pb === "boolean") {
    pb = padding;
  }

  return <Text data-component-name="Text Heading" pt={pt} pb={pb} {...forwardedProps} />;
}

AutoPaddingHeading.defaultProps = {
  as: "h2",
  fontFamily: "gilroyBold"
};

export function HeadingExtraExtraLarge(props) {
  return (
    <AutoPaddingHeading {...props} fontSize={[40, 46, 54, 64]} padding={[30, 40, null, null]} />
  );
}

export function HeadingExtraLarge(props) {
  return (
    <AutoPaddingHeading {...props} fontSize={[24, 32, 36, 44]} padding={[30, 40, null, null]} />
  );
}

export function HeadingLarge(props) {
  return (
    <AutoPaddingHeading {...props} fontSize={[24, 28, 32, 36]} padding={[30, 40, null, null]} />
  );
}

export function HeadingMediumLarge(props) {
  return (
    <AutoPaddingHeading {...props} fontSize={[20, 22, 26, 32]} padding={[30, 40, null, null]} />
  );
}

export function HeadingMedium(props) {
  return (
    <AutoPaddingHeading {...props} fontSize={[18, 20, 22, 24]} padding={[30, 40, null, null]} />
  );
}

export function HeadingSmall(props) {
  return (
    <AutoPaddingHeading {...props} fontSize={[16, 18, 20, 22]} padding={[30, 40, null, null]} />
  );
}

export function HeadingExtraSmall(props) {
  return <AutoPaddingHeading {...props} fontSize={[14, 14, 16, 18]} padding={[20, 30, null, 40]} />;
}

export function HeadingExtraExtraSmall(props) {
  return <AutoPaddingHeading {...props} fontSize={[13, 14, 15, 16]} padding={[20, 30, null, 40]} />;
}

export function IntroBlockHeading(props) {
  return (
    <AutoPaddingHeading {...props} fontSize={[15, 20, 25, 30]} padding={[30, 40, null, null]} />
  );
}
