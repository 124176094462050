import { useCallback, useMemo } from "react";

import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { ADD_ITEM_TO_BASKET, OPEN_ORDER_QUERY, REMOVE_ITEM_FROM_BASKET } from "graphql/shop";
import { useDataLoader } from "hooks/useDataLoader";

export default function useBasket() {
  const [removeItemFromBasketMutation, { loading: removeItemMutationLoading }] = useMutation(
    REMOVE_ITEM_FROM_BASKET
  );
  const [addItemToBasketMutation, { loading: addItemMutationLoading }] = useMutation(
    ADD_ITEM_TO_BASKET
  );

  const { data: orderData, loading: orderLoading, error: orderError } = useDataLoader({
    query: OPEN_ORDER_QUERY,
    fetchPolicy: "network-only"
  });

  const openOrder = orderData?.openOrder;

  const isSubmitting = removeItemMutationLoading || addItemMutationLoading;

  const addItemToBasket = useCallback(
    ({ clinicLocationId, compositeId }) => {
      if (isSubmitting) return;
      addItemToBasketMutation({
        variables: {
          clinicLocationId,
          compositeId
        },
        refetchQueries: [
          {
            query: OPEN_ORDER_QUERY
          }
        ]
      }).catch(error => {
        console.log("Error encountered adding item to basket", error);
        Sentry.captureException(error);
      });
    },
    [addItemToBasketMutation, isSubmitting]
  );

  const removeItemFromBasket = useCallback(
    productInfo => {
      if (isSubmitting) return;

      removeItemFromBasketMutation({
        variables: {
          clinicLocationId: productInfo.clinicLocationId,
          compositeId: productInfo.compositeId
        },
        refetchQueries: [
          {
            query: OPEN_ORDER_QUERY
          }
        ]
      }).catch(error => {
        console.log("Error encountered removing item from basket", error);
        Sentry.captureException(error);
      });
    },
    [removeItemFromBasketMutation, isSubmitting]
  );

  const api = useMemo(
    () => ({
      removeItemFromBasket,
      isSubmitting,
      addItemToBasket,
      openOrder,
      orderError,
      orderLoading
    }),
    [removeItemFromBasket, isSubmitting, addItemToBasket, openOrder, orderError, orderLoading]
  );

  return api;
}
