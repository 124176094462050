import { useLocation } from "react-router-dom";

import { PARTNERS_PRACTITIONER_URL, PARTNERS_AFFILIATE_URL } from "core/urls";

export default function usePartnerPageCheck() {
  const location = useLocation();
  return (
    (location.pathname.startsWith("/patients") || location.pathname.startsWith("/partners")) &&
    ![PARTNERS_PRACTITIONER_URL, PARTNERS_AFFILIATE_URL].includes(location.pathname)
  );
}
