import { useEffect } from "react";

import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Asterisk from "tpo/Asterisk";
import ControlledRadioGroup from "tpo/ControlledRadioGroup";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";

const PATIENT_NOTIFICATION_ALL = "Opt in all";
const PATIENT_NOTIFICATION_NONE = "Opt out all";
const PATIENT_NOTIFICATION_EXCLUDE_RESULTS = "Opt out results";

const UPDATE_PATIENT_NOTIFICATIONS_MUTATION = gql`
  mutation UpdatePatientNotificationsMutation($input: UpdatePatientNotificationsMutationInput!) {
    updatePatientNotificationsMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export default function PatientNotificationSettingsForm() {
  const { organisation } = useOrganisationContext();

  const api = useDjangoGraphqlForm({
    mutation: UPDATE_PATIENT_NOTIFICATIONS_MUTATION,
    mutationName: "updatePatientNotificationsMutation",
    defaultValues: {
      id: organisation?.id || "",
      patientNotifications: organisation?.patientNotifications || ""
    },
    onChange: (values, { onSubmit }) => {
      onSubmit(values);
    }
  });

  const { reset } = api.methods;

  useEffect(() => {
    if (!organisation) {
      return;
    }
    reset({
      id: organisation.id,
      patientNotifications: organisation.patientNotifications
    });
  }, [organisation, reset]);

  return (
    <FormProvider {...api.methods}>
      <form>
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          Patient notification settings
          <Asterisk />
        </Box>
        <Spacer py={40 / 2} />
        <ControlledRadioGroup
          name="patientNotifications"
          label="Select an option below"
          labelProps={{
            fontFamily: "gilroyBold",
            fontSize: 18
          }}
          Container={Stack}
          gap={20}
          values={[
            {
              value: PATIENT_NOTIFICATION_ALL,
              label: PATIENT_NOTIFICATION_ALL,
              labelProps: {
                fontSize: 14,
                fontFamily: "gilroyBold"
              },
              helpText:
                "Your patient will receive a notification via email when their order reaches one of the following stages: The order has shipped, their specimen has been received or when results are ready"
            },
            {
              value: PATIENT_NOTIFICATION_EXCLUDE_RESULTS,
              label: PATIENT_NOTIFICATION_EXCLUDE_RESULTS,
              labelProps: {
                fontSize: 14,
                fontFamily: "gilroyBold"
              },
              helpText:
                "All notifications will be sent, except your patient will not be notified when results are ready"
            },
            {
              value: PATIENT_NOTIFICATION_NONE,
              label: PATIENT_NOTIFICATION_NONE,
              labelProps: {
                fontSize: 14,
                fontFamily: "gilroyBold"
              },
              helpText:
                "Your patient will receive no communications relating to the process of the order"
            }
          ]}
        />
      </form>
    </FormProvider>
  );
}
