import { PanelBoxV2 } from "tpo/Boxes";

export default function Container({
  children,
  bg,
  height,
  "data-component-name": dataComponentName
}) {
  return (
    <PanelBoxV2
      outer={{
        px: [20, 20, "5.5vw"],
        py: 2,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bg,
        zIndex: 4,
        height,
        "data-component-name": dataComponentName
      }}
    >
      {children}
    </PanelBoxV2>
  );
}
