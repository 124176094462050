import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import Box from "../components/Box";
import Text from "../components/Text";
import { FLAG_Z_INDEX } from "../core/constants";

export default function Flag({ bg, text, textProps, ...rest }) {
  const _textProps = { ...Flag.defaultProps.textProps, ...textProps };

  return (
    <Box display="flex" height={60} width={80} {...rest}>
      <Box
        dataComponentName="Flag"
        bg={bg}
        height={60}
        width={60}
        zIndex={FLAG_Z_INDEX}
        display="flex"
        alignItems="center"
      >
        <Text ml={25} {..._textProps}>
          {text}
        </Text>
      </Box>
      <Box bg={bg} borderRadius="50%" height={60} width={60} ml={-24}></Box>
    </Box>
  );
}

Flag.propTypes = {
  bg: propTypes.color.bg,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textProps: PropTypes.object
};

Flag.defaultProps = {
  bg: "blue",
  textProps: {
    color: "white",
    fontSize: 42,
    fontFamily: "dinBold"
  }
};
