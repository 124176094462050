import gql from "graphql-tag";
import { PAGE_INFO_FIELDS } from "graphql/pagination";

export const ORGANISATION_STOCK_LIST_QUERY = gql`
  query OrganisationStockListQuery(
    $organisation: ID!
    $page: Int
    $orderBy: String
    $search: String
    $filter: String
  ) {
    organisationStockPagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
      filter: $filter
    ) {
      items {
        id
        testProduct {
          id
          name
          supplier {
            id
            name
          }
        }
        createdFromOrder {
          id
          checkoutDate
        }
        testProductOption {
          id
        }
        orderTestItem {
          id
          providerTestId
          providerItemId
        }
        registeredUser {
          id
          firstName
          lastName
          email
        }
        omnosBarcodeId
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_STOCK_DETAILS_QUERY = gql`
  query OrganisationStockDetailsQuery($id: ID!, $organisation: ID!) {
    organisationStockItem(id: $id, organisation: $organisation) {
      id
      omnosBarcodeId
      testProduct {
        id
        name
        supplier {
          id
          name
        }
      }
      createdFromOrder {
        id
        checkoutDate
      }
      testProductOption {
        id
      }
      orderTestItem {
        id
        providerTestId
        providerItemId
      }
      registeredUser {
        id
        firstName
        lastName
        email
      }
      remainingCost
      returnShippingCost
    }
  }
`;
