import { createContext, useContext } from "react";
import { useMemo, useState } from "react";

import { gql, useMutation } from "@apollo/client";

const NotificationsContext = createContext();

const ACKNOWLEDGE_NOTIFICATION_MUTATION = gql`
  mutation AcknowledgeNotificationMutation($input: AcknowledgeNotificationMutationInput!) {
    acknowledgeNotificationMutation(input: $input) {
      ok
    }
  }
`;

export function useNotificationsContext() {
  const context = useContext(NotificationsContext);
  return context;
}

export function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [acknowledgeNotification] = useMutation(ACKNOWLEDGE_NOTIFICATION_MUTATION);
  const [acknowledged, setAcknowledged] = useState(new Set());

  const api = useMemo(
    () => ({
      addNotifications: notificationsToAdd => {
        const existingIds = notifications.map(n => n.id);
        const newNotifications = notificationsToAdd.filter(n => !existingIds.includes(n.id));
        if (newNotifications.length) {
          setNotifications(notifications => [...notifications, ...newNotifications]);
        }
      },
      acknowledgeNotification: id => {
        acknowledgeNotification({ variables: { input: { id } } })
          .then(() => {
            setAcknowledged(acknowledged => {
              const newAcknowledged = new Set(acknowledged);
              newAcknowledged.add(id);
              return newAcknowledged;
            });
          })
          .catch(err => {
            // acknowledge on the client even if the server fails
            setAcknowledged(acknowledged => {
              const newAcknowledged = new Set(acknowledged);
              newAcknowledged.add(id);
              return newAcknowledged;
            });
            console.log(`Error acknowledging notification: ${err}`);
          });
      },
      notifications,
      acknowledged,
      nextNotification: filter => {
        return notifications.find(notification =>
          filter
            ? !acknowledged.has(notification.id) && filter(notification)
            : !acknowledged.has(notification.id)
        );
      }
    }),
    [acknowledged, acknowledgeNotification, notifications, setNotifications]
  );

  return <NotificationsContext.Provider value={api}>{children}</NotificationsContext.Provider>;
}
