import { Controller, useController, useFormContext } from "react-hook-form";

import Checkbox from "./Checkbox";
import InputWrapper from "./InputWrapper";

export default function ControlledCheckboxGroup({
  Container,
  containerProps,
  name,
  values,
  checkboxLabelProps,
  ...props
}) {
  const { control } = useFormContext();
  const {
    fieldState: { error }
  } = useController({ name, control });
  const ContainerComponent = Container || "Fragment";

  // NB -
  // values prop is the list of values
  // field.value below is the array of chosen values for the checkbox group form field

  return (
    <InputWrapper
      error={error?.message}
      input={
        <ContainerComponent {...containerProps}>
          {values?.map(({ label, value }) => (
            <Controller
              key={value}
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    {...field}
                    name={name}
                    value={value}
                    label={label}
                    onChange={e => {
                      if (e.target.checked) {
                        if (!field.value.includes(e.target.value)) {
                          field.onChange([...field.value, e.target.value]);
                        }
                      } else {
                        if (field.value.includes(e.target.value)) {
                          field.onChange(field.value.filter(val => val !== e.target.value));
                        }
                      }
                    }}
                    checked={field.value.includes(value)}
                    styles={{
                      labelProps: checkboxLabelProps
                    }}
                    {...props}
                  />
                );
              }}
            />
          ))}
        </ContainerComponent>
      }
    />
  );
}
