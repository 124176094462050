import { forwardRef, useContext, useState } from "react";

import { Redirect, useParams } from "react-router-dom";

import { gql } from "@apollo/client";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Divider from "components/Divider";
import { ExternalTextLink, InternalTextLink } from "components/Links";
import Page from "components/Page";
import Text from "components/Text";
import DatapointGraph from "components/cot/DatapointGraph";
import { ResultPresentationConsumer } from "contexts/ResultPresentation";
import { theme } from "core/theme";
import { ERROR_404_URL, getPatientTestBiomarkerUrl, getTestDatapointUrl } from "core/urls";
import { CHART_CONFIGURATION_FIELDS, RANGE_DEFINITION_FIELDS } from "graphql/tpo/results/types";
import { dueForRetestOrExpired } from "utils/shared";

import Accordion from "./Accordion";
import { Research } from "./Accordions";
import { PanelBoxV2 } from "./Boxes";
import { ChartCardWrapper, GenesAllelesText, RetestDueSubTitle } from "./ChartCards";
import ChartModal from "./ChartModal";
import { CollapseableText } from "./CollapseableText";
import { ChartDetailLayout, ChartDetailModalLayout } from "./Layouts";
import PercentageBar from "./PercentageBar";
import Spacer from "./Spacer";
import Stack from "./Stack";
import { DatapointCharts, RELATED_RESULTS_QUERY } from "./UserDatapoints";
import {
  RelatedResultContext,
  RelatedResults,
  USER_RESULT_QUERY,
  UserResultModelContent
} from "./UserResult";
import { ViewerContext } from "./Viewer";

export function BiomarkersTested({ chartProps, color, value }) {
  return (
    <Stack gap={10}>
      <Box color={color} fontFamily="gilroyBold" fontSize={10} textTransform="uppercase">
        {`${value}% Biomarkers Tested`}
      </Box>
      <PercentageBar {...chartProps} value={value} />
    </Stack>
  );
}

BiomarkersTested.defaultProps = {
  color: "dark"
};

export const USER_DATAPOINT_QUERY = gql`
  query USER_DATAPOINT_QUERY($userDatapointId: ID!, $userId: ID) {
    userDatapoint(userDatapointId: $userDatapointId, userId: $userId) {
      id
      uploaded
      dateExpired
      datapoint {
        id
        name
        category
        units
        description
        function
        reason
        chartType
        testProductSet {
          id
          productFamily {
            id
            name
            slug
          }
        }
      }
      riskAllele
      value
      interpretation
      rangeUsed {
        ...RangeDefinitionFields
      }
      riskAllele
      chartConfiguration {
        ...ChartConfigurationFields
      }
      datapointState {
        id
        description
      }
      rangeUsed {
        ...RangeDefinitionFields
        minimum
        maximum
      }
    }
  }
  ${RANGE_DEFINITION_FIELDS}
  ${CHART_CONFIGURATION_FIELDS}
`;

function UserDatapointContent({ userDatapoint, openModal }) {
  const isGenesAlleles = userDatapoint.datapoint.chartType === "GENES_ALLELES_CHART";

  return (
    <ChartDetailLayout
      chart={
        <ChartCardWrapper
          title={!isGenesAlleles ? userDatapoint.datapoint.name : null}
          subtitle={
            dueForRetestOrExpired(userDatapoint.dueForRetest, userDatapoint.dateExpired) &&
            !isGenesAlleles && <RetestDueSubTitle />
          }
          chart={<DatapointCharts datapoint={userDatapoint} />}
          showArrowCircle={false}
          showBorder={false}
          pb={0}
          showCotIcon={!isGenesAlleles}
          cotChart={<DatapointGraph userDatapoint={userDatapoint} />}
        />
      }
      text={<UserDatapointTexts userDatapoint={userDatapoint} />}
      accordion={
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          size={20}
          gap={[10, 10, 20]}
        >
          <RelatedResultContext.Provider
            value={{
              openModal: result => {
                openModal({
                  type: "results",
                  item: result
                });
              }
            }}
          >
            <RelatedResultsAccordion userDatapoint={userDatapoint} />
          </RelatedResultContext.Provider>
          <RelatedResearchAccordion userDatapoint={userDatapoint} />
        </Accordion>
      }
    />
  );
}

function UserDatapointTexts({ userDatapoint }) {
  return (
    <>
      {userDatapoint.datapoint?.description && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            What is it?
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            {userDatapoint.datapoint?.description}
          </CollapseableText>
        </Box>
      )}
      {userDatapoint.datapoint?.function && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            What does it do?
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            {userDatapoint.datapoint?.function}
          </CollapseableText>
        </Box>
      )}
      {userDatapoint.datapoint?.reason && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            What measure it?
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            {userDatapoint.datapoint?.reason}
          </CollapseableText>
        </Box>
      )}
      {userDatapoint.datapointState?.description && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            Your interpretation
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            {userDatapoint.datapointState?.description}
          </CollapseableText>
        </Box>
      )}
    </>
  );
}

function RelatedResultsAccordion({ userDatapoint }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  return (
    <Accordion.Item value="relatedResults">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Results
        </Text>
      </Accordion.Control>
      <Accordion.Panel px={[20, 20, 40]} pb={[2, 2, 20]}>
        <DataLoader
          query={RELATED_RESULTS_QUERY}
          variables={{
            userDatapointId: userDatapoint.id,
            userId: clientId
          }}
          fetchPolicy="no-cache"
          nextFetchPolicy="no-cache"
          render={({ userDatapoint }) => (
            <RelatedResults clientId={clientId} results={userDatapoint.results} py={[2, 2, 20]} />
          )}
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
}

const RELATED_RESEARCH_QUERY = gql`
  query RelatedResearchQuery($userId: ID, $userDatapointId: ID!) {
    userDatapoint(userId: $userId, userDatapointId: $userDatapointId) {
      id
      datapointState {
        id
        research {
          id
          name
          url
        }
      }
    }
  }
`;

function RelatedResearchAccordion({ userDatapoint: { id: userDatapointId } }) {
  const viewerContext = useContext(ViewerContext);

  return (
    <Research
      research={
        <DataLoader
          query={RELATED_RESEARCH_QUERY}
          variables={{
            userId: viewerContext?.userId,
            userDatapointId
          }}
          render={({ userDatapoint }) =>
            userDatapoint?.datapointState?.research?.length ? (
              userDatapoint?.datapointState?.research?.map(research => (
                <ExternalTextLink
                  display="block"
                  key={research.id}
                  fontSize={16}
                  href={research.url}
                  py={[2, 2, 20]}
                >
                  {research.name}
                </ExternalTextLink>
              ))
            ) : (
              <Box>No research found</Box>
            )
          }
        />
      }
    />
  );
}

export function UserDatapointModelContent({ userDatapoint }) {
  const isGenesAlleles = userDatapoint.datapoint.chartType === "GENES_ALLELES_CHART";

  return (
    <ChartDetailModalLayout
      chart={
        <ChartCardWrapper
          title={!isGenesAlleles ? userDatapoint.datapoint.name : null}
          subtitle={
            dueForRetestOrExpired(userDatapoint.dueForRetest, userDatapoint.dateExpired) &&
            !isGenesAlleles && <RetestDueSubTitle />
          }
          chart={<DatapointCharts datapoint={userDatapoint} />}
          showArrowCircle={false}
          pb={0}
          pt={0}
          showCotIcon={!isGenesAlleles}
          cotChart={<DatapointGraph userDatapoint={userDatapoint} />}
        />
      }
      text={<UserDatapointTexts userDatapoint={userDatapoint} />}
      accordion={
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          size={20}
          gap={[10, 10, 20]}
        >
          <RelatedResultsAccordion userDatapoint={userDatapoint} />
          <RelatedResearchAccordion userDatapoint={userDatapoint} />
        </Accordion>
      }
    />
  );
}

export const DatapointChartCard = forwardRef(
  ({ onClick, userDatapoint, includeRelatedResults }, ref) => {
    const viewerContext = useContext(ViewerContext);
    const clientId = viewerContext?.userId;

    const isGenesAlleles = userDatapoint.datapoint.chartType === "GENES_ALLELES_CHART";

    return (
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "white",
          borderRadius: 5,
          cursor: "pointer",
          px: [20],
          py: [20]
        }}
        ref={ref}
      >
        <ChartCardWrapper
          title={userDatapoint.datapoint.name}
          subtitle={
            dueForRetestOrExpired(userDatapoint.dueForRetest, userDatapoint.dateExpired) &&
            !isGenesAlleles && <RetestDueSubTitle />
          }
          chart={<DatapointCharts datapoint={userDatapoint} />}
          onClick={onClick}
          showCotIcon={!isGenesAlleles}
          cotChart={<DatapointGraph userDatapoint={userDatapoint} />}
        />
        {includeRelatedResults && (
          <>
            <Spacer py={20} />
            <Divider color={theme.colors.haze} />
            <Accordion variant="separated" multiple size={20}>
              <Accordion.Item value="relatedResults">
                <Accordion.Control justifyContent="flex-end" p={0} pt={20} gap={10}>
                  <Box
                    fontFamily="gilroyBold"
                    textTransform="uppercase"
                    fontSize={10}
                    letterSpacing="2.8px"
                  >
                    related results
                  </Box>
                </Accordion.Control>
                <Accordion.Panel px={0} py={20}>
                  <DataLoader
                    query={RELATED_RESULTS_QUERY}
                    variables={{
                      userDatapointId: userDatapoint.id,
                      userId: clientId
                    }}
                    render={({ userDatapoint: userDatapointWithRelatedResults }) => (
                      <RelatedResults
                        results={userDatapointWithRelatedResults?.results}
                        leftFontSize={[14]}
                        rightFontSize={[14]}
                        py={2}
                      />
                    )}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </PanelBoxV2>
    );
  }
);

DatapointChartCard.defaultProps = {
  includeRelatedResults: true
};

export const GeneDatapointSummary = forwardRef(
  ({ bg, userDatapoint, openModal, gap, py, px }, ref) => {
    const viewerContext = useContext(ViewerContext);
    const clientId = viewerContext?.userId;

    const nameParts = userDatapoint.datapoint.name.split("-").map(part => part.trim());

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={gap}
        py={py}
        key={userDatapoint.id}
        bg={bg}
        px={px}
        ref={ref}
      >
        <Box width="40%">
          {openModal ? (
            <Text
              fontSize={[14, 14, 15]}
              color="anchorBlue"
              cursor="pointer"
              onClick={() => openModal(userDatapoint)}
              marginCorrection={false}
            >
              {nameParts[0]}
            </Text>
          ) : (
            <InternalTextLink
              fontSize={[14, 14, 15]}
              href={
                clientId
                  ? getPatientTestBiomarkerUrl(clientId, userDatapoint.id)
                  : getTestDatapointUrl(userDatapoint.id)
              }
            >
              {nameParts[0]}
            </InternalTextLink>
          )}
        </Box>
        <Box width="40%">
          {openModal ? (
            <Text
              fontSize={[14, 14, 15]}
              color="anchorBlue"
              cursor="pointer"
              onClick={() => openModal(userDatapoint)}
              marginCorrection={false}
            >
              rs{nameParts[1]}
            </Text>
          ) : (
            <InternalTextLink
              fontSize={[14, 14, 15]}
              href={
                clientId
                  ? getPatientTestBiomarkerUrl(clientId, userDatapoint.id)
                  : getTestDatapointUrl(userDatapoint.id)
              }
            >
              rs{nameParts[1]}
            </InternalTextLink>
          )}
        </Box>
        <Box textAlign="right" width="40%">
          <DatapointCharts datapoint={userDatapoint}>
            <ResultPresentationConsumer>
              {({ chartProps, interpretationColor }) => {
                if (userDatapoint.datapoint.chartType === "GENES_ALLELES_CHART") {
                  return (
                    <GenesAllelesText
                      text={chartProps.type}
                      color={chartProps.color}
                      fontSize={[14, 14, 15]}
                    />
                  );
                }
                return (
                  <Text
                    fontSize={[14, 14, 15]}
                    fontFamily="gilroyBold"
                    color={interpretationColor}
                    textAlign="right"
                  >
                    {userDatapoint.chartConfiguration.interpretation}
                  </Text>
                );
              }}
            </ResultPresentationConsumer>
          </DatapointCharts>
        </Box>
      </Box>
    );
  }
);

function ModalContent({ type, item }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;
  if (type === "results") {
    return (
      <DataLoader
        query={USER_RESULT_QUERY}
        variables={{
          userId: clientId,
          userResultId: item.id
        }}
        render={({ userResult, relatedGenes }) => (
          <UserResultModelContent userResult={userResult} relatedGenes={relatedGenes} />
        )}
      />
    );
  }
  return null;
}

function UserDatapoint() {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  const { userDatapointId } = useParams();
  const [modalContent, setModalContent] = useState();

  return (
    <DataLoader
      query={USER_DATAPOINT_QUERY}
      variables={{
        userId: clientId,
        userDatapointId
      }}
      render={({ userDatapoint }) => {
        if (!userDatapoint) {
          return <Redirect to={ERROR_404_URL} />;
        }

        return (
          <ViewerContext.Provider
            value={{
              userId: clientId
            }}
          >
            <Page bg="haze">
              <UserDatapointContent
                openModal={obj => {
                  setModalContent(obj);
                }}
                userDatapoint={userDatapoint}
              />
              <ChartModal close={() => setModalContent()} show={!!modalContent}>
                {!!modalContent && (
                  <ModalContent type={modalContent.type} item={modalContent.item} />
                )}
              </ChartModal>
            </Page>
          </ViewerContext.Provider>
        );
      }}
    />
  );
}

export default function UserDatapointPage() {
  const { userDatapointId } = useParams();
  return <UserDatapoint key={userDatapointId} />;
}
