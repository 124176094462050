import Box from "../components/Box";

export const FlagProps = { mb: [4, 5] };
export const HeaderProps = { mb: [4, 5] };
export const BodyCopyProps = { mb: [6, 50] };
export const ContentProps = { mb: [5, 6] };

function Common({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>;
}

export const Flag = props => <Common {...props} />;
Flag.defaultProps = {
  ...FlagProps
};

export const Header = props => <Common {...props} />;
Header.defaultProps = {
  ...HeaderProps
};

export const BodyCopy = props => <Common {...props} />;
BodyCopy.defaultProps = {
  ...BodyCopyProps
};

export const Content = props => <Common {...props} />;
Content.defaultProps = {
  ...ContentProps
};

export const Button = props => <Common {...props} />;
Button.defaultProps = {
  display: "flex",
  justifyContent: "center"
};

const Section = {};

export default Object.assign(Section, {
  Flag,
  Header,
  BodyCopy,
  Content,
  Button
});
