import { useCallback, useEffect, useState } from "react";

import Box from "components/Box";
import Group from "tpo/Group";
import Slider from "tpo/Slider";

export function PatientPayeeOptions({ basket, onBasketUpdate }) {
  const [sliderValue, setSliderValue] = useState(0);
  const [changeTimeout, setChangeTimeout] = useState(null);

  useEffect(() => {
    if (basket.commissionPercentageSplit !== sliderValue) {
      setSliderValue(basket.commissionPercentageSplit);
    }
  }, [basket]);

  // Let's only call onBasketUpdate 500ms after the user has stopped sliding the slider
  const handleSliderChange = useCallback(newValue => {
    if (changeTimeout) {
      clearTimeout(changeTimeout);
    }
    setChangeTimeout(
      setTimeout(() => {
        onBasketUpdate({ commissionPercentageSplit: newValue });
      }, 500)
    );
    setSliderValue(newValue);
  });

  return (
    <>
      <Group alignItems="center" gap={10} width="100%" justifyContent="flex-end" flexWrap="wrap">
        <Box fontFamily="gilroyBold" fontSize={16}>
          Split commission: {sliderValue}%
        </Box>
        <Slider
          maxWidth="300px"
          onChange={newValue => handleSliderChange(newValue)}
          value={sliderValue}
        />
      </Group>
    </>
  );
}
