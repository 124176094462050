import React, { useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { DASHBOARD_HOME_URL, REQUEST_PASSWORD_RESET_URL, SIGNUP_URL } from "../core/urls";
import { LOG_IN_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import Form from "./Form";
import Input from "./Input";
import { InternalTextLink } from "./Links";
import ResendVerificationEmailButton from "./ResendVerificationEmailButton";

export function AuthAction({ children, url }) {
  return (
    <InternalTextLink href={url} textAlign="center" display="block" p={15}>
      {children}
    </InternalTextLink>
  );
}

function LoginPage() {
  useDocTitle("Login");
  const { setCsrfToken, user, setUser } = useAuthContext();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (user !== null) {
      if (location.search.includes("?next=")) {
        history.push(location.search.replace("?next=", ""));
      } else {
        history.push(DASHBOARD_HOME_URL);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <AccountPage heading="Login">
      <Box pt={40}>
        <Form
          mutation={LOG_IN_MUTATION}
          handleSubmitted={output => {
            setCsrfToken(output.csrfToken);
            setUser(output.user);
          }}
          data={[
            {
              fields: [
                {
                  name: "email",
                  initialValue: "",
                  widget: {
                    component: Input,
                    props: {
                      type: "email",
                      label: "Email address",
                      autoComplete: "email"
                    }
                  }
                },
                {
                  name: "password",
                  initialValue: "",
                  widget: {
                    component: Input,
                    props: {
                      type: "password",
                      label: "Password",
                      showEye: true
                    }
                  },
                  boxProps: {
                    pt: "15px"
                  }
                }
              ]
            }
          ]}
          formWrapperProps={{
            mb: 30
          }}
          Button={ResendVerificationEmailButton}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <AuthAction url={SIGNUP_URL}>Click here to sign up</AuthAction>
        <AuthAction url={REQUEST_PASSWORD_RESET_URL}>Forgotten your password?</AuthAction>
      </Box>
    </AccountPage>
  );
}

export default LoginPage;
