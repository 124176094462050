import Box from "components/Box";
import { useBasketContext } from "contexts/BasketContext";
import { useHeaderContext } from "contexts/HeaderContext";
import { PRACTITIONER } from "core/constants";
import { REGISTER_KIT_URL, TESTS_URL } from "core/urls";
import LinkWrapper from "tpo/LinkWrapper";
import BurgerButton from "tpo/header/BurgerButton";

import BasketButton from "./BasketButton";
import Common from "./Common";
import LoggedInMenuItems from "./LoggedInMenuItems";
import LoggedOutMenuDrawer from "./LoggedOutMenuDrawer";
import LoggedOutMenuItems from "./LoggedOutMenuItems";
import UserMenuDrawer from "./UserMenuDrawer";

export default function UserHeader({ user, userSubmissions }) {
  // Always use this header if the user isn't a partner
  // If a partner this will only render is the user isn't at a partner url

  const { open, onClick } = useHeaderContext();
  const { openOrder } = useBasketContext();

  const drawerMenu = user ? (
    userSubmissions ? (
      <UserMenuDrawer bg="blue" user={user} userSubmissions={userSubmissions} />
    ) : null
  ) : (
    <LoggedOutMenuDrawer bg="blue" />
  );

  return (
    <Common
      bg="blue"
      user={user}
      right={
        <>
          <Box
            as={LinkWrapper}
            color="white"
            fontFamily="gilroyBold"
            fontSize={16}
            to={TESTS_URL}
            onClick={onClick}
            display={["none", "none", "inline"]}
          >
            Shop
          </Box>
          {user ? (
            <>
              <LoggedInMenuItems bg="blue" user={user} />
              <Box
                as={LinkWrapper}
                color="green"
                fontFamily="gilroyBold"
                fontSize={16}
                to={REGISTER_KIT_URL}
                textDecoration="underline"
                display={["none", "none", "inline"]}
                onClick={onClick}
              >
                Register
              </Box>
            </>
          ) : (
            <LoggedOutMenuItems bg="blue" />
          )}
          {!user || user?.partnerUserProfile?.tier !== PRACTITIONER ? (
            <BasketButton openOrder={openOrder} />
          ) : null}
          <BurgerButton onClick={open} />
          {drawerMenu}
        </>
      }
    />
  );
}
