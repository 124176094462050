import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { LOG_OUT_MUTATION } from "graphql/accounts";

export default function useLogOut() {
  const [logoutMutation] = useMutation(LOG_OUT_MUTATION);

  const logout = useCallback(() => {
    logoutMutation()
      .then(() => {
        window.location.href = "/";
      })
      .catch(error => {
        console.log("Error logging out");
        console.log(error);
        Sentry.captureException(error);
      });
  }, [logoutMutation]);
  return logout;
}
