import React from "react";

import Header from "./Header";
import { HeadingExtraLarge } from "./Headings";

function DashboardHeader({ children, color, maxWidth, backgroundColor }) {
  return (
    <Header maxWidth={maxWidth} backgroundColor={backgroundColor} color={color}>
      <HeadingExtraLarge as="h1">{children}</HeadingExtraLarge>
    </Header>
  );
}

export default DashboardHeader;
