import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { REGENERUS } from "components/organisations/constants";
import { getAPIBase } from "core/config";
import ChevronComponent from "tpo/Chevron";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function DownloadRequisitionPanel({ stock: { orderTestItem, testProduct } }) {
  return (
    <Stack gap={40}>
      <Box>
        <Box fontFamily="gilroyBold" fontSize={28}>
          Thanks for registering
        </Box>
        <Spacer py={2} />
        <Box fontSize={16} fontFamily="gilroyMedium">
          Please download and print your lab requisition form and add it to the kit before giving to
          your client. This is required for the test to be processed by the labs. Failure to do so
          will mean that your sample is unable to be processed.
        </Box>
        <Spacer py={2} />
      </Box>
      <ExternalTextLink
        href={`${getAPIBase()}/requisition/download/${orderTestItem.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {testProduct.supplier.name == REGENERUS && (
          <ButtonV2 color="dark" rightIcon={<ChevronComponent />} mr="auto">
            Download
          </ButtonV2>
        )}
      </ExternalTextLink>
    </Stack>
  );
}
