import { createContext, useContext } from "react";

import usePrevious from "hooks/usePrevious";

import ArrowCircle from "../components/ArrowCircle";
import Box from "../components/Box";
import { PageButton } from "../deprecated/Buttons";
import useBreakPointBasedPropIndex from "../hooks/useBreakPointBasedPropIndex";

const PaginationContext = createContext();

function MobilePagination() {
  const { totalPages, currentPage, hasNextPage, hasPreviousPage, size, onChange } = useContext(
    PaginationContext
  );

  return (
    <>
      {hasPreviousPage && (
        <ArrowCircle direction="left" cursor="pointer" onClick={() => onChange(currentPage - 1)} />
      )}
      {hasPreviousPage && currentPage > 2 && (
        <PageButton
          data-component-name="PaginationButton"
          label="..."
          bg="haze"
          color="dark"
          width={size}
        />
      )}
      <PageButton
        data-component-name="PaginationButton"
        key={currentPage}
        label={currentPage}
        bg="dark"
        color="white"
        width={size}
      />
      {hasNextPage && currentPage < totalPages - 1 && (
        <PageButton
          data-component-name="PaginationButton"
          label="..."
          bg="haze"
          color="dark"
          width={size}
        />
      )}
      {hasNextPage && <ArrowCircle cursor="pointer" onClick={() => onChange(currentPage + 1)} />}
    </>
  );
}

function DefaultPagination() {
  const { totalPages, currentPage, hasNextPage, hasPreviousPage, size, onChange } = useContext(
    PaginationContext
  );

  const middlePages = [currentPage - 1, currentPage, currentPage + 1].filter(
    page => page > 0 && page <= totalPages
  );

  return (
    <>
      {hasPreviousPage && (
        <ArrowCircle direction="left" cursor="pointer" onClick={() => onChange(currentPage - 1)} />
      )}
      {!middlePages.includes(1) && (
        <PageButton
          data-component-name="PaginationButton"
          label="1"
          bg="haze"
          color="dark"
          handleClick={() => onChange(1)}
          width={size}
        />
      )}
      {hasPreviousPage && currentPage > 2 && (
        <PageButton
          data-component-name="PaginationButton"
          label="..."
          bg="haze"
          color="dark"
          width={size}
        />
      )}
      {[currentPage - 1, currentPage, currentPage + 1]
        .filter(page => page > 0 && page <= totalPages)
        .map(pageNumber => (
          <PageButton
            key={pageNumber}
            label={pageNumber}
            bg={currentPage === pageNumber ? "dark" : "haze"}
            color={currentPage === pageNumber ? "white" : "dark"}
            handleClick={() => onChange(pageNumber)}
            width={size}
            data-component-name="PaginationButton"
          />
        ))}
      {hasNextPage && currentPage < totalPages - 1 && (
        <PageButton
          data-component-name="PaginationButton"
          label="..."
          bg="haze"
          color="dark"
          width={size}
        />
      )}
      {!middlePages.includes(totalPages) && (
        <PageButton
          label={totalPages}
          bg="haze"
          color="dark"
          handleClick={() => onChange(totalPages)}
          width={size}
          data-component-name="PaginationButton"
        />
      )}
      {hasNextPage && <ArrowCircle cursor="pointer" onClick={() => onChange(currentPage + 1)} />}
    </>
  );
}

function Pagination() {
  const { variant: variantProp } = useContext(PaginationContext);

  const propIndex = useBreakPointBasedPropIndex();
  let variant;

  if (Array.isArray(variantProp)) {
    variant = variantProp?.[propIndex] || "default";
  } else {
    variant = variantProp;
  }

  if (variant === "mobile") {
    return <MobilePagination />;
  }

  if (variant === "default") {
    return <DefaultPagination />;
  }
}

function PaginationWrapper({
  // totalPages,
  // currentPage,
  // hasNextPage,
  // hasPreviousPage,
  pageInfo: newPageInfo,
  onChange,
  size,
  variant
}) {
  const previousPageInfo = usePrevious(newPageInfo);
  const pageInfo = newPageInfo || previousPageInfo;

  if (pageInfo === undefined) return null;

  if (!pageInfo.totalPages || pageInfo.currentPage === undefined || pageInfo.totalPages === 1)
    return null;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={[10, 10, 20]}>
      <PaginationContext.Provider
        value={{
          onChange,
          size,
          ...pageInfo,
          variant
        }}
      >
        <Pagination />
      </PaginationContext.Provider>
    </Box>
  );
}

PaginationWrapper.defaultProps = {
  variant: "default"
};

export default PaginationWrapper;
