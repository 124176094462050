import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import OrganisationProfile from "components/organisations/business/OrganisationProfile";
import OrganisationCheckout from "components/organisations/checkout/OrganisationCheckout";
import OrganisationOrders from "components/organisations/orders";
import OrganisationViewOrder from "components/organisations/orders/OrganisationViewOrder";
import { ERROR_404_URL } from "core/urls";
import useFirstAvailableOrganisation from "hooks/organisations/useFirstAvailableOrganisation";
import PartnerRoute from "tpo/PartnerRoute";

import OrganisationCommissions from "./comissions/OrganisationCommissions";
import { CMP_PERMISSION_PLACE_ORDERS, CMP_PERMISSION_VIEW_RESULTS } from "./constants";
import OrganisationPatients from "./patients";
import PractitionerProfile from "./profile/PractitionerProfile";
import OrganisationShop from "./shop/OrganisationShop";

export default function OrganisationDashboard() {
  const match = useRouteMatch();

  const { loadingElement } = useFirstAvailableOrganisation();

  if (loadingElement) return loadingElement;

  /**
   * Remember the page component is already wrapping this component, OrganisationDashboard,
   * so none of the children rendered here should render a page component themselves.
   */

  return (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={`${match.path}/profile`} />
      </Route>
      <PartnerRoute
        permissionsRequired={[]}
        exact
        path={`${match.path}/profile`}
        component={PractitionerProfile}
      />
      <PartnerRoute
        permissionsRequired={[]}
        exact
        path={`${match.path}/business`}
        component={OrganisationProfile}
      />
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_VIEW_RESULTS]}
        // exact
        path={`${match.path}/patients`}
        component={OrganisationPatients}
      />
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        exact
        path={`${match.path}/commissions`}
        component={OrganisationCommissions}
      />
      <Redirect from={`${match.path}/affiliate_links`} to={`${match.path}/profile`} />
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        exact
        path={`${match.path}/checkout`}
        component={OrganisationCheckout}
      />
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        path={`${match.path}/shop`}
        component={OrganisationShop}
      />
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        path={`${match.path}/order/:orderId`}
        component={OrganisationViewOrder}
        exact
      />
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        path={`${match.path}/orders`}
        component={OrganisationOrders}
      />
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
