import { useRef } from "react";

import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import { PARTNER_USER_PROFILE_FIELDS, PARTNER_USER_PROFILE_QUERY } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useScrollToError from "hooks/form/useScrollToError";
import { PanelBoxV2 } from "tpo/Boxes";
import EditFormButtons from "tpo/EditFormButtons";

import ExpertPanel from "./sections/Expert";
import OtherDetails from "./sections/OtherDetails";
import YourDetailsSection from "./sections/YourDetails";

function getDefaultValues(partnerUserProfile) {
  const yourDetailsFormFields = {
    yourDetailsFirstName: partnerUserProfile.user.firstName || "",
    yourDetailsLastName: partnerUserProfile.user.lastName || "",
    yourDetailsJobRole: partnerUserProfile.jobRole || "",
    yourDetailsPhoneNumber: partnerUserProfile.phoneNumber || ""
  };
  const expertFormFields = {
    expertPublic: partnerUserProfile?.expert?.public || false,
    expertAvatar: {
      key: partnerUserProfile?.expert?.avatar || "",
      fileUrl: partnerUserProfile?.expert?.resizedAvatarUrl || ""
    },
    expertBio: partnerUserProfile?.expert?.bio || "",
    expertConsultationType: partnerUserProfile?.expert?.consultationType || "",
    expertSupportedTests: partnerUserProfile?.expert?.supportedTests?.map(st => st.id) || [],
    expertSpecialisms: partnerUserProfile?.expert?.specialisms?.map(s => s.id) || [],
    expertTestimonials: partnerUserProfile?.expert?.testimonials
      ? [
          ...partnerUserProfile?.expert.testimonials,
          ...[...Array(5 - partnerUserProfile?.expert.testimonials.length).keys()].map(() => ({}))
        ].map(obj => ({
          id: obj.id ?? "",
          name: obj.name ?? "",
          detail: obj.detail ?? ""
        }))
      : [
          ...[...Array(5).keys()].map((_, idx) => ({
            id: "",
            name: "",
            detail: ""
          }))
        ]
  };
  const otherDetailsFormFields = {
    otherDetailsProfessionalBodies: partnerUserProfile.professionalBodies || [],
    otherDetailsInstagram: partnerUserProfile.instagram || "",
    otherDetailsLinkedin: partnerUserProfile.linkedin || "",
    otherDetailsYoutube: partnerUserProfile.youtube || "",
    otherDetailsWebsiteBlog: partnerUserProfile.websiteBlog || ""
  };
  return {
    ...yourDetailsFormFields,
    ...expertFormFields,
    ...otherDetailsFormFields
  };
}

const SAVE_PROFILE_MUTATION = gql`
  mutation SaveProfileMutation($input: SaveProfileMutationInput!) {
    saveProfileMutation(input: $input) {
      partnerUserProfile {
        ...PartnerUserProfileFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${PARTNER_USER_PROFILE_FIELDS}
`;

export default function ProfileForm({ partnerUserProfile }) {
  const submitButtonRef = useRef();

  const api = useDjangoGraphqlForm({
    defaultValues: getDefaultValues(partnerUserProfile),
    mutation: SAVE_PROFILE_MUTATION,
    mutationName: "saveProfileMutation",
    mutationOptions: {
      refetchQueries: [
        {
          query: PARTNER_USER_PROFILE_QUERY
        }
      ]
    },
    transformer: data => ({
      ...data,
      expertConsultationType: data.expertConsultationType
        ? data.expertConsultationType.join(",")
        : null,
      expertAvatar: data.expertAvatar?.key || ""
    }),
    handleSuccess: ({ data, api: { reset } }) => {
      reset(getDefaultValues(data.saveProfileMutation.partnerUserProfile));
      console.log("Profile saved successfully");
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
      }
    },
    handleFailure: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
    }
  });

  useScrollToError(api);

  // TODO: remember to test the form resubmis after errors are encountered

  return (
    <>
      <FormProvider {...api.methods} submitButtonRef={submitButtonRef}>
        <form onSubmit={api.methods.handleSubmit(api.onSubmit)}>
          <YourDetailsSection />
          <ExpertPanel partnerUserProfile={partnerUserProfile} />
          <OtherDetails partnerUserProfile={partnerUserProfile} />
          {api.methods.formState.isDirty && (
            <PanelBoxV2
              outer={{
                bg: "white",
                position: "sticky",
                bottom: 0,
                borderTopColor: "haze",
                borderTopStyle: "solid",
                borderTopWidth: 1,
                pt: [20, 20, 40],
                pb: [30, 30, 60],
                transition: "all 100ms ease-out",
                transform: "translateY(0)",
                opacity: 1,
                zIndex: 1
              }}
              gap={10}
              inner={{
                display: "flex",
                justifyContent: "center",
                gap: 20
              }}
            >
              <EditFormButtons
                cancelButtonProps={{
                  "data-component-name": "practitionerProfileCancelButton"
                }}
                saveButtonProps={{
                  "data-component-name": "practitionerProfileSaveButton"
                }}
              />
            </PanelBoxV2>
          )}
        </form>
      </FormProvider>
    </>
  );
}
