import { useContext } from "react";

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { theme } from "../core/theme";
import { SIGNUP_DONE_URL } from "../core/urls";
import { RESEND_VERIFICATION_EMAIL } from "../graphql/accounts";
import { SolidButton } from "./Buttons";
import { FormContext } from "./Form";

const EMAIL_NOT_VERIFIED_ERROR_MESSAGE =
  "We notice your account is not yet fully activated. Please complete email verification step.";

export default function ResendVerificationEmailButton() {
  const { values, errors, submitting, submit } = useContext(FormContext);
  const history = useHistory();
  const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL);

  if (errors === EMAIL_NOT_VERIFIED_ERROR_MESSAGE) {
    return (
      <SolidButton
        width="100%"
        maxWidth={300}
        mx="auto"
        marginTop={theme.spacing.large}
        handleClick={() =>
          resendVerificationEmail({
            variables: {
              input: {
                email: values?.email
              }
            }
          }).then(response => {
            if (
              response?.data?.resendVerificationEmailMutation &&
              !response?.data?.resendVerificationEmailMutation?.errors?.length
            ) {
              return history.push(SIGNUP_DONE_URL);
            }
          })
        }
      >
        resend verification email
      </SolidButton>
    );
  }

  return (
    <SolidButton
      submitting={submitting}
      width="100%"
      maxWidth={300}
      mx="auto"
      marginTop={theme.spacing.large}
      handleClick={() => {
        if (!submitting) {
          submit();
        }
      }}
      data-component-name="Submit button"
    >
      submit
    </SolidButton>
  );
}
