import { useCallback } from "react";

import { Redirect, useParams } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import DataLoader from "components/DataLoader";
import Page from "components/Page";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { ERROR_404_URL } from "core/urls";
import { SUPPLEMENT_DETAIL_QUERY } from "graphql/shop";
import { SupplementDetailContent } from "tpo/shop/SupplementDetailPage";

export default function SupplementDetailPage() {
  const { slug } = useParams();

  const { basket, addSupplementToBasket } = useOrganisationBasketContext();
  const { setBasketOpen } = useAppState();

  const onAddToBasket = useCallback(
    ({ supplement }) => {
      addSupplementToBasket(supplement.id);
      setBasketOpen(true);
    },
    [addSupplementToBasket, basket, setBasketOpen]
  );

  return (
    <Page bg="haze">
      <DataLoader
        query={SUPPLEMENT_DETAIL_QUERY}
        variables={{
          slug
        }}
        render={({ supplement }) =>
          supplement ? (
            <SupplementDetailContent
              onAddToBasket={() => onAddToBasket({ supplement })}
              description={supplement.description}
              name={supplement.name}
              productImage={supplement.productImage}
              dietryRestrictions={supplement.dietryRestrictions}
              price={supplement.price}
              directions={supplement.directions}
              relatedUserResults={supplement.relatedUserResults}
              dimensions={{
                columnWidth: 760
              }}
              status={supplement.status}
            />
          ) : (
            <Redirect to={ERROR_404_URL} />
          )
        }
      />
    </Page>
  );
}
