import Box from "../components/Box";
import { PanelBoxV2 } from "./Boxes";
import Spacer from "./Spacer";

function Jumbotron({ bg, color, title, top, mt }) {
  return (
    <Box bg={bg} mt={mt}>
      {top}
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          px: 20
        }}
      >
        <Spacer py={[25, 25, 40]} />
        <Box
          color={color}
          fontSize={[24, 34, 34, 44]}
          lineHeight={["130%", "115%", "115%"]}
          fontFamily="gilroyBold"
          as="h1"
        >
          {title}
        </Box>
        <Spacer py={[15, 15, 20]} />
      </PanelBoxV2>
    </Box>
  );
}

Jumbotron.defaultProps = {
  bg: "purple",
  color: "white"
};

export default Jumbotron;
