import { useEffect, useState } from "react";

import { useAppState } from "components/AppStateProvider";
import { FadeTransition } from "components/animations/FadeTransition";
import { ReactComponent as BasketIcon } from "images/tpo/basket.svg";
import Center from "tpo/Center";
import Group from "tpo/Group";
import ButtonV2 from "v2/Buttons";

export default function BasketButton({ basket, openOrder }) {
  const { basketOpen, setBasketOpen } = useAppState();
  const [basketCount, setBasketCount] = useState(0);

  useEffect(() => {
    if (openOrder) {
      setBasketCount(openOrder.testItems.length + openOrder.supplementItems.length);
    } else if (basket) {
      let count = basket.productLineItems.reduce((total, item) => total + item.quantity, 0);
      let supplementCount = basket.supplementLineItems.reduce(
        (total, item) => total + item.quantity,
        0
      );
      setBasketCount(count + supplementCount);
    }
  }, [basket, openOrder]);

  return (
    <Group position="relative" height="100%" justifyContent="center">
      <ButtonV2
        onClick={() => setBasketOpen(!basketOpen)}
        data-component-name="BasketButton"
        gap={10}
        p={0}
        leftIcon={<BasketIcon height={20} />}
      ></ButtonV2>
      <FadeTransition in={basketCount > 0}>
        <Center
          bg="green"
          fontSize={12}
          fontFamily="gilroyBold"
          py={0}
          px={0}
          width={24}
          height={24}
          position="absolute"
          top={-12}
          right={-12}
          borderRadius={"50%"}
          data-component-name="BasketButtonCount"
        >
          {basketCount}
        </Center>
      </FadeTransition>
    </Group>
  );
}
