import React from "react";

import useDocTitle from "../hooks/use-doc-title";
import IFramePage from "./IFramePage";

function CookiePage() {
  useDocTitle("Cookie policy");
  return <IFramePage title="Cookie Policy" src="/cookie-policy-content.html" />;
}

export default CookiePage;
