import React from "react";

import Box from "./Box";
import styles from "./RichText.module.scss";

function RichText({ children, ...props }) {
  return (
    <Box
      className={styles.richText}
      dangerouslySetInnerHTML={{ __html: children }}
      {...props}
    ></Box>
  );
}

export default RichText;
