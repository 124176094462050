import { useEffect, useState } from "react";

import Box from "components/Box";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Modal from "tpo/Modal";
import SelectPatient from "tpo/SelectPatient";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

import CreatePatientForm from "../forms/CreatePatientForm";
import PatientDetails from "./PatientDetails";

export default function PatientSelection() {
  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);

  const { organisation } = useOrganisationContext();
  const { basket, updateBasketDetails } = useOrganisationBasketContext();
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  useEffect(() => {
    if (!basket || !basket.patient || !organisation) {
      return;
    } else {
      let foundPatient = organisation.patients.find(
        patient => patient.platformUserProfile.pk === basket.patient.pk
      );
      setSelectedPatientId(foundPatient.id);
    }
  }, [basket, setSelectedPatientId]);

  const handleSelectedPatient = value => {
    if ((value && value === selectedPatientId) || !organisation) {
      return;
    }
    setSelectedPatientId(value);
    let pup = organisation.patients.find(p => p.id === value).platformUserProfile;
    updateBasketDetails({
      patient: pup.pk
    });
  };

  const patient = organisation?.patients.find(p => p.id === selectedPatientId);

  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        bg: "white",
        px: [20, 20, 40],
        pt: [20, 20, 40],
        pb: [50, 50, 80],
        borderRadius: 5
      }}
    >
      <Box fontFamily="gilroyBold" fontSize={28}>
        Assign this order to patient
      </Box>
      <Spacer py={2} />
      <Box fontFamily="gilroyMedium" fontSize={16}>
        Once you assign this order to a patient, the kit(s) will automatically be registered to them
        and can’t be changed.
      </Box>
      <Spacer py={15} />
      <SelectPatient
        patients={organisation?.patients || []}
        value={selectedPatientId}
        onChange={handleSelectedPatient}
      />
      <Spacer py={15} />
      <ButtonV2
        color="green"
        onClick={() => setShowCreatePatientModal(true)}
        rightIcon={<ChevronComponent />}
      >
        create a new patient
      </ButtonV2>
      <Spacer py={15} />
      {patient && <PatientDetails patient={patient} />}{" "}
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [2, 2, 20],
          pb: 20
        }}
        bg="white"
        show={showCreatePatientModal}
        close={() => setShowCreatePatientModal(false)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <Box maxWidth={760} mx="auto">
          <CreatePatientForm
            handleSuccess={({ data }) => {
              // TODO: previously we were emptying the patient state list
              // now patient list is unaffected.
              // We should add the new patient to the list though
              setSelectedPatientId(null);
              updateBasketDetails({
                patient: data.createPatientMutation.patient.platformUserProfile.pk
              });
              setShowCreatePatientModal(false);
            }}
          />
        </Box>
      </Modal>
    </PanelBoxV2>
  );
}
