import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import { Spinner } from "components/Buttons";
import Grid from "components/Grid";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ORGANISATION_ORDER_DETAIL_QUERY } from "graphql/organisations/queries/orders";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import Currency from "tpo/Currency";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";

import OrderListItem from "../ui/OrderListItem";
import OrganisationOrderSummary from "./OrganisationOrderSummary";

export default function OrganisationOrderDetails({ order: orderSummary }) {
  const { organisation } = useOrganisationContext();
  const [order, setOrder] = useState(orderSummary);
  const [basket, setBasket] = useState(null);
  const { data, loading } = useQuery(ORGANISATION_ORDER_DETAIL_QUERY, {
    variables: {
      organisation: parseInt(organisation.id),
      id: order.id
    }
  });

  useEffect(() => {
    if (loading || !data?.organisationOrder) return;
    setOrder(data.organisationOrder);
    setBasket(data.organisationOrder.sourceBasket);
  }, [data, loading]);

  const totalPrices = {
    refunded: basket?.refunded || 0,
    shipping: basket?.basketShippingCost || 0,
    total: basket?.basketTotalPrice || 0,
    commission: basket?.basketTotalCommission || 0
  };

  if (basket?.refunded) {
    totalPrices.total = totalPrices.total - basket.refunded;
    let ratioRefunded = basket.refunded / basket.basketTotalPrice;
    totalPrices.commission = totalPrices.commission - totalPrices.commission * ratioRefunded;
  }

  return (
    <Box
      bg="white"
      borderRadius={5}
      maxWidth={1280}
      pt={[30, 30, 60]}
      pb={[50, 50, 80]}
      px={[20, 20, 40]}
    >
      <Box maxWidth={1020} mx="auto">
        <PanelBoxV2
          maxWidth={860}
          outer={{
            px: 20,
            pb: [20, 20, 40]
          }}
        >
          <Box fontFamily="gilroyMedium" fontSize={18}>
            Ordered by:{" "}
            {loading || !basket
              ? "..."
              : `${basket?.practitioner?.user.firstName} ${basket?.practitioner?.user.lastName}`}
          </Box>
        </PanelBoxV2>
        <PanelBoxV2
          maxWidth={860}
          outer={{
            bg: "haze",
            py: 20,
            px: 20
          }}
        >
          <Box py={20}>
            <OrganisationOrderSummary order={order} />
          </Box>
          {loading || !basket ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Stack py={20}>
              <Box fontFamily="gilroyBold" fontSize={16}>
                Shipping details
              </Box>
              <Grid
                gridTemplateColumns="minmax(min-content, max-content) minmax(min-content, max-content)"
                fontFamily="gilroyMedium"
                fontSize={14}
              >
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {basket.shippingAddress.name}
                </span>
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {basket.shippingAddress.line1}
                </span>
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {basket.shippingAddress.line2}
                </span>
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {basket.shippingAddress.city}
                </span>
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {basket.shippingAddress.postcode}
                </span>
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {basket.shippingAddress.country.name}
                </span>
              </Grid>
            </Stack>
          )}
        </PanelBoxV2>
        {basket && !loading && (
          <PanelBoxV2
            maxWidth={860}
            outer={{
              pt: [30, 30, 60],
              pb: [60, 60, 120],
              px: 20
            }}
          >
            <Stack gap={20}>
              {order.testItems.map(lineItem => (
                <OrderListItem
                  order={order}
                  orderTestItem={lineItem}
                  name={lineItem.nameInBasket}
                  registrationId={lineItem.providerTestId}
                  price={lineItem.price}
                  clinicLocation={lineItem.clinicLocation}
                  clinicBooking={lineItem.clinicBooking}
                  key={lineItem.id}
                />
              ))}
              {order.supplementItems.map(lineItem => (
                <OrderListItem
                  order={order}
                  orderTestItem={lineItem}
                  name={lineItem.nameInBasket}
                  price={lineItem.price}
                  key={lineItem.id}
                />
              ))}
            </Stack>
            <Spacer py={20} />
            <Stack gap={10} alignItems="flex-end">
              {totalPrices.refunded > 0 && (
                <Currency
                  value={totalPrices.refunded * -1}
                  color="red"
                  prefix={"REFUNDED"}
                  fontSize={28}
                />
              )}
              <Currency prefix="SHIPPING" value={totalPrices.shipping} fontSize={28} />
              <Currency prefix="TOTAL" value={totalPrices.total} fontSize={44} />
              {basket.payee == "patient" && totalPrices.commission > 0 && (
                <Currency prefix="COMMISSION" value={totalPrices.commission} fontSize={28} />
              )}
            </Stack>
          </PanelBoxV2>
        )}
      </Box>
    </Box>
  );
}
