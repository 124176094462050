import { useLocation } from "react-router-dom";

import Box from "components/Box";
import { useHeaderContext } from "contexts/HeaderContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { TESTS_URL, getPartnerShopTabUrl } from "core/urls";
import LinkWrapper from "tpo/LinkWrapper";
import BurgerButton from "tpo/header/BurgerButton";

import { SHOP_TESTS_URL } from "../../core/urls";
import AffiliateDrawer from "./AffiliateDrawer";
import BasketButton from "./BasketButton";
import Common from "./Common";
import LoggedInMenuItems from "./LoggedInMenuItems";
import LoggedOutMenuDrawer from "./LoggedOutMenuDrawer";
import LoggedOutMenuItems from "./LoggedOutMenuItems";
import PractitionerDrawer from "./PractitionerDrawer";

export default function PartnerHeader({ user }) {
  // PartnerHeader should only be rendered if the user is a partner
  // and the user is at a partner related url

  const location = useLocation();
  const { open, onClick } = useHeaderContext();

  const isPractitioner = user?.partnerUserProfile?.tier === "Practitioner";
  const isAffiliate = user?.partnerUserProfile?.tier === "Affiliate";

  let drawerMenu = <LoggedOutMenuDrawer bg="dark" />;
  if (isPractitioner) {
    drawerMenu = <PractitionerDrawer bg="dark" user={user} />;
  } else if (isAffiliate) {
    drawerMenu = <AffiliateDrawer bg="dark" user={user} />;
  }

  const { basket } = useOrganisationBasketContext();

  let common = (
    <>
      <BasketButton basket={basket} />
      <BurgerButton onClick={open} />
      {drawerMenu}
    </>
  );

  let right = user ? (
    <>
      <Box
        as={LinkWrapper}
        color="white"
        fontFamily="gilroyBold"
        fontSize={16}
        to={isPractitioner ? getPartnerShopTabUrl("tests") : SHOP_TESTS_URL}
        onClick={onClick}
        display={["none", "none", "inline"]}
      >
        Shop
      </Box>
      <LoggedInMenuItems bg="dark" user={user} />
      {common}
    </>
  ) : (
    <>
      <Box
        as={LinkWrapper}
        color="white"
        fontFamily="gilroyBold"
        fontSize={16}
        to={TESTS_URL}
        onClick={onClick}
        display={["none", "none", "inline"]}
      >
        Shop
      </Box>
      <LoggedOutMenuItems bg="dark" />
      {common}
    </>
  );

  if (location.pathname.startsWith("/patients")) {
    right = null;
  }

  return (
    <Common
      bg="dark"
      left={
        <>
          <Box
            color="white"
            fontFamily="gilroyRegular"
            fontSize={15}
            textTransform="uppercase"
            letterSpacing={3.75}
            textAlign="right"
          >
            partners
          </Box>
        </>
      }
      user={user}
      right={right}
    />
  );
}
