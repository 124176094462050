import { useHistory, useLocation, matchPath } from "react-router-dom";

import Box from "components/Box";
import { useHeaderContext } from "contexts/HeaderContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import {
  DASHBOARD_HOME_URL,
  PARTNERS_COMMISSIONS_URL,
  PARTNERS_ORDERS_URL,
  PARTNERS_ORGANISATIONS_URL,
  PARTNERS_PROFILE_URL,
  PARTNERS_SHOP_URL,
  getPartnerShopTabUrl
} from "core/urls";
import useLogOut from "hooks/header/useLogOut";
import { ReactComponent as UserIcon } from "images/user.svg";
import Drawer from "tpo/Drawer";
import Group from "tpo/Group";
import NavLink from "tpo/NavLink";

export default function PractitionerDrawer({ bg }) {
  const { opened, close, onClick } = useHeaderContext();
  const { availableOrganisations, organisationId, setOrganisationId } = useOrganisationContext();
  const location = useLocation();
  const history = useHistory();

  const logout = useLogOut();

  return (
    <Drawer.Provider maxWidth={700} opened={opened} close={close}>
      <Drawer.Root bg={bg}>
        <Group height={60}>
          <Drawer.CloseButton color="white" position="fixed" right={[20, 20, "5.5vw"]} top={20} />
        </Group>
        <Box
          pt={[30, 30, 60]}
          pb={[50, 50, 80]}
          pl={[30, 30, 60]}
          pr={[20, 20, "5.5vw"]}
          overflow="auto"
        >
          <NavLink
            fontSize={24}
            color="white"
            label="Account"
            leftIcon={<UserIcon fill="white" width={20} />}
          >
            <NavLink
              color="white"
              fontSize={16}
              label="Personal"
              onClick={() => {
                onClick();
                history.push(DASHBOARD_HOME_URL);
              }}
            />
            {availableOrganisations?.map(organisation => (
              <NavLink
                key={`organisation-${organisation.id}`}
                color="white"
                fontSize={16}
                // Rest of the code
                label={organisation.name}
                active={organisationId === organisation.id}
                onClick={() => {
                  setOrganisationId(organisation.id);
                  onClick();
                  history.push(PARTNERS_ORGANISATIONS_URL);
                }}
              />
            ))}
          </NavLink>
          <NavLink
            color="white"
            label="Profile"
            fontSize={24}
            active={location.pathname === PARTNERS_PROFILE_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_PROFILE_URL);
            }}
          />
          <NavLink
            color="white"
            label="Organisation"
            fontSize={24}
            active={location.pathname === PARTNERS_ORGANISATIONS_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_ORGANISATIONS_URL);
            }}
          />
          <NavLink
            color="white"
            label="Practitioner shop"
            fontSize={24}
            active={matchPath(location.pathname, {
              path: PARTNERS_SHOP_URL,
              exact: true
            })}
            onClick={() => {
              onClick();
              history.push(getPartnerShopTabUrl("tests"));
            }}
          />
          <NavLink
            color="white"
            label="Orders"
            fontSize={24}
            active={location.pathname === PARTNERS_ORDERS_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_ORDERS_URL);
            }}
          />
          <NavLink
            color="white"
            label="Commission"
            fontSize={24}
            active={location.pathname === PARTNERS_COMMISSIONS_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_COMMISSIONS_URL);
            }}
          />
          <NavLink color="red" label="Logout" onClick={logout} />
        </Box>
      </Drawer.Root>
    </Drawer.Provider>
  );
}
