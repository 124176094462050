import { useHistory } from "react-router-dom";

import styled from "styled-components";

import Box from "../components/Box";
import { SolidButton as OldSolidButton } from "../components/Buttons";
import Text from "../components/Text";
import { theme } from "../core/theme";
import { getTestUrl } from "../core/urls";
import Section from "../deprecated/Section";
import { ReactComponent as Tick } from "../images/tick.svg";
import {
  HeadingExtraExtraSmall,
  HeadingExtraLarge,
  HeadingExtraSmall,
  HeadingMedium
} from "./Headings";
import { HeadingMediumLarge } from "./Headings";

function IconPositionNumber({ position }) {
  return (
    <Box
      bg="brand"
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={114}
      height={114}
    >
      <Text color="white" fontSize={44} fontFamily="gilroyBold">
        #{position}
      </Text>
    </Box>
  );
}

function PrimaryHeader({ color, small = false, textAlign = "center" }) {
  return (
    <Section.Header>
      <HeadingExtraLarge
        color={color}
        fontSize={small ? [18, 28, 28] : [24, 34, 34, 44]}
        textAlign={textAlign}
      >
        Best matched test
      </HeadingExtraLarge>
    </Section.Header>
  );
}

function SecondHeader({ color }) {
  return (
    <Section.Header>
      <HeadingExtraExtraSmall color={color} fontSize={[18]}>
        Your best matched test
      </HeadingExtraExtraSmall>
    </Section.Header>
  );
}

function BodyCopy({ color, mb, ml, mr, maxWidth }) {
  return (
    <Section.BodyCopy mb={mb} ml={ml} mr={mr} maxWidth={maxWidth}>
      <Text color={color} fontSize={[15, 16]}>
        This test has been selected based on the answers you provided in the self assessment
        questionnaire and does not take any previous test results into consideration.
      </Text>
    </Section.BodyCopy>
  );
}

const Image = styled.img`
  ${({ stacked }) => {
    return stacked
      ? `
    @media (max-width: ${stacked}) {
      width: 100%;
    }
    @media (min-width: ${stacked}) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    `
      : `
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    `;
  }}
`;

function SymptomList({ color, userTestRecommendation }) {
  return (
    <>
      <HeadingExtraSmall color={color} fontSize={[16, 16, 18]} mt={40}>
        Can help identify the causes of:
      </HeadingExtraSmall>
      <Box mt={30}>
        {userTestRecommendation.symptoms.slice(0, 5).map(symptom => (
          <Box display="flex" key={symptom.id} my={2}>
            <Tick
              fill={theme.colors.green}
              width={20}
              height={15}
              style={{ minWidth: "20px", minHeight: "15px" }}
            />
            <Text color={color} ml={2}>
              {symptom.name}
            </Text>
          </Box>
        ))}
      </Box>
    </>
  );
}

function Symptoms({ children, usedWithinProductContext = false, color, userTestRecommendation }) {
  const history = useHistory();

  return (
    <>
      {!usedWithinProductContext && (
        <HeadingMedium color={color} fontSize={[18, 18, 28]}>
          {userTestRecommendation.product.name}
        </HeadingMedium>
      )}
      <HeadingExtraSmall color={color} fontSize={[16, 16, 18]} mt={40}>
        Can help identify the causes of:
      </HeadingExtraSmall>
      <Box mt={30}>
        {userTestRecommendation.symptoms.slice(0, 5).map(symptom => (
          <Box display="flex" key={symptom.id} my={2}>
            <Tick
              fill={theme.colors.green}
              width={20}
              height={15}
              style={{ minWidth: "20px", minHeight: "15px" }}
            />
            <Text color={color} ml={2}>
              {symptom.name}
            </Text>
          </Box>
        ))}
      </Box>
      {!usedWithinProductContext && (
        <OldSolidButton
          paddingSize="mediumNarrow"
          bg="green"
          borderColor="green"
          mr="auto"
          mt={30}
          handleClick={() => {
            history.push({
              pathname: getTestUrl(
                userTestRecommendation.product.slug,
                userTestRecommendation.product.productFamily.slug
              ),
              state: {
                fromSection: false
              }
            });
          }}
        >
          Learn More &gt;
        </OldSolidButton>
      )}
      {children}
    </>
  );
}

function TestMatchDegreeComponent({ degree, bg, color, ...rest }) {
  return (
    <Box {...rest}>
      <Box display="flex" mb={20}>
        <Box bg={bg} width={17} height={17} borderRadius={100} mr={2} />
        <HeadingMediumLarge fontSize={[18, 28]} color={color}>
          {degree}
        </HeadingMediumLarge>
      </Box>
      <Text color={color} fontFamily="gilroyMedium">
        Match for your current symptoms
      </Text>
    </Box>
  );
}

function TestMatchDegree({ userTestRecommendation, ...props }) {
  if (userTestRecommendation.score < 0.14) {
    return <TestMatchDegreeComponent bg="#FC9257" degree="Very Weak" {...props} />;
  }
  if (userTestRecommendation.score < 0.28) {
    return <TestMatchDegreeComponent bg="#FCCE57" degree="Weak" {...props} />;
  }
  if (userTestRecommendation.score < 0.42) {
    return <TestMatchDegreeComponent bg="#D3F060" degree="Moderate" {...props} />;
  }
  if (userTestRecommendation.score < 0.56) {
    return <TestMatchDegreeComponent bg="#36D275" degree="Strong" {...props} />;
  }
  return <TestMatchDegreeComponent bg="#00BF86" degree="Very Strong" {...props} />;
}

export const BestMatchedTest = Object.assign(
  {},
  {
    Icon: ({ position = 1 }) => <IconPositionNumber position={position} />,
    Header: {
      Primary: PrimaryHeader,
      Secondary: SecondHeader
    },
    BodyCopy,
    Symptoms,
    SymptomList,
    Image,
    TestMatchDegree
  }
);
