import React, { useEffect } from "react";

import { DASHBOARD_HOME_URL } from "../core/urls";
import Loading from "./Loading";

function HomePage() {
  useEffect(() => {
    window.location.href = DASHBOARD_HOME_URL;
  });

  return <Loading />;
}

export default HomePage;
