import { useCallback } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

export default function StripeConnectDetailsPanel() {
  const { organisation, updateOrganisationDetails } = useOrganisationContext();

  const handleToggleAutomaticInvoicing = useCallback(
    checked => {
      updateOrganisationDetails({
        automaticInvoicingEnabled: checked
      });
    },
    [organisation, updateOrganisationDetails]
  );

  return (
    <Box>
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
        Link your bank account
      </Box>
      <Spacer py={40 / 2} />
      <Box fontFamily="gilroyBold" fontSize={18}>
        Automatic invoicing
      </Box>
      <Spacer py={2} />
      <Box fontFamily="gilroyMedium" fontSize={16}>
        For a more seamless payment integration you can opt in to have invoices automatically taken
        from your account when they are due. If you opt in we will give you an extra 5% discount on
        your next order.
      </Box>
      <Spacer py={4} />
      <Checkbox
        label={"Allow payment to be automatically taken after 45 days"}
        checked={organisation?.automaticInvoicingEnabled}
        onChange={({ target }) => handleToggleAutomaticInvoicing(target.checked)}
      />
      <Spacer py={4} />
      <Center>
        <ExternalTextLink
          href={organisation?.onboardingUrl}
          target="_self"
          rel="noopener noreferrer"
        >
          <ButtonV2 color="green" rightIcon={<ChevronComponent />}>
            Edit Bank Details
          </ButtonV2>
        </ExternalTextLink>
      </Center>
    </Box>
  );
}
