import { MIGRATE_AFFILIATE_TO_PRACTITIONER_MUTATION } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";

import Common from "./ui/CommonForm";

export default function AffiliateApplyingToBePractitionerForm({
  setFormSubmitted,
  affiliateProfile
}) {
  const api = useDjangoGraphqlForm({
    transformer: input => ({
      ...input,
      professionalBodies: input.professionalBodies.join(","),
      documents: JSON.stringify(
        input.documents.reduce(
          (acc, doc) => ({
            ...acc,
            [doc.type]: {
              key: doc.key,
              name: doc.name
            }
          }),
          {}
        )
      )
    }),
    mutation: MIGRATE_AFFILIATE_TO_PRACTITIONER_MUTATION,
    mutationName: "migrateAffiliateToPractitionerMutation",
    defaultValues: {
      practitionerType: "",
      professionalBodies: [],
      documents: [],
      businessName: affiliateProfile.businessName || "",
      phoneNumber: affiliateProfile.phoneNumber || "",
      jobRole: affiliateProfile.jobRole || "",
      businessLocationCity: affiliateProfile.businessLocationCity || "",
      sources: affiliateProfile.sources || "",
      sourcesOther: affiliateProfile.sourcesOther || "",
      instagram: affiliateProfile.instagram || "",
      linkedin: affiliateProfile.linkedin || "",
      youtube: affiliateProfile.youtube || "",
      websiteBlog: affiliateProfile.websiteBlog || "",
      acceptTerms: false
    },
    handleSuccess: () => {
      setFormSubmitted(true);
    }
  });

  return <Common api={api} includePartnerUpdatesConsentField={false} />;
}
