import { useEffect, useState } from "react";

import Box from "components/Box";
import { MODAL_Z_INDEX } from "core/constants";
import Group from "tpo/Group";
import ToggleSwitch from "tpo/ToggleSwitch";
import Tooltip from "tpo/Tooltip";
import { QuestionButton } from "v2/Buttons";

export function OrganisationPayeeOptions({ basket, onboardingComplete, onBasketUpdate }) {
  // if any of basket.productLineItems have a valid .clinicLocation, then disable the toggle

  const [hasClinicLocations, setHasClinicLocations] = useState(false);

  useEffect(() => {
    if (!basket) return;

    setHasClinicLocations(basket?.productLineItems?.some(item => item?.clinicLocation?.id));
  }, [basket]);

  return onboardingComplete ? (
    <>
      <Group gap={20} position="relative">
        <Group alignItems="center" gap={10}>
          <Tooltip
            content={
              <Box
                bg="black"
                color="white"
                maxWidth={200}
                p={2}
                pb={20}
                borderRadius={5}
                zIndex={MODAL_Z_INDEX + 1}
                position="relative"
              >
                When you order as stock you will only be charged for the kit price + postage. Once
                the kit is registered we will charge the remaining balance + a postage fee to return
                to the lab.
              </Box>
            }
            placement="bottom-end"
          >
            <QuestionButton />
          </Tooltip>
          <Box color="dark" fontFamily="gilroyBold" fontSize={15} lineHeight="24px">
            Order as stock?
          </Box>
        </Group>
        <ToggleSwitch
          data={[
            {
              label: "No",
              value: false,
              bg: "dark",
              color: "white"
            },
            {
              label: "Yes",
              value: true,
              bg: "white",
              color: "dark"
            }
          ]}
          onChange={purchaseAsStock =>
            onBasketUpdate({ purchaseAsStock: hasClinicLocations ? false : purchaseAsStock })
          }
          value={basket?.purchaseAsStock}
          height={40}
          disabledBackground="haze"
        />
        {hasClinicLocations && (
          <Tooltip
            content={
              <Box
                bg="black"
                color="white"
                p={2}
                borderRadius={5}
                maxWidth={250}
                textAlign="center"
                zIndex={MODAL_Z_INDEX + 1}
                position="relative"
              >
                Cannot purchase as stock when basket contains products with clinic bookings.
              </Box>
            }
            placement="bottom"
          >
            <Box
              position="absolute"
              top={0}
              right={0}
              width="100%"
              height="100%"
              bg="rgba(255, 255, 255, 0.7)"
              style={{ cursor: "not-allowed" }}
              onClick={e => e.stopPropagation()}
            />
          </Tooltip>
        )}
      </Group>
    </>
  ) : null;
}
