import { useContext } from "react";

import Box from "components/Box";
import Grid from "components/Grid";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import { ModalInstanceContext } from "tpo/Modal";
import Stack from "tpo/Stack";

import BiomarkersAndSymptomsList from "./BiomarkersAndSymptomsList";
import Carousel from "./Carousel";
import DiscoverOtherTestsPanel from "./DiscoverOtherTestsPanel";
import MainDetails from "./MainDetails";
import SampleReports from "./SampleReports";
import SecurityPanel from "./SecurityPanel";
import SelfAssessmentInvitationPanel from "./SelfAssessmentInvitationPanel";
import TestProductInformationPanels from "./TestProductInformationPanels";
import TestRecommendationPanel from "./TestRecommendationPanel";
import YourResultsPanel from "./YourResultsPanel";

// TODO - change Component name to the file name
export default function TestProductDetailContent({
  carouselImages,
  name,
  description,
  categories,
  sampleTypes,
  numOfBiomarkersTested,
  processingTime,
  options,
  consumerCurrentPrice,
  consumerFullPrice,
  userTestRecommendation,
  slug,
  informationPanels,
  sampleReports,
  biomarkersTested,
  relatedSymptoms,
  onProductOptionSelection,
  testType,
  practitionerSupportRequired,
  dimensions = {},
  onAddToBasket,
  status,
  howItWorksPanel
}) {
  const modalInstanceContext = useContext(ModalInstanceContext);
  const withInModal = !!modalInstanceContext;

  return (
    <>
      <Box display="none" data-component-name="ProductDetail" />
      {!!carouselImages.length && (
        <PanelBoxV2
          maxWidth={1020}
          outer={{
            bg: "white",
            pt: withInModal ? [50, 50, 0] : [50, 50, 80],
            pb: [60],
            px: [20, 20, "5.5vw"]
          }}
        >
          <Box bg="haze" p={20}>
            <Carousel images={carouselImages} bg="haze" />
          </Box>
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "haze",
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, "5.5vw"]
        }}
        stacked
        gap={[20, 20, 40]}
      >
        <Box>
          <MainDetails
            status={status}
            name={name}
            description={description}
            categories={categories}
            sampleTypes={sampleTypes}
            numOfBiomarkersTested={numOfBiomarkersTested}
            processingTime={processingTime}
            options={options}
            priceElement={
              <Group gap={10}>
                {consumerFullPrice !== undefined && consumerFullPrice > consumerCurrentPrice && (
                  <Currency fontSize={[44]} value={consumerFullPrice} color="red" strikethrough />
                )}
                <Currency fontSize={[44]} value={consumerCurrentPrice} />
              </Group>
            }
            onProductOptionSelection={onProductOptionSelection}
            productOptionCardStackBg="white"
            testType={testType}
            practitionerSupportRequired={practitionerSupportRequired}
            onAddToBasket={onAddToBasket}
          />
        </Box>
        <TestRecommendationPanel userTestRecommendation={userTestRecommendation} />
        {(!userTestRecommendation || userTestRecommendation.rank > 5) && (
          <SelfAssessmentInvitationPanel productSlug={slug} />
        )}
        <Stack gap={40}>
          <TestProductInformationPanels panels={informationPanels} />
          {!!sampleReports?.length && (
            <Box bg="white" borderRadius={5} px={[20, 20, 40]} py={[30, 30, 60]}>
              <SampleReports sampleReports={sampleReports} />
            </Box>
          )}
        </Stack>
      </PanelBoxV2>
      {!!(biomarkersTested?.length || relatedSymptoms?.length) && (
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "partners",
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            px: [20, 20, "5.5vw"]
          }}
        >
          <BiomarkersAndSymptomsList
            relatedSymptoms={relatedSymptoms}
            biomarkersTested={biomarkersTested}
          />
        </PanelBoxV2>
      )}
      {howItWorksPanel}
      <PanelBoxV2
        maxWidth={1280}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, "5.5vw"],
          bg: "haze"
        }}
        stacked
        gap={40}
        {...(dimensions?.yourResultsPanel?.panelBox || {})}
      >
        <YourResultsPanel.Title
          containerProps={{
            maxWidth: 760,
            mx: "auto",
            ...(dimensions?.yourResultsPanel?.title || {})
          }}
        />
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          {...(dimensions?.yourResultsPanel?.cardContainer || {})}
        >
          <YourResultsPanel.ResultsCard {...(dimensions?.yourResultsPanel?.resultsCard || {})} />
          <YourResultsPanel.RecommendationsCard
            {...(dimensions?.yourResultsPanel?.recommendationsCard || {})}
          />
          <YourResultsPanel.SupportCard {...(dimensions?.yourResultsPanel?.supportCard || {})} />
        </Grid>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={1280}
        outer={{
          pt: [30, 30, 60],
          px: [20, 20, "5.5vw"],
          bg: "white"
        }}
        stacked
        gap={40}
        {...(dimensions?.securityPanel?.panelBox || {})}
      >
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr"]}
          gridRowGap={0}
          gridColumnGap={0}
          {...(dimensions?.securityPanel?.dataSecurity || {})}
        >
          <SecurityPanel.DataSecurity py={[60, 60, 120]} px={[50, 50, 80]} />
          <SecurityPanel.DataSecurityImage minHeight={[400, null]} gridRowStart={["1", null]} />
        </Grid>
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr"]}
          gridRowGap={0}
          gridColumnGap={0}
          {...(dimensions?.securityPanel?.labsYouCanTrust || {})}
        >
          <SecurityPanel.LabsYouCanTrustImage minHeight={[400, null]} gridRowStart={["1", null]} />
          <SecurityPanel.LabsYouCanTrust py={[60, 60, 120]} px={[50, 50, 80]} />
        </Grid>
      </PanelBoxV2>
      <Stack gap={40} pt={60} pb={80} bg="white">
        <DiscoverOtherTestsPanel />
      </Stack>
    </>
  );
}
