export default function ListIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.3001 2.69998C4.3001 3.85989 3.36001 4.79998 2.2001 4.79998C1.04018 4.79998 0.100098 3.85989 0.100098 2.69998C0.100098 1.54006 1.04018 0.599976 2.2001 0.599976C3.36001 0.599976 4.3001 1.54006 4.3001 2.69998Z"
        fill="inherit"
      />
      <path
        d="M4.3001 9.00002C4.3001 10.1599 3.36001 11.1 2.2001 11.1C1.04018 11.1 0.100098 10.1599 0.100098 9.00002C0.100098 7.84011 1.04018 6.90002 2.2001 6.90002C3.36001 6.90002 4.3001 7.84011 4.3001 9.00002Z"
        fill="inherit"
      />
      <path
        d="M4.3001 15.3C4.3001 16.4599 3.36001 17.4 2.2001 17.4C1.04018 17.4 0.100098 16.4599 0.100098 15.3C0.100098 14.14 1.04018 13.2 2.2001 13.2C3.36001 13.2 4.3001 14.14 4.3001 15.3Z"
        fill="inherit"
      />
      <path d="M6.3999 13.725H17.9499V16.875H6.3999V13.725Z" fill="inherit" />
      <path d="M6.3999 7.42505H17.9499V10.575H6.3999V7.42505Z" fill="inherit" />
      <path d="M6.3999 1.125H17.9499V4.275H6.3999V1.125Z" fill="inherit" />
    </svg>
  );
}
