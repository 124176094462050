import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { ActionRequiredProvider } from "contexts/ActionRequiredContext";
import { ERROR_404_URL } from "core/urls";

import CallbackRequests from "./CallbackRequests";
import ManagePatients from "./ManagePatients";
import NewResults from "./NewResults";

export default function Routes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={`${match.path}/manage_patients`} />
      </Route>
      <Route exact path={`${match.path}/manage_patients`}>
        <ActionRequiredProvider key="manage_patients">
          <ManagePatients />
        </ActionRequiredProvider>
      </Route>
      <Route exact path={`${match.path}/callback_requests`}>
        <ActionRequiredProvider key="callback_requests">
          <CallbackRequests />
        </ActionRequiredProvider>
      </Route>
      <Route exact path={`${match.path}/new_results`}>
        <ActionRequiredProvider key="new_results">
          <NewResults />
        </ActionRequiredProvider>
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
