import { useCallback, useEffect, useState } from "react";

import { FormProvider, useWatch } from "react-hook-form";

import Box from "components/Box";
import Errors from "components/Errors";
import Stack from "components/Stack";
import { FadeTransition } from "components/animations/FadeTransition";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import {
  CREATE_SHIPPING_ADDRESS_MUTATION,
  UPDATE_SHIPPING_ADDRESS_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_BASKET_DETAIL_QUERY } from "graphql/organisations/queries/organisation";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Checkbox from "tpo/Checkbox";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import NativeSelect from "tpo/NativeSelect";

export default function CreateOrUpdateShippingAddressForm({
  shippingCountries,
  handleSuccess,
  handleFailure,
  mutationOptions,
  error,
  ...props
}) {
  const { organisation } = useOrganisationContext();
  const { basket, updateBasketDetails } = useOrganisationBasketContext();
  const [shippingAddress, setShippingAddress] = useState(null);
  const [mutation, setMutation] = useState(CREATE_SHIPPING_ADDRESS_MUTATION);
  const [mutationName, setMutationName] = useState("createShippingAddressMutation");
  const [customsConfirmation, setCustomsConfirmation] = useState(basket?.customsConfirmation);
  const [saveTimeout, setSaveTimeout] = useState(null);

  useEffect(() => {
    if (!organisation || !basket?.shippingAddress) {
      return;
    }
    setShippingAddress(basket.shippingAddress);
    setMutation(UPDATE_SHIPPING_ADDRESS_MUTATION);
    setMutationName("updateShippingAddressMutation");
  }, [organisation, basket]);

  useEffect(() => {
    if (!shippingAddress) {
      return;
    }
    methods.reset({
      name: shippingAddress?.name,
      line1: shippingAddress?.line1,
      line2: shippingAddress?.line2,
      city: shippingAddress?.city,
      postcode: shippingAddress?.postcode,
      country: shippingAddress?.country?.isoCode
    });
  }, [shippingAddress]);

  mutationOptions = {
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ],
    ...mutationOptions
  };

  const { methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation,
    mutationName,
    mutationOptions,
    defaultValues: {
      name: shippingAddress?.name,
      line1: shippingAddress?.line1,
      line2: shippingAddress?.line2,
      city: shippingAddress?.city,
      postcode: shippingAddress?.postcode,
      country: shippingAddress?.country?.isoCode
    },
    handleSuccess,
    handleFailure,
    ...props
  });

  const fieldsToWatch = ["name", "line1", "line2", "city", "postcode", "country"];
  const watchAllFields = useWatch({
    control: methods.control,
    name: fieldsToWatch
  });
  const watchCountry = methods.watch("country");

  useEffect(() => {
    clearTimeout(saveTimeout);
    setSaveTimeout(
      setTimeout(() => {
        let changed = false;
        let empty = false;
        for (const idx in watchAllFields) {
          let key = fieldsToWatch[idx];
          if (watchAllFields[idx] !== shippingAddress?.[key]) {
            if (key == "country") {
              // then compare to isoCode
              if (watchAllFields[idx] !== shippingAddress?.[key]?.isoCode) {
                changed = true;
              }
            } else {
              changed = true;
            }
          }
          if (!watchAllFields[idx]) {
            empty = true;
          }
        }
        if (changed && !empty) {
          methods.handleSubmit(handleSubmitShippingAddress)();
        }
      }, 2500)
    );
  }, [watchAllFields]);

  useEffect(() => {
    if (!watchCountry) {
      return;
    }
    // if the country changes, update customs checkbox
    // and submit the change immediately
    setCustomsConfirmation(watchCountry === "GB");
    clearTimeout(saveTimeout);
    methods.handleSubmit(handleSubmitShippingAddress)();
  }, [watchCountry]);

  useEffect(() => {
    if (!basket || basket.customsConfirmation === customsConfirmation) {
      return;
    }
    updateBasketDetails({
      customsConfirmation
    });
  }, [customsConfirmation]);

  const handleSubmitShippingAddress = useCallback(
    input => {
      if (!organisation) {
        return;
      }
      return onSubmit({
        ...input,
        customsConfirmation: undefined, // only used to show submit button in non GB countries
        organisation: parseInt(organisation.id),
        id: shippingAddress?.id
      });
    },
    [organisation, onSubmit]
  );

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmitShippingAddress)}>
          <Box fontFamily="gilroyBold" fontSize={28} mb={5}>
            Shipping details
          </Box>
          <Stack gap={20}>
            <ControlledFormField name="name" Component={FloatingLabelInput} label="Name" />
            <ControlledFormField
              name="line1"
              Component={FloatingLabelInput}
              label="Address line 1"
            />
            <ControlledFormField
              name="line2"
              Component={FloatingLabelInput}
              label="Address line 2"
            />
            <ControlledFormField name="city" Component={FloatingLabelInput} label="City" />
            <ControlledFormField name="postcode" Component={FloatingLabelInput} label="Postcode" />
            <ControlledFormField name="country" Component={NativeSelect} label="">
              <option value="">Select country</option>
              {shippingCountries.map((country, idx) => (
                <option key={`${country.isoCode}-${idx}`} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </ControlledFormField>
          </Stack>
        </form>
      </FormProvider>
      <FadeTransition in={watchCountry && watchCountry !== "GB" && !error} timeout={50}>
        <Box fontFamily="gilroyMedium" fontSize={16} mb={5}>
          All our products are shipped from the UK and will be sent “DAP – Delivery at place”. We
          will arrange for our courier to deliver to your specified address, but please be aware
          that you as the buyer will be responsible for any cost related to local custom clearance
          regulations.
        </Box>
        <Checkbox
          label="I understand I am responsible for any cost related to local custom clearance regulation"
          checked={customsConfirmation}
          onChange={({ target }) => setCustomsConfirmation(target.checked)}
        />
      </FadeTransition>
      <FadeTransition in={nonFieldError || error}>
        <Errors>{nonFieldError || error}</Errors>
      </FadeTransition>
    </>
  );
}
