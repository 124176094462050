import { useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { USER_SUBMISSION_FIELDS_TRUNCATED } from "../graphql/tpo/results/types";
import { AnswersContext, DownloadQuestionsContext } from "./QuestionnaireContexts";

const USER_SUBMISSION_QUERY = gql`
  query UserSubmissionQuery {
    userSubmission {
      ...UserSubmissionFieldsTruncated
      sectionStatus
    }
  }
  ${USER_SUBMISSION_FIELDS_TRUNCATED}
`;

export function useSubmission() {
  const { data } = useQuery(USER_SUBMISSION_QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache"
  });
  return data?.userSubmission;
}

export function useSection() {
  const { step: section } = useParams();
  return +section;
}

function useQuestionsAndAnswersAndLatestSubmission(query, variables) {
  const [fetch, { data: questionsAndAnswers, loading }] = useLazyQuery(query, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      ...variables
    }
  });

  useEffect(() => {
    if (variables.userSubmissionId) {
      fetch();
    }
  }, [fetch, variables.userSubmissionId]);

  return {
    loading,
    questions: questionsAndAnswers?.questions,
    answers: questionsAndAnswers?.userAnswers,
    submission: questionsAndAnswers?.userSubmission
  };
}

export function useDownloadQuestions({ query, variables }) {
  const { loading, questions, answers, submission } = useQuestionsAndAnswersAndLatestSubmission(
    query,
    variables
  );

  return {
    loading,
    questions,
    answers,
    submission
  };
}

export function usePercentageAnswers() {
  const { answered } = useContext(AnswersContext);
  const { questions } = useContext(DownloadQuestionsContext);
  if (!questions?.length) return;
  return ((Object.keys(answered).length / questions.length) * 100).toFixed(0);
}

export function useTestProductSlug() {
  const params = useParams();
  return params.testProductSlug;
}

const PRODUCT_NAME_QUERY = gql`
  query ProductNameQuery($slug: String!) {
    testProduct: consumerTestProduct(slug: $slug) {
      id
      name
      truncatedName
    }
  }
`;

export function useTestProduct() {
  const testProductSlug = useTestProductSlug();
  const { data: testProductData } = useQuery(PRODUCT_NAME_QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: { slug: testProductSlug }
  });
  return testProductData;
}
