import { forwardRef } from "react";

import Box from "../components/Box";

export const PanelBoxV2 = forwardRef(
  ({ children, stacked, gap, maxWidth, inner: innerProps, outer: outerProps }, ref) => {
    let outer = {};
    let inner = {};

    if (stacked) {
      inner = {
        ...inner,
        display: "flex",
        flexDirection: "column",
        gap
      };
    }

    if (maxWidth) {
      inner = {
        ...inner,
        maxWidth,
        mx: "auto",
        width: "100%"
      };
    }

    outer = {
      ...outer,
      ...outerProps
    };

    inner = {
      ...inner,
      ...innerProps
    };

    return (
      <Box ref={ref} {...outer}>
        <Box {...inner}>{children}</Box>
      </Box>
    );
  }
);

PanelBoxV2.defaultProps = {
  gap: 10
};
