import React from "react";

import BackgroundImage from "./BackgroundImage";
import Box from "./Box";
import { SizerBox } from "./Boxes";
import { HeadingExtraLarge } from "./Headings";

export default function ImageAndIconHeader({
  icon,
  heading,
  backgroundImage,
  backgroundColor,
  color
}) {
  const padding = [60, 80, 100, 120];
  const iconSize = [150, 250, null, null];

  return (
    <BackgroundImage backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Box
        py={padding}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <SizerBox width={iconSize} height={iconSize}>
          {icon}
        </SizerBox>
        <HeadingExtraLarge color={color} pt textAlign="center" as="h1">
          {heading}
        </HeadingExtraLarge>
      </Box>
    </BackgroundImage>
  );
}
