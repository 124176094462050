import { useEffect } from "react";

export default function useScrollToError(formApi) {
  useEffect(() => {
    if (formApi.scrollToError) {
      const errors = document.querySelectorAll(".error");
      if (errors.length) {
        const error = errors[0];
        error.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [formApi.scrollToError]);
}
