export default function Filter({ fill, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8281 9.125C14.292 9.125 12.9961 10.1572 12.5879 11.5625H2.51562V13.4375H12.5879C12.9951 14.8428 14.292 15.875 15.8281 15.875C17.3643 15.875 18.6601 14.8428 19.0684 13.4375H22.4844V11.5625H19.0674C18.6602 10.1572 17.3633 9.125 15.8281 9.125ZM15.8281 14C15.001 14 14.3281 13.3272 14.3281 12.5C14.3281 11.6729 15.001 11 15.8281 11C16.6553 11 17.3281 11.6729 17.3281 12.5C17.3281 13.3272 16.6553 14 15.8281 14ZM9.17188 16.625C7.63575 16.625 6.33988 17.6572 5.93163 19.0625H2.51562V20.9375H5.93163C6.33885 22.3428 7.63573 23.375 9.17188 23.375C10.708 23.375 12.0039 22.3428 12.4121 20.9375H22.4844V19.0625H12.4111C12.0039 17.6572 10.707 16.625 9.17188 16.625ZM9.17188 21.5C8.34473 21.5 7.67188 20.8272 7.67188 20C7.67188 19.1729 8.34473 18.5 9.17188 18.5C9.99903 18.5 10.6719 19.1729 10.6719 20C10.6719 20.8272 9.99903 21.5 9.17188 21.5ZM12.4111 4.0625C12.0039 2.65723 10.707 1.625 9.17088 1.625C7.63475 1.625 6.33888 2.65723 5.93063 4.0625H2.51562V5.9375H5.93163C6.33885 7.34278 7.63573 8.375 9.17188 8.375C10.708 8.375 12.0039 7.34278 12.4121 5.9375H22.4844V4.0625H12.4111ZM9.17188 6.5C8.34473 6.5 7.67188 5.82715 7.67188 5C7.67188 4.17285 8.34473 3.5 9.17188 3.5C9.99903 3.5 10.6719 4.17285 10.6719 5C10.6719 5.82715 9.99903 6.5 9.17188 6.5Z"
        fill={fill}
      />
    </svg>
  );
}

Filter.defaultProps = {
  fill: "#000",
  size: 25
};
