import Box from "components/Box";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";

import ClinicBookingOptions from "./ClinicBookingOptions";

export default function OrderListItem({
  name,
  registrationId,
  price,
  clinicLocation,
  clinicBooking,
  order,
  orderTestItem
}) {
  return (
    <Stack
      gap={20}
      pb={20}
      borderBottomColor="midGrey"
      borderBottomStyle="solid"
      borderBottomWidth={1}
    >
      <Group gap={20} justifyContent="space-between">
        <Box>
          <Box fontFamily="gilroyBold" fontSize={18}>
            {name}
          </Box>
          {registrationId && (
            <Box fontFamily="gilroyMedium" fontSize={14}>
              Kit registration ID: {registrationId}
            </Box>
          )}
        </Box>
        <Stack mt={2} alignItems="flex-end">
          <Currency
            fontSize={28}
            value={clinicLocation ? price - clinicLocation.clinic.fee : price}
          />
        </Stack>
      </Group>
      {clinicLocation && (
        <>
          <Box>
            <Box fontFamily="gilroyBold" fontSize={16}>
              Venous draw clinic:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={14}>
              {clinicLocation.clinic.name}
              {clinicLocation.address}
            </Box>
            <Group justifyContent="space-between">
              <Box fontFamily="gilroyBold" fontSize={16}>
                Clinic fee
              </Box>
              <Currency value={clinicLocation.clinic.fee} fontSize={18} />
            </Group>
          </Box>
          {clinicBooking && <ClinicBookingOptions order={order} orderTestItem={orderTestItem} />}
        </>
      )}
    </Stack>
  );
}
