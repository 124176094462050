import Box from "components/Box";

import Spacer from "./Spacer";
import Stack from "./Stack";

export default function FormControl({
  as = "div",
  label,
  labelProps,
  helpText,
  children,
  fields,
  spaceAboveChildren,
  spaceAboveHelpText,
  pb,
  borderBottom,
  "data-testid": dataTestId
}) {
  const hasChildren = !!(children || fields);

  let actualChildren = children;

  if (fields) {
    actualChildren = <Stack gap={20}>{fields}</Stack>;
  }

  return (
    <Box
      as={as}
      border={0}
      p={0}
      m={0}
      pb={pb}
      borderBottomWidth={borderBottom ? 1 : 0}
      borderBottomStyle="solid"
      borderBottomColor="dark"
      data-testid={dataTestId}
    >
      {as === "fieldset" ? (
        <Box as="legend" {...labelProps}>
          {label}
        </Box>
      ) : (
        label
      )}
      {label && helpText && <Spacer py={spaceAboveHelpText} />}
      {helpText}
      {(label || helpText) && hasChildren && <Spacer pt={spaceAboveChildren} />}
      {actualChildren}
    </Box>
  );
}

FormControl.defaultProps = {
  spaceAboveChildren: 4,
  spaceAboveHelpText: 1
};
