import { cloneElement } from "react";

import Box from "../components/Box";

function Tabs({ children, pl }) {
  if (!children) return null;

  return (
    <Box display="flex" overflowX="auto" gap={10} pl={pl}>
      {children.map((child, index) =>
        cloneElement(child, {
          ml: index === 0 ? "auto" : null,
          mr: index === children.length - 1 ? "auto" : null
        })
      )}
    </Box>
  );
}

Tabs.defaultProps = {
  width: "100vw",
  pl: 20
};

export default Tabs;
