// return a timstamp that updates
import { useEffect, useState } from "react";

export default function useBackoff({ initial = 1000, max = 10000, backoffFactor = 2 }) {
  const [current, setCurrent] = useState(initial);

  function next() {
    let newCurrent = Math.min(current * backoffFactor, max);
    setCurrent(newCurrent);
  }

  return [current, { next }];
}
