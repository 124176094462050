import React from "react";

import PropTypes from "prop-types";

import Box from "./Box";

function Header({ children, color, maxWidth, backgroundColor }) {
  return (
    <Box
      px={20}
      pt={[100, 110, 120, null]}
      pb={[30, null, 40, null]}
      bg={backgroundColor}
      data-component-name="Header"
    >
      <Box maxWidth={maxWidth} mx="auto" color={color}>
        {children}
      </Box>
    </Box>
  );
}

Header.defaultProps = {
  maxWidth: 1200,
  color: "white",
  backgroundColor: "blue"
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  maxWidth: PropTypes.number
};

export default Header;
