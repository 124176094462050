const { default: Box } = require("components/Box");
const {
  default: ProveQualificationsField
} = require("tpo/partnerDashboard/practitioner/Application/ui/ProfessionalQualificationsField");
const { useFormContext } = require("react-hook-form");
const { default: FormControl } = require("tpo/FormControl");
const { default: Stack } = require("tpo/Stack");

export default function QualificationProof({ practitionerTypes }) {
  const { watch } = useFormContext();
  const practitionerTypeValue = watch("practitionerType");

  if (!practitionerTypeValue) return null;

  return (
    <FormControl
      label={
        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="26px">
          Qualification Proof
        </Box>
      }
      spaceAboveHelpText={2}
      helpText={
        <Box fontFamily="gilroyMedium" fontSize={16}>
          Please upload a copy of at least one of your qualifications. We will require this before
          we can complete your approval to the platform.
        </Box>
      }
      spaceAboveChildren={[20, 20, 40]}
      data-testid="qualificationProofField"
    >
      <ProveQualificationsField practitionerTypes={practitionerTypes} />
    </FormControl>
  );
}
