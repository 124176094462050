export const getTotalAnsweredAndAsked = (allQuestions, allAnswers) => {
  const totalQuestions = allQuestions.map(question => question.id);
  const totalAnswered = new Set(
    allAnswers
      .map(answer => answer?.question?.id)
      .filter(questionId => totalQuestions.includes(questionId))
  ).size;

  return [totalQuestions.length, totalAnswered];
};

export const padQuestionNumber = (questionNumber, totalQuestions) => {
  return questionNumber.toString().padStart(totalQuestions.toString().length, "0");
};

export function usedQuestionnaireNavigation(location) {
  return location.state?.fromSection;
}

export function usedAppBackButton(history) {
  return history.action === "POP";
}
