import { createContext, useContext, useMemo } from "react";

import { gql, useQuery } from "@apollo/client";

import { useOrganisationContext } from "./organisations/OrganisationContext";

const ActionRequiredContext = createContext();

const ACTION_REQUIRED_QUERY = gql`
  query ActionRequired($organisation: ID!) {
    contactExpertsUnrespondedCount
    organisationUserTestsForReview(organisation: $organisation) {
      id
      user {
        id
        fullName
        email
      }
      testProduct {
        id
        name
      }
      orderTestItem {
        id
        providerTestId
      }
    }
  }
`;

export function ActionRequiredProvider({ children }) {
  const { organisation } = useOrganisationContext();

  const {
    data: { contactExpertsUnrespondedCount, organisationUserTestsForReview } = {},
    refetch
  } = useQuery(ACTION_REQUIRED_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      organisation: parseInt(organisation?.id)
    },
    skip: !organisation
  });

  const callbackRequestsCount = contactExpertsUnrespondedCount;
  const newResultsCount = organisationUserTestsForReview?.length;

  const api = useMemo(
    () => ({
      callbackRequestsCount,
      newResultsCount,
      refetch
    }),
    [callbackRequestsCount, newResultsCount, refetch]
  );

  return <ActionRequiredContext.Provider value={api}>{children}</ActionRequiredContext.Provider>;
}

export function useActionRequired() {
  return useContext(ActionRequiredContext);
}
