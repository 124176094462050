import React from "react";

import { useHistory } from "react-router-dom";

import { RESET_PASSWORD_COMPLETE_URL } from "../core/urls";
import { RESET_PASSWORD_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import Box from "./Box";
import Form from "./Form";
import Input from "./Input";

function ResetPasswordPage({ match }) {
  useDocTitle("Reset password");
  const history = useHistory();
  return (
    <AccountPage heading="Password reset">
      <Box pt={40}>
        <Form
          mutation={RESET_PASSWORD_MUTATION}
          handleSubmitted={() => {
            history.push(RESET_PASSWORD_COMPLETE_URL);
          }}
          extraInput={{
            userId: match.params.userId,
            token: match.params.token
          }}
          data={[
            {
              fields: [
                {
                  name: "newPassword",
                  initialValue: "",
                  widget: {
                    component: Input,
                    props: {
                      type: "password",
                      label: "New password",
                      showEye: true
                    }
                  }
                }
              ]
            }
          ]}
          formWrapperProps={{
            mb: 30
          }}
        />
      </Box>
    </AccountPage>
  );
}

export default ResetPasswordPage;
