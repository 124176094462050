import { forwardRef, useState } from "react";

import { FadeTransition } from "components/animations/FadeTransition";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import { ResultPresentationProvider } from "contexts/ResultPresentation";
import { useOnScreenBoundingRectAnchor } from "hooks/useOnScreen";
import { Results } from "tpo/QuestionnaireOutro";
import { buildDiscreteChartConfig } from "utils/charts/discreteCharts";
import { buildGradientChartConfig } from "utils/charts/gradientCharts";
import {
  buildSegmentedChartConfig,
  buildSegmentedProgressChartConfig
} from "utils/charts/segmentedCharts";

import ArrowCircle from "../components/ArrowCircle";
import Box from "../components/Box";
import Circle from "../components/Circle";
import { SpanText } from "../components/Text";
import Danger from "../images/Danger";
import { ReactComponent as CotIcon } from "../images/cot.svg";
import Center from "./Center";
import {
  DiscreteChart,
  GradientChart,
  SegmentedChart,
  SegmentedProgressChart,
  UnknownChart
} from "./Charts";
import Group from "./Group";
import Stack from "./Stack";

function ExpiredMarkersMessage({ dateExpired }) {
  return (
    <Center>
      <Group gap={10} alignItems="center">
        <Danger />
        <Box color="error" fontSize={16} fontFamily="gilroyBold">
          {dateExpired
            ? `Expired ${new Date(dateExpired).toLocaleDateString(undefined, {
                month: "numeric",
                year: "numeric"
              })}`
            : "All markers have expired"}
        </Box>
      </Group>
    </Center>
  );
}

export function DiscreteChartCard({ children, result, isGenesTest }) {
  const { datapointHasExpired, includeExpiredMarkers } = useChangeOverTimeContext();
  const { interpretationColor, ...chartProps } = buildDiscreteChartConfig({
    colours: result.chartConfiguration.colours,
    terminology: result.chartConfiguration.terminology,
    normalisedRange: result.chartConfiguration.normalisedRange,
    value:
      includeExpiredMarkers || Results.isGenesRelated
        ? result.chartConfiguration.normalisedRange.value
        : result.freshValue
  });

  const chartCard = (
    <Stack>
      <Box>
        <Box fontSize={16} fontFamily="gilroyBold" textTransform="uppercase" textAlign="center">
          {result.chartConfiguration.interpretation}
        </Box>
        <Box py={1} />
        <DiscreteChart
          {...chartProps}
          hasExpired={!includeExpiredMarkers && datapointHasExpired(result)}
        />
      </Box>
      {!includeExpiredMarkers && datapointHasExpired(result) && (
        <ExpiredMarkersMessage dateExpired={result.dateExpired} />
      )}
    </Stack>
  );

  if (children) {
    return (
      <ResultPresentationProvider
        value={{
          chartCard,
          chartProps,
          result,
          interpretationColor
        }}
      >
        {children}
      </ResultPresentationProvider>
    );
  }

  return chartCard;
}

export function SegementedProgressChartCard({ children, result, isGenesTest }) {
  const { includeExpiredMarkers, datapointHasExpired } = useChangeOverTimeContext();

  const { interpretationColor, ...chartProps } = buildSegmentedProgressChartConfig({
    colours: result.chartConfiguration.colours,
    value:
      includeExpiredMarkers || result.isGenesRelated
        ? result.chartConfiguration.normalisedRange.value
        : result.freshValue,
    terminology: result.chartConfiguration.terminology,
    fill: true
  });

  const headingMaps = {
    CONTINUOUS_CHART: "Probability",
    GENES_RISK_CHART: "Risk",
    GENES_POTENTIAL_CHART: "Potential"
  };

  const chartCard = (
    <>
      <Box fontSize={10} fontFamily="gilroyBold" textTransform="uppercase" textAlign="center">
        {result.chartConfiguration.interpretationQualifier || headingMaps[result.result.chartType]}
      </Box>
      <Box fontSize={16} fontFamily="gilroyBold" textTransform="uppercase" textAlign="center">
        {result.chartConfiguration.interpretation}
      </Box>
      <Box py={2} />
      <SegmentedProgressChart
        {...chartProps}
        hasExpired={!includeExpiredMarkers && datapointHasExpired(result)}
      />
      <Box py={2} />
      {!includeExpiredMarkers && datapointHasExpired(result) && (
        <ExpiredMarkersMessage dateExpired={result.dateExpired} />
      )}
    </>
  );

  if (children) {
    return (
      <ResultPresentationProvider
        value={{
          chartCard,
          chartProps,
          result,
          interpretationColor
        }}
      >
        {children}
      </ResultPresentationProvider>
    );
  }

  return chartCard;
}

export function GradientsChartsCard({ children, datapoint }) {
  const chartProps = buildGradientChartConfig({
    range: datapoint.chartConfiguration.chartRange.range,
    normalisedRange: datapoint.chartConfiguration.normalisedRange,
    terminology: datapoint.chartConfiguration.terminology,
    title: datapoint.datapoint.name
  });

  const { datapointHasExpired, includeExpiredMarkers } = useChangeOverTimeContext();
  const [onScreen, OnScreenAnchor] = useOnScreenBoundingRectAnchor({
    rootMargin: "0px 0px -150px 0px",
    once: true
  });

  const units = datapoint?.datapoint?.units;
  const value = datapoint?.value;
  const interpretation = datapoint.chartConfiguration.interpretation;

  const chartCard = chartProps ? (
    <Stack>
      <OnScreenAnchor />
      <Box>
        {" "}
        <Box fontSize={15} textAlign="center">
          <Box as="span" fontFamily="gilroyBold" color="dark" key="value">
            {value}
          </Box>
          {units && (
            <Box as="span" fontFamily="gilroyBold" color="midGrey" key="units">
              {units}
            </Box>
          )}
        </Box>
        <Box fontSize={16} fontFamily="gilroyBold" textTransform="uppercase" textAlign="center">
          {interpretation}
        </Box>
        <Box py={2} />
        <GradientChart
          {...chartProps}
          hasExpired={!includeExpiredMarkers && datapointHasExpired(datapoint)}
          onScreen={onScreen}
        />
      </Box>
      {!includeExpiredMarkers && datapointHasExpired(datapoint) && (
        <Center>
          <Group gap={10} alignItems="center">
            <Danger />
            <Box color="error" fontSize={16} fontFamily="gilroyBold">
              {datapoint.dateExpired
                ? `Expired ${new Date(datapoint.dateExpired).toLocaleDateString(undefined, {
                    month: "numeric",
                    year: "numeric"
                  })}`
                : "All markers have expired"}
            </Box>
          </Group>
        </Center>
      )}
    </Stack>
  ) : (
    <UnknownChart />
  );

  let interpretationColor = "dark"; // helps to spot problems in the FE if we use a different color
  if (interpretation?.toLowerCase() === "optimal") {
    interpretationColor = "#00BF86";
  } else if (
    interpretation?.toLowerCase() === "below optimal" ||
    interpretation?.toLowerCase() === "above optimal"
  ) {
    interpretationColor = "#FCA557";
  } else if (interpretation?.toLowerCase() === "low" || interpretation?.toLowerCase() === "high") {
    interpretationColor = "#FF5D6D";
  }

  if (children) {
    return (
      <ResultPresentationProvider
        value={{
          chartCard,
          result: datapoint,
          chartProps,
          interpretationColor
        }}
      >
        {children}
      </ResultPresentationProvider>
    );
  }

  return chartCard;
}

export function SegmentedChartChard({ children, datapoint }) {
  const chartProps = buildSegmentedChartConfig({
    colours: datapoint.chartConfiguration.colours,
    terminology: datapoint.chartConfiguration.terminology,
    normalisedRange: datapoint.chartConfiguration.normalisedRange,
    interpretation: datapoint.chartConfiguration.interpretation
  });

  const { datapointHasExpired, includeExpiredMarkers } = useChangeOverTimeContext();

  const chartCard = (
    <Stack>
      <Box>
        <Box fontSize={16} fontFamily="gilroyBold" textTransform="uppercase" textAlign="center">
          {datapoint.chartConfiguration.interpretation}
        </Box>
        <Box py={2} />
        <SegmentedChart
          {...chartProps}
          hasExpired={!includeExpiredMarkers && datapointHasExpired(datapoint)}
        />
      </Box>
      {!includeExpiredMarkers && datapointHasExpired(datapoint) && (
        <ExpiredMarkersMessage dateExpired={datapoint.dateExpired} />
      )}
    </Stack>
  );

  if (children) {
    return (
      <ResultPresentationProvider
        value={{
          chartCard,
          result: datapoint,
          chartProps
        }}
      >
        {children}
      </ResultPresentationProvider>
    );
  }

  return chartCard;
}

export function GenesAllelesChartCard({ children, datapoint }) {
  const arrayValue = JSON.parse(datapoint.value);
  const {
    riskAllele,
    datapoint: { name: title }
  } = datapoint;

  const value = arrayValue.join("");
  let color = "dark";
  let risk;
  if (riskAllele?.value === 1.0) {
    color = "red";
    risk = "High";
  } else if (riskAllele?.value === 0.5) {
    color = "orange";
    risk = "Moderate";
  } else if (riskAllele?.value === 0) {
    color = "green";
    risk = "Optimal";
  }

  let splitTitle = title.split("-");
  let snp = splitTitle?.[1] || "";

  const chartProps = {
    color,
    risk,
    snp,
    type: value
  };

  const rsPrefixedSnp = "rs" + snp.trim();

  const chartCard = (
    <Center>
      <Stack>
        <Box fontSize={44} fontFamily="gilroyBold" textTransform="uppercase" textAlign="center">
          {splitTitle[0]}
        </Box>
        <Center>
          <Box>
            <Box
              as="span"
              fontSize={16}
              fontFamily="gilroyBold"
              textTransform="uppercase"
              lineHeight="150%"
            >
              snp:
            </Box>
            <Box as="span" fontSize={16} fontFamily="gilroyMedium" lineHeight="150%" ml={1}>
              {rsPrefixedSnp}
            </Box>
          </Box>
        </Center>
        <Group justifyContent="space-between" gap={40}>
          <Box>
            <Box as="span" fontSize={16} fontFamily="gilroyMedium" lineHeight="150%">
              Risk Allele:
            </Box>
            <Box
              as="span"
              fontSize={16}
              fontFamily="gilroyBold"
              textTransform="uppercase"
              lineHeight="150%"
              ml={1}
            >
              {riskAllele?.alleles?.join("")}
            </Box>
          </Box>
          <Box>
            <Box as="span" fontSize={16} fontFamily="gilroyMedium" lineHeight="150%">
              Your Alleles:
            </Box>
            <Box
              as="span"
              fontSize={16}
              fontFamily="gilroyBold"
              textTransform="uppercase"
              lineHeight="150%"
              ml={1}
              color={color}
            >
              {value}
            </Box>
          </Box>
        </Group>
      </Stack>
    </Center>
  );

  if (children) {
    return (
      <ResultPresentationProvider
        value={{
          chartCard,
          result: datapoint,
          chartProps
        }}
      >
        {children}
      </ResultPresentationProvider>
    );
  }

  return chartCard;
}

export function UnknownChartCard({ children, result }) {
  const chartCard = <UnknownChart />;

  if (children) {
    return (
      <ResultPresentationProvider
        value={{
          chartCard,
          result
        }}
      >
        {children}
      </ResultPresentationProvider>
    );
  }

  return chartCard;
}

export function RetestDueSubTitle() {
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  return includeExpiredMarkers ? (
    <Box display="flex" gap={5} fontFamily="gilroyMedium" fontSize={14}>
      <Danger />
      Due for retest
    </Box>
  ) : null;
}

export const ChartCardWrapper = forwardRef(
  ({ title, chart, onClick, showArrowCircle, cotChart, subtitle, showCotIcon }, ref) => {
    const [showCurrentChart, setShowCurrentChart] = useState(true);
    const [showCotChart, setShowCotChart] = useState(false);

    function toggleChart() {
      if (showCurrentChart) {
        setShowCurrentChart(false);
      } else {
        setShowCotChart(false);
      }
    }
    return (
      <Box
        ref={ref}
        onClick={e => {
          if (!e.cotIconClick && onClick) {
            onClick();
          }
        }}
      >
        <Box display="flex" flexDirection="column" gap={10}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Box fontFamily="gilroyBold" fontSize={16} lineHeight="24px">
                {title}
              </Box>
              {subtitle}
            </Box>
            {(showCotIcon || showArrowCircle) && (
              <Group gap={10}>
                {showCotIcon && (
                  <Circle
                    onClick={e => {
                      e.cotIconClick = true;
                      toggleChart();
                    }}
                    cursor="pointer"
                  >
                    <CotIcon />
                  </Circle>
                )}
                {showArrowCircle && <ArrowCircle cursor="pointer" />}
              </Group>
            )}
          </Box>
          <FadeTransition in={showCotChart} onExited={() => setShowCurrentChart(true)}>
            <Box
              onClick={e => {
                e.cotIconClick = true;
              }}
            >
              {cotChart}
            </Box>
          </FadeTransition>
          <FadeTransition in={showCurrentChart} onExited={() => setShowCotChart(true)}>
            <Box>{chart}</Box>
          </FadeTransition>
        </Box>
      </Box>
    );
  }
);

ChartCardWrapper.defaultProps = {
  bg: "inherit",
  pt: 20,
  pb: 40,
  showArrowCircle: true,
  showCotIcon: true
};

export function GenesAllelesText({ color, fontFamily, fontSize, marginCorrection, text }) {
  return (
    <Box>
      <SpanText
        color={color}
        fontFamily={fontFamily}
        fontSize={fontSize}
        marginCorrection={marginCorrection}
        ml={1}
      >
        {text}
      </SpanText>
    </Box>
  );
}
