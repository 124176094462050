import { gql } from "@apollo/client";
import { PAGE_INFO_FIELDS } from "graphql/pagination";

export const ORGANISATION_PRACITITIONER_LIST_QUERY = gql`
  query OrganisationPractitionerListQuery(
    $organisation: ID!
    $page: Int
    $orderBy: String
    $search: String
  ) {
    organisationInvitePagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
    ) {
      items {
        id
        created
        accepted
        lastSent
        recipient {
          id
          created
        }
        roles {
          id
          permissions
        }
        firstName
        lastName
        email
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const INVITE_PRACTITIONER_TO_ORGANISATION_MUTATION = gql`
  mutation InvitePractitionerToOrganisationMutation(
    $input: InvitePractitionerToOrganisationMutationInput!
  ) {
    invitePractitionerToOrganisationMutation(input: $input) {
      errors {
        field
        messages
      }
      organisationPractitionerInvite {
        id
        recipient {
          id
        }
      }
    }
  }
`;

export const UPDATE_PRACTITIONER_ROLE_MUTATION = gql`
  mutation UpdatePractitionerRoleMutation($input: UpdatePractitionerRoleMutationInput!) {
    updatePractitionerRoleMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
