import React from "react";

import { PropTypes } from "prop-types";

import { theme } from "../core/theme";
import { ReactComponent as Tick } from "../images/tick.svg";
import Box from "./Box";
import Text from "./Text";

function Checkbox({
  handleChange,
  onChange,
  value,
  disabled,
  label,
  error,
  fieldName,
  containsLink,
  labelProps
}) {
  let extraLabelProps = containsLink ? { position: "relative", zIndex: 1 } : {};
  // without the zIndex any link inside the label is not clickable

  handleChange = onChange || handleChange;

  return (
    <Box position="relative" data-component-name="checkbox">
      <Box
        as="input"
        id={fieldName}
        type="checkbox"
        onChange={() => {
          handleChange(!value);
        }}
        value={value}
        disabled={disabled}
        opacity={0}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        height={50}
      />
      <Box
        htmlFor={fieldName}
        as="label"
        color={error ? theme.colors.red : undefined}
        display="flex"
        alignItems="center"
        {...extraLabelProps}
        {...labelProps}
      >
        <Box
          bg={value ? "green" : "transparent"}
          width={50}
          height={50}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderColor={value ? "green" : "carbon"}
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="5px"
          flexShrink={0}
        >
          <Tick fill={value ? "white" : theme.colors.carbon} width={25} />
        </Box>
        <Text ml="15px" style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {label}
        </Text>
      </Box>
    </Box>
  );
}

Checkbox.defaultProps = {
  disabled: false,
  error: false,
  className: ""
};

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  // label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fieldName: PropTypes.string.isRequired
};

export default Checkbox;
