import { useEffect, useRef, useState } from "react";

import Box from "../components/Box";
import { theme } from "../core/theme";
import useWindowSize from "../hooks/use-window-size";
import ChevronComponent from "./Chevron";

function Button({ onClick, mr, text, direction }) {
  return (
    <Box
      as="button"
      display="flex"
      alignItems="center"
      onClick={onClick}
      cursor="pointer"
      mr={mr}
      textTransform="uppercase"
      fontSize={10}
      letterSpacing={2.8}
      fontFamily="gilroyBold"
      lineHeight="12px"
      textAlign="left"
    >
      {text}
      <Box as="span">
        <ChevronComponent fill="black" direction={direction} />
      </Box>
    </Box>
  );
}

export function CollapseableText({
  children,
  fontSize,
  lineHeight,
  maxLines,
  fontFamily,
  containerProps,
  closedStateButtonText,
  openedStateButtonText
}) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [boolean, setBoolean] = useState(false);
  const ref = useRef();

  const { width: viewportWidth } = useWindowSize();
  const viewportWidthOnLoad = useRef();

  if (viewportWidthOnLoad.current === undefined) {
    viewportWidthOnLoad.current = viewportWidth;
  }

  useEffect(() => {
    const container = ref.current;
    if (container) {
      const height = container.offsetHeight;

      let fontSizeAtThisBreakPoint = fontSize;

      if (Array.isArray(fontSize)) {
        fontSizeAtThisBreakPoint =
          fontSize
            .map((fs, idx) => [fs, theme.breakpoints[idx]])
            .find(([fs, breakpoint]) => breakpoint <= viewportWidthOnLoad.current)?.[0] ||
          fontSize[fontSize.length - 1];
      }

      if ((height - 2) / fontSizeAtThisBreakPoint > maxLines * lineHeight) {
        setIsOverflowing(true);
      }
    }
  }, [setIsOverflowing, lineHeight, maxLines, fontSize]);

  return (
    <Box {...containerProps}>
      <Box
        lineHeight={`${lineHeight}em`}
        height={(function () {
          if (!isOverflowing || boolean) {
            return "auto";
          }
          return `${maxLines * lineHeight}em`;
        })()}
        overflow="hidden"
        ref={ref}
        fontSize={fontSize}
        fontFamily={fontFamily}
        whiteSpace="pre-line"
      >
        {children}
      </Box>
      {isOverflowing && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            mr={2}
            onClick={() => setBoolean(!boolean)}
            text={boolean ? openedStateButtonText : closedStateButtonText}
            direction={boolean ? "top" : "bottom"}
          />
        </Box>
      )}
    </Box>
  );
}

CollapseableText.defaultProps = {
  lineHeight: 1.5,
  maxLines: 3,
  fontSize: 16,
  fontFamily: "gilroyMedium",
  closedStateButtonText: "read more",
  openedStateButtonText: "read less"
};

export function HeadlineCollapseableText({ lineHeight, fontSize, fontFamily, headLine, fullText }) {
  const [boolean, setBoolean] = useState(false);

  return (
    <Box>
      <Box
        lineHeight={`${lineHeight}em`}
        overflow="hidden"
        fontSize={fontSize}
        fontFamily={fontFamily}
        whiteSpace="pre-line"
      >
        {boolean ? fullText : headLine}
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button
          mr={2}
          onClick={() => setBoolean(!boolean)}
          text={boolean ? "collapse" : "expand"}
          chevronDir={boolean ? "top" : "bottom"}
        />
      </Box>
    </Box>
  );
}

HeadlineCollapseableText.defaultProps = {
  lineHeight: 1.5,
  maxLines: 1,
  fontFamily: "gilroyMedium"
};
