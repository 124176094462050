import React from "react";

import PropTypes from "prop-types";

import Box from "./Box";
import Text from "./Text";

function CenteredHeader({ color, backgroundColor, heading }) {
  return (
    <Box color={color} bg={backgroundColor}>
      <Text
        as="h2"
        fontFamily="gilroyBold"
        pt={[100, 60, 60, 60]}
        pb={[20, 60, 60, 60]}
        textAlign="center"
        fontSize={[12, 14, 16, 18]}
        uppercase
        letterSpacing="0.1em"
      >
        {heading}
      </Text>
    </Box>
  );
}

CenteredHeader.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  heading: PropTypes.string.isRequired
};

export default CenteredHeader;
