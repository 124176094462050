import { useState } from "react";

import Circle from "components/Circle";
import { useHeaderContext } from "contexts/HeaderContext";
import { useUserProfileContext } from "contexts/UserProfileContext";
import { PRACTITIONER } from "core/constants";
import { DASHBOARD_HOME_URL, PARTNERS_PROFILE_URL } from "core/urls";
import usePartnerPageCheck from "hooks/partner/usePartnerPageCheck";
import { ReactComponent as UserIcon } from "images/user.svg";
import ChevronComponent from "tpo/Chevron";
import LinkWrapper from "tpo/LinkWrapper";
import Menu from "tpo/Menu";
import ButtonV2 from "v2/Buttons";

import LoggedInMenuOrganisations from "./LoggedInMenuOrganisations";

export default function LoggedInMenuItems({ bg, user }) {
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const isPartnerPage = usePartnerPageCheck();
  const { isPartnerUser } = useUserProfileContext();
  const { onClick } = useHeaderContext();

  return (
    <>
      {user?.partnerUserProfile?.tier === PRACTITIONER && (
        <Menu
          opened={accountDropdownOpen}
          onChange={setAccountDropdownOpen}
          trigger="hover"
          xOffset={60}
          yOffset={30}
        >
          <Menu.Target>
            <ButtonV2
              display={["none", "none", "inline-flex"]}
              leftIcon={<UserIcon fill="white" width={16} />}
              px={0}
              py={0}
              letterSpacing="unset"
              textTransform="unset"
              fontSize={16}
              gap={10}
              rightIcon={<ChevronComponent direction={accountDropdownOpen ? "top" : "bottom"} />}
            >
              Account
            </ButtonV2>
          </Menu.Target>
          <Menu.Dropdown
            bg={bg}
            top={45}
            minWidth={240}
            left={-30}
            borderRadius={5}
            py={40}
            boxShadow="5px 5px 0px 0px rgba(0, 0, 0, 0.20)"
          >
            <Menu.Item
              selectedColor="rgba(255, 255, 255, 0.10)"
              color="white"
              fontFamily="gilroyBold"
              fontSize={14}
              py={16}
              px={30}
              as={LinkWrapper}
              to={DASHBOARD_HOME_URL}
              display="block"
              underline={false}
              position="relative"
              onClick={onClick}
            >
              {!isPartnerPage && (
                <Circle
                  backgroundColor="green"
                  position="absolute"
                  top="50%"
                  size={5}
                  transform="translate(-50%, -50%)"
                  left="15px"
                />
              )}
              Personal
            </Menu.Item>
            {isPartnerUser ? (
              <LoggedInMenuOrganisations />
            ) : (
              <Menu.Item
                selectedColor="rgba(255, 255, 255, 0.10)"
                color="white"
                fontFamily="gilroyBold"
                fontSize={14}
                py={16}
                px={30}
                as={LinkWrapper}
                to={PARTNERS_PROFILE_URL}
                display="block"
                underline={false}
                position="relative"
                onClick={onClick}
              >
                Partner
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );
}
