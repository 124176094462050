import BackArrow from "images/BackArrow";
import { ActionIcon } from "v2/Buttons";

export default function BackButton({ onClick }) {
  return (
    <ActionIcon onClick={onClick}>
      <BackArrow height={20} />
    </ActionIcon>
  );
}
