import { useHistory } from "react-router-dom";

import { SpacedContentBox } from "../components/Boxes";
import { SolidButton } from "../components/Buttons";
import { IntroBlockContent } from "../components/IntroBlock";
import Page from "../components/Page";
import useDocTitle from "../hooks/use-doc-title";
import ExternalKitRegistrationHeader from "./ExternalKitRegistrationHeader";

function ExternalKitRegistrationComplete() {
  useDocTitle("Register test kit");
  const history = useHistory();

  return (
    <Page addNavBarHeight={false} header={<ExternalKitRegistrationHeader />}>
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        <IntroBlockContent
          heading="Complete"
          text="Congratulations! Your test is now registered and will be linked to your account. Please follow the instructions on how to take the test, post and return to us. You will be notified via email when your results are ready to be viewed."
        />
        <SolidButton
          my={40}
          maxWidth={300}
          width="100%"
          mx="auto"
          handleClick={() => {
            history.push("/external-kit-registration");
          }}
          data-component-name="Register another button"
        >
          Register another test
        </SolidButton>
      </SpacedContentBox>
    </Page>
  );
}

export default ExternalKitRegistrationComplete;
