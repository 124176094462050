import React from "react";

import { useHistory } from "react-router-dom";

import {
  DASHBOARD_HOME_URL,
  LOGIN_URL,
  SIGNUP_COMPLETE_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL
} from "../core/urls";
import {
  ACCEPT_ADMIN_INVITATION_MUTATION,
  ACCEPT_AFFILIATE_INVITATION_MUTATION,
  ACCEPT_PROFESSIONAL_INVITATION_MUTATION,
  ACCEPT_PROFESSIONAL_LINK_INVITATION_MUTATION,
  ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION,
  AFFILIATE_LINK_QUERY,
  PARTNER_LINK_CODE_QUERY,
  RESULTS_ACCESS_REQUEST_QUERY
} from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import Checkbox from "./Checkbox";
import DataLoader from "./DataLoader";
import Form from "./Form";
import Input from "./Input";
import { ExternalTextLink } from "./Links";
import Text, { SpanText } from "./Text";

const emailField = {
  initialValue: "",
  name: "platformUserEmail",

  widget: {
    component: Input,
    props: {
      type: "email",
      label: "Email",
      autoComplete: "email"
    }
  }
};

const firstNameField = {
  initialValue: "",
  name: "platformUserFirstName",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "First name",
      autoComplete: "given-name"
    }
  }
};

function UserDetailsFooter({ partnerLinkCode }) {
  const history = useHistory();
  const url = `${LOGIN_URL}?next=/accounts/partner/link/${partnerLinkCode}`;

  return (
    <Box pt={20}>
      Already a user? Click{" "}
      <SpanText
        as="a"
        href={url}
        color="purple"
        onClick={e => {
          e.preventDefault();
          history.push(url);
        }}
        underline
      >
        here
      </SpanText>{" "}
      to login
    </Box>
  );
}

const lastNameField = {
  initialValue: "",
  name: "platformUserLastName",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Last name",
      autoComplete: "family-name"
    }
  }
};

const passwordField = {
  initialValue: "",
  name: "password",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "password",
      label: "Password",
      showEye: true
    }
  }
};

function resultsAccessGrantedField(identifier) {
  return {
    initialValue: false,
    name: "resultsAccessGranted",
    boxProps: {
      pt: 40
    },
    widget: {
      component: Checkbox,
      props: {
        label: `I'm happy for ${identifier} to have access to my results`
      }
    }
  };
}

const acceptTermsField = {
  initialValue: false,
  name: "acceptTerms",
  boxProps: {
    pt: 40
  },
  widget: {
    component: Checkbox,
    props: {
      label: (
        <span>
          I have read and agree to the{" "}
          <ExternalTextLink href={TERMS_OF_SERVICE_URL}>terms of service</ExternalTextLink>
          {" and the "}
          <ExternalTextLink href={TERMS_OF_USE_URL}>terms of use</ExternalTextLink>
        </span>
      ),
      containsLink: true
    }
  }
};

export function AcceptAdminInvite(props) {
  useDocTitle("Accept admin invite");
  return (
    <AccountPage heading="Welcome">
      <Text pt={40}>
        You've been invited to join the Omnos platform. Please set a password to begin your journey.
      </Text>
      <Box pt={40}>
        <Form
          data={[
            {
              fields: [emailField, passwordField, firstNameField, lastNameField, acceptTermsField]
            }
          ]}
          mutation={ACCEPT_ADMIN_INVITATION_MUTATION}
          extraInput={{ invitationId: props.match.params.invitationId }}
          handleSubmitted={() => {
            props.history.push(SIGNUP_COMPLETE_URL);
          }}
          formWrapperProps={{
            mb: 30
          }}
        />
      </Box>
    </AccountPage>
  );
}

function userDetailsFieldSet(props) {
  return [
    {
      fields: [emailField, passwordField, firstNameField, lastNameField]
    }
  ];
}

export function AcceptPartnerLinkInvite(props) {
  useDocTitle("Accept partner invite");
  const { user } = useAuthContext();
  return (
    <DataLoader
      query={PARTNER_LINK_CODE_QUERY}
      variables={{ partnerLinkCode: props.match.params.partnerLinkCode }}
      render={({ checkIncomingPartnerLinkCode }) => {
        if (checkIncomingPartnerLinkCode.hashId === null)
          return (
            <AccountPage heading="Invitation not found">
              <Text pt={40}>
                I'm sorry, we can't seem to find that invite. Please contact support@omnos.me.
              </Text>
            </AccountPage>
          );
        let formData;
        formData = [
          ...(!user ? userDetailsFieldSet(props) : []),
          {
            fields: [
              resultsAccessGrantedField(checkIncomingPartnerLinkCode.identifier),
              acceptTermsField
            ]
          }
        ];
        return (
          <AccountPage heading={user ? "Results access request" : "Welcome"}>
            {user && (
              <Text pt={40}>You've been invited to share your results with an Omnos Partner</Text>
            )}
            {!user && (
              <Text pt={40}>
                You've been invited to join the Omnos platform. Please set a password to begin your
                journey.
              </Text>
            )}
            {!user && <UserDetailsFooter partnerLinkCode={props.match.params.partnerLinkCode} />}

            <Box pt={user ? 20 : 40}>
              <Form
                data={formData}
                mutation={ACCEPT_PROFESSIONAL_LINK_INVITATION_MUTATION}
                extraInput={{ partnerLinkCode: props.match.params.partnerLinkCode }}
                handleSubmitted={() => {
                  props.history.push(user ? DASHBOARD_HOME_URL : SIGNUP_COMPLETE_URL);
                }}
                formWrapperProps={{
                  mb: 30
                }}
              />
            </Box>
          </AccountPage>
        );
      }}
    ></DataLoader>
  );
}
export function AcceptPartnerInvite(props) {
  useDocTitle("Accept partner invite");
  return (
    <DataLoader
      query={AFFILIATE_LINK_QUERY}
      variables={{ id: props.match.params.invitationId }}
      render={({ affiliateLink }) => {
        if (affiliateLink === null)
          return (
            <AccountPage heading="Invitation not found">
              <Text pt={40}>
                I'm sorry, we can't seem to find that invite. Please contact support@omnos.me.
              </Text>
            </AccountPage>
          );
        const isProfessional = affiliateLink.resultsAccessLink !== null;
        let formData;
        if (isProfessional) {
          formData = [
            {
              fields: [
                emailField,
                passwordField,
                firstNameField,
                lastNameField,
                resultsAccessGrantedField(affiliateLink.partnerUserProfile.user.identifier),
                acceptTermsField
              ]
            }
          ];
        } else {
          formData = [
            {
              fields: [emailField, passwordField, firstNameField, lastNameField, acceptTermsField]
            }
          ];
        }
        return (
          <AccountPage heading="Welcome">
            <Text pt={40}>
              You've been invited to join the Omnos platform. Please set a password to begin your
              journey.
            </Text>

            <Box pt={40}>
              <Form
                data={formData}
                mutation={
                  isProfessional
                    ? ACCEPT_PROFESSIONAL_INVITATION_MUTATION
                    : ACCEPT_AFFILIATE_INVITATION_MUTATION
                }
                extraInput={{ invitationId: props.match.params.invitationId }}
                handleSubmitted={() => {
                  props.history.push(SIGNUP_COMPLETE_URL);
                }}
                formWrapperProps={{
                  mb: 30
                }}
              />
            </Box>
          </AccountPage>
        );
      }}
    ></DataLoader>
  );
}

export function AcceptResultsAccessLink(props) {
  useDocTitle("Accept Results Access Link");
  return (
    <DataLoader
      query={RESULTS_ACCESS_REQUEST_QUERY}
      variables={{ id: props.match.params.requestId }}
      render={data => {
        if (data.resultsAccessLink === null)
          return (
            <AccountPage heading="Results access request not found">
              <Text pt={40}>
                I'm sorry, we can't seem to find that access request. Please contact
                support@omnos.me.
              </Text>
            </AccountPage>
          );
        return (
          <AccountPage heading="Results access request">
            <Text pt={40}>You've been invited to share your results with an Omnos Partner</Text>
            <Box pt={40}>
              <Form
                data={[
                  {
                    fields: [
                      resultsAccessGrantedField(
                        data.resultsAccessLink.partnerUserProfile.user.identifier
                      )
                    ]
                  }
                ]}
                mutation={ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION}
                extraInput={{ requestId: props.match.params.requestId }}
                handleSubmitted={() => {
                  props.history.push(DASHBOARD_HOME_URL);
                }}
                formWrapperProps={{
                  mb: 30
                }}
              />
            </Box>
          </AccountPage>
        );
      }}
    ></DataLoader>
  );
}
