import { Link } from "react-router-dom";

import styled from "styled-components";

/**
 * Use this instead when you want to pass Link as the
 * value for the `as` prop.
 *
 * When implemenating the new nav I was doing things like this:
 *
 * <Button as={Link}></Button>
 *
 * If any other other camelCase props are passed to the Button component
 * in this example then you'll see warnings in the console because it is
 * wanting to, or is, adding the camelCase prop as a html attribute to the
 * underlying anchor component rendered by the Link component.
 *
 * So to avoid this use LinkWrapper instead and if the camelCase prop you want
 * to use is missing from the current array of props below just add it in and
 * the warning should disappear.
 *
 */

const LinkWrapper = styled(Link).withConfig({
  shouldForwardProp: prop =>
    ![
      "textTransform",
      "textAlign",
      "borderRadius",
      "justifyContent",
      "alignItems",
      "selectedColor"
    ].includes(prop)
})``;

export default LinkWrapper;
