import React from "react";

import { useHistory } from "react-router-dom";

import { CHANGE_PASSWORD_COMPLETE_URL } from "../core/urls";
import { CHANGE_PASSWORD_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import Box from "./Box";
import Form from "./Form";
import Input from "./Input";

function ChangePassword() {
  useDocTitle("Change password");
  const history = useHistory();
  return (
    <AccountPage heading="Change password">
      <Box pt={40}>
        <Form
          mutation={CHANGE_PASSWORD_MUTATION}
          handleSubmitted={() => {
            history.push(CHANGE_PASSWORD_COMPLETE_URL);
          }}
          data={[
            {
              fields: [
                {
                  name: "currentPassword",
                  initialValue: "",
                  widget: {
                    component: Input,
                    props: {
                      type: "password",
                      label: "Current password",
                      showEye: true
                    }
                  }
                },
                {
                  name: "newPassword",
                  initialValue: "",
                  widget: {
                    component: Input,
                    props: {
                      type: "password",
                      label: "New password",
                      showEye: true
                    }
                  },
                  boxProps: {
                    pt: "15px"
                  }
                }
              ]
            }
          ]}
          formWrapperProps={{
            mb: 30
          }}
        />
      </Box>
    </AccountPage>
  );
}

export default ChangePassword;
