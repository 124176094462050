import React, { forwardRef } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { border, color, flexbox, layout, space } from "styled-system";

import { theme } from "../core/theme";
import { spinAnimation } from "./Loading";

export const Spinner = styled.span`
  height: 16px;
  left: ${props => {
    return props.right && "10px";
  }};
  position: relative;
  right: ${props => {
    return props.left && "25px";
  }};
  width: 0;

  &::after {
    animation: ${spinAnimation} 1000ms linear infinite;
    border-bottom: 2px solid transparent;
    border-left: 2px solid
      ${props => {
        return props.color;
      }};
    border-radius: 50%;
    border-right: 2px solid
      ${props => {
        return props.color;
      }};
    border-top: 2px solid transparent;
    content: "";
    height: 16px;
    position: absolute;
    width: 16px;
  }
`;

export const ButtonWithSpinner = forwardRef(
  (
    {
      className,
      children,
      submitting,
      handleClick,
      onClick,
      disabled,
      handleMouseOver,
      onMouseOver,
      "data-component-name": dataComponentName,
      type
    },
    ref
  ) => (
    <button
      ref={ref}
      type={type}
      disabled={disabled}
      className={className}
      onClick={e => {
        if (handleClick) {
          e.preventDefault();
          // TODO - only support onClick
          handleClick(e);
        } else if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      onMouseOver={handleMouseOver || onMouseOver}
      data-component-name={dataComponentName}
    >
      {children}
      {submitting && <Spinner right color="white" />}
    </button>
  )
);

ButtonWithSpinner.defaultProps = {
  type: "button"
};

ButtonWithSpinner.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  submitting: PropTypes.bool,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  "data-component-name": PropTypes.string
};

export const SolidButton = styled(ButtonWithSpinner)`
  ${color}
  ${space}
  ${border}
  ${layout}
  ${flexbox}
  align-items: center;
  display: flex;
  font-family: "Gilroy W05 bold", sans-serif;
  font-size: 13px;
  justify-content: center;
  letter-spacing: 0.1em;
  opacity: ${props => {
    return props.disabled ? 0.5 : 1;
  }};
  padding: ${props => {
    if (props.paddingSize === "small") return "10px 20px";
    if (props.paddingSize === "medium") return "15px 40px";
    if (props.paddingSize === "mediumNarrow") return "15px 30px";
    return "20px 40px";
  }};
  text-align: center;
  text-transform: uppercase;
`;

SolidButton.defaultProps = {
  bg: theme.colors.green,
  color: "white",
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  borderStyle: "solid",
  borderWidth: 2,
  borderColor: theme.colors.green,
  paddingSize: "large"
};
