import React from "react";

import PropTypes from "prop-types";

import { theme } from "../core/theme";
import { ReactComponent as Logo } from "../images/logo.svg";
import Box from "./Box";
import { HeadingExtraLarge } from "./Headings";
import Page from "./Page";

function AccountPageHeader({ children, color, maxWidth, backgroundColor }) {
  return (
    <Box
      px={20}
      pt={[100, 110, 120, null]}
      pb={[30, null, 40, null]}
      bg={backgroundColor}
      color={color}
    >
      <Box maxWidth={maxWidth} mx="auto">
        {children}
      </Box>
    </Box>
  );
}

export function BaseAccountPage({ children }) {
  return (
    <Page
      header={
        <AccountPageHeader backgroundColor="purple" maxWidth={1200}>
          <Box width={[170, 180, 190, 200]}>
            <Logo style={{ display: "block" }} />
          </Box>
        </AccountPageHeader>
      }
    >
      {children}
    </Page>
  );
}

function AccountPage({ heading, children }) {
  return (
    <BaseAccountPage>
      <Box px={20} py={theme.spacing.large}>
        <Box maxWidth={800} mx="auto">
          {heading && <HeadingExtraLarge as="h1">{heading}</HeadingExtraLarge>}
          {children}
        </Box>
      </Box>
    </BaseAccountPage>
  );
}

AccountPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  heading: PropTypes.string
};

export default AccountPage;
