import { createContext, useContext, useMemo } from "react";

import Box from "../components/Box";

const CardContext = createContext();

function Card({ children, withBorder, with3DShadowEffect, opacity, ...props }) {
  const shadow = (
    <Box
      borderRadius={5}
      borderColor="dark"
      borderWidth="2px"
      position="absolute"
      top="4px"
      width="100%"
      left="4px"
      height="100%"
      bg="carbon"
    />
  );

  const containerProps = useMemo(() => {
    let containerProps = {
      position: "relative",
      zIndex: 1,
      ...props
    };

    if (withBorder) {
      containerProps = {
        ...containerProps,
        borderRadius: 5,
        borderStyle: "solid",
        borderColor: "dark",
        borderWidth: 2
      };
    }

    if (containerProps.onClick) {
      containerProps = {
        ...containerProps,
        cursor: "pointer"
      };
    }

    return containerProps;
  }, [props, withBorder]);

  const api = useMemo(
    () => ({
      withBorder,
      with3DShadowEffect,
      containerProps
    }),
    [containerProps, withBorder, with3DShadowEffect]
  );

  return (
    <CardContext.Provider value={api}>
      <Box position="relative" zIndex={0} opacity={opacity}>
        {with3DShadowEffect && shadow}
        <Box {...containerProps}>{children}</Box>
      </Box>
    </CardContext.Provider>
  );
}

function Header({ children, ...props }) {
  const api = useContext(CardContext);
  return (
    <Box {...props} borderRadius={api.containerProps.borderRadius}>
      {children}
    </Box>
  );
}

function Body({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

Card.defaultProps = {
  with3DShadowEffect: false,
  withBorder: false,
  display: "flex",
  flexDirection: "column"
};

Card.Header = Header;
Card.Body = Body;

export default Card;
