import React from "react";

import { useHistory } from "react-router-dom";

import gql from "graphql-tag";

import { TESTS_URL } from "../core/urls";
import BasicPage from "./BasicPage";
import DataLoader from "./DataLoader";

export const DISCOUNT_CODE_REDIRECT_QUERY = gql`
  query DiscountCodeRedirectPageQuery($slug: String!) {
    discountCodeShareUrl(slug: $slug) {
      code
      hashId
      tier
      type
    }
  }
`;

function DiscountCodeRedirectPage({
  match: {
    params: { discountCode }
  }
}) {
  const history = useHistory();

  return (
    <BasicPage heading="Fetching Discount code">
      <DataLoader
        query={DISCOUNT_CODE_REDIRECT_QUERY}
        variables={{
          slug: discountCode
        }}
        render={({ discountCodeShareUrl }) => {
          if (discountCodeShareUrl.code) {
            const url = `${window.location.origin}${TESTS_URL}?&utm_content=${discountCodeShareUrl.code}&utm_source=${discountCodeShareUrl.hashId}&utm_medium=${discountCodeShareUrl.tier}&utm_campaign=partners&utm_term=${discountCodeShareUrl.type}&discountcode=${discountCodeShareUrl.code}`;
            window.location.href = url;
          } else {
            history.push(TESTS_URL);
          }
          return <></>;
        }}
      />
    </BasicPage>
  );
}

export default DiscountCodeRedirectPage;
