import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import { SpanText } from "../components/Text";
import { OutlineButton, SolidButton } from "../deprecated/Buttons";
import ChevronComponent from "./Chevron";

export function ChevronButton({
  BtnComponent,
  children,
  chevronProps,
  chevronDir,
  chevronPosition,
  ...rest
}) {
  return (
    <BtnComponent fontSize={12} px={5} py={4} {...rest}>
      {chevronPosition === "right" && (
        <>
          {children}
          <ChevronComponent direction={chevronDir} {...chevronProps} />
        </>
      )}
      {chevronPosition === "left" && (
        <>
          <ChevronComponent direction={chevronDir} {...chevronProps} />
          {children}
        </>
      )}
    </BtnComponent>
  );
}

ChevronButton.propTypes = {
  BtnComponent: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  chevronProps: PropTypes.object,
  chevronDir: PropTypes.string
};

ChevronButton.defaultProps = {
  BtnComponent: SolidButton,
  chevronDir: "right",
  chevronProps: {},
  chevronPosition: "right"
};

export function SolidChevronButton({
  bg,
  children,
  color,
  borderColor,
  variant,
  lineHeight,
  ...rest
}) {
  return (
    <ChevronButton bg={bg} color={color} borderColor={borderColor} variant={variant} {...rest}>
      <SpanText lineHeight={lineHeight}>{children}</SpanText>
    </ChevronButton>
  );
}

SolidChevronButton.propTypes = {
  bg: propTypes.color.bg,
  children: PropTypes.node.isRequired,
  color: propTypes.color.color,
  borderColor: propTypes.border.borderColor,
  variant: PropTypes.string,
  lineHeight: propTypes.typography.lineHeight
};

SolidChevronButton.defaultProps = {
  bg: "dark",
  color: "white",
  borderColor: "dark",
  lineHeight: 1,
  variant: "lg"
};

export function OutlineChevronButton({
  borderColor,
  chevronProps,
  children,
  color,
  handleButtonClick,
  variant
}) {
  return (
    <ChevronButton
      BtnComponent={OutlineButton}
      color={color}
      borderColor={borderColor}
      handleClick={handleButtonClick}
      chevronProps={chevronProps}
      variant={variant}
    >
      {children}
    </ChevronButton>
  );
}

OutlineChevronButton.propTypes = {
  borderColor: propTypes.border.borderColor,
  chevronProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  color: propTypes.color.color,
  handleButtonClick: PropTypes.func,
  variant: PropTypes.string
};

OutlineChevronButton.defaultProps = {
  color: "white",
  borderColor: "white",
  chevronProps: {
    width: "6px",
    fill: "white"
  },
  variant: "lg"
};
