import { SolidChevronButton } from "./ChevronButton";

export function Button({ children, ...rest }) {
  return (
    <SolidChevronButton bg="white" color="dark" {...rest}>
      {children}
    </SolidChevronButton>
  );
}

export function SubmitButton({ onClick, text }) {
  return (
    <Button
      bg="white"
      color="dark"
      direction="right"
      chevronProps={{
        fill: "dark"
      }}
      handleClick={onClick}
    >
      {text}
    </Button>
  );
}
