import { cloneElement, useState } from "react";

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  useClick,
  safePolygon
} from "@floating-ui/react";

import "./tooltipStyles.css";

export default function Tooltip({ content, children, placement }) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start"
      }),
      shift()
    ]
  });

  // Event listeners to change the open state
  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon({
      requireIntent: false
    })
  });
  const focus = useFocus(context);
  const click = useClick(context);

  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    click,
    dismiss,
    role
  ]);

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps()
      })}
      <FloatingPortal>
        {isOpen &&
          cloneElement(content, {
            ref: refs.setFloating,
            style: floatingStyles,
            ...getFloatingProps()
          })}
      </FloatingPortal>
    </>
  );
}

Tooltip.defaultProps = {
  placement: "right"
};
