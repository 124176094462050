import { useLocation } from "react-router-dom";

import { useNotificationsContext } from "contexts/Notification";

import ExpiredMarkers from "./notifications/ui/ExpiredMarkers";
import NotificationModal from "./notifications/ui/NotificationModal";

export default function UserOnlyNotifications() {
  const api = useNotificationsContext();
  const location = useLocation();

  const userNotification = api.nextNotification(
    un => un.notification.type === "tpo_results.ExpiredMarkers"
  );

  if (location.pathname.startsWith("/patients") || location.pathname.startsWith("/partners")) {
    return null;
  }

  return userNotification ? (
    <NotificationModal onClose={() => api.acknowledgeNotification(userNotification.id)}>
      <ExpiredMarkers />
    </NotificationModal>
  ) : null;
}
