import { useCallback } from "react";

import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { PanelBoxV2 } from "tpo/Boxes";

import CreateOrUpdateShippingAddressForm from "../forms/CreateOrUpdateShippingAddressForm";

export default function ShippingDetails({ shippingCountries }) {
  const { basket, updateBasketDetails, error } = useOrganisationBasketContext();

  const handleUpdateShippingAddress = useCallback(
    input => {
      updateBasketDetails({
        shippingAddress: parseInt(input)
      });
    },
    [updateBasketDetails]
  );

  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        bg: "white",
        px: [20, 20, 40],
        pt: [20, 20, 40],
        pb: [50, 50, 80],
        borderRadius: 5
      }}
      stacked
      gap={40}
    >
      <CreateOrUpdateShippingAddressForm
        handleSuccess={({ data }) => {
          let addressData =
            data.createShippingAddressMutation?.address ||
            data.updateShippingAddressMutation?.address;
          if (addressData) {
            return handleUpdateShippingAddress(addressData?.id);
          }
        }}
        error={error}
        shippingCountries={shippingCountries}
      />
    </PanelBoxV2>
  );
}
