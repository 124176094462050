import gql from "graphql-tag";

import { CHART_CONFIGURATION_FIELDS } from "../results/types";
import { QUESTION_FIELDS, USER_ANSWER_FIELDS } from "./types";

export const QUESTIONS_QUERY = gql`
  query QuestionsQuery($presentationSection: Int) {
    questions(presentationSection: $presentationSection) {
      ...QuestionFields
    }
  }
  ${QUESTION_FIELDS}
`;

export const PROGRESS_BAR_QUERY = gql`
  query ProgressBarQuery($userSubmissionId: ID) {
    allQuestions: questions {
      ...QuestionFields
    }
    allUserAnswers: userAnswers(userSubmissionId: $userSubmissionId) {
      ...UserAnswerFields
    }
  }
  ${QUESTION_FIELDS}
  ${USER_ANSWER_FIELDS}
`;

export const QUESTIONNAIRE_QUERY = gql`
  query QuestionsQuery($presentationSection: Int, $userSubmissionId: ID, $productSlug: String) {
    questions(presentationSection: $presentationSection, productSlug: $productSlug) {
      ...QuestionFields
    }
    userAnswers(
      presentationSection: $presentationSection
      userSubmissionId: $userSubmissionId
      productSlug: $productSlug
    ) {
      ...UserAnswerFields
    }
  }
  ${QUESTION_FIELDS}
  ${USER_ANSWER_FIELDS}
`;

export const RECOMMENDED_SUPPLEMENTS_TRUNCATED_QUERY = gql`
  query RecommendedSupplementsTruncated {
    recommendedSupplements {
      id
    }
  }
`;

export const EXAMPLE_FOODS_QUERY = gql`
  query ExampleFoodsQuery($userId: ID) {
    foodsToDo: examples(userId: $userId, verb: "Increase", category: "Food") {
      id
      slug
      name
      parentCategory {
        id
        name
      }
    }
    foodsToAvoid: examples(userId: $userId, verb: "Reduce", category: "Food") {
      id
      slug
      name
      parentCategory {
        id
        name
      }
    }
  }
`;

export const EXAMPLE_QUERY = gql`
  query Example($exampleId: ID, $userId: ID, $verbGroup: VerbGroups) {
    example(exampleId: $exampleId) {
      id
      name
      relatedUserResults(userId: $userId, verbGroup: $verbGroup) {
        id
        chartConfiguration {
          ...ChartConfigurationFields
        }
        result {
          id
          name
        }
        resultState {
          id
          name
        }
      }
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
`;
