import { useHistory } from "react-router-dom";

import { useAuthContext } from "components/AuthProvider";
import useWindowSize from "hooks/use-window-size";
import { ReactComponent as Logo } from "images/logo.svg";
import { ReactComponent as MobileLogo } from "images/tpo/omnos-navbar-logo-mobile.svg";
import Group from "tpo/Group";
import BackButton from "tpo/header/BackButton";

import Container from "./Container";
import ReleaseButton from "./ReleaseButton";

export default function Common({ bg, left, right, user }) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 960;

  const history = useHistory();

  const impersonated = useAuthContext()?.user?.impersonated;

  return (
    <Container bg={bg} data-component-name="NavHeader">
      <Group alignItems="center" justifyContent="space-between">
        <Group alignItems="center" gap={20}>
          <BackButton onClick={() => history.goBack()} />
          <Logo
            height={40}
            style={{
              display: isMobile ? "none" : "block"
            }}
          />
          <MobileLogo
            height={40}
            style={{
              display: isMobile ? "block" : "none"
            }}
          />
          {impersonated && <ReleaseButton ml="10px" user={user} />}
          {left}
        </Group>
        <Group alignItems="center" gap={20}>
          {right}
        </Group>
      </Group>
    </Container>
  );
}
