import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import styled from "styled-components";
import {
  border,
  boxShadow,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  typography
} from "styled-system";

import { isTouch } from "../core/utils";

const Box = styled.div.attrs(props => {
  return {
    // 1.0 components use data-component-name
    // 2.0 components use dataComponentName
    // when I was factoring out the common components
    // I overlooked this.
    // This gets it to work again
    // TODO - use either key throughout 1.0 and 2.0 components
    // dataComponentName is probably preferable because it is camelCase
    "data-component-name": props.dataComponentName || props["data-component-name"]
  };
})`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${grid}
  ${typography}
  ${system({
    cursor: {
      property: "cursor"
    },
    transition: {
      property: "transition"
    },
    transform: {
      property: "transform"
    },
    textTransform: {
      property: "text-transform"
    },
    aspectRatio: {
      property: "aspect-ratio"
    },
    scrollSnapType: {
      property: "scroll-snap-type"
    },
    direction: {
      property: "direction"
    },
    listStyle: {
      property: "list-style"
    },
    whiteSpace: {
      property: "white-space"
    },
    background: {
      property: "background"
    },
    textDecoration: {
      property: "text-decoration"
    },
    gap: {
      property: "gap"
    },
    boxShadow: {
      property: "box-shadow"
    },
    filter: {
      property: "filter"
    },
    visibility: {
      property: "visibility"
    },
    textDecorationLine: {
      property: "text-decoration-line"
    },
    gridColumnStart: {
      property: "grid-column-start"
    },
    gridColumnEnd: {
      property: "grid-column-end"
    },
    gridRowStart: {
      property: "grid-row-start"
    },
    gridRowEnd: {
      property: "grid-row-end"
    },
    wordWrap: {
      property: "word-wrap"
    },
    overflowWrap: {
      property: "overflow-wrap"
    }
  })}
`;

Box.defaultProps = {
  color: "dark"
};

export default Box;

function BaseToBox({ href, className, children, "data-component-name": dataComponentName }) {
  const history = useHistory();
  return (
    <a
      href={href}
      className={className}
      onClick={e => {
        e.preventDefault();
        history.push(href);
      }}
      data-component-name={dataComponentName}
    >
      {children}
    </a>
  );
}

BaseToBox.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  "data-children-name": PropTypes.string
};

export const ToBox = styled(BaseToBox)`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${props => {
    if (props.hoverRollover && !isTouch()) {
      return `
      transform: translate3d(0, 0, 0);
      transition: transform 250ms;
        :hover {
          transform: translate3d(0, -5px, 0);
        }
      `;
    }
    if (props.scaleRollover && !isTouch()) {
      return `
      transform: scale3d(1, 1, 1);
      transition: transform 250ms;
        :hover {
          transform: scale3d(1.02, 1.02, 1);
        }
      `;
    }
  }}
`;

ToBox.defaultProps = {
  hoverRollover: false,
  display: "block"
};
