import { useState } from "react";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import Loading from "components/Loading";
import GoogleMap from "components/Map";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useDebounce } from "hooks/useDebounce";
import BackArrow from "images/BackArrow";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import EmptySearch from "tpo/EmptySearch";
import Group from "tpo/Group";
import Modal from "tpo/Modal";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import TextInput from "tpo/TextInput";
import ButtonV2, { ActionIcon } from "v2/Buttons";

const DEFAULT_LAT = 53.383331;
const DEFAULT_LNG = -1.466667;

export function ChooseClinic({
  onAddToBasket,
  query,
  clinicLocationsField,
  testProductOptionPriceField
}) {
  const { testProductOption } = useTestProductModalContext();
  const [postcode, setPostcode] = useState("");
  const debouncedPostcode = useDebounce(postcode);

  const { data } = useQuery(query, {
    variables: {
      compositeId: testProductOption.compositeId,
      postCode: debouncedPostcode
    }
  });

  let places = [];
  let lat = DEFAULT_LAT;
  let lng = DEFAULT_LNG;
  const clinics = data?.[clinicLocationsField];

  if (clinics?.length) {
    lat = clinics[0].coords?.lat;
    lng = clinics[0].coords?.lng;
    places = clinics
      .map(clinic => ({
        id: clinic.id,
        pos: {
          lat: clinic.coords?.lat,
          lng: clinic.coords?.lng
        }
      }))
      .filter(place => place.pos.lat !== undefined && place.pos.lng !== undefined);
  }

  return (
    <Stack gap={20} p={80} pt={40} px={[20, 20, 80]}>
      <Box>
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
          Phlebotomy clinics
        </Box>
        <Spacer py={[20]} />
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          Search below to find and select where you’d like to have your blood draw taken. You will
          receive a special venous draw kit in the post that you should take along to your selected
          store or clinic. You will be required to book an appointment with the location prior to
          your visit
        </Box>
      </Box>
      <TextInput
        placeholder="Enter postcode"
        styles={{
          input: {
            borderColor: "dark",
            borderWidth: 1,
            borderStyle: "solid",
            p: 2
          }
        }}
        onChange={e => setPostcode(e.target.value)}
        value={postcode}
      />
      <Box>
        <GoogleMap places={places} lat={lat} lng={lng} zoom={6} />
      </Box>
      {clinics?.length ? (
        clinics.map(clinic => (
          <Stack
            borderColor="dark"
            borderStyle="solid"
            borderWidth={1}
            flexDirection={["column", "row"]}
            justifyContent={[null, "space-between"]}
            gap={20}
            key={clinic.id}
            p={20}
            data-component-name="ClinicCard"
          >
            <Box>
              <Box fontFamily="gilroyBold" fontSize={[16, 18]} pb={2}>
                {`${clinic.clinic.name} (${clinic.distance}km)`}
              </Box>
              <Box fontFamily="gilroyMedium" color="midGrey" fontSize={16}>
                {clinic.address}
              </Box>
            </Box>
            <Group justifyContent="flex-end">
              <Stack gap={20}>
                <Box textAlign="right">
                  <Currency
                    fontSize={44}
                    value={parseFloat(
                      +testProductOption[testProductOptionPriceField] + +clinic.clinic.fee
                    ).toFixed(2)}
                    mb={-15}
                    display="inline-block"
                  />
                  <Box fontFamily="gilroyMedium" fontSize={14}>
                    Includes £{clinic.clinic.fee} fee
                  </Box>
                </Box>
                <ButtonV2
                  color="green"
                  onClick={() => onAddToBasket({ clinic })}
                  rightIcon={<ChevronComponent />}
                >
                  add to basket
                </ButtonV2>
              </Stack>
            </Group>
          </Stack>
        ))
      ) : (
        <EmptySearch message="No stores found" mt={20} />
      )}
    </Stack>
  );
}

export default function BaseTestProductModal({
  clinicsInfoField,
  chooseClinicView,
  productDetailView,
  organisationShop = false,
  bg: defaultBg = "white"
}) {
  /**
   * Both the normal shop and the practitioner shop will use this component.
   * Both will have to implement their own views.  This reduces the risk of
   * the wrong prices being shown.  Otherwise I think it might be easy for somebody
   * to change something not realising that it will affect the other view.
   */

  let bg = "white";

  let content = null;

  const {
    loading,
    selectTestProductId,
    selectedTestProductOptionId,
    selectTestProductOptionId,
    selectedTestProductId,
    testProductOption
  } = useTestProductModalContext();

  let dataComponentName = "TestProductModal";

  if (selectedTestProductId && loading) {
    content = <Loading />;
  } else if (
    selectedTestProductOptionId &&
    testProductOption &&
    testProductOption[clinicsInfoField]?.clinics?.length &&
    chooseClinicView
  ) {
    dataComponentName = "ChooseClinicModal";
    content = chooseClinicView;
  } else if (selectedTestProductId && productDetailView) {
    bg = defaultBg;
    content = productDetailView;
  }

  if (!content) return null;

  return (
    <Modal
      maxWidth={1020}
      closeButton
      headerProps={{
        p: [2, 2, 20],
        pb: 20,
        bg: "transparent"
      }}
      bg={bg}
      show={!!content}
      close={() => {
        selectTestProductId(null);
      }}
      onClose={() => {
        selectTestProductId(null);
      }}
      mode={["fullScreen", "fullScreen", "centered"]}
      title={
        selectedTestProductId &&
        testProductOption &&
        testProductOption[clinicsInfoField]?.clinics?.length && (
          <ActionIcon
            borderRadius="50%"
            height={[40, 40, 55]}
            onClick={() => selectTestProductOptionId(null)}
            width={[40, 40, 55]}
            variant="outline"
            style={{
              pointerEvents: "auto"
            }}
          >
            <BackArrow color="black" />
          </ActionIcon>
        )
      }
      data-component-name={dataComponentName}
    >
      {content}
      {organisationShop}
    </Modal>
  );
}
