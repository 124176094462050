import React from "react";

import useDocTitle from "../hooks/use-doc-title";
import IFramePage from "./IFramePage";

function TermsOfServicePage() {
  useDocTitle("Terms of service");
  return <IFramePage title="Terms of  Service" src="/terms-of-service.html" />;
}

export default TermsOfServicePage;
