import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { OPEN_ORDER_QUERY, UPDATE_DISCOUNT_MUTATION } from "graphql/shop";
import useBasket from "tpo/shop/hooks/useBasket";

export const BasketContext = createContext();

function Discount({ children }) {
  const basketApi = useBasketContext();

  const openOrder = basketApi.openOrder;

  // openOrder is guaranteed to be defined here
  // See BasketProvider below for why

  const [discountCodeError, setDiscountCodeError] = useState();
  const [discountCode, setDiscountCode] = useState(openOrder.discountCode || "");

  useEffect(() => {
    if (discountCodeError) {
      const discountIsApplied =
        openOrder.discountCode !== "" && openOrder.discountCode === discountCode;
      if (discountIsApplied) {
        setDiscountCodeError("");
      }
    }
  }, [discountCodeError, setDiscountCodeError, openOrder.discountCode, discountCode]);

  const [updateDiscountMutation, { loading: updateDiscountLoading }] = useMutation(
    UPDATE_DISCOUNT_MUTATION
  );

  const isSubmitting = basketApi.isSubmitting || updateDiscountLoading;

  const removeDiscount = useCallback(() => {
    if (isSubmitting) return;

    updateDiscountMutation({
      variables: {
        input: {
          id: openOrder.id,
          discountCode: ""
        }
      },
      refetchQueries: [
        {
          query: OPEN_ORDER_QUERY
        }
      ],
      awaitRefetchQueries: true
    })
      .then(result => {
        if (result.data.updateDiscountMutation.errors.length) {
          if (result.data.updateDiscountMutation.errors[0].messages) {
            setDiscountCodeError(result.data.updateDiscountMutation.errors[0].messages[0]);
          }
        } else {
          setDiscountCode("");
        }
      })
      .catch(error => {
        console.log("Error updating discount", error);
        Sentry.captureException(error);
      });
  }, [updateDiscountMutation, openOrder.id, isSubmitting, setDiscountCodeError]);

  const updateDiscount = useCallback(() => {
    if (isSubmitting) return;

    updateDiscountMutation({
      variables: {
        input: {
          id: openOrder.id,
          discountCode: discountCode.trim()
        }
      },
      refetchQueries: [
        {
          query: OPEN_ORDER_QUERY
        }
      ],
      awaitRefetchQueries: true
    })
      .then(result => {
        if (result.data.updateDiscountMutation.errors.length) {
          if (result.data.updateDiscountMutation.errors[0].messages) {
            setDiscountCodeError(result.data.updateDiscountMutation.errors[0].messages[0]);
          }
        }
      })
      .catch(error => {
        console.log("Error updating discount", error);
        Sentry.captureException(error);
      });
  }, [updateDiscountMutation, openOrder.id, isSubmitting, setDiscountCodeError, discountCode]);

  return (
    <BasketContext.Provider
      value={{
        ...basketApi,
        discountCode,
        setDiscountCode,
        discountCodeError,
        updateDiscount,
        removeDiscount
      }}
    >
      {children}
    </BasketContext.Provider>
  );
}

export function BasketProvider({ children }) {
  const api = useBasket();

  if (!api.openOrder) {
    return <BasketContext.Provider value={api}>{children}</BasketContext.Provider>;
  }

  return (
    <BasketContext.Provider value={api}>
      <Discount>{children}</Discount>
    </BasketContext.Provider>
  );
}

export function useBasketContext() {
  return useContext(BasketContext);
}
