import { useState } from "react";

import { useFormContext } from "react-hook-form";

import InputWrapper from "tpo/InputWrapper";
import Menu, { Button } from "tpo/Menu";
import ButtonV2 from "v2/Buttons";

export default function PractitionerTypeField({ practitionerTypes }) {
  const { setValue, watch, getFieldState, clearErrors } = useFormContext();
  const practitionerTypeValue = watch("practitionerType");
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const { error } = getFieldState("practitionerType");

  return (
    <>
      <InputWrapper
        error={error?.message}
        input={
          <Menu opened={showFilterMenu} onChange={setShowFilterMenu} trigger="hover">
            <Menu.Target>
              <Button open={showFilterMenu} color="dark" data-component-name="practitionerType">
                Select Option
              </Button>
            </Menu.Target>
            <Menu.Dropdown
              mt={2}
              py={2}
              zIndex={2}
              minWidth={200}
              height={400}
              maxHeight={400}
              overflowY="auto"
            >
              {practitionerTypes.map(sortOption => (
                <Menu.Item
                  onClick={() => {
                    setValue("practitionerType", sortOption.id);
                    setValue("documents", []);
                    setValue("professionalBodies", []);
                    clearErrors(["practitionerType", "documents", "professionalBodies"]);
                  }}
                  selected={sortOption.id === practitionerTypeValue}
                  key={sortOption.id}
                  fontSize={14}
                  lineHeight={1.7}
                  py={16}
                  data-testid={`practitionerTypeOption:${sortOption.role}`}
                >
                  {sortOption.role}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        }
      />
      {practitionerTypeValue && (
        <ButtonV2
          color="green"
          variant="filled"
          py={[2, 2, 16]}
          px={[16, 16, 20]}
          fontSize={[10, 10, 12]}
          borderRadius="100px"
          borderWidth="2px"
          gap={10}
          height={[27, 27, 40]}
          data-testid="practitionerTypeValue"
        >
          {practitionerTypes.find(({ id }) => id === practitionerTypeValue)?.role}
        </ButtonV2>
      )}
    </>
  );
}
