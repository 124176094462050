const { default: AcceptedBadge } = require("./AcceptedBadge");
const { default: ConnectedBadge } = require("./ConnectedBadge");
const { default: InvitedBadge } = require("./InvitedBadge");
const { default: RefusedBadge } = require("./RefusedBadge");

const CONNECTED_STATUS = "Connected";
const REFUSED_STATUS = "Refused";
const INVITED_STATUS = "Invited";
const ACCEPTED_STATUS = "Accepted";

const STATUS_BADGES = {
  [CONNECTED_STATUS]: <ConnectedBadge />,
  [ACCEPTED_STATUS]: <AcceptedBadge />,
  [INVITED_STATUS]: <InvitedBadge />,
  [REFUSED_STATUS]: <RefusedBadge />
};

export default STATUS_BADGES;
