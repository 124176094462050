import { useParams } from "react-router-dom";

import ParamsContext from "contexts/ParamsContext";

function Params({ children }) {
  const params = useParams();
  return <ParamsContext.Provider value={params}>{children}</ParamsContext.Provider>;
}

export default Params;
