import React from "react";

import { useHistory, useLocation } from "react-router";

import { theme } from "../core/theme";
import { DASHBOARD_HOME_URL } from "../core/urls";
import { SolidButton } from "../deprecated/Buttons";
import Box from "./Box";

function SwitcherHeader({ backgroundColor, maxWidth, color }) {
  const location = useLocation();
  const history = useHistory();

  let dashboardFg, dashboardBg;
  let partnersFg, partnersBg;

  if (location.pathname === DASHBOARD_HOME_URL) {
    dashboardFg = theme.colors.dark;
    dashboardBg = "white";
    partnersFg = "white";
    partnersBg = theme.colors.dark;
  } else {
    dashboardFg = "white";
    dashboardBg = theme.colors.dark;
    partnersFg = theme.colors.dark;
    partnersBg = "haze";
  }

  return (
    <Box px={20} pt={[100, 110, 120, null]} bg={backgroundColor} data-component-name="Header">
      <Box display="flex" justifyContent="center" position="relative">
        <SolidButton
          bg={dashboardBg}
          color={dashboardFg}
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
          borderColor={dashboardBg}
          lineHeight={1}
          variant="lg"
          handleClick={() => {
            return history.push({
              pathname: DASHBOARD_HOME_URL
            });
          }}
        >
          my omnos
        </SolidButton>
        <SolidButton
          bg={partnersBg}
          color={partnersFg}
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
          borderColor={partnersBg}
          lineHeight={1}
          variant="lg"
        >
          partners
        </SolidButton>
      </Box>
    </Box>
  );
}

SwitcherHeader.defaultProps = {
  maxWidth: 1200,
  color: "white",
  backgroundColor: "blue"
};

export default SwitcherHeader;
