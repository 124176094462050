import OrganisationTabs from "components/organisations/OrganisationTabs";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";

import Switcher from "./Switcher";

export default function PatientsTemplate({ children, active }) {
  return (
    <>
      <OrganisationTabs selectedTab="patients" />
      <PanelBoxV2
        outer={{
          pb: [50, 50, 80],
          px: [20],
          bg: "haze"
        }}
        stacked
        gap={[20, 20, 40]}
      >
        <Center pt={[30, 30, 60]}>
          <Switcher active={active} />
        </Center>
        {children}
      </PanelBoxV2>
    </>
  );
}
