export const PAYEE_PATIENT = "patient";
export const PAYEE_ORGANISATION = "organisation";

export const REGENERUS = "Regenerus";

export const CMP_PERMISSION_ADMIN = "Admin access";

export const CMP_PERMISSION_VIEW_RESULTS = "View results";

export const CMP_PERMISSION_VIEW_PATIENTS = "View patients";
export const CMP_PERMISSION_ADD_PATIENTS = "Add patients";
export const CMP_PERMISSION_DELETE_PATIENTS = "Delete patients";

export const CMP_PERMISSION_VIEW_PRACTITIONERS = "View practitioners";
export const CMP_PERMISSION_ADD_PRACTITIONERS = "Add practitioners";
export const CMP_PERMISSION_DELETE_PRACTITIONERS = "Delete practitioners";

export const CMP_PERMISSION_VIEW_ORDERS = "View orders";
export const CMP_PERMISSION_PLACE_ORDERS = "Place orders";
export const CMP_PERMISSION_CANCEL_ORDERS = "Cancel orders";

export const CMP_PERMISSION_VIEW_STOCK = "View stock";
export const CMP_PERMISSION_REGISTER_STOCK = "Register stock";

export const CMP_PERMISSION_VIEW_COMMISSIONS = "View commissions";
export const CMP_PERMISSION_VIEW_ACCOUNT_BALANCES = "View account balances";

export const CMP_PERMISSION_VIEW_ALL_ORDERS = "View all orders";
export const CMP_PERMISSION_VIEW_ALL_STOCK = "View all stock";
export const CMP_PERMISSION_VIEW_ALL_PATIENTS = "View all patients";
export const CMP_PERMISSION_VIEW_ALL_COMMISSIONS = "View all commissions";
