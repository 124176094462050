import CommissionNotice from "components/CommisionNotice";
import Page from "components/Page";
import { PanelBoxV2 } from "tpo/Boxes";
import Spacer from "tpo/Spacer";

import OrganisationTabs from "../OrganisationTabs";
import OrganisationAccountBalance from "./ui/OrganisationAccountBalance";
import OrganisationListCommissions from "./ui/OrganisationListCommissions";

// import OrganisationListPayouts from "./ui/OrganisationListPayouts";

export default function OrganisationCommissions() {
  return (
    <>
      <OrganisationTabs selectedTab="commissions" />
      <PanelBoxV2
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20],
          bg: "haze"
        }}
      >
        <CommissionNotice />
        <Spacer py={[2, 2, 20]} />
        <OrganisationListCommissions />
        <Spacer py={[2, 2, 20]} />
        {/*
        <OrganisationListPayouts />
        <Spacer py={[2, 2, 20]} />
        */}
        <OrganisationAccountBalance />
      </PanelBoxV2>
    </>
  );
}
