import { gql } from "@apollo/client";

import { PROFILE_FORM_FIELDS } from "./types";

// Specifically get the fields needed for the form
export const PROFILE_QUERY = gql`
  query ProfileQuery {
    partnerUserProfile {
      ...ProfileFormFields
    }
  }
  ${PROFILE_FORM_FIELDS}
`;
