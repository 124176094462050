import { useDataLoader } from "hooks/useDataLoader";

import useProductFilters from "./useProductFilters";

export default function useTestProducts({
  testingServices,
  categories,
  sampleTypes,
  queryVariables,
  search,
  sort,
  query,
  connectionsFieldName,
  productFiltersDrawerBg
}) {
  const { menu, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      gender: "all",
      categories: [],
      sampleTypes: [],
      testingServices: []
    },
    allFilters: {
      gender: ["all", "female", "male"],
      testingServices: [...testingServices].sort(),
      categories: [...categories].sort(),
      sampleTypes: [...sampleTypes].sort()
    },
    filtersTitle: "Product Filters",
    filterSelected: {
      gender: ["male", "female"]
    },
    drawerBg: productFiltersDrawerBg
  });

  const { data, loading, fetchMore } = useDataLoader({
    query,
    variables: {
      orderBy: sort,
      search,
      first: 10,
      after: "",
      gender: validatedFilters.gender?.[0] !== "all" ? validatedFilters.gender?.[0] : undefined,
      testingServices: validatedFilters.testingServices.join(","),
      areasOfInterest: validatedFilters.categories.join(","),
      sampleTypes: validatedFilters.sampleTypes.join(","),
      currency: "GBP",
      ...queryVariables
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  const products = data?.[connectionsFieldName]?.edges.map(edge => edge.node);
  const hasMore = data?.[connectionsFieldName]?.pageInfo?.hasNextPage;
  const endCursor = data?.[connectionsFieldName]?.pageInfo?.endCursor;

  return {
    search,
    sort,
    productFilters: {
      menu,
      selectedFilters
    },
    products: {
      items: products,
      hasMore,
      endCursor
    },
    loading,
    fetchMore
  };
}
