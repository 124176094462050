import React from "react";

import { DASHBOARD_HOME_URL } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import { InternalTextLink } from "./Links";
import Text from "./Text";

function ChangePasswordSuccess() {
  useDocTitle("Change password complete");
  return (
    <AccountPage heading="Password updated">
      <Text pt={40}>Your password was successfully updated</Text>
      <Text pt={40}>
        Click <InternalTextLink href={DASHBOARD_HOME_URL}>here</InternalTextLink> to return to the
        home page
      </Text>
    </AccountPage>
  );
}

export default ChangePasswordSuccess;
