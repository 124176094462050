import { createContext, useContext } from "react";

export const ChartConfigurationContext = createContext();

export function ChartConfigurationProvider({ children, chartConfiguration }) {
  return (
    <ChartConfigurationContext.Provider value={chartConfiguration}>
      {children}
    </ChartConfigurationContext.Provider>
  );
}

export function ChartConfigurationConsumer({ children }) {
  return (
    <ChartConfigurationContext.Consumer>
      {chartConfiguration => children(chartConfiguration)}
    </ChartConfigurationContext.Consumer>
  );
}

export function useChartConfiguration() {
  return useContext(ChartConfigurationContext);
}
