import React, { useContext, useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import * as Sentry from "@sentry/browser";

import { getAPIBase } from "../core/config";
import Loading from "./Loading";

export const AuthContext = React.createContext();
export const useAuthContext = () => useContext(AuthContext);

function AuthProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [csrfToken, setCsrfToken] = useState(undefined);

  useEffect(() => {
    async function getUserData() {
      fetch(`${getAPIBase()}/api/get-user/`, { credentials: "include" })
        .then(response => {
          // Make a copy as we can't read the response twice
          const responseClone = response.clone();
          return response.json().catch(() => {
            console.log("Problem getting /api/get-user/");
            responseClone.text().then(text => {
              console.log("Response text was");
              console.log(text);
            });
            return null;
          });
        })
        .then(data => {
          setCsrfToken(data.csrfToken);
          // user will be {id, email}
          setUser(data.user);
        });
    }
    getUserData();
  }, []);

  useEffect(() => {
    if (user) {
      console.log(`Setting Sentry user id to ${user.id}`);
      Sentry.configureScope(scope => {
        scope.setUser({ id: user.id });
      });
    }
    // eslint-disable-next-line
  }, [user]);

  // IMPORTANT, don't try and render anything unless we have a CSRF
  // Quite a few pages need to make queries straight-away now, and this safegaurds any issues on that front
  if (csrfToken === undefined) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        csrfToken,
        setCsrfToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default withRouter(AuthProvider);
