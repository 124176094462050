import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ORGANISATION_ORDER_DETAIL_QUERY } from "graphql/organisations/queries/orders";
import Stack from "tpo/Stack";

import OrganisationTabs from "../OrganisationTabs";
import OrganisationOrderDetails from "./shared/OrganisationOrderDetails";

export default function OrganisationViewOrder() {
  // get the orderId url param and fetch the order
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const { organisation } = useOrganisationContext();

  const { data, loading } = useQuery(ORGANISATION_ORDER_DETAIL_QUERY, {
    variables: {
      organisation: parseInt(organisation?.id),
      id: orderId
    },
    skip: !orderId || !organisation
  });

  useEffect(() => {
    if (loading || !data?.organisationOrder) return;
    setOrder(data.organisationOrder);
  }, [data, loading]);

  return (
    <>
      <OrganisationTabs selectedTab="orders" />
      <Stack
        maxWidth={1538}
        mx="auto"
        pt={[30, 30, 60]}
        pb={[50, 50, 80]}
        px={[20, 20, "5.5vw"]}
        gap={[20, 20, 40]}
      >
        {order && <OrganisationOrderDetails order={order} />}
      </Stack>
    </>
  );
}
