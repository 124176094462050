import { forwardRef } from "react";

import Box from "components/Box";
import RichText from "components/RichText";
import { theme } from "core/theme";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import IsThisTestRightForYou from "./IsThisTestRightForYou";
import Pills from "./Pills";

const StackedListingViewCard = forwardRef(
  (
    {
      name,
      description,
      numOfBiomarkersTested,
      slug,
      image,
      badge,
      matchedSymptoms,
      stacked,
      categories,
      productAudience,
      onClick,
      label,
      imageBackgroundColor,
      testType,
      priceElement
    },
    ref
  ) => {
    return (
      <Box
        borderRadius="5px"
        data-component-name="Test product card"
        display="flex"
        flexDirection={stacked ? "column" : "row"}
        overflow="hidden"
        onClick={onClick}
        cursor="pointer"
        ref={ref}
      >
        <Box
          bg={imageBackgroundColor}
          position="relative"
          width={stacked ? "100%" : "40%"}
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain"
          }}
          minHeight={300}
        >
          {!!badge && (
            <Box position="absolute" zIndex={1}>
              {badge}
            </Box>
          )}
        </Box>
        <Stack
          backgroundColor="white"
          p={[4, 4, 5, 6]}
          flexGrow={1}
          width={stacked ? "100%" : "60%"}
          gap={20}
        >
          {label}
          <Box>
            <Box as="h2" fontFamily="gilroyBold">
              {name}
            </Box>
            <Spacer py={2} />
            <RichText>{description}</RichText>
          </Box>
          <Box>
            <Group alignItems="center" gap={10}>
              <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
                Sample type:
              </Box>
              {!!testType && <Pills.Pill color="purple">{testType}</Pills.Pill>}
            </Group>
            <Group gap={10}>
              <Box as="span" fontFamily="gilroyBold" fontSize={16}>
                Biomarkers tested:
              </Box>
              <Box as="span" fontFamily="gilroyMedium" fontSize={16}>
                {numOfBiomarkersTested}
              </Box>
            </Group>
            {!!matchedSymptoms.length && (
              <Group gap={10} flexWrap="wrap">
                <Box
                  as="span"
                  fontFamily="gilroyBold"
                  fontSize={16}
                  style={{
                    wordWrap: "nowrap"
                  }}
                >
                  Top 5 matched symptoms:
                </Box>
                <Box as="span" fontFamily="gilroyMedium" fontSize={16}>
                  {matchedSymptoms.join(",")}
                </Box>
              </Group>
            )}
          </Box>
          {productAudience === "B2C" && <IsThisTestRightForYou slug={slug} />}
          <Box
            display="flex"
            flexDirection={stacked ? "column" : "row"}
            alignItems={stacked ? "start" : "center"}
          >
            <Box display="flex" alignItems="center" gap={10}>
              {priceElement}
            </Box>
            <ButtonV2
              ml={stacked ? 0 : theme.spacing.medium}
              mt={stacked ? 20 : 0}
              rightIcon={<ChevronComponent />}
              color="green"
            >
              learn more
            </ButtonV2>
          </Box>
          <Pills.Group>
            {categories.map(c => (
              <Pills.Pill
                key={c}
                color="haze"
                sx={{
                  color: "dark"
                }}
              >
                {c}
              </Pills.Pill>
            ))}
          </Pills.Group>
        </Stack>
      </Box>
    );
  }
);

export default StackedListingViewCard;
