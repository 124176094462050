import { useContext, useEffect, useMemo, useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import { gql, useLazyQuery } from "@apollo/client";
import { useAppState } from "components/AppStateProvider";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import {
  TestProductModalContext,
  TestProductModalProvider
} from "contexts/TestProductModalContext";
import { TestProductOptionProvider } from "contexts/TestProductOptionContext";
import { theme } from "core/theme";
import {
  CONSUMER_TEST_PRODUCT_DETAIL_QUERY,
  CONSUMER_TEST_PRODUCT_OPTION_FIELDS
} from "graphql/shop";
import { isFunction } from "lodash";
import { ExpiredMarkersToggleSwitch } from "tpo/ExpiredMarkersToggleSwitch";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import Grid from "../components/Grid";
import Image from "../components/Image";
import Loading from "../components/Loading";
import Page from "../components/Page";
import Text from "../components/Text";
import { ExecutionProvider, useExecutionContext } from "../contexts/ExecutionContext";
import {
  FOOD,
  NUTRITION,
  THINGS_TO_TAKE,
  LIFESTYLE,
  HEALTH,
  FITNESS,
  PRACTITIONER
} from "../core/constants";
import {
  RECOMMENDATIONS_URL,
  SUPPLEMENTS_URL,
  FOODS_URL,
  getPatientRecommendationUrl,
  getTPORecommendationUrl,
  getPatientSupplementUrlUsingSlug,
  getTPOSupplementUrl,
  getPatientFoodListUrl,
  getTPOFoodUrl
} from "../core/urls";
import { PageButton, SolidButton } from "../deprecated/Buttons";
import { EXAMPLE_FIELDS } from "../graphql/tpo/content/types";
import {
  CHART_CONFIGURATION_FIELDS,
  USER_RESULT_FIELDS_TRUNCATED,
  USER_TEST_RECOMMENDATION_FIELDS
} from "../graphql/tpo/results/types";
import useDocTitle from "../hooks/use-doc-title";
import useWindowSize from "../hooks/use-window-size";
import padlockImage from "../images/tpo/padlock.svg";
import { PanelBoxV2 } from "./Boxes";
import RecommendationCard from "./Cards";
import { CollapseableText } from "./CollapseableText";
import Currency from "./Currency";
import Jumbotron from "./Jumbotron";
import { TabsContext, LazyTabs } from "./LazyTabs";
import Modal from "./Modal";
import NothingToShow from "./NothingToShow";
import Overlay from "./Overlay";
import RecommendationIcon from "./RecommendationIcon";
import Spacer from "./Spacer";
import Stack from "./Stack";
import Tabs from "./Tabs";
import { RelatedResults } from "./UserResult";
import { CardListContext } from "./UserResults";
import { useCardList } from "./UserSubsector";
import { ViewerContext } from "./Viewer";
import StackedListingViewCard from "./shop/StackedListingViewCard";
import { getCarouselImages } from "./shop/TestProductDetailPage";
import TestProductModal from "./shop/TestProductModal";
import TestRecommendationBadge from "./shop/TestRecommendationBadge";

/**
 * This is basically a re-write of the original page.
 *
 * In writing it again I've noticed that prioritySubsector is always null for recommendation types (from the gql
 * query - see below).
 *
 * userSubsectors {
 *  recommendations {
 *    prioritySubsector {
 *      id
 *      priority
 *    }
 *  }
 * }
 *
 * Before I had code for filtering recommendations so that recommendations would only be included for a userSubsector
 * if one of two conditions were true:
 *
 * a. prioritySubsector is null
 * b. prioritySubsector.priority === userSubsector.priority
 *
 * It seems though basically no filtering would have occured on the old page because prioritySubsector is always null.
 * This re-write is the same in that it too won't attempt to remove recommendations from a userSubsector if they are showing
 * already for another userSubsector.
 *
 * Does this need to be addressed BE?
 */

const RECOMMENDATION_MODAL_QUERY = gql`
  query RecommendationQuery($recommendationId: ID, $userId: ID) {
    recommendation(recommendationId: $recommendationId, userId: $userId) {
      name
      category
      verb
      id
      description
      examples {
        id
        name
      }
      relatedUserResults(userId: $userId) {
        id
        ...UserResultFieldsTruncated
        chartConfiguration {
          ...ChartConfigurationFields
        }
        resultState {
          id
          name
        }
      }
    }
  }
  ${USER_RESULT_FIELDS_TRUNCATED}
  ${CHART_CONFIGURATION_FIELDS}
`;

export function RecommendationModalContent({ recommendationId }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;
  return (
    <DataLoader
      query={RECOMMENDATION_MODAL_QUERY}
      variables={{
        recommendationId,
        userId: clientId
      }}
      render={({ recommendation }) => {
        if (recommendation) {
          const hasExamples = !!recommendation?.examples.length;

          return (
            <>
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20,
                  pb: [50, 50, 80]
                }}
              >
                <RecommendationIcon
                  category={recommendation.category || NUTRITION}
                  width={150}
                  height={150}
                  fill="dark"
                />
                <Box py={[2, 2, 20]} />
                <Box as="h2" fontFamily="gilroyBold" fontSize={36}>
                  {recommendation.name}
                </Box>
                <Box py={[2, 2, 20]} />
                <CollapseableText>{recommendation.description}</CollapseableText>
              </PanelBoxV2>
              {hasExamples && (
                <PanelBoxV2
                  maxWidth={600}
                  outer={{
                    pt: [30, 30, 60],
                    pb: [50, 50, 80],
                    px: [50],
                    bg: "haze"
                  }}
                >
                  <Box as="h2" fontFamily="gilroyBold" fontSize={28}>
                    Examples
                  </Box>
                  <Box py={[7.5, 7.5, 15]} />
                  <Box
                    as="ul"
                    fontFamily="gilroyMedium"
                    fontSize={[16]}
                    listStyle="disc"
                    style={{
                      listStylePosition: "inside"
                    }}
                  >
                    The following examples are related to the above recommendation.
                    <Box py={[7.5, 7.5, 15]} />
                    {recommendation?.examples.map(example => (
                      <Box as="li" py={1} key={example.id}>
                        {example.name}
                      </Box>
                    ))}
                  </Box>
                </PanelBoxV2>
              )}
              {!!recommendation.relatedUserResults.length && (
                <PanelBoxV2
                  maxWidth={600}
                  outer={{
                    pt: [40],
                    pb: [50, 50, 80],
                    px: [50],
                    bg: hasExamples ? "white" : "haze"
                  }}
                >
                  <Box as="h2" fontFamily="gilroyBold" fontSize={28}>
                    Why should you do this?
                  </Box>
                  <Box py={[7.5, 7.5, 15]} />
                  <Box>The above suggestion is based on the following results</Box>
                  <Box py={20} />
                  <Box fontFamily="gilroyBold" fontSize={18}>
                    Linked results
                  </Box>
                  <Box py={15} />
                  <Box display="flex" flexDirection="column" gap={20}>
                    <RelatedResults results={recommendation.relatedUserResults} py={0} />
                  </Box>
                </PanelBoxV2>
              )}
            </>
          );
        }
        return null;
      }}
    />
  );
}

export const USER_SUBSECTOR_FIELDS = gql`
  fragment UserSubsectorFields on UserSubsectorType {
    id
    name
    thingsToDoCount
    thingsToAvoidCount
    thingsToTakeCount
    peopleToSeeCount
  }
`;

export const USER_SUBSECTORS_QUERY = gql`
  query UserSubsectorsQuery(
    $excludeEmptyRecommendations: Boolean
    $includeThingsToDoCount: Boolean
    $includeThingsToAvoidCount: Boolean
    $includeThingsToTakeCount: Boolean
    $includePeopleToSeeCount: Boolean
    $userId: ID
    $suppressed: Boolean
    $fresh: Boolean
  ) {
    userSubsectors(
      excludeEmptyRecommendations: $excludeEmptyRecommendations
      includeThingsToDoCount: $includeThingsToDoCount
      includeThingsToAvoidCount: $includeThingsToAvoidCount
      includeThingsToTakeCount: $includeThingsToTakeCount
      includePeopleToSeeCount: $includePeopleToSeeCount
      userId: $userId
      suppressed: $suppressed
      fresh: $fresh
    ) {
      ...UserSubsectorFields
    }
    userTestRecommendations(userId: $userId) {
      ...UserTestRecommendationFields
    }
  }
  ${USER_SUBSECTOR_FIELDS}
  ${USER_TEST_RECOMMENDATION_FIELDS}
`;

const RECOMMENDATION_QUERY = gql`
  query Recommendations(
    $userSubsectorId: ID!
    $verb: String
    $verbGroup: String
    $userId: ID
    $suppressed: Boolean
    $fresh: Boolean
  ) {
    recommendations(
      userSubsectorId: $userSubsectorId
      verb: $verb
      verbGroup: $verbGroup
      userId: $userId
      suppressed: $suppressed
      fresh: $fresh
    ) {
      id
      name
      slug
      category
    }
  }
`;

const SUPPLEMENTS_QUERY = gql`
  query Supplements($userSubsectorId: ID!, $userId: ID, $suppressed: Boolean) {
    userSupplements(userSubsectorId: $userSubsectorId, userId: $userId, suppressed: $suppressed) {
      id
      name
      slug
    }
  }
`;

const FOOD_LIST_QUERY = gql`
  query UserSubsectorExamplesQuery(
    $userSubsectorId: ID
    $category: String
    $userId: ID
    $suppressed: Boolean
  ) {
    examples(
      userSubsectorId: $userSubsectorId
      category: $category
      userId: $userId
      suppressed: $suppressed
    ) {
      ...ExampleFields
      verb
    }
  }
  ${EXAMPLE_FIELDS}
`;

export const RECOMMENDATION_BG = "orange";
export const SUPPLEMENT_BG = "supplements";
export const FOOD_BG = "green";
export const PEOPLE_TO_SEE_BG = "partners"; // shade of pink

const ICON_BGS = {
  [LIFESTYLE]: "lifestyle",
  [HEALTH]: "health",
  [FITNESS]: "fitness"
};

export const recommendationsMapper = data =>
  data.recommendations?.map(recommendation => ({
    id: recommendation.id,
    name: recommendation.name,
    bg: ICON_BGS[recommendation.category] || RECOMMENDATION_BG,
    icon: recommendation.category || NUTRITION,
    slug: recommendation.slug,
    type: "recommendations"
  }));

function useTabs({ tabs: initialTabs, defaultTab = 0 }) {
  const [tabs, setTabs] = useState(initialTabs);
  const [selected, setSelected] = useState(tabs[defaultTab]);
  return {
    tabs,
    tab: selected,
    setTabs,
    setTab: setSelected,
    isSelected: tab => tab === selected
  };
}

export const TABS = {
  thingsToDo: {
    id: "things_to_do",
    name: "Things To Do",
    query: RECOMMENDATION_QUERY,
    variables: ({ userSubsector, userId, suppressed, fresh }) => ({
      userSubsectorId: userSubsector.id,
      verbGroup: "things_to_do",
      userId,
      suppressed,
      fresh
    }),
    getItems: recommendationsMapper,
    url: RECOMMENDATIONS_URL
  },
  thingsToAvoid: {
    id: "things_to_avoid",
    name: "Things To Avoid",
    query: RECOMMENDATION_QUERY,
    variables: ({ userSubsector, userId, suppressed, fresh }) => ({
      userSubsectorId: userSubsector.id,
      verbGroup: "things_to_avoid",
      userId,
      suppressed,
      fresh
    }),
    getItems: recommendationsMapper,
    url: RECOMMENDATIONS_URL
  },
  thingsToTake: {
    id: "things_to_take",
    name: "Things To Take",
    query: SUPPLEMENTS_QUERY,
    variables: ({ userSubsector, userId, suppressed, fresh }) => ({
      userSubsectorId: userSubsector.id,
      userId,
      suppressed,
      fresh
    }),
    getItems: data =>
      data?.userSupplements?.map(supplement => ({
        id: supplement.id,
        name: supplement.name,
        bg: SUPPLEMENT_BG,
        icon: THINGS_TO_TAKE,
        slug: supplement.slug,
        type: "supplements"
      })),
    url: SUPPLEMENTS_URL
  },
  peopleToSee: {
    id: "people_to_see",
    name: "People To See",
    query: RECOMMENDATION_QUERY,
    variables: ({ userSubsector, userId, suppressed, fresh }) => ({
      userSubsectorId: userSubsector.id,
      verb: "See",
      userId,
      suppressed,
      fresh
    }),
    getItems: recommendationsMapper,
    url: RECOMMENDATIONS_URL
  },
  foodList: {
    id: "food_list",
    name: "Food List",
    query: FOOD_LIST_QUERY,
    variables: ({ userSubsector, userId, suppressed, fresh }) => ({
      userSubsectorId: userSubsector.id,
      category: "Food",
      userId,
      suppressed,
      fresh
    }),
    getItems: data =>
      data?.examples?.map(example => ({
        id: example.id,
        name: `${example.verb} ${example.name}`,
        bg: FOOD_BG,
        icon: FOOD,
        slug: example.slug,
        type: "examples"
      })),
    url: FOODS_URL
  }
};

function ButtonTab({ children, selected, ...defaultProps }) {
  const props = selected
    ? { backgroundColor: "dark", borderColor: "dark", color: "white" }
    : { color: "midGrey", bg: "transparent", borderColor: "transparent" };

  return (
    <SolidButton {...defaultProps} {...props}>
      {children}
    </SolidButton>
  );
}

ButtonTab.defaultProps = {
  padding: "13px 20px",
  fontSize: 12,
  lineHeight: "12px",
  borderTopLeftRadius: 40,
  borderTopRightRadius: 40,
  borderBottomLeftRadius: 40,
  borderBottomRightRadius: 40,
  whiteSpace: "nowrap"
};

export function initialiseTabs(countObj, tabs) {
  const nonEmptyTabs = [];
  if (countObj.thingsToDoCount > 0) {
    nonEmptyTabs.push(tabs.thingsToDo);
  }
  if (countObj.thingsToAvoidCount > 0) {
    nonEmptyTabs.push(tabs.thingsToAvoid);
  }
  if (countObj.thingsToTakeCount > 0) {
    nonEmptyTabs.push(tabs.thingsToTake);
  }
  if (countObj.peopleToSeeCount > 0) {
    nonEmptyTabs.push(tabs.peopleToSee);
  }
  nonEmptyTabs.push(tabs.foodList);
  // it's too slow to count these server side
  // shouldn't matter anyway since we should always have foodlist if we have recommendations
  // worse case is we show a "Empty List" message
  return nonEmptyTabs;
}

function EmptyList() {
  return (
    <Text color="midGrey" textAlign="center">
      Empty list
    </Text>
  );
}

function Content({ data, tab }) {
  return tab.getItems(data)?.length ? (
    <Grid gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"]}>
      {tab.getItems(data).map(item => (
        <RecommendationCard key={item.id} {...item} />
      ))}
    </Grid>
  ) : (
    <EmptyList />
  );
}

export function RecommendationTabs({ tabs, tab, setTab }) {
  return (
    <Tabs>
      {tabs.map(_tab => (
        <ButtonTab key={_tab.id} selected={_tab.id === tab.id} handleClick={() => setTab(_tab.id)}>
          {_tab.name}
        </ButtonTab>
      ))}
    </Tabs>
  );
}

export function TabsContent({ loading, data, tab }) {
  return !!loading || !data ? <Loading /> : <Content data={data} tab={tab} />;
}

function TabsProviderEmpty() {
  return null;
}

function TabsProviderNonEmpty({ children, tabs: tabsProp }) {
  const { tabs, tab, setTab, isSelected } = useTabs({ tabs: tabsProp });
  const executionContext = useExecutionContext();
  const [fetch, { data, loading }] = useLazyQuery(tab.query, {
    variables: isFunction(tab.variables) ? tab.variables(executionContext) : tab.variables,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache"
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  const api = useMemo(
    () => ({
      tabs,
      tab,
      setTab,
      isSelected,
      loading,
      data
    }),
    [tabs, tab, setTab, isSelected, loading, data]
  );

  return <TabsContext.Provider value={api}>{children}</TabsContext.Provider>;
}

export function TabsProvider({ children, tabs: tabsProp, countObj: obj }) {
  const initialTabs = initialiseTabs(obj, tabsProp);

  if (initialTabs.length) {
    return (
      <TabsProviderNonEmpty tabs={initialTabs} obj={obj}>
        {children}
      </TabsProviderNonEmpty>
    );
  }

  return <TabsProviderEmpty />;
}

export function TabsAndContent({ children, tabs: initialTabs, obj }) {
  return (
    <TabsProvider initialTabs={initialTabs} obj={obj}>
      <TabsContext.Consumer>
        {({ tabs, isSelected, setTab, loading, data, tab }) => (
          <>
            <Tabs tabs={tabs} isSelected={isSelected} setTab={setTab} />
            <TabsContent loading={loading} data={data} tab={tab} />
            {children}
          </>
        )}
      </TabsContext.Consumer>
    </TabsProvider>
  );
}

export function Recommendations({ children, countObj, tabs }) {
  const initialTabs = initialiseTabs(countObj, tabs);

  if (!initialTabs.length) {
    return null;
  }

  return (
    <TabsAndContent tabs={initialTabs} obj={countObj}>
      {children}
    </TabsAndContent>
  );
}

const USER_SUBSECTOR_QUERY = gql`
  query UserSubsectorQuery(
    $category: String
    $userSubsectorId: ID!
    $excludeEmptyRecommendations: Boolean
    $includeThingsToDoCount: Boolean
    $includeThingsToAvoidCount: Boolean
    $includeThingsToTakeCount: Boolean
    $includePeopleToSeeCount: Boolean
    $userId: ID
    $suppressed: Boolean
    $fresh: Boolean
  ) {
    examples(
      category: $category
      userSubsectorId: $userSubsectorId
      userId: $userId
      suppressed: $suppressed
    ) {
      id
    }
    userSubsector(
      userSubsectorId: $userSubsectorId
      excludeEmptyRecommendations: $excludeEmptyRecommendations
      includeThingsToDoCount: $includeThingsToDoCount
      includeThingsToAvoidCount: $includeThingsToAvoidCount
      includeThingsToTakeCount: $includeThingsToTakeCount
      includePeopleToSeeCount: $includePeopleToSeeCount
      userId: $userId
      suppressed: $suppressed
      fresh: $fresh
    ) {
      id
      name
      thingsToDoCount
      thingsToAvoidCount
      thingsToTakeCount
      peopleToSeeCount
    }
  }
`;

function TabsPerUserSubsectorProvider({ children, examples, userSubsector }) {
  const viewerContext = useContext(ViewerContext);
  const history = useHistory();
  const cardListContext = useContext(CardListContext);

  const tabs = useMemo(
    () => [
      ...(userSubsector.thingsToDoCount > 0
        ? [
            {
              ...TABS.thingsToDo,
              getItems: data =>
                recommendationsMapper(data).map(item => ({
                  ...item,
                  onClick: () => {
                    if (cardListContext?.viewCard) {
                      cardListContext.viewCard({
                        type: "recommendations",
                        item,
                        bg: item.bg
                      });
                    } else {
                      if (viewerContext?.userId) {
                        history.push(getPatientRecommendationUrl(viewerContext?.userId, item.id));
                      } else {
                        history.push(getTPORecommendationUrl(item.id));
                      }
                    }
                  }
                }))
            }
          ]
        : []),
      ...(userSubsector.thingsToAvoidCount > 0
        ? [
            {
              ...TABS.thingsToAvoid,
              getItems: data =>
                recommendationsMapper(data).map(item => ({
                  ...item,
                  onClick: () => {
                    if (cardListContext?.viewCard) {
                      cardListContext.viewCard({
                        type: "recommendations",
                        item,
                        bg: item.bg
                      });
                    } else {
                      if (viewerContext?.userId) {
                        history.push(getPatientRecommendationUrl(viewerContext?.userId, item.id));
                      } else {
                        history.push(getTPORecommendationUrl(item.id));
                      }
                    }
                  }
                }))
            }
          ]
        : []),
      ...(userSubsector.thingsToTakeCount > 0
        ? [
            {
              ...TABS.thingsToTake,
              getItems: data =>
                TABS.thingsToTake.getItems(data).map(item => ({
                  ...item,
                  onClick: () => {
                    if (viewerContext?.userId) {
                      history.push(
                        getPatientSupplementUrlUsingSlug(viewerContext?.userId, item.slug)
                      );
                    } else {
                      history.push(getTPOSupplementUrl(item.slug));
                    }
                  }
                }))
            }
          ]
        : []),
      ...(userSubsector.peopleToSeeCount > 0
        ? [
            {
              ...TABS.peopleToSee,
              getItems: data =>
                recommendationsMapper(data).map(item => ({
                  ...item,
                  onClick: () => {
                    if (viewerContext?.userId) {
                      history.push(getPatientRecommendationUrl(viewerContext?.userId, item.id));
                    } else {
                      history.push(getTPORecommendationUrl(item.id));
                    }
                  }
                }))
            }
          ]
        : []),
      ...(examples.length
        ? [
            {
              ...TABS.foodList,
              getItems: data =>
                TABS.foodList.getItems(data).map(item => ({
                  ...item,
                  onClick: () => {
                    if (viewerContext?.userId) {
                      history.push(getPatientFoodListUrl(viewerContext?.userId));
                    } else {
                      history.push(getTPOFoodUrl());
                    }
                  }
                }))
            }
          ]
        : [])
    ],
    [examples, userSubsector, history, viewerContext, cardListContext]
  );

  return <LazyTabs tabs={tabs}>{children}</LazyTabs>;
}

export function LoadUserSubsector({ userSubsector, render, suppressed }) {
  const viewerContext = useContext(ViewerContext);
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  return (
    <DataLoader
      query={USER_SUBSECTOR_QUERY}
      variables={{
        userSubsectorId: userSubsector.id,
        category: "Food",
        excludeEmptyRecommendations: true,
        includeThingsToDoCount: true,
        includeThingsToAvoidCount: true,
        includeThingsToTakeCount: true,
        includePeopleToSeeCount: true,
        userId: viewerContext?.userId,
        suppressed,
        fresh: !includeExpiredMarkers
      }}
      render={render}
    />
  );
}

export function UserSubsectorContent() {
  return (
    <TabsContext.Consumer>
      {({ tabs, isSelected, setTab, loading, data, tab: tabId }) => (
        <Stack gap={60}>
          <RecommendationTabs tabs={tabs} isSelected={isSelected} setTab={setTab} />
          <PanelBoxV2
            maxWidth={1280}
            outer={{
              px: 20
            }}
          >
            <TabsContent loading={loading} data={data} tab={tabs.find(tab => tab.id === tabId)} />
          </PanelBoxV2>
        </Stack>
      )}
    </TabsContext.Consumer>
  );
}

export function UserSubsector({ userSubsector }) {
  const viewerContext = useContext(ViewerContext);
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  return (
    <LoadUserSubsector
      userSubsector={userSubsector}
      render={props => (
        <ExecutionProvider
          context={{
            userSubsector: props.userSubsector,
            userId: viewerContext?.userId,
            fresh: !includeExpiredMarkers
          }}
        >
          <TabsPerUserSubsectorProvider
            examples={props.examples}
            userSubsector={props.userSubsector}
          >
            <UserSubsectorContent />
          </TabsPerUserSubsectorProvider>
        </ExecutionProvider>
      )}
    />
  );
}

function UserTestRecommendationsListForConsumers({ userTestRecommendations }) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;
  const { userInfo } = useAppState();
  const user = userInfo?.user;

  return (
    <TestProductOptionProvider
      testProductOptionFragment={CONSUMER_TEST_PRODUCT_OPTION_FIELDS}
      testProductOptionFragmentName="ConsumerTestProductOptionFields"
      testProductOptionFragmentType="TestProductOptionType"
    >
      <TestProductModalProvider
        testProductQuery={CONSUMER_TEST_PRODUCT_DETAIL_QUERY}
        testProductFieldName="consumerTestProduct"
      >
        <TestProductModalContext.Consumer>
          {({ testProduct, selectTestProductId }) => {
            let bg;
            if (testProduct) {
              const carouselImages = getCarouselImages(testProduct);
              bg = carouselImages.length ? "white" : "haze";
            }

            return (
              <>
                <Grid>
                  {userTestRecommendations.slice(0, 5).map((userTestRecommendation, index) => (
                    <StackedListingViewCard
                      stacked={isMobile}
                      badge={<TestRecommendationBadge bg="white" color="dark" rank={index + 1} />}
                      matchedSymptoms={userTestRecommendation.symptoms
                        ?.slice(0, 5)
                        .map(symptom => symptom.name)}
                      key={userTestRecommendation.id}
                      name={userTestRecommendation.product.name}
                      priceElement={
                        <>
                          {userTestRecommendation.product.consumerFullPrice &&
                            userTestRecommendation.product.consumerFullPrice !==
                              userTestRecommendation.product.consumerCurrentPrice && (
                              <Currency
                                fontSize={44}
                                decimalFontSize={28}
                                value={userTestRecommendation.product.consumerFullPrice}
                                color="#e44c4b"
                                strikethrough
                              />
                            )}
                          <Currency
                            value={userTestRecommendation.product.consumerCurrentPrice}
                            fontSize={44}
                            decimalFontSize={28}
                          />
                        </>
                      }
                      description={userTestRecommendation.product.content.description}
                      sampleTypes={userTestRecommendation.product.sampleTypes}
                      numOfBiomarkersTested={
                        userTestRecommendation.product.content.numOfBiomarkersTested
                      }
                      slug={userTestRecommendation.product.slug}
                      image={userTestRecommendation.product.content.image1}
                      categories={userTestRecommendation.product.content.categories.map(
                        cat => cat.name
                      )}
                      productAudience={userTestRecommendation.product.productAudience}
                      imageBackgroundColor={theme.colors.purple}
                      testType={userTestRecommendation.product.content.testType}
                      onClick={() => {
                        if (user?.partnerUserProfile?.tier === PRACTITIONER) {
                          return;
                        }
                        selectTestProductId(userTestRecommendation.product.id);
                      }}
                    />
                  ))}
                </Grid>
                <TestProductModal bg={bg} />
              </>
            );
          }}
        </TestProductModalContext.Consumer>
      </TestProductModalProvider>
    </TestProductOptionProvider>
  );
}

export function UserTestRecommendationsPanel({ listElement }) {
  return (
    <>
      <Spacer py={[2]} />
      <PanelBoxV2
        maxWidth={760}
        outer={{
          px: 40
        }}
      >
        <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          Top 5 suggested tests
        </Box>
        <Box py={[2, 2, 20]} />
        <CollapseableText fontSize={[14, 14, 16]}>
          {`This test has been selected based on the answers you provided in the self assessment questionnaire and do not take any previous test results into consideration.
      `}
        </CollapseableText>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={1280}
        outer={{
          px: 20
        }}
      >
        {listElement}
      </PanelBoxV2>
    </>
  );
}

export function PriorityRecommendations({
  userSubsectors,
  userTestRecommendationsElement,
  header,
  bodyCopy
}) {
  const [page, setPage] = useState(0);
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  useEffect(() => {
    // if page is greater than the number of subsectors, set it to the last subsector
    if (page > userSubsectors.length - 1) {
      setPage(userSubsectors.length - 1);
    }
  }, [page, setPage, userSubsectors]);

  // guard against the re-render before the effect applies
  if (page > userSubsectors.length - 1) {
    return null;
  }

  return (
    <PanelBoxV2
      stacked
      gap={[30, 30, 40]}
      outer={{
        pt: [30, 30, 60],
        pb: [50, 50, 80]
      }}
    >
      <PanelBoxV2
        maxWidth={760}
        outer={{
          px: [40]
        }}
      >
        <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          {header}
        </Box>
        <Box py={[2, 2, 20]} />
        {bodyCopy && <CollapseableText fontSize={[14, 14, 16]}>{bodyCopy}</CollapseableText>}
      </PanelBoxV2>
      <Stack gap={30}>
        <Tabs>
          {userSubsectors.map((userSubsector, index) => (
            <PageButton
              bg={index === page ? "black" : "white"}
              color={index === page ? "white" : "black"}
              label={index + 1}
              handleClick={() => {
                setPage(index);
              }}
              key={userSubsector.id}
            />
          ))}
        </Tabs>
        <Box textAlign="center" px={20} fontFamily="gilroyBold" fontSize={18}>
          {userSubsectors[page]?.name}
        </Box>
      </Stack>
      <ViewerContext.Consumer>
        {({ userId }) => (
          <LoadUserSubsector
            key={userSubsectors[page].id}
            userSubsector={userSubsectors[page]}
            suppressed={false}
            render={props => (
              <ExecutionProvider
                context={{
                  userSubsector: props.userSubsector,
                  userId,
                  suppressed: false,
                  fresh: !includeExpiredMarkers
                }}
              >
                <TabsPerUserSubsectorProvider
                  examples={props.examples}
                  userSubsector={props.userSubsector}
                >
                  <TabsContext.Consumer>
                    {({ tabs, setTab, loading, data, tab }) => (
                      <Stack gap={[30, 30, 70]}>
                        <RecommendationTabs tabs={tabs} tab={tab} setTab={setTab} />
                        <PanelBoxV2
                          maxWidth={1280}
                          outer={{
                            px: 20
                          }}
                        >
                          <TabsContent loading={loading} data={data} tab={tab} />
                        </PanelBoxV2>
                      </Stack>
                    )}
                  </TabsContext.Consumer>
                </TabsPerUserSubsectorProvider>
              </ExecutionProvider>
            )}
          />
        )}
      </ViewerContext.Consumer>
      {userTestRecommendationsElement}
    </PanelBoxV2>
  );
}

PriorityRecommendations.defaultProps = {
  header: "Your Prioritised Recommendation List",
  bodyCopy: `Priority recommendations are generated based on the most important subsectors to tackle first.

  To take ownership of your own health you will need a set of recommendations following your wellness test. These are broken down into priorities using your self assessment and tests you have taken.
  Always start on Priority 1 and when you are ready add further recommendations from the next priority in line. Regular updates of the symptoms questionnaire as well as new tests will keep this list updated.

  If you would like to discuss your results in more depth, contact us about booking a 45-minute consultation.
  `
};

export function UserSubsectors({ userSubsectors, userTestRecommendations }) {
  return (
    <Page bg="haze">
      <Jumbotron title="Recommendations" top={<ExpiredMarkersToggleSwitch />} />

      {userSubsectors?.length ? (
        <PriorityRecommendations
          userSubsectors={userSubsectors}
          userTestRecommendationsElement={
            userTestRecommendations?.length ? (
              <UserTestRecommendationsPanel
                listElement={
                  <UserTestRecommendationsListForConsumers
                    userTestRecommendations={userTestRecommendations}
                  />
                }
              />
            ) : null
          }
        />
      ) : (
        <NothingToShow
          header={
            <PanelBoxV2
              maxWidth={760}
              outer={{
                px: 20
              }}
            >
              <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                {PriorityRecommendations.defaultProps.header}
              </Box>
              <Box py={[2, 2, 20]} />
              <CollapseableText fontSize={[14, 14, 16]}>
                {PriorityRecommendations.defaultProps.bodyCopy}
              </CollapseableText>
            </PanelBoxV2>
          }
          jumbotron={
            <>
              <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                No recommendations available
              </Box>
              <Box py={[2, 2, 20]} />
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                Looks like your patient is yet to complete their symptom questionnaire.
              </Box>
            </>
          }
        />
      )}
    </Page>
  );
}

const PRIORITY_USER_SUBSECTOR_QUERY = gql`
  query UserSubsectorsQuery(
    $priority: Int!
    $excludeEmptyRecommendations: Boolean
    $includeThingsToDoCount: Boolean
    $includeThingsToAvoidCount: Boolean
    $includeThingsToTakeCount: Boolean
    $includePeopleToSeeCount: Boolean
    $category: String
    $suppressed: Boolean
    $fresh: Boolean
  ) {
    examples(
      userSubsectorPriority: $priority
      category: $category
      suppressed: $suppressed
      fresh: $fresh
    ) {
      id
    }
    userSubsector(
      priority: $priority
      excludeEmptyRecommendations: $excludeEmptyRecommendations
      includeThingsToDoCount: $includeThingsToDoCount
      includeThingsToAvoidCount: $includeThingsToAvoidCount
      includeThingsToTakeCount: $includeThingsToTakeCount
      includePeopleToSeeCount: $includePeopleToSeeCount
      suppressed: $suppressed
      fresh: $fresh
    ) {
      id
      name
      thingsToDoCount
      thingsToAvoidCount
      thingsToTakeCount
      peopleToSeeCount
    }
  }
`;

export function TopPriorityUserSubsector({ children, id, fallback }) {
  const { includeExpiredMarkers } = useChangeOverTimeContext();
  return (
    <DataLoader
      query={PRIORITY_USER_SUBSECTOR_QUERY}
      variables={{
        priority: id,
        excludeEmptyRecommendations: true,
        includeThingsToDoCount: true,
        includeThingsToAvoidCount: true,
        includeThingsToTakeCount: true,
        includePeopleToSeeCount: true,
        category: "Food",
        suppressed: false,
        fresh: !includeExpiredMarkers
      }}
      render={props =>
        props.userSubsector ? (
          <ExecutionProvider
            context={{
              userSubsector: props.userSubsector,
              suppressed: false,
              fresh: !includeExpiredMarkers
            }}
          >
            <TabsPerUserSubsectorProvider
              examples={props.examples}
              userSubsector={props.userSubsector}
            >
              {children}
            </TabsPerUserSubsectorProvider>
          </ExecutionProvider>
        ) : (
          fallback || <Box>nothing to show</Box>
        )
      }
    />
  );
}

export function RecommendationTabGrid({ tabs, loading, data, tab, overlay, setTab }) {
  return (
    <Stack gap={[30, 30, 60]}>
      <Box position="relative">
        {overlay && <Overlay bg="haze" opacity={0.8} />}
        <RecommendationTabs tabs={tabs} tab={tab} setTab={setTab} />
      </Box>
      <PanelBoxV2
        maxWidth={1280}
        outer={{
          px: 20
        }}
        inner={{
          position: "relative"
        }}
      >
        <TabsContent loading={loading} data={data} tab={tab} />
        {overlay && (
          <>
            <Overlay bg="haze" opacity={0.8} zIndex={1} />
            <Overlay zIndex={2} display="flex" justifyContent="center" alignItems="center">
              <Box
                bg="white"
                mx="auto"
                height="calc(100% - 40px)"
                width="calc(100% - 40px)"
                maxWidth={600}
                maxHeight={300}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={4}
              >
                <Image src={padlockImage} height={70} width={70} />
                <Text fontSize={[15, 15, 16]} mt={20} textAlign="center">
                  Take our symptoms questionnaire to unlock
                </Text>
              </Box>
            </Overlay>
          </>
        )}
      </PanelBoxV2>
    </Stack>
  );
}

RecommendationTabGrid.defaultProps = {
  overlay: false
};

function PrioritisedUserSubsectorRecommendations() {
  useDocTitle("Recommendations");
  const { clientId } = useParams();

  const { viewCard, closeCard, cardOpen } = useCardList();
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  const cardListApi = useMemo(
    () => ({
      viewCard,
      closeCard
    }),
    [viewCard, closeCard]
  );

  return (
    <DataLoader
      query={USER_SUBSECTORS_QUERY}
      variables={{
        excludeEmptyRecommendations: true,
        includeThingsToDoCount: true,
        includeThingsToAvoidCount: true,
        includeThingsToTakeCount: true,
        includePeopleToSeeCount: true,
        userId: clientId,
        suppressed: false,
        fresh: !includeExpiredMarkers
      }}
      render={props => (
        <ViewerContext.Provider
          value={{
            userId: clientId
          }}
        >
          <CardListContext.Provider value={cardListApi}>
            <UserSubsectors {...props} />
          </CardListContext.Provider>
          <Modal
            mode="centered"
            bg={cardOpen?.bg}
            close={closeCard}
            show={!!cardOpen}
            maxWidth={1020}
            closeButton
            headerProps={{
              pr: 20,
              pt: 20,
              pb: [40, 40, 20]
            }}
          >
            {cardOpen?.item?.id && (
              <RecommendationModalContent recommendationId={cardOpen.item.id} />
            )}
          </Modal>
        </ViewerContext.Provider>
      )}
    />
  );
}

export default PrioritisedUserSubsectorRecommendations;
