import { createContext, useContext } from "react";

import useTestProductModal from "tpo/shop/hooks/useTestProductModal";

export const TestProductModalContext = createContext();

export function TestProductModalProvider({
  children,
  testProductQuery,
  testProductFieldName,
  testProductOptionFragment,
  testProductOptionFragmentName,
  testProductOptionFragmentType
}) {
  const api = useTestProductModal({
    testProductQuery,
    testProductFieldName,
    testProductOptionFragment,
    testProductOptionFragmentName,
    testProductOptionFragmentType
  });

  return (
    <TestProductModalContext.Provider value={api}>{children}</TestProductModalContext.Provider>
  );
}

export function useTestProductModalContext() {
  return useContext(TestProductModalContext);
}
