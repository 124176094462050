import { gql } from "@apollo/client";

export const FILE_TYPE_FIELDS = gql`
  fragment FileTypeFields on FileTypeType {
    id
    extension
    contentType
  }
`;

export const TEST_DATA_FILE_FIELDS = gql`
  fragment TestDataFileFields on TestDataFileType {
    id
    testingService
    sampleFile
    name
    fileType {
      ...FileTypeFields
    }
  }
  ${FILE_TYPE_FIELDS}
`;
