import { gql } from "@apollo/client";

export const PROFILE_FORM_FIELDS = gql`
  fragment ProfileFormFields on PartnerUserProfileType {
    id
    pk
    user {
      id
      firstName
      lastName
      hashId
    }
    jobRole
    phoneNumber
    description
    descriptionOther
    qualifications
    specialisms
    specialismsOther
    instagram
    linkedin
    youtube
    websiteBlog
    practitionerType {
      id
      role
    }
    professionalBodies
    documents {
      id
      fileUrl
      fileName
      fileKey
    }
    isApprovedToBeExpert
  }
`;
