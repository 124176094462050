import { Controller, useFormContext } from "react-hook-form";

export default function ControlledFormField({ label, name, Component, ...props }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Component {...field} name={name} label={label} error={error?.message} {...props} />
      )}
    />
  );
}
