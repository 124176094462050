import { useState } from "react";

import { useFormContext } from "react-hook-form";

import { ReactComponent as CrossIcon } from "images/cross.svg";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import ButtonV2 from "v2/Buttons";

export default function ProfessionalBodiesField({ name = "professionalBodies" }) {
  const [professionalBodyValue, setProfessionalBodyValue] = useState("");
  const { setValue, watch } = useFormContext();
  const professionalBodies = watch(name);

  return (
    <>
      <Group gap={20}>
        <FloatingLabelInput
          label="Enter Details"
          style={{
            flexGrow: 1
          }}
          value={professionalBodyValue}
          onChange={e => setProfessionalBodyValue(e.target.value)}
          name={name}
        />
        <ButtonV2
          type="button"
          color="dark"
          px={20}
          py={2}
          onClick={() => {
            if (professionalBodyValue) {
              setValue(name, [...new Set([...professionalBodies, professionalBodyValue])], {
                shouldDirty: true
              });
              setProfessionalBodyValue("");
            }
          }}
          data-testid="addProfessionalBodyButton"
        >
          add
        </ButtonV2>
      </Group>
      {!!professionalBodies?.length && (
        <Group pt={[20, 20, 40]} gap={10} flexWrap="wrap">
          {professionalBodies.map(pb => (
            <ButtonV2
              leftIcon={<CrossIcon fill="white" width={10} />}
              color="green"
              variant="filled"
              py={[2, 2, 16]}
              px={[16, 16, 20]}
              fontSize={[10, 10, 12]}
              borderRadius="100px"
              borderWidth="2px"
              gap={10}
              height={[27, 27, 40]}
              key={pb}
              data-component-name="professionalBodyValue"
              onClick={() => {
                setValue(
                  name,
                  professionalBodies.filter(p => p !== pb),
                  { shouldDirty: true }
                );
              }}
            >
              {pb}
            </ButtonV2>
          ))}
        </Group>
      )}
    </>
  );
}
