import { useCallback, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { PAYEE_PATIENT } from "components/organisations/constants";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import {
  ADD_PRODUCT_TO_ORGANISATION_BASKET_MUTATION,
  ADD_SUPPLEMENT_TO_ORGANISATION_BASKET_MUTATION,
  REMOVE_PRODUCT_FROM_ORGANISATION_BASKET_MUTATION,
  REMOVE_SUPPLEMENT_FROM_ORGANISATION_BASKET_MUTATION,
  UPDATE_ORGANISATION_BASKET_DETAILS_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_BASKET_DETAIL_QUERY } from "graphql/organisations/queries/organisation";

export default function useOrganisationBasket() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { organisation } = useOrganisationContext();

  const { data: { basket } = {} } = useQuery(
    ORGANISATION_BASKET_DETAIL_QUERY,
    {
      variables: { organisation: parseInt(organisation?.id) },
      skip: !organisation,
      fetchPolicy: "network-only",
      onCompleted: () => {
        setLoading(false);
      },
      onError: error => {
        setError(error);
        setLoading(false);
      }
    },
    [organisation]
  );

  function handleErrors(responseData) {
    Object.keys(responseData).forEach(key => {
      if (responseData[key].errors && responseData[key].errors.length) {
        setError(responseData[key].errors[0].messages[0]);
      }
    });
  }

  const [_addProductToBasket] = useMutation(ADD_PRODUCT_TO_ORGANISATION_BASKET_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_removeProductFromBasket] = useMutation(REMOVE_PRODUCT_FROM_ORGANISATION_BASKET_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_addSupplementToBasket] = useMutation(ADD_SUPPLEMENT_TO_ORGANISATION_BASKET_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_removeSupplementFromBasket] = useMutation(
    REMOVE_SUPPLEMENT_FROM_ORGANISATION_BASKET_MUTATION,
    {
      onCompleted: data => {
        handleErrors(data);
        setLoading(false);
      },
      onError: error => {
        setError(error);
        setLoading(false);
      },
      refetchQueries: [
        {
          query: ORGANISATION_BASKET_DETAIL_QUERY,

          variables: { organisation: parseInt(organisation?.id) }
        }
      ]
    }
  );

  const [_updateBasketDetails] = useMutation(UPDATE_ORGANISATION_BASKET_DETAILS_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const addProductToBasket = useCallback(
    (productId, productOptionId, clinicLocationId) => {
      setLoading(true);
      setError(null);

      _addProductToBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            testProductCode: productId,
            testProductOption: productOptionId,
            clinicLocation: clinicLocationId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _addProductToBasket]
  );

  const removeProductFromBasket = useCallback(
    (productId, productOptionId, clinicLocationId) => {
      setLoading(true);
      setError(null);

      _removeProductFromBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            testProductCode: productId,
            testProductOption: productOptionId,
            clinicLocation: clinicLocationId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _removeProductFromBasket]
  );

  const addSupplementToBasket = useCallback(
    supplementId => {
      setLoading(true);
      setError(null);

      _addSupplementToBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            supplement: supplementId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _addSupplementToBasket]
  );

  const removeSupplementFromBasket = useCallback(
    supplementId => {
      setLoading(true);
      setError(null);

      _removeSupplementFromBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            supplement: supplementId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _removeSupplementFromBasket]
  );

  const updateBasketDetails = useCallback(
    fields => {
      setLoading(true);
      setError(null);

      _updateBasketDetails({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            // default field values to prevent overwrite
            payee: basket.payee,
            purchaseAsStock: basket.payee === PAYEE_PATIENT ? false : basket.purchaseAsStock,
            ...fields
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _updateBasketDetails]
  );

  return {
    loading,
    error,
    basket,
    addProductToBasket,
    removeProductFromBasket,
    addSupplementToBasket,
    removeSupplementFromBasket,
    updateBasketDetails
  };
}
