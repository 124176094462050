import { useContext } from "react";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import { DiscreteAxis, GradientAxis, SegmentedAxis } from "components/cot/AxisCharts";
import { GraphTemplate } from "components/cot/GraphTemplate";
import { LineGraph } from "components/cot/LineGraph";
import { USER_DATAPOINTS_QUERY } from "graphql/cot";
import "styles/cot/tooltip.css";
import { ViewerContext } from "tpo/Viewer";
import { buildDiscreteGraphConfiguration } from "utils/cot/discreteGraphs";
import { buildGradientGraphConfiguration } from "utils/cot/gradientGraphs";
import { buildSegmentedGraphConfiguration } from "utils/cot/segmentedGraphs";

export function GradientGraph({ chartConfiguration, userDatapoints }) {
  const config = buildGradientGraphConfiguration({
    chartConfiguration,
    userDatapoints
  });

  if (!config) return null;

  return (
    <GraphTemplate
      chart={
        <LineGraph
          fullRange={config.fullRange}
          points={config.points}
          tooltips={[config.tooltips]}
          colors={config.colors}
        />
      }
      yAxisWidth={30}
      yAxis={
        <Box position="absolute" height={290} top={50}>
          <GradientAxis
            background={config.background}
            labels={config.fullRange.map(point => point.label)}
          />
        </Box>
      }
    />
  );
}
export function DiscreteGraph({ chartConfiguration, userDatapoints }) {
  const config = buildDiscreteGraphConfiguration({
    chartConfiguration,
    userDatapoints
  });

  if (!config) return null;

  return (
    <GraphTemplate
      chart={
        <LineGraph
          fullRange={config.fullRange}
          points={config.points}
          tooltips={[config.tooltips]}
          colors={config.colors}
        />
      }
      yAxisWidth={35}
      yAxis={
        <Box
          position="absolute"
          width={290}
          transform="scale(1, -1) rotate(-270deg)"
          top={180}
          left={-120}
        >
          <DiscreteAxis chartConfiguration={chartConfiguration} />
        </Box>
      }
    />
  );
}
export function SegmentedGraph({ chartConfiguration, userDatapoints }) {
  const config = buildSegmentedGraphConfiguration({
    chartConfiguration,
    userDatapoints
  });

  if (!config) return null;

  return (
    <GraphTemplate
      chart={
        <LineGraph
          fullRange={config.fullRange}
          points={config.points}
          tooltips={[config.tooltips]}
          colors={config.colors}
        />
      }
      yAxisWidth={35}
      yAxis={
        <Box position="absolute" width={290} transform="rotate(-90deg)" top={180} left={-120}>
          <SegmentedAxis chartConfiguration={chartConfiguration} />
        </Box>
      }
    />
  );
}

export default function DatapointGraph({
  userDatapoint: {
    datapoint: { id: datapointId },
    chartConfiguration
  }
}) {
  const viewerContext = useContext(ViewerContext);

  const { data } = useQuery(USER_DATAPOINTS_QUERY, {
    variables: {
      datapointId,
      cot: true,
      userId: viewerContext?.userId
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  if (!data) return null;

  let userDatapoints = [];

  try {
    // reduce this array to one datapoint per userDatapoint.dateExtracted (rounded to the nearest day)
    // use the highest userDatapoint.chartConfiguration.normalisedRange.value for each day

    // sort the data by dateExtracted
    const dedupedData = [...data.userDatapoints]
      .sort((a, b) => {
        return new Date(a.dateExtracted).getTime() - new Date(b.dateExtracted).getTime();
      })
      .reduce((acc, userDatapoint) => {
        const { dateExtracted } = userDatapoint;
        const roundedDate = new Date(dateExtracted).setHours(0, 0, 0, 0);

        if (!acc[roundedDate]) {
          acc[roundedDate] = userDatapoint;
        } else {
          try {
            const existingValue = parseFloat(
              acc[roundedDate].chartConfiguration.normalisedRange.value
            );
            const newValue = parseFloat(userDatapoint.chartConfiguration.normalisedRange.value);

            if (newValue > existingValue) {
              acc[roundedDate] = userDatapoint;
            }
          } catch (e) {
            console.warn("Unable to parse value", e);
          }
        }

        return acc;
      }, {});

    // convert the object back into an array
    userDatapoints = Object.values(dedupedData);
  } catch (e) {
    console.warn("Unable to deduplicate datapoints by day", e);
  }

  if (chartConfiguration.chartType === "GRADIENT_CHART") {
    return (
      <GradientGraph chartConfiguration={chartConfiguration} userDatapoints={userDatapoints} />
    );
  } else if (chartConfiguration.chartType === "DISCRETE_CHART") {
    return (
      <DiscreteGraph chartConfiguration={chartConfiguration} userDatapoints={userDatapoints} />
    );
  } else if (chartConfiguration.chartType === "SEGMENTED_CHART") {
    return (
      <SegmentedGraph chartConfiguration={chartConfiguration} userDatapoints={userDatapoints} />
    );
  }

  return null;
}
