import { forwardRef } from "react";

import Text from "components/Text";
import { SelectExpand } from "tpo/Expand";

const ChooseProductSelect = forwardRef(({ value, onChange, label, items }, ref) => (
  <SelectExpand
    top={
      <Text color="white" fontFamily="gilroyBold">
        {items.find(i => i.value === value)?.content || label}
      </Text>
    }
    callback={onChange}
    items={items}
    push={true}
    ref={ref}
    px={20}
    py={25}
  />
));

export default ChooseProductSelect;
