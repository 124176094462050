import { forwardRef } from "react";

import Box from "components/Box";
import Grid from "components/Grid";
import { theme } from "core/theme";

const BaseRadio = forwardRef(({ name, onChange, value, disabled, color, checked }, ref) => (
  <Box position="relative" data-component-name="checkbox">
    <Box
      as="input"
      name={name}
      type="radio"
      value={value}
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      height={40}
      // TODO - this is currently causing a warning in the console
      // if checked is set then onChange is required i think on the input
      // try pointer: none on the overlay and put onChange on here instead
      // of onClick on the overlay
      checked={checked}
    />
    <Box
      display="block"
      borderRadius="50%"
      width={40}
      height={40}
      borderWidth="2px"
      borderStyle="solid"
      borderColor={theme.colors.green}
      zIndex={1}
      backgroundColor="white"
      position="relative"
      top={0}
      onClick={() => onChange(value)}
    />
    <Box
      position="absolute"
      top="5px"
      left="5px"
      width={30}
      height={30}
      backgroundColor={theme.colors.green}
      borderRadius="50%"
      opacity={checked ? 1 : 0}
      style={{ pointerEvents: "none", transition: "opacity 250ms" }}
      zIndex={1}
    />
  </Box>
));

const Radio = forwardRef(({ helpText, labelProps, label, error, ...props }, ref) => (
  <Grid gridTemplateColumns="auto 1fr" gridRowGap={0}>
    <BaseRadio color={error ? theme.colors.red : undefined} {...props} ref={ref} gridRowStart="1" />
    <Box
      fontFamily="gilroyMedium"
      fontSize={14}
      {...labelProps}
      gridColumnStart="2"
      gridRowStart="1"
      alignSelf="center"
    >
      {label}
    </Box>
    <Box
      fontFamily="gilroyMedium"
      fontSize={14}
      color="midGrey"
      gridColumnStart="2"
      gridRowStart="2"
    >
      {helpText}
    </Box>
  </Grid>
));

export default Radio;
