import { useEffect, useRef, useState } from "react";

export default function useTween({ start, end, duration }) {
  const [value, setValue] = useState(start);
  const [isTweening, setIsTweening] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  const tweenRef = useRef();

  useEffect(() => {
    if (isTweening) {
      startTween();
    }
    return () => {
      stopTween();
    };
  }, [start, end, duration]);

  const setProgress = progress => {
    startTimeRef.current = Date.now() - (progress / (end - start)) * duration;
  };

  const updateTween = () => {
    const now = Date.now();
    const time = Math.min(1, (now - startTimeRef.current) / duration);
    const value = start + (end - start) * time;
    setValue(value);
    if (now >= endTimeRef.current) {
      cancelAnimationFrame(tweenRef.current);
      setIsTweening(false);
      setIsComplete(true);
    } else {
      tweenRef.current = requestAnimationFrame(updateTween);
    }
  };

  const startTween = () => {
    startTimeRef.current = Date.now();
    endTimeRef.current = startTimeRef.current + duration;
    setIsTweening(true);
    tweenRef.current = requestAnimationFrame(updateTween);
  };

  const stopTween = () => {
    setIsTweening(false);
    cancelAnimationFrame(tweenRef.current);
  };

  return { progress: value, setProgress, startTween, stopTween, isComplete, isTweening };
}
