import Box from "components/Box";
import useBreakPointBasedPropIndex from "hooks/useBreakPointBasedPropIndex";
import { ReactComponent as Logo } from "images/tpo/omnos-navbar-logo-mobile.svg";
import Spacer from "tpo/Spacer";

function OmnosLogo() {
  const i = useBreakPointBasedPropIndex();
  const logoWidths = [130, 130, 150];
  const logoWidth = logoWidths[i];
  return (
    <Logo
      height={logoWidth}
      width={logoWidth}
      style={{
        minWidth: logoWidth
      }}
    />
  );
}

export default function OmnosHeader() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      px={[30, 30, "5.5vw"]}
      gap={[20, 20, null]}
      bg="purple"
    >
      <Spacer py={[2, 2, 20]} />
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        gap={[20, 20, 60]}
        alignItems="center"
        maxWidth={760}
        mx="auto"
      >
        <OmnosLogo />
        <Box display="flex" flexDirection="column" gap={[20, 20, 30]}>
          <Box color="white" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
            Omnos shop
          </Box>
          <Box color="white" fontFamily="gilroyMedium" fontSize={14}>
            Welcome to the Omnos Shop! Discover our wide range of functional tests designed to guide
            you on your health & wellness journey.
          </Box>
        </Box>
      </Box>
      <Spacer py={[2, 2, 20]} />
    </Box>
  );
}
