import Box from "../components/Box";

function Group({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

Group.defaultProps = {
  display: "flex",
  flexDirection: "row"
};

export default Group;
