import Box from "../components/Box";
import Text from "../components/Text";
import Accordion, { AccordionContext } from "./Accordion";
import ChevronComponent from "./Chevron";

function getAnswerColor(score) {
  if (score === 0) {
    return "green";
  } else if (score === 1) {
    return "orange";
  }
  return "red";
}

export function Research({ research }) {
  return (
    <Accordion.Item value="relatedResearch">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Research
        </Text>
      </Accordion.Control>
      <Accordion.Panel px={[20, 20, 40]} pb={[2, 2, 20]} position="relative">
        {research}
      </Accordion.Panel>
    </Accordion.Item>
  );
}

export function SymptomsList({ questions }) {
  return questions.map(question => (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      gap={[10, 10, 0]}
      key={`${question.id}`}
      py={[2, 2, 20]}
    >
      <Box textAlign="left" width={[null, null, 0.5]} fontSize={[14, 14, 16]}>
        {question.question.text}
      </Box>
      <Box textAlign={["left", "left", "right"]} width={[null, null, 0.5]}>
        <Text
          color={!!question.answerText && getAnswerColor(question.answerScore)}
          fontFamily="gilroyBold"
          textAlign={["left", "left", "right"]}
          fontSize={[14, 14, 16]}
        >
          {question.answerText || "N/A"}
        </Text>
      </Box>
    </Box>
  ));
}

export function Symptoms({ experienced, notExperienced }) {
  return (
    <Accordion.Item value="symptoms">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Symptoms
        </Text>
      </Accordion.Control>
      <Accordion.Panel p={0} position="relative">
        <Accordion variant="default" chevronVariant="default" multiple size={15}>
          <Accordion.Item value="experienced">
            <Accordion.Control
              p={15}
              pr={[30, 30, 35]}
              pl={[15, 15, 30]}
              chevronIcon={
                <AccordionContext.Consumer>
                  {({ openState }) => (
                    <ChevronComponent
                      fill="dark"
                      height={10}
                      width={10}
                      direction={openState.has("experienced") ? "bottom" : "top"}
                      style={{
                        margin: 0
                      }}
                    />
                  )}
                </AccordionContext.Consumer>
              }
            >
              <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
                Experienced
              </Text>
            </Accordion.Control>
            <Accordion.Panel p={0} pb={[2, 2, 20]} px={[20, 20, 40]}>
              {experienced}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="notExperienced">
            <Accordion.Control
              p={15}
              pr={[30, 30, 35]}
              pl={[15, 15, 30]}
              chevronIcon={
                <AccordionContext.Consumer>
                  {({ openState }) => (
                    <ChevronComponent
                      fill="dark"
                      height={10}
                      width={10}
                      direction={openState.has("notExperienced") ? "bottom" : "top"}
                      style={{
                        margin: 0
                      }}
                    />
                  )}
                </AccordionContext.Consumer>
              }
            >
              <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
                Not experienced
              </Text>
            </Accordion.Control>
            <Accordion.Panel p={0} pb={[2, 2, 20]} px={[20, 20, 40]} position="relative">
              {notExperienced}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
