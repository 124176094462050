import React from "react";

import { useHistory } from "react-router-dom";

import { getQuestionnaireIntroUrl } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import BasicPage from "./BasicPage";
import { SolidButton } from "./Buttons";
import Text from "./Text";

function PlatformUserProfileCompletePage() {
  useDocTitle("Profile complete");
  const history = useHistory();

  return (
    <BasicPage heading="Profile complete">
      <Text pt={40}>You can now take your first questionnaire</Text>
      <SolidButton
        mt={80}
        width="100%"
        maxWidth={300}
        mx="auto"
        handleClick={() => {
          history.push(getQuestionnaireIntroUrl("initial"));
        }}
        data-component-name="Initial questionnaire button"
      >
        Initial questionnaire
      </SolidButton>
    </BasicPage>
  );
}

export default PlatformUserProfileCompletePage;
