import { createContext, useEffect, useMemo, useRef, useState } from "react";

export const InfiniteScrollContext = createContext();

export default function InfiniteScroll({
  children,
  next,
  hasMore,
  loader,
  loading,
  endMessage,
  items
}) {
  const [bottomElement, setBottomElement] = useState();

  const nextRef = useRef();
  nextRef.current = next;

  useEffect(() => {
    const currentObserverTarget = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore) {
          if (nextRef.current) {
            nextRef.current();
          }
        }
      },
      { threshold: 0, root: null, rootMargin: "1000px" }
      // threshold 0 means: when it is only partly visible, not wholly
    );

    if (bottomElement) {
      currentObserverTarget.observe(bottomElement);
    }

    return () => {
      if (bottomElement) {
        currentObserverTarget.unobserve(bottomElement);
      }
    };
  }, [bottomElement, hasMore]);

  const infiniteScrollApi = useMemo(
    () => ({
      setBottomElement,
      itemsList: items || []
    }),
    [setBottomElement, items]
  );

  return (
    <InfiniteScrollContext.Provider value={infiniteScrollApi}>
      {children}
      {loading && loader}
      {hasMore && endMessage}
    </InfiniteScrollContext.Provider>
  );
}
