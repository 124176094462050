import ExpertForm from "./Form";
import UnapprovedPanel from "./UnapprovedPanel";

export default function ExpertPanel({ partnerUserProfile }) {
  return partnerUserProfile.isApprovedToBeExpert ? (
    <ExpertForm partnerUserProfile={partnerUserProfile} />
  ) : (
    <UnapprovedPanel />
  );
}
