import Box from "components/Box";
import { InternalTextLink } from "components/Links";
import Asterisk from "tpo/Asterisk";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function YourDetailsSection() {
  return (
    <PanelBoxV2
      maxWidth={1532}
      outer={{
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        px: [20],
        bg: "haze",
        "data-component-name": "YourDetailsSection"
      }}
    >
      <PanelBoxV2 maxWidth={1020}>
        <PanelBoxV2
          maxWidth={760}
          outer={{
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            bg: "white",
            borderRadius: 5,
            px: 20
          }}
          stacked
          gap={[20, 20, 40]}
        >
          <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
            Your details
            <Asterisk />
          </Box>
          <Stack gap={20}>
            <ControlledFormField
              name="yourDetailsFirstName"
              label="First name"
              Component={FloatingLabelInput}
            />
            <ControlledFormField
              name="yourDetailsLastName"
              label="Last name"
              Component={FloatingLabelInput}
            />
            <ControlledFormField
              name="yourDetailsJobRole"
              label="Role"
              Component={FloatingLabelInput}
            />
            <ControlledFormField
              name="yourDetailsPhoneNumber"
              label="Contact Number"
              Component={FloatingLabelInput}
            />
          </Stack>
          <Stack gap={20} flexDirection={["column", "row", "row"]} alignItems="flex-start">
            <ButtonV2
              as={InternalTextLink}
              underline={false}
              color="dark"
              rightIcon={<ChevronComponent />}
              href="/settings"
            >
              edit personal profile
            </ButtonV2>
          </Stack>
        </PanelBoxV2>
      </PanelBoxV2>
    </PanelBoxV2>
  );
}
