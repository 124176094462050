import { createContext, useEffect, useMemo, useState } from "react";

import { v4 } from "uuid";

export const body = document.getElementsByTagName("body")[0];

export const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [modalIdShowing, setModalIdShowing] = useState(undefined);

  useEffect(() => {
    if (modalIdShowing !== undefined) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "unset";
    }
    return () => {
      body.style.overflow = "unset";
    };
  }, [modalIdShowing]);

  const api = useMemo(
    () => ({
      show: id => {
        setModalIdShowing(id);
      },
      getId: () => v4(),
      close: id => {
        if (modalIdShowing === id) {
          setModalIdShowing(undefined);
        }
      }
    }),
    [setModalIdShowing, modalIdShowing]
  );

  return <ModalContext.Provider value={api}>{children}</ModalContext.Provider>;
}
