import styled from "styled-components";

import Box from "../components/Box";
import { theme } from "../core/theme";

const Square = styled(Box)`
  ${props => {
    if (props.withBorder) {
      return `
        border-color: ${theme.colors.dark};
        border-style: solid;
        border-width: 1px;
    `;
    }
  }}
`;

export default Square;
