import React from "react";

import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import Text from "./Text";

function RequestPasswordResetDonePage() {
  useDocTitle("Request password reset done");
  return (
    <AccountPage heading="Password reset link sent">
      <Text pt={40}>A password reset link has been sent to the email address you provided.</Text>
      <Text pt={40}>Please check your inbox.</Text>
    </AccountPage>
  );
}

export default RequestPasswordResetDonePage;
