import Circle from "components/Circle";
import { useHeaderContext } from "contexts/HeaderContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PARTNERS_ORGANISATIONS_URL } from "core/urls";
import usePartnerPageCheck from "hooks/partner/usePartnerPageCheck";
import LinkWrapper from "tpo/LinkWrapper";
import Menu from "tpo/Menu";

export default function LoggedInMenuOrganisations() {
  const { availableOrganisations, organisationId, setOrganisationId } = useOrganisationContext();
  const isPartnerPage = usePartnerPageCheck();
  const { onClick } = useHeaderContext();
  return (
    <>
      {availableOrganisations?.map(organisation => (
        <Menu.Item
          key={`organisation-${organisation.id}`}
          selectedColor="rgba(255, 255, 255, 0.10)"
          color="white"
          fontFamily="gilroyBold"
          fontSize={14}
          py={16}
          px={30}
          as={LinkWrapper}
          to={isPartnerPage ? "#" : PARTNERS_ORGANISATIONS_URL}
          display="block"
          underline={false}
          position="relative"
          onClick={evt => {
            setOrganisationId(organisation.id);
            onClick(evt);
          }}
        >
          {organisationId === organisation.id && isPartnerPage && (
            <Circle
              backgroundColor="green"
              position="absolute"
              top="50%"
              size={5}
              transform="translate(-50%, -50%)"
              left="15px"
            />
          )}
          {organisation.name}
        </Menu.Item>
      ))}
    </>
  );
}
