import Box from "../components/Box";
import Loading from "../components/Loading";
import BasePage from "../components/Page";
import { HeadingExtraSmall } from "./Headings";

function Page({ children, Header, header = "Questionnaire", Main, Footer }) {
  return (
    <BasePage bg="blue" includeFooter={false}>
      {Header || (
        <Box my={[20, 20, 50, 50]} mb={[50, 50, 50, 50]}>
          <HeadingExtraSmall color="white" fontSize={[24, 38]} textAlign="center">
            {header}
          </HeadingExtraSmall>
        </Box>
      )}
      {Main}
      {Footer}
      {children}
    </BasePage>
  );
}

export function LoadingPage() {
  return (
    <Page>
      <Loading color="white" />
    </Page>
  );
}

export default Page;
