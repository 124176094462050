import gql from "graphql-tag";
import { PAGE_INFO_FIELDS } from "graphql/pagination";

export const ORGANISATION_COMMISSIONS_LIST_QUERY = gql`
  query OrganisationCommissionsListQuery(
    $organisation: Int!
    $page: Int
    $orderBy: String
    $search: String
  ) {
    organisationCommissionsPagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
    ) {
      items {
        id
        checkoutDate
        user {
          id
          firstName
          lastName
          email
        }
        sourceBasket {
          id
          payee
          paymentStatus
          currency {
            id
            isoCode
          }
          basketTotalCommission
        }
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_ACCOUNT_BALANCE_QUERY = gql`
  query OrganisationAccountBalanceQuery($organisation: Int!) {
    connectedAccountBalances(organisation: $organisation) {
      id
      currency
      pending
      available
    }
  }
`;
