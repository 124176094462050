import { useEffect, useState } from "react";

import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import SortMenu from "tpo/SortMenu";

// import { AddBankAccount } from "tpo/Notifications";
import OrganisationTabs from "../OrganisationTabs";
import { CMP_PERMISSION_ADMIN } from "../constants";
import AddTeamMemberForm from "./forms/AddTeamMemberForm";
import PatientNotificationSettingsForm from "./forms/PatientNotificationSettingsForm";
import UpdateOrganisationDetailsForm from "./forms/UpdateOrganisationDetailsForm";
import StripeConnectDetailsPanel from "./ui/StripeConnectDetailsPanel";
import StripeConnectSetupPanel from "./ui/StripeConnectSetupPanel";
import TeamMembersList from "./ui/TeamMembersList";

export default function OrganisationProfile() {
  const {
    availableOrganisations,
    organisation,
    setOrganisationId,
    permissions
  } = useOrganisationContext();
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [showOrgMenu, setShowOrgMenu] = useState(false);
  const [membersRefetch, setMembersRefetch] = useState(0);

  const canEdit = permissions.includes(CMP_PERMISSION_ADMIN);

  useEffect(() => {
    if (!organisation) return;

    setOnboardingComplete(organisation?.onboardingStatus === "complete");
  }, [organisation]);

  return (
    <>
      <OrganisationTabs selectedTab="business" />
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [60, 60, 80],
          pb: 60,
          px: [20, 20, "5.5vw"],
          bg: "haze"
        }}
        stacked
        gap={40}
      >
        <Center>
          <SortMenu
            value={organisation?.id || ""}
            setValue={v => setOrganisationId(v)}
            open={showOrgMenu}
            setOpen={setShowOrgMenu}
            options={availableOrganisations.map(({ id: value, name: label }) => ({
              label: label,
              value
            }))}
            styles={{
              button: {
                color: "dark",
                "data-component-name": "OrganisationMenuButton"
              },
              dropdown: {
                bg: "dark",
                "data-component-name": "OrganisationMenuDropdown"
              },
              item: {
                color: "white",
                selectedColor: "rgba(255, 255, 255, 0.10)"
              }
            }}
            placeholder="Organisation"
          />
        </Center>
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "white",
            px: [20, 20, 40],
            pt: [20, 20, 40],
            pb: [50, 50, 80],
            borderRadius: 5
          }}
          stacked
          gap={40}
        >
          <UpdateOrganisationDetailsForm canEdit={canEdit} />
        </PanelBoxV2>
        {canEdit && (
          <>
            <PanelBoxV2
              maxWidth={760}
              outer={{
                bg: "white",
                px: [20, 20, 40],
                pt: [20, 20, 40],
                pb: [50, 50, 80],
                borderRadius: 5
              }}
              stacked
              gap={40}
            >
              {onboardingComplete && <StripeConnectDetailsPanel />}
              {!onboardingComplete && <StripeConnectSetupPanel />}
            </PanelBoxV2>
            <PanelBoxV2
              maxWidth={760}
              outer={{
                bg: "white",
                px: [20, 20, 40],
                pt: [20, 20, 40],
                pb: [50, 50, 80],
                borderRadius: 5
              }}
              stacked
              gap={40}
            >
              <PatientNotificationSettingsForm />
            </PanelBoxV2>
            {organisation.teamMembersEnabled && (
              <>
                <PanelBoxV2
                  maxWidth={760}
                  outer={{
                    bg: "white",
                    px: [20, 20, 40],
                    pt: [20, 20, 40],
                    pb: [50, 50, 80],
                    borderRadius: 5
                  }}
                  stacked
                  gap={0}
                >
                  <TeamMembersList refetch={membersRefetch} />
                </PanelBoxV2>
                <PanelBoxV2
                  maxWidth={760}
                  outer={{
                    bg: "white",
                    px: [20, 20, 40],
                    pt: [20, 20, 40],
                    pb: [50, 50, 80],
                    borderRadius: 5
                  }}
                  stacked
                  gap={40}
                >
                  <AddTeamMemberForm onSuccess={() => setMembersRefetch(Math.random())} />
                </PanelBoxV2>
              </>
            )}
          </>
        )}
      </PanelBoxV2>
    </>
  );
}
