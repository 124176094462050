import gql from "graphql-tag";

export const CREATE_USER_SUBMISSION_MUTATION = gql`
  mutation CreateUserSubmissionMutation($input: CreateUserSubmissionMutationInput!) {
    createUserSubmissionMutation(input: $input) {
      submissionId
      errors {
        field
        messages
      }
    }
  }
`;

export const COMPLETE_USER_SUBMISSION_MUTATION = gql`
  mutation CompleteUserSubmissionMutation($input: CompleteUserSubmissionMutationInput!) {
    completeUserSubmissionMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const SUBMIT_USER_ANSWER_MUTATION = gql`
  mutation SubmitUserAnswerMutation($input: SubmitUserAnswerMutationInput!) {
    submitUserAnswerMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
