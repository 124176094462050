import gql from "graphql-tag";
import { PAGE_INFO_FIELDS } from "graphql/pagination";
import { ORDER_FIELDS, ORDER_TEST_ITEM_FIELDS } from "graphql/shop";

export const ORGANISATION_ORDERS_LIST_QUERY = gql`
  query OrganisationOrdersListQuery(
    $organisation: Int!
    $page: Int
    $orderBy: String
    $search: String
    $filter: String
  ) {
    organisationOrdersPagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
      filter: $filter
    ) {
      items {
        id
        checkoutDate
        user {
          id
          firstName
          lastName
          email
        }
        testItemSet {
          id
          stock {
            id
            paymentInvoice {
              id
              stripeId
              paymentStatus
              url
              invoiceUrl
              dateDue
            }
            paymentCheckoutSession {
              id
              stripeId
              paymentStatus
              url
              invoiceUrl
            }
          }
        }
        sourceBasket {
          id
          payee
          paymentStatus
          orderStatus
          basketTotalPrice
          currency {
            id
            isoCode
          }
          paymentInvoice {
            id
            stripeId
            paymentStatus
            url
            invoiceUrl
            dateDue
          }
          paymentCheckoutSession {
            id
            stripeId
            paymentStatus
            url
            invoiceUrl
          }
        }
        status
        discountedTotal
        postageCosts
        suppliedFromPracticeStock
        isStockOrder
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_ORDER_DETAIL_QUERY = gql`
  query OrganisationOrderDetailQuery($organisation: Int!, $id: String!) {
    organisationOrder(organisation: $organisation, id: $id) {
      id
      checkoutDate
      user {
        id
        firstName
        lastName
        email
      }
      status
      sourceBasket {
        id
        refunded
        organisation {
          id
          name
        }
        shippingAddress {
          id
          name
          line1
          line2
          city
          postcode
          country {
            id
            name
          }
        }
        practitioner {
          id
          user {
            firstName
            lastName
            email
          }
        }
        currency {
          id
          isoCode
        }
        paymentInvoice {
          id
          stripeId
          paymentStatus
          url
          invoiceUrl
        }
        paymentCheckoutSession {
          id
          stripeId
          paymentStatus
          url
          invoiceUrl
        }
        payee
        basketRrpTotalPrice
        basketTotalPrice
        basketTotalCommission
        basketShippingCost
        paymentStatus
        orderStatus
        productLineItems {
          id
          product {
            id
            name
          }
          productOption {
            id
            testProductOptionFamily {
              id
              name
            }
          }
          clinicLocation {
            id
            address
            clinic {
              name
            }
          }
          quantity
          pricePerUnit
          fullPricePerUnit
          rrpPerUnit
          clinicFeesPerUnit
        }
      }
      ...OrderFields
      testItems {
        ...OrderTestItemFields
      }
      supplementItems {
        id
        nameInBasket
        groupBy
        price
        product {
          id
          compositeId
          name
          productType
          price
          slug
        }
      }
    }
  }
  ${ORDER_FIELDS}
  ${ORDER_TEST_ITEM_FIELDS}
`;
