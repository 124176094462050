import { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { getPartnerDashboardOrderUrl } from "core/urls";
import RefetchEvent from "events/refetch";
import { CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION } from "graphql/organisations/mutations";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import { ORGANISATION_ORDERS_LIST_QUERY } from "graphql/organisations/queries/orders";
import { ORGANISATION_BASKET_DETAIL_QUERY } from "graphql/organisations/queries/organisation";
import { ORGANISATION_STOCK_LIST_QUERY } from "graphql/organisations/queries/stock";

import AcknowledgeAndSubmitPanel from "../ui/AcknowledgeAndSubmitPanel";

export default function PayOrderLaterForm({ onSubmit, canSubmit }) {
  const history = useHistory();

  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  const [createPatientOrderFromBasketMutation, { data, loading, error }] = useMutation(
    CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION,
    {
      variables: {
        input: {
          id: basket?.id,
          organisation: organisation?.id
        }
      },
      refetchQueries: [
        {
          query: ORGANISATION_BASKET_DETAIL_QUERY,
          variables: {
            organisation: parseInt(organisation?.id)
          }
        }
      ]
    }
  );

  useEffect(() => {
    if (data?.createPatientOrderFromBasketMutation?.order?.id) {
      onSubmit && onSubmit();
      RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
      history.push(getPartnerDashboardOrderUrl(data.createPatientOrderFromBasketMutation.order.id));
    }
  });

  return (
    <AcknowledgeAndSubmitPanel
      title="Payment Details"
      description="By placing this order, a payment link will be created that can be sent to your patient. They will be able to pay using this link at a later date. The order will not be processed until payment has been received."
      label="I acknowledge that this order will be created and I will receive a payment link to be sent to my patient."
      onSubmit={() => {
        createPatientOrderFromBasketMutation();
      }}
      canSubmit={canSubmit}
    />
  );
}
