import { useCallback } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Loading from "components/Loading";
import Page from "components/Page";
import OrganisationTabs from "components/organisations/OrganisationTabs";
import {
  TestProductModalContext,
  TestProductModalProvider,
  useTestProductModalContext
} from "contexts/TestProductModalContext";
import { TestProductOptionProvider } from "contexts/TestProductOptionContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ERROR_404_URL } from "core/urls";
import {
  PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
  PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY,
  PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS,
  SHOP_LISTING_FILTERS_QUERY
} from "graphql/shop";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import { PanelBoxV2 } from "tpo/Boxes";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";
import TestProductListCard from "tpo/partnerDashboard/practitioner/TestProductListCard";
import ListPageTemplate from "tpo/shop/ListPageTemplate";
import Pills from "tpo/shop/Pills";
import { SupplementListContent } from "tpo/shop/SupplementListPage";
import { getCarouselImages } from "tpo/shop/TestProductDetailPage";
import useTestProducts from "tpo/shop/hooks/useTestProducts";

import TestProductModal from "../../../tpo/partnerDashboard/practitioner/TestProductModal";

function TestProductListContent({ sampleTypes, categories, testingServices }) {
  const history = useHistory();
  const location = useLocation();

  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Recommended",
        value: "sort_order"
      },
      {
        label: "A - Z",
        value: "name"
      },
      {
        label: "Z - A",
        value: "-name"
      },
      {
        label: "Price Asc",
        value: "trade_current_price_annotation"
      },
      {
        label: "Price Desc",
        value: "-trade_current_price_annotation"
      }
    ]
  });

  const {
    productFilters: { menu, selectedFilters },
    products: { items, hasMore, endCursor },
    loading,
    fetchMore
  } = useTestProducts({
    query: PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
    connectionsFieldName: "practitionerTestProductConnections",
    testingServices,
    categories,
    sampleTypes,
    search: debouncedSearch,
    sort: controls.sort,
    productFiltersDrawerBg: "dark"
  });

  const { selectTestProductId } = useTestProductModalContext();
  const { addProductToBasket } = useOrganisationBasketContext();
  const { setBasketOpen } = useAppState();

  const handleAddToBasket = useCallback(
    testProduct => {
      if (testProduct.options?.length) {
        selectTestProductId(testProduct.id);
        return;
      }
      addProductToBasket(testProduct.id);
      selectTestProductId(null);
      setBasketOpen(true);
    },
    [addProductToBasket, selectTestProductId, setBasketOpen]
  );

  return (
    <ListPageTemplate
      // TODO - use the standard url approach involving core.urls
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests"
      }}
      bg="haze"
      tab="tests"
      title="Find a matching test"
      subtitle={`Search or filter our comprehensive list of functional tests to find the most appropriate test for your patient. `}
    >
      <PanelBoxV2
        maxWidth={1220}
        outer={{
          bg: "haze",
          pb: [30, 30, 100],
          px: [20, 20, "5.5vw"]
        }}
      >
        <Stack gap={20}>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            gap={20}
            justifyContent={[null, null, "space-between"]}
            flexWrap="wrap"
          >
            <Group gap={10}>{menu}</Group>
            <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
              <Search
                value={search}
                onChange={s => {
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("search", s);
                  history.push({
                    path: location.path,
                    search: searchParams.toString()
                  });
                }}
                maxWidth={[null, null, 350]}
                minWidth={310}
                width="100%"
              />
              <Box display="flex" justifyContent="flex-end">
                <SortMenu
                  value={controls.sort}
                  setValue={controls.setSort}
                  open={controls.sortMenuOpen}
                  setOpen={controls.setSortMenuOpen}
                  options={controls.sortOptions}
                  placeholder="Sort By"
                />
              </Box>
            </Box>
          </Box>
          {selectedFilters}
        </Stack>
        <Spacer py={[2, 2, 20]} />
        <InfiniteScroll
          loader={<Loading />}
          hasMore={hasMore}
          loading={loading}
          next={() => {
            if (endCursor) {
              fetchMore({
                variables: {
                  after: endCursor,
                  first: 10
                }
              });
            }
          }}
          items={items}
        >
          <Stack>
            <InfiniteScrollContext.Consumer>
              {({ itemsList, setBottomElement }) => (
                <>
                  {itemsList.map((testProduct, idx) => (
                    <TestProductListCard
                      status={testProduct.status}
                      title={testProduct.name}
                      subtitle={
                        <Box color="midGrey" fontFamily="gilroyMedium" fontSize={14}>
                          {testProduct.laboratory}
                        </Box>
                      }
                      addText={testProduct.options?.length ? "Select option" : "Add"}
                      pills={
                        <>
                          {testProduct.content.testType && (
                            <Pills.Pill color="purple">{testProduct.content.testType}</Pills.Pill>
                          )}
                          {testProduct.content.categories
                            .map(cat => cat.name)
                            .map(c => (
                              <Pills.Pill
                                key={c}
                                color="haze"
                                sx={{
                                  color: "dark"
                                }}
                              >
                                {c}
                              </Pills.Pill>
                            ))}
                        </>
                      }
                      tradeCurrentPrice={testProduct.tradeCurrentPrice}
                      tradeFullPrice={testProduct.tradeFullPrice}
                      retailFullPrice={testProduct.retailFullPriceForPractitionerShop}
                      key={testProduct.id}
                      onAdd={e => {
                        e.onAdd = true;
                        handleAddToBasket(testProduct);
                      }}
                      onSelect={e => {
                        if (e.onAdd) return;
                        selectTestProductId(testProduct.id);
                      }}
                      ref={idx === itemsList.length - 1 ? setBottomElement : null}
                    />
                  ))}
                </>
              )}
            </InfiniteScrollContext.Consumer>
          </Stack>
        </InfiniteScroll>
      </PanelBoxV2>
      <TestProductModalContext.Consumer>
        {({ testProduct }) => {
          let bg;
          if (testProduct) {
            const carouselImages = getCarouselImages(testProduct);
            bg = carouselImages.length ? "white" : "haze";
          }
          return <TestProductModal bg={bg} />;
        }}
      </TestProductModalContext.Consumer>
    </ListPageTemplate>
  );
}

function TestProductListPage() {
  return (
    <>
      <OrganisationTabs selectedTab="shop" />
      <DataLoader
        query={SHOP_LISTING_FILTERS_QUERY}
        variables={{
          includeFilters: ["Both", "B2B"]
        }}
        render={({ sampleTypes, categories, testingServices, userTestRecommendations }) => {
          return (
            <TestProductOptionProvider
              testProductOptionFragment={PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS}
              testProductOptionFragmentName="PractitionerTestProductOptionFields"
              testProductOptionFragmentType="TestProductOptionType"
            >
              <TestProductModalProvider
                testProductQuery={PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY}
                testProductFieldName="practitionerTestProduct"
                testProductOptionFragment={PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS}
                testProductOptionFragmentName="practitionerTestProductOption"
                testProductOptionFragmentType="PractitionerTestProductOptionType"
              >
                <TestProductListContent
                  sampleTypes={sampleTypes}
                  categories={categories.map(cat => cat.name)}
                  testingServices={testingServices}
                  userTestRecommendations={userTestRecommendations}
                />
              </TestProductModalProvider>
            </TestProductOptionProvider>
          );
        }}
      />
    </>
  );
}

function SupplementListPage() {
  const { organisation } = useOrganisationContext();
  const { basket, addSupplementToBasket } = useOrganisationBasketContext();
  const { setBasketOpen } = useAppState();

  const { data, error, loading } = useQuery(SHOP_LISTING_FILTERS_QUERY);

  const onAddToBasket = useCallback(
    ({ supplement }) => {
      addSupplementToBasket(supplement.id);
      setBasketOpen(true);
    },
    [addSupplementToBasket, basket]
  );

  if (loading) {
    return <Loading />;
  }
  return (
    <SupplementListContent
      bg="haze"
      brands={data?.brands}
      dietryRestrictions={data?.dietryRestrictions}
      types={data?.types}
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests"
      }}
      header={<OrganisationTabs selectedTab="shop" />}
      title="Find a matching supplement"
      subtitle="Use the sample type or testing service below to find the test type that matches your sample. Alternatively choose a client to see their recommended tests."
      onAddToBasket={onAddToBasket}
      productFiltersDrawerBg="dark"
    />
  );
}

export default function OrganisationShop() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={`${match.path}/tests`} />
      </Route>
      <Route path={`${match.path}/supplements`} exact>
        <SupplementListPage />
      </Route>
      <Route path={`${match.path}/tests`} exact>
        <TestProductListPage />
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
