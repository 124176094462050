import { useContext } from "react";

import Box from "components/Box";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import { ModalInstanceContext } from "tpo/Modal";
import Stack from "tpo/Stack";
import BiomarkersAndSymptomsList from "tpo/shop/BiomarkersAndSymptomsList";
import Carousel from "tpo/shop/Carousel";
import MainDetails from "tpo/shop/MainDetails";
import SampleReports from "tpo/shop/SampleReports";
import TestProductInformationPanels from "tpo/shop/TestProductInformationPanels";

export default function TruncatedTestProductDetailContent({
  practitionerSupportRequired,
  name,
  description,
  categories,
  sampleTypes,
  numOfBiomarkersTested,
  processingTime,
  options,
  tradeCurrentPrice,
  tradeFullPrice,
  retailFullPrice,
  relatedSymptoms,
  biomarkersTested,
  informationPanels,
  onProductOptionSelection,
  testType,
  dimensions = {},
  onAddToBasket,
  status,
  carouselImages,
  sampleReports
}) {
  const modalInstanceContext = useContext(ModalInstanceContext);
  const withInModal = !!modalInstanceContext;

  return (
    <>
      <Box display="none" data-component-name="TruncatedProductDetail" />
      {!!carouselImages.length && (
        <PanelBoxV2
          maxWidth={1020}
          outer={{
            bg: "white",
            pt: withInModal ? [50, 50, 0] : [50, 50, 80],
            pb: [60],
            px: [20, 20, "5.5vw"]
          }}
        >
          <Box bg="haze" p={20}>
            <Carousel images={carouselImages} bg="haze" />
          </Box>
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "haze",
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, "5.5vw"]
        }}
      >
        <Box>
          <MainDetails
            status={status}
            practitionerSupportRequired={practitionerSupportRequired}
            name={name}
            description={description}
            categories={categories}
            sampleTypes={sampleTypes}
            numOfBiomarkersTested={numOfBiomarkersTested}
            processingTime={processingTime}
            options={options}
            priceElement={
              <Stack>
                <Group gap={10}>
                  {tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice && (
                    <Currency
                      fontSize={[24, 44]}
                      value={tradeFullPrice}
                      decimalFontSize={[16, 28]}
                      color="dark"
                      strikethrough
                      data-testid="truncatedProductDetail:tradeFullPrice"
                    />
                  )}
                  <Currency
                    fontSize={[24, 44]}
                    value={tradeCurrentPrice}
                    decimalFontSize={[16, 28]}
                    data-testid="truncatedProductDetail:tradeCurrentPrice"
                    color={
                      tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice
                        ? "red"
                        : "dark"
                    }
                  />
                </Group>
                <Currency
                  prefix="RRP"
                  color="midGrey"
                  fontSize={18}
                  value={retailFullPrice}
                  data-testid="truncatedProductDetail:retailFullPrice"
                />
              </Stack>
            }
            onProductOptionSelection={onProductOptionSelection}
            productOptionCardStackBg="white"
            testType={testType}
            onAddToBasket={onAddToBasket}
          />
        </Box>
      </PanelBoxV2>
      {!!(relatedSymptoms?.length || biomarkersTested?.length) && (
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "partners",
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            px: [20, 20, "5.5vw"]
          }}
        >
          <BiomarkersAndSymptomsList
            relatedSymptoms={relatedSymptoms}
            biomarkersTested={biomarkersTested}
          />
        </PanelBoxV2>
      )}
      {!!sampleReports?.length && (
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "white",
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            px: [20, 20, "5.5vw"]
          }}
        >
          <SampleReports sampleReports={sampleReports} />
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "haze",
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, "5.5vw"]
        }}
        stacked
        gap={40}
      >
        <TestProductInformationPanels panels={informationPanels} />
      </PanelBoxV2>
    </>
  );
}
