import { FormProvider, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { useAuthContext } from "components/AuthProvider";
import Box from "components/Box";
import { InternalTextLink } from "components/Links";
import Page from "components/Page";
import { REQUEST_PASSWORD_RESET_URL, SIGNUP_DONE_URL } from "core/urls";
import { LOG_IN_MUTATION, RESEND_VERIFICATION_EMAIL } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import ButtonV2 from "v2/Buttons";

import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ChevronComponent from "./Chevron";
import ControlledFormField from "./ControlledFormField";
import FloatingLabelInput from "./FloatingLabelInput";
import Jumbotron from "./Jumbotron";
import FloatingLabelPasswordInput from "./PasswordInput";
import Stack from "./Stack";

const EMAIL_NOT_VERIFIED_ERROR_MESSAGE =
  "We notice your account is not yet fully activated. Please complete email verification step.";

function FormButton({ nonFieldError }) {
  const { getValues } = useFormContext();
  const history = useHistory();
  const [resendVerificationEmailMutation] = useMutation(RESEND_VERIFICATION_EMAIL);

  if (nonFieldError !== EMAIL_NOT_VERIFIED_ERROR_MESSAGE) {
    return (
      <ButtonV2 color="green" rightIcon={<ChevronComponent />} type="submit">
        submit
      </ButtonV2>
    );
  }

  return (
    <ButtonV2
      color="green"
      onClick={() => {
        resendVerificationEmailMutation({
          variables: {
            input: {
              email: getValues("email")
            }
          }
        })
          .then(response => {
            if (
              response?.data?.resendVerificationEmailMutation &&
              !response?.data?.resendVerificationEmailMutation?.errors?.length
            ) {
              return history.push(SIGNUP_DONE_URL);
            }
          })
          .catch(error => {
            console.log(
              "Error encountered performing the resend verification email mutation",
              error
            );
          });
      }}
      rightIcon={<ChevronComponent />}
      type="button"
    >
      resend verification email
    </ButtonV2>
  );
}

function NonFieldError({ error }) {
  if (!error) return null;

  return (
    <Box color="red" py={20}>
      {error}
    </Box>
  );
}

function SignInForm({ bottomLinks }) {
  const { setCsrfToken, setUser } = useAuthContext();

  const { methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: LOG_IN_MUTATION,
    mutationName: "logInMutation",
    defaultValues: {
      email: "",
      password: ""
    },
    handleSuccess: ({ data }) => {
      setCsrfToken(data.logInMutation.csrfToken);
      setUser(data.logInMutation.user);
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PanelBoxV2
          maxWidth={600}
          outer={{
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            px: 20
          }}
          stacked
          gap={40}
        >
          <Stack gap={20}>
            <NonFieldError error={nonFieldError} />
            <ControlledFormField
              name="email"
              label="Email address"
              Component={FloatingLabelInput}
            />
            <ControlledFormField
              name="password"
              label="Password"
              Component={FloatingLabelPasswordInput}
            />
          </Stack>
          <Center gap={20} stacked>
            <FormButton nonFieldError={nonFieldError} />
          </Center>
          <Center gap={20} stacked>
            {bottomLinks}
            <InternalTextLink href={REQUEST_PASSWORD_RESET_URL}>
              Forgotten your password?
            </InternalTextLink>
          </Center>
        </PanelBoxV2>
      </form>
    </FormProvider>
  );
}

export default function SignIn({ bg, color, title, bottomLinks }) {
  useDocTitle("Sign in");
  return (
    <Page>
      <Jumbotron bg={bg} color={color} title={title} />
      <SignInForm bottomLinks={bottomLinks} />
    </Page>
  );
}

SignIn.defaultProps = {
  title: "Log In"
};
