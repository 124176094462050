import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { ERROR_404_URL } from "core/urls";

import OrganisationListOrders from "./OrganisationListOrders";
import { OrganisationStock } from "./OrganisationStock";

export default function Orders() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Redirect from={`${match.path}`} to={`${match.path}/direct`} exact />
      <Route path={`${match.path}/direct`} exact>
        <OrganisationListOrders />
      </Route>
      <Route path={`${match.path}/stock`} exact>
        <OrganisationStock />
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
