import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { gql } from "@apollo/client";
import DataLoader from "components/DataLoader";
import Page from "components/Page";
import { PLATFORM_PROFILE_DIET_PREFERENCES_URL, PLATFORM_PROFILE_FORM_URL } from "core/urls";
import { GOALS_MUTATION } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import ButtonV2 from "v2/Buttons";

import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ChevronComponent from "./Chevron";
import ControlledCheckboxGroup from "./ControlledCheckboxGroup";
import Jumbotron from "./Jumbotron";
import Stack from "./Stack";

const GOALS_PAGE_QUERY = gql`
  query GoalsPageQuery {
    goals {
      id
      text
    }
    platformUserProfile {
      id
      pk
      isComplete
      goals {
        id
      }
    }
  }
`;

function GoalsForm({ goals, platformUserProfile }) {
  const history = useHistory();

  const { methods, onSubmit } = useDjangoGraphqlForm({
    mutation: GOALS_MUTATION,
    mutationName: "goalsMutation",
    defaultValues: {
      // NB it will use the logged in user to get the right platform user profile
      // so no need for the id here
      goals: platformUserProfile.goals.map(goal => goal.id)
    },
    handleSuccess: () => {
      history.push(PLATFORM_PROFILE_DIET_PREFERENCES_URL);
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PanelBoxV2
          maxWidth={600}
          outer={{
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            px: 20
          }}
          stacked
          gap={40}
        >
          <ControlledCheckboxGroup
            Container={Stack}
            containerProps={{
              gap: 20
            }}
            name="goals"
            values={
              goals?.map(goal => ({
                label: goal.text,
                value: goal.id
              })) || []
            }
          />

          <Center>
            <ButtonV2 color="green" rightIcon={<ChevronComponent />} type="submit">
              submit
            </ButtonV2>
          </Center>
        </PanelBoxV2>
      </form>
    </FormProvider>
  );
}

export default function Goals() {
  useDocTitle("Goals");
  return (
    <Page>
      <Jumbotron bg="blue" title="Goals" />
      <DataLoader
        query={GOALS_PAGE_QUERY}
        fetchPolicy="network-only"
        nextFetchPolicy="network-only"
        render={({ goals, platformUserProfile }) =>
          platformUserProfile.isComplete ? (
            <GoalsForm goals={goals} platformUserProfile={platformUserProfile} />
          ) : (
            <Redirect to={PLATFORM_PROFILE_FORM_URL} />
          )
        }
      />
    </Page>
  );
}
