import { useCallback, useEffect, useRef, useState } from "react";

import { FormProvider } from "react-hook-form";

import Box from "components/Box";
import Errors from "components/Errors";
import Text from "components/Text";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import {
  INVITE_PRACTITIONER_TO_ORGANISATION_MUTATION,
  ORGANISATION_PRACITITIONER_LIST_QUERY
} from "graphql/organisations/queries/practitioners";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import ControlledCheckboxGroup from "tpo/ControlledCheckboxGroup";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2, { IndicativeButton } from "v2/Buttons";

export default function AddTeamMemberForm({ onSuccess = () => {} }) {
  const [showForm, setShowForm] = useState(true);
  const [existingPractitioner, setExistingPractitioner] = useState(false);
  const { organisation, organisationRoles } = useOrganisationContext();
  const [roleValues, setRoleValues] = useState([]);
  const [errors, setErrors] = useState([]);
  const submitRef = useRef();

  useEffect(() => {
    if (organisationRoles) {
      setRoleValues(
        organisationRoles.map(({ id, name }) => ({
          label: name,
          value: id
        }))
      );
    }
  }, [organisationRoles]);

  const api = useDjangoGraphqlForm({
    mutation: INVITE_PRACTITIONER_TO_ORGANISATION_MUTATION,
    mutationName: "invitePractitionerToOrganisationMutation",
    mutationOptions: {
      refetchQueries: [
        {
          query: ORGANISATION_PRACITITIONER_LIST_QUERY,
          variables: {
            organisation: parseInt(organisation?.id),
            page: 0,
            orderBy: "last_name"
          }
        }
      ]
    },
    defaultValues: {
      organisation: parseInt(organisation?.id),
      firstName: "",
      lastName: "",
      email: "",
      roles: []
    },
    handleSuccess: evt => {
      const response = evt.data.invitePractitionerToOrganisationMutation;
      const invite = response.organisationPractitionerInvite;
      setExistingPractitioner(invite.recipient?.id !== null);
      onSuccess();
      submitRef.current.setPending(false);
      submitRef.current.setSuccessful(true);
      setTimeout(() => {
        setShowForm(false);
      }, 2000);
    },
    handleFailure: errors => {
      const nonFieldErrors = errors.filter(error => error.field === "_All__");
      setErrors(nonFieldErrors);
      submitRef.current.setPending(false);
      submitRef.current.setSuccessful(false);
    }
  });

  const handleCreateInviteSubmit = useCallback(
    input => {
      setErrors([]);
      let variables = {
        ...input,
        organisation: parseInt(organisation.id)
      };

      return api.onSubmit(variables);
    },
    [organisation, api]
  );

  if (showForm) {
    return (
      <FormProvider {...api.methods} submitFormRef={submitRef}>
        <Stack as="form" onSubmit={api.methods.handleSubmit(handleCreateInviteSubmit)} gap={0}>
          <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
            Add team members
          </Box>
          <Spacer py={40 / 2} />
          <Box fontFamily="gilroyMedium" fontSize={16}>
            Add additional users to your organisation. Set their various permission levels and
            access controls. They will be invited to create an account before gaining access to the
            partner dashboard.
          </Box>
          <Spacer py={2} />
          <Stack gap={20}>
            <ControlledFormField
              Component={FloatingLabelInput}
              name="firstName"
              label="First name"
            />
            <ControlledFormField Component={FloatingLabelInput} name="lastName" label="Last name" />
            <ControlledFormField
              Component={FloatingLabelInput}
              name="email"
              label="Email address"
            />
            {organisationRoles && (
              <ControlledCheckboxGroup
                name="roles"
                Container={Group}
                containerProps={{
                  flexWrap: "wrap",
                  gap: [20, 20, 40],
                  pt: 2
                }}
                values={roleValues.map(role => ({
                  label: role.label,
                  value: role.value
                }))}
              />
            )}
          </Stack>
          <Spacer py={40 / 2} />
          <Center>
            <IndicativeButton
              ref={submitRef}
              defaultColor="green"
              pendingColor="darkgrey"
              successColor="#2ecc71"
              failureColor="error"
              rightIcon={<ChevronComponent />}
              sx={{
                mx: "auto"
              }}
            >
              submit
            </IndicativeButton>
          </Center>
          {errors && errors.length > 0 && (
            <Errors>
              {errors.map(error => (
                <>
                  {error.messages.map((message, idx) => (
                    <p key={`error-message-${idx}`}>{message}</p>
                  ))}
                </>
              ))}
            </Errors>
          )}
        </Stack>
      </FormProvider>
    );
  }

  const fullName = `${api.methods.getValues("firstName")} ${api.methods.getValues("lastName")}`;

  return (
    <Stack gap={0}>
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
        Member invited
      </Box>
      <Spacer py={40 / 2} />
      <Box fontFamily="gilroyMedium" fontSize={16}>
        {existingPractitioner
          ? `We’ve sent ${fullName} an invite, once they accept they will have access to your organisation.`
          : `We’ve sent ${fullName} an invite, they will need to apply to be a practitioner. Once approved
        they will have access to your organisation.`}
      </Box>
      <Spacer py={40 / 2} />
      <ButtonV2
        color="green"
        rightIcon={<ChevronComponent />}
        mx="auto"
        onClick={() => {
          api.methods.reset({
            firstName: "",
            lastName: "",
            email: "",
            roles: []
          });
          setShowForm(true);
        }}
      >
        new invite
      </ButtonV2>
    </Stack>
  );
}
