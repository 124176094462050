import { createContext, useCallback, useContext, useMemo } from "react";

import { usePersistentState } from "./LocalStorage";

export const ChangeOverTimeContext = createContext();

export function ChangeOverTimeContextProvider({ children }) {
  const [includeExpiredMarkers, setIncludeExpiredMarkers] = usePersistentState(
    "includeExpiredMarkers",
    true
  );

  const toggleExpiredMarkers = useCallback(() => {
    setIncludeExpiredMarkers(!includeExpiredMarkers);
  }, [includeExpiredMarkers, setIncludeExpiredMarkers]);

  const datapointHasExpired = useCallback(datapoint => {
    if (datapoint.result !== undefined) {
      // this is a result, so return expired prop on result
      return datapoint.expired;
    }

    let dateExpired = datapoint.dateExpired;

    if (typeof dateExpired === "string") {
      dateExpired = new Date(dateExpired);
    }

    return dateExpired <= new Date();
  }, []);

  const api = useMemo(
    () => ({
      includeExpiredMarkers,
      toggleExpiredMarkers,
      datapointHasExpired
    }),
    [includeExpiredMarkers, toggleExpiredMarkers, datapointHasExpired]
  );

  return <ChangeOverTimeContext.Provider value={api}>{children}</ChangeOverTimeContext.Provider>;
}

export function useChangeOverTimeContext() {
  return useContext(ChangeOverTimeContext);
}
