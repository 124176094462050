import { createContext, useContext } from "react";

export const ResultPresentationContext = createContext();

export function ResultPresentationProvider({ children, value }) {
  return (
    <ResultPresentationContext.Provider value={value}>
      {children}
    </ResultPresentationContext.Provider>
  );
}

export function ResultPresentationConsumer({ children }) {
  return (
    <ResultPresentationContext.Consumer>
      {value => children(value)}
    </ResultPresentationContext.Consumer>
  );
}

export function useResultPresentation() {
  return useContext(ResultPresentationContext);
}
