import ArrowCircle from "components/ArrowCircle";
import Box from "components/Box";
import Textarea from "components/Textarea";
import { ReactComponent as Pencil } from "images/pencil.svg";
import Accordion, { AccordionContext } from "tpo/Accordion";
import ControlledFormField from "tpo/ControlledFormField";
import FormControl from "tpo/FormControl";
import Stack from "tpo/Stack";
import TextInput from "tpo/TextInput";

export default function Testimonial({ idx }) {
  return (
    <Accordion.Item value={`testimonial${idx}`}>
      <Accordion.Control
        bg="transparent"
        data-component-name={`TestimonialAccordionControl:${idx}`}
        chevronIcon={
          <AccordionContext.Consumer>
            {({ openState }) => (
              <ArrowCircle
                backgroundColor="dark"
                arrowColor="white"
                direction={openState.has(`testimonial${idx}`) ? "bottom" : "top"}
              />
            )}
          </AccordionContext.Consumer>
        }
        py={20}
        fontFamily="gilroyBold"
        letterSpacing="2px"
        textTransform="uppercase"
        fontSize={18}
      >
        {`Testimonial ${idx + 1}`}
      </Accordion.Control>
      <Accordion.Panel bg="transparent" as={Stack} gap={40} pb={40}>
        <FormControl
          label={
            <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
              Testimonal Detail
            </Box>
          }
        >
          <ControlledFormField
            Component={Textarea}
            name={`expertTestimonials.${idx}.detail`}
            rows={8}
            placeholder="Add your bio"
            style={{
              resize: "none",
              padding: "10px",
              width: "100%",
              fontFamily: "Gilroy W05 Medium",
              fontSize: 18,
              border: "1px solid black"
            }}
            maxLength="250"
          />
        </FormControl>
        <FormControl
          label={
            <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
              Patient Name
            </Box>
          }
        >
          <ControlledFormField
            Component={TextInput}
            name={`expertTestimonials.${idx}.name`}
            rightIcon={<Pencil fill="black" width={16} />}
            px={18}
            py={20}
            bg="white"
            styles={{
              input: {
                border: "1px solid black",
                pl: 2
              }
            }}
          />
        </FormControl>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
