import React, { useEffect, useRef, useState } from "react";

import { PropTypes } from "prop-types";

import { BaseAccountPage } from "./AccountPage";
import Box from "./Box";

const IFramePage = props => {
  const [contentHeight, setContentHeight] = useState(0);

  const iframeEl = useRef(null);

  useEffect(() => {
    function tick() {
      let scrollHeight;
      try {
        scrollHeight = iframeEl.current.contentWindow.document.body.scrollHeight + 80;
        // the terms of use page doesn't have enough height without the 80
      } catch (error) {
        scrollHeight = null;
      }
      if (scrollHeight !== null) {
        setContentHeight(scrollHeight);
      }
    }

    let id = setInterval(tick, 500);
    return () => clearInterval(id);
  }, []);

  return (
    <BaseAccountPage>
      <Box px={20}>
        <Box maxWidth={1000} mx="auto">
          <iframe
            style={{
              height: `${contentHeight}px`,
              width: "100%",
              border: "none",
              margin: "50px auto"
            }}
            title={props.title}
            ref={iframeEl}
            src={props.src}
          ></iframe>
        </Box>
      </Box>
    </BaseAccountPage>
  );
};

IFramePage.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
};

export default IFramePage;
