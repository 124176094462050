import React from "react";

import useDocTitle from "../hooks/use-doc-title";
import IFramePage from "./IFramePage";

function PartnerTermsAndConditionsPage() {
  useDocTitle("Partner Terms and Conditions");
  return <IFramePage title="Privacy Policy" src="/partner-terms-and-conditions-content.html" />;
}

export default PartnerTermsAndConditionsPage;
