export default function BackArrow({ color }) {
  return (
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.17299 11.2211L9.19013 19.4947C9.84292 20.1684 10.9037 20.1684 11.5565 19.4947C12.2093 18.8211 12.2093 17.7263 11.5565 17.0526L6.39535 11.7263H23.3272C24.2554 11.7263 25 10.9579 25 10C25 9.04211 24.2554 8.27368 23.3272 8.27368H6.37495L11.4953 2.93684C12.1481 2.26316 12.1379 1.16842 11.4851 0.494737C11.1485 0.168421 10.7303 0 10.3019 0C9.87352 0 9.43493 0.168421 9.10853 0.51579L0 10.0105L1.17299 11.2211Z"
        fill={color}
      />
    </svg>
  );
}

BackArrow.defaultProps = {
  color: "white"
};
