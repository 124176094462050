import PropTypes from "prop-types";

import { theme } from "../core/theme";
import Box from "./Box";

export function PanelBox({
  children,
  outerMaxWidth,
  innerMaxWidth,
  backgroundColor,
  pl,
  pr,
  "data-component-name": dataComponentName
}) {
  return (
    <Box
      pl={pl}
      pr={pr}
      pt={theme.spacing.large}
      pb={theme.spacing.large}
      maxWidth={outerMaxWidth}
      bg={backgroundColor}
      ml="auto"
      mr="auto"
      dataComponentName={dataComponentName}
    >
      <Box maxWidth={innerMaxWidth} mx="auto">
        {children}
      </Box>
    </Box>
  );
}

PanelBox.defaultProps = {
  backgroundColor: "white",
  outerMaxWidth: 1200,
  innerMaxWidth: 1000,
  pl: 20,
  pr: 20
};

export function SpacedPanelBox({
  pt,
  pb,
  mb,
  children,
  outerMaxWidth,
  innerMaxWidth,
  backgroundColor,
  pl,
  pr,
  fullWidth,
  "data-component-name": dataComponentName
}) {
  return (
    <Box
      mt={theme.spacing.large}
      pl={fullWidth ? 0 : 20}
      pr={fullWidth ? 0 : 20}
      pt={pt}
      pb={pb}
      mb={mb}
      data-component-name={dataComponentName}
    >
      <PanelBox
        children={children}
        outerMaxWidth={outerMaxWidth}
        innerMaxWidth={innerMaxWidth}
        backgroundColor={backgroundColor}
        pl={pl}
        pr={pr}
      />
    </Box>
  );
}

SpacedPanelBox.defaultProps = {
  fullWidth: false
};

export function SpacedContentBox({
  mt,
  pt,
  pb,
  mb,
  backgroundColor,
  children,
  maxWidth,
  "data-component-name": dataComponentName
}) {
  return (
    <Box
      px={20}
      pt={pt}
      pb={pb}
      mt={mt}
      mb={mb}
      bg={backgroundColor}
      data-component-name={dataComponentName}
    >
      <Box maxWidth={maxWidth} mx="auto">
        {children}
      </Box>
    </Box>
  );
}

SpacedContentBox.defaultProps = {
  maxWidth: 800,
  mt: theme.spacing.large
};

export function StripBox(props) {
  const { children, stripColor, location, height, ...forwardProps } = props;
  return (
    <Box {...forwardProps} position="relative">
      <Box
        position="absolute"
        top={location === "top" ? 0 : undefined}
        bottom={location === "bottom" ? 0 : undefined}
        left={0}
        right={0}
        height={height}
        backgroundColor={stripColor}
      />
      <Box position="relative">{children}</Box>
    </Box>
  );
}

StripBox.defaultProps = {
  stripColor: "white",
  location: "top",
  height: 30
};

StripBox.propTypes = {
  stripColor: PropTypes.string,
  location: PropTypes.oneOf(["top", "bottom"]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export function SizerBox(props) {
  return (
    <Box
      data-component-name="Sizer box"
      {...props}
      position="relative"
      css={`
        & > * {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      `}
    />
  );
}
