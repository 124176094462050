import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { theme } from "../core/theme";
import Box from "./Box";
import { HeadingMediumLarge, IntroBlockHeading } from "./Headings";
import styles from "./List.module.scss";
import Text from "./Text";

const ListColumns = styled(Box)`
  display: flex;
  flex-wrap: wrap;

  & > * {
    padding-right: 20px;
    width: ${props => {
      return `${100 / props.columns[0]}%`;
    }};
  }

  @media (min-width: 640px) {
    & > * {
      width: ${props => {
        return `${100 / props.columns[1]}%`;
      }};
    }
  }

  @media (min-width: 960px) {
    & > * {
      width: ${props => {
        return `${100 / props.columns[2]}%`;
      }};
    }
  }

  @media (min-width: 1280px) {
    & > * {
      width: ${props => {
        return `${100 / props.columns[3]}%`;
      }};
    }
  }
`;

ListColumns.defaultProps = {
  columns: [1, 1, 2, 2]
};

function List({ items }) {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index} className={styles.listItem}>
          <span>{item}</span>
        </li>
      ))}
    </ul>
  );
}

export function ListItem({ columns, ...rest }) {
  return (
    <Box display="flex" justifyContent="space-between" {...rest}>
      {columns.map((column, index) => (
        <column.Component key={index} {...column.props} />
      ))}
    </Box>
  );
}

ListItem.propTypes = {
  columns: PropTypes.array
};

export function BulletList({ title, items, underline, itemProps, ...rest }) {
  return (
    <>
      <Box as="ul" listStyle="disc" {...rest}>
        {title && (
          <Text fontFamily="gilroyBold" fontSize={15} mt={4} mb={5} textTransform="capitalize">
            {title}
          </Text>
        )}
        {items.map((result, index) => (
          <Box as="li" key={index} fontSize={15} ml={5} {...itemProps}>
            <Text fontFamily="gilroyBold" fontSize={15} mt={2} underline={underline}>
              {result}
            </Text>
          </Box>
        ))}
      </Box>
    </>
  );
}

BulletList.defaultProps = {
  underline: true
};

export function ListBox({
  heading,
  text,
  items,
  backgroundColor,
  headingSize,
  maxColumns = 1,
  "data-component-name": dataComponentName
}) {
  const HeadingComponent = headingSize === "s" ? IntroBlockHeading : HeadingMediumLarge;
  const [lists, setLists] = useState([]);

  useEffect(() => {
    setLists(
      items.reduce((lists, item, idx) => {
        lists[idx % maxColumns]
          ? lists[idx % maxColumns].push(item)
          : (lists[idx % maxColumns] = [item]);
        return lists;
      }, [])
    );
  }, [items, maxColumns]);

  return (
    <Box
      data-component-name={dataComponentName}
      bg={backgroundColor}
      pt={theme.spacing.large}
      pb={theme.spacing.large}
      px={20}
    >
      <Box maxWidth={800} mx="auto">
        {heading && <HeadingComponent pb>{heading}</HeadingComponent>}
        {text && <Text pb={30}>{text}</Text>}
        <ListColumns
          columns={[1, Math.min(2, maxColumns), Math.min(3, maxColumns), Math.min(3, maxColumns)]}
        >
          {lists.map((list, idx) => (
            <List key={`list-${idx}`} items={list} />
          ))}
        </ListColumns>
      </Box>
    </Box>
  );
}

ListBox.defaultProps = {
  headingSize: "l"
};

ListBox.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string,
  headingSize: PropTypes.oneOf(["s", "l"])
};

export default List;
