import gql from "graphql-tag";

export const VALIDATE_KIT_MUTATION = gql`
  mutation ValidateTestItemMutation($input: ValidateTestItemMutationInput!) {
    validateTestItemMutation(input: $input) {
      errors {
        field
        messages
      }
      testProductId
    }
  }
`;

export const ACTIVATE_KIT_MUTATION = gql`
  mutation ActivateTestItemMutation($input: ActivateTestItemMutationInput!) {
    activateTestItemMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACTIVATE_EXTERNAL_KIT_MUTATION = gql`
  mutation ActivateExternalTestItemMutation($input: ActivateExternalTestItemMutationInput!) {
    activateExternalTestItemMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
