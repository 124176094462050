import { useEffect, useState, useRef, useCallback } from "react";

import Chart from "vendor/react-apexcharts";

import Box from "components/Box";
import { useTestBrushConfigContext } from "contexts/TestBrushConfig";
import useWindowSize from "hooks/use-window-size";
import { buildLineGraphConfiguration, generateMarker, getIcon } from "utils/cot/lineGraphs";
import { roundTimestampToDay } from "utils/shared";

const DPI_RATIO = 4;
const BRUSH_HEIGHT = 5 * DPI_RATIO;
const BRUSH_MARGINS = [10 * DPI_RATIO, 8];

function handleZoomChange({ points, xaxis = null, margin = 0.03 }) {
  let firstDatapoint;
  let lastDatapoint;

  if (!points || points.length === 0) {
    return {
      xaxis: {
        min: new Date().getTime() - 1000 * 60 * 60 * 24 * 3,
        max: new Date().getTime() + 1000 * 60 * 60 * 24 * 3
      }
    };
  }

  // if points[0][0] is an array we have multiple series
  // otherwise we have a single series
  if (Array.isArray(points[0][0])) {
    let firstOfEachSeries = points.map(series => series[0][0]);
    let lastOfEachSeries = points.map(series => series[series.length - 1][0]);

    firstDatapoint = new Date(Math.min(...firstOfEachSeries)).getTime();
    lastDatapoint = new Date(Math.max(...lastOfEachSeries)).getTime();
  } else {
    firstDatapoint = new Date(points[0][0]).getTime();
    lastDatapoint = new Date(points[points.length - 1][0]).getTime();
  }

  let delta = lastDatapoint - firstDatapoint;
  let deltaMargin = delta * margin;

  let furthestLeft = new Date(firstDatapoint - deltaMargin).getTime();
  let furthestRight = new Date(lastDatapoint + deltaMargin).getTime();

  if (firstDatapoint === lastDatapoint) {
    furthestLeft = new Date(firstDatapoint - 1000 * 60 * 60 * 24 * 3).getTime();
    furthestRight = new Date(lastDatapoint + 1000 * 60 * 60 * 24 * 3).getTime();
  }

  if (!xaxis) {
    xaxis = {
      min: furthestLeft,
      max: furthestRight
    };
  }

  if (xaxis.max > furthestRight) {
    xaxis.max = furthestRight;
  }

  if (xaxis.min < furthestLeft) {
    xaxis.min = furthestLeft;
  }

  return { xaxis };
}
export function LineGraph({ colors, type, height, width, fullRange, points, tooltips }) {
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    async function prepareOptions() {
      let icon = await getIcon();
      let annotations = await Promise.all(
        points.map(async ([x, y], index) => {
          return {
            x: roundTimestampToDay(x),
            y,
            image: {
              path: await generateMarker(icon, colors[index])
            },
            marker: {
              size: 8
            }
          };
        })
      );

      let { series, options } = buildLineGraphConfiguration({
        fullRange,
        points,
        tooltips
      });

      options = {
        ...options,
        annotations: {
          points: annotations
        }
      };

      options.chart.events = {
        beforeResetZoom: () => handleZoomChange({ points }),
        beforeZoom: (ctx, { xaxis }) => handleZoomChange({ points, xaxis })
      };

      let {
        xaxis: { min: xMin, max: xMax }
      } = handleZoomChange({ points });

      options.xaxis.min = xMin;
      options.xaxis.max = xMax;

      setSeries(series);
      setOptions(options);
    }

    prepareOptions();
  }, []);

  if (!series || !options) return null;

  return <Chart type={type} height={height} width={width} options={options} series={series} />;
}

LineGraph.defaultProps = {
  type: "line",
  height: 350,
  width: "100%"
};

function ResultsBrushGraph({ width, min, max, brushSeries }) {
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);
  const [showGraph, setShowGraph] = useState(false);
  const canvasRef = useRef(null);
  const { width: windowWidth } = useWindowSize();

  const transposeValue = useCallback(
    value => {
      return Math.floor(((value - min) / (max - min)) * graphWidth * DPI_RATIO);
    },
    [min, max, graphWidth]
  );

  useEffect(() => {
    if (!canvasRef.current) return;

    setGraphWidth(canvasRef.current.clientWidth);
  }, [canvasRef, windowWidth]);

  useEffect(() => {
    if (!canvasRef.current || !brushSeries) return;

    const splitSeries = {};
    setShowGraph(false);

    brushSeries.forEach(series => {
      series.data.forEach(datapoint => {
        if (!splitSeries[datapoint.x]) {
          splitSeries[datapoint.x] = { data: [] };
        }
        splitSeries[datapoint.x].data.push(datapoint);
      });
    });

    let graphHeight = Object.keys(splitSeries).length * 23;
    setGraphHeight(graphHeight);

    const ctx = canvasRef.current.getContext("2d");

    ctx.clearRect(0, 0, graphWidth * DPI_RATIO, graphHeight * DPI_RATIO);

    let labelsRendered = {};

    let y = BRUSH_MARGINS[0];
    Object.keys(splitSeries).forEach(key => {
      let series = splitSeries[key];

      series.data.forEach(datapoint => {
        let start = transposeValue(datapoint.y[0]);
        let end = transposeValue(datapoint.y[1]);

        if (start < 0) start = 0;
        if (start > graphWidth * DPI_RATIO) return;
        if (end > graphWidth * DPI_RATIO) end = graphWidth * DPI_RATIO;

        if (!labelsRendered[key]) {
          ctx.fillStyle = "#6a6a6a";
          ctx.font = `${10 * DPI_RATIO + 2}px Gilroy W05 Medium`;
          ctx.fontWeight = "bolder";

          // if the text total width + x is greater than the graph width
          // we need to move it to the left
          let labelStart = start;
          let { width: labelWidth } = ctx.measureText(key);

          if (labelWidth + labelStart > graphWidth * DPI_RATIO) {
            labelStart = graphWidth * DPI_RATIO - labelWidth - 10 * DPI_RATIO;
          }

          ctx.fillText(key, labelStart, y);
          labelsRendered[key] = true;
        }

        ctx.strokeStyle = datapoint.fillColor;
        ctx.lineWidth = BRUSH_HEIGHT;
        ctx.beginPath();
        if (datapoint.fill.type === "pattern") {
          ctx.setLineDash([BRUSH_HEIGHT * 0.5, BRUSH_HEIGHT * 0.5]);
        } else {
          ctx.setLineDash([]);
        }
        ctx.moveTo(start, y + 6 * DPI_RATIO);
        ctx.lineTo(end, y + 6 * DPI_RATIO);
        ctx.stroke();
      });

      y += BRUSH_HEIGHT * 2 + BRUSH_MARGINS[0] + BRUSH_MARGINS[1];

      setShowGraph(true);
    });
  }, [canvasRef, brushSeries, width, min, max, graphWidth, graphHeight, transposeValue]);

  return (
    <Box
      height={graphHeight}
      width="100%"
      position="relative"
      style={{
        marginLeft: "0px",
        marginRight: "5px",
        marginTop: "-10px"
      }}
    >
      <canvas
        ref={canvasRef}
        width={graphWidth * DPI_RATIO}
        height={graphHeight * DPI_RATIO}
        style={{
          position: "absolute",
          opacity: showGraph ? 1 : 0,
          transition: "opacity 0.8s ease-in-out",
          top: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          height: graphHeight
        }}
      />
    </Box>
  );
}

export function ResultsLineGraph({
  chartId,
  colors,
  type,
  height,
  width,
  fullRange,
  points,
  tooltips,
  productCodes
}) {
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);
  const [brushMin, setBrushMin] = useState(null);
  const [brushMax, setBrushMax] = useState(null);
  const [testBrushConfig, setTestBrushConfig] = useState(null);
  const [testAnnotations, setTestAnnotations] = useState(null);

  const { getRelevantConfig } = useTestBrushConfigContext();

  useEffect(() => {
    const { testBrushConfig, testAnnotations } = getRelevantConfig(chartId, productCodes);
    setTestBrushConfig(testBrushConfig);
    setTestAnnotations(testAnnotations);
  }, [productCodes, getRelevantConfig, chartId]);

  useEffect(() => {
    // only if we have all the necessary data
    if (!fullRange || !points || !tooltips) return;

    async function prepareOptions() {
      let icon = await getIcon();

      let annotations = {
        points: [
          points[0].length > 0 && {
            x: roundTimestampToDay(points[0][points[0].length - 1][0]),
            y: points[0][points[0].length - 1][1],
            image: {
              path: await generateMarker(icon, "#4b2ed4")
            },
            marker: {
              size: 8
            }
          },
          points[1].length > 0 && {
            x: roundTimestampToDay(points[1][points[1].length - 1][0]),
            y: points[1][points[1].length - 1][1],
            image: {
              path: await generateMarker(icon, "#6a6a6a")
            },
            marker: {
              size: 8
            }
          }
        ]
      };

      let { series, options } = buildLineGraphConfiguration({
        chartId,
        fullRange,
        points,
        tooltips,
        result: true
      });

      const handleZoomUpdateBrush = newAxis => {
        let { xaxis } = handleZoomChange({ points, xaxis: newAxis });
        setBrushMin(xaxis.min);
        setBrushMax(xaxis.max);
        return { xaxis };
      };

      options.chart.events = {
        beforeResetZoom: () => handleZoomUpdateBrush(),
        beforeZoom: (ctx, { xaxis }) => handleZoomUpdateBrush(xaxis)
      };

      annotations.xaxis = testAnnotations || [];

      options = {
        ...options,
        annotations
      };

      let {
        xaxis: { min: xMin, max: xMax }
      } = handleZoomChange({ points });

      options.xaxis.min = xMin;
      options.xaxis.max = xMax;

      setSeries(series);
      setOptions(options);

      setBrushMin(xMin);
      setBrushMax(xMax);
    }

    prepareOptions();
  }, [fullRange, points, tooltips, chartId, testAnnotations]);

  if (!series || !options) return null;

  options.xaxis.type = "datetime";

  return (
    <>
      <Chart
        id={chartId}
        type={type}
        height={height}
        width={width}
        options={options}
        series={series}
      />
      {testBrushConfig?.series && (
        <ResultsBrushGraph
          brushSeries={testBrushConfig.series}
          min={brushMin}
          max={brushMax}
          width={width}
        />
      )}
    </>
  );
}

ResultsLineGraph.defaultProps = {
  type: "line",
  height: 350,
  width: "100%"
};
