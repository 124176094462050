import { useCallback, useState } from "react";

export default function useDisclosure() {
  const [opened, setOpen] = useState(false);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return [opened, { open, close }];
}
