import Box from "components/Box";

export default function TestRecommendationBadge({ bg, color, rank }) {
  return (
    <Box bg={bg} borderRadius="100px" px={4} py={2} mt={4} ml={4}>
      <Box
        color={color}
        fontFamily="gilroyBold"
        fontSize={12}
        letterSpacing={2.8}
        textTransform="uppercase"
      >
        {rank === 1 ? "Suggested Next Test" : `#${rank} Matched`}
      </Box>
    </Box>
  );
}
