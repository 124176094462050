const breakpoints = ["640px", "960px", "1280px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export const theme = {
  colors: {
    // Colors
    anchorBlue: "rgb(75, 46, 212)",
    blue: "#19234a",
    dark: "#1a1a1a",
    green: "#00bf86",
    purple: "#4b2ed4",
    brand: "#5220DD",
    red: "#e34b4b",
    orange: "#fca557",
    // Greys
    haze: "#f2f2f2",
    cloud: "#ddd",
    carbon: "#ccc",
    slate: "#999",
    charcoal: "#777",
    midGrey: "#818181",
    // Themes
    bloods: "#14b7d8",
    exercise: "#17c6e9",
    fitness: "#17c6e9",
    genetics: "#ff5d6d",
    health: "#ffc647",
    lifestyle: "#ccbaff",
    nutrition: "#ff905f",
    partners: "#ffccca",
    specialists: "#ffde9b",
    stress: "#00bbbf",
    supplements: "#cbe1da",
    error: "#E44C4B",
    disabled: "rgba(255, 255, 255, 0.30)"
  },
  //      0  1  2   3   4   5   6   7   8   9    10
  space: [0, 5, 10, 15, 20, 30, 40, 60, 80, 120, 160],
  breakpoints,
  fonts: {
    dinBold: "DINNextW01-CondensedMed",
    gilroyMedium: "Gilroy W05 Medium",
    gilroyBold: "Gilroy W05 Bold",
    gilroyRegular: "Gilroy W05 Regular"
  },
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints.sm})`,
    medium: `@media screen and (min-width: ${breakpoints.md})`,
    large: `@media screen and (min-width: ${breakpoints.lg})`
  },
  capSizes: {
    dinBold: {
      height: 0.7,
      offset: 0.09
    },
    gilroyRegular: {
      height: 0.71,
      offset: 0
    },
    gilroyMedium: {
      height: 0.71,
      offset: 0
    },
    gilroyBold: {
      height: 0.71,
      offset: 0
    }
  },
  spacing: {
    extraLarge: [40, 60, 80, 100],
    large: [40, 40, 60, 80],
    medium: [20, 20, 30, 40],
    small: ["10px", "10px", 15, 20]
  }
};
