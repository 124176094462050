import gql from "graphql-tag";
import { PAGE_INFO_FIELDS } from "graphql/pagination";
import { USER_TEST_FIELDS_TRUNCATED } from "graphql/tpo/results/types";

export const CONNECTED_USERS_QUERY = gql`
  query ConnectedUsersQuery($page: Int, $orderBy: String, $search: String) {
    connectedUsersPagination(page: $page, orderBy: $orderBy, search: $search) {
      items {
        id
        platformUserProfile {
          id
          user {
            id
            firstName
            lastName
            pageAvailability {
              testResultsAvailable
            }
          }
        }
        email
        createdDateFormatted
        granted
        status
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_USER_TESTS_FOR_REVIEW_QUERY = gql`
  query OrganisationUserTestsForReview($organisation: ID!) {
    organisationUserTestsForReview(organisation: $organisation) {
      id
      user {
        id
        fullName
        email
      }
      testProduct {
        id
        name
      }
      orderTestItem {
        id
        providerTestId
      }
    }
  }
`;

export const PATIENT_TESTS = gql`
  query PatientTests($userId: ID!, $userTestId: ID!, $allTests: Boolean!) {
    userTests(userId: $userId, allTests: $allTests) {
      ...UserTestFieldsTruncated
    }
    userTest(userId: $userId, userTestId: $userTestId) {
      ...UserTestFieldsTruncated
    }
  }
  ${USER_TEST_FIELDS_TRUNCATED}
`;
