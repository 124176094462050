import { useEffect } from "react";

import RefetchEvent from "events/refetch";

export default function useRefetch(targetQuery, callback) {
  useEffect(() => {
    const handler = query => {
      if (query === targetQuery) {
        callback();
      }
    };
    RefetchEvent.listen(handler);
    return () => {
      RefetchEvent.unlisten(handler);
    };
  }, [targetQuery, callback]);
}
