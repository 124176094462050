import React, { useEffect, useState } from "react";

import Box from "../components/Box";
import Grid from "../components/Grid";
import Input from "../components/Input";

export function MonthYearInput({ handleChange, error, boxProps, initialValue }) {
  let initialMonth = "";
  let initialYear = "";

  if (!!initialValue) {
    const dateOfBirthSplit = initialValue.split("-");
    initialMonth = dateOfBirthSplit[1];
    initialYear = dateOfBirthSplit[2];
  }

  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);

  useEffect(() => {
    if (month !== "" && year !== "") {
      handleChange(`${year}-${month}`);
    } else {
      handleChange("");
    }
    // eslint-disable-next-line
  }, [month, year]);

  return (
    <Grid gridTemplateColumns="1fr 1fr" {...boxProps}>
      <Input
        value={month}
        handleChange={setMonth}
        label="Month"
        showPencil={false}
        type="number"
        error={error}
        data-component-name="Month input"
      ></Input>
      <Input
        value={year}
        handleChange={setYear}
        label="Year"
        showPencil={false}
        type="number"
        error={error}
        data-component-name="Year input"
      ></Input>
    </Grid>
  );
}

MonthYearInput.defaultProps = {
  initialMonth: "",
  initialYear: ""
};

export function MonthYearInputField({ label, ...rest }) {
  return (
    <Box>
      {label}
      <Box>
        <MonthYearInput {...rest} />
      </Box>
    </Box>
  );
}
