import { isUndefined, round } from "lodash";
import { constructGradientFromTerms } from "utils/shared";

export function buildGradientChartConfig({ normalisedRange, range, terminology, title }) {
  if (normalisedRange.range.length !== range.length) {
    return null;
  }

  const minimum = normalisedRange.minimum;
  const maximum = normalisedRange.maximum;
  const value = normalisedRange.value;

  const fullRange = [
    {
      value: minimum,
      position: 0
    },
    ...normalisedRange.range.map((point, index) => ({
      value: round(point, 2),
      label: round(range[index], 2),
      position:
        (100 / normalisedRange.range.length) * index + 100 / normalisedRange.range.length / 2
    })),
    {
      value: maximum,
      position: 100
    }
  ];

  const boundedValue = round(Math.max(minimum, Math.min(value, maximum)), 2);
  const upperLimitIndex = fullRange.findIndex(point => boundedValue <= point.value);
  const lowerLimitIndex = upperLimitIndex > 0 ? upperLimitIndex - 1 : 0;

  let normalisedValue;

  if (upperLimitIndex === lowerLimitIndex) {
    normalisedValue = fullRange[upperLimitIndex].position;
  } else {
    const min = fullRange[lowerLimitIndex].value;
    const max = fullRange[upperLimitIndex].value;
    const ratio = (boundedValue - min) / (max - min);
    const distance = fullRange[upperLimitIndex].position - fullRange[lowerLimitIndex].position;
    normalisedValue = fullRange[lowerLimitIndex].position + distance * ratio;
  }

  const background = constructGradientFromTerms(terminology.join(","));
  const points = fullRange.filter(point => !isUndefined(point.label));

  return {
    background,
    points,
    value: normalisedValue
  };
}
