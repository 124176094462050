import { forwardRef } from "react";

import { ReactComponent as CrossIcon } from "images/cross.svg";

import { theme } from "../core/theme";
import { ReactComponent as SearchIcon } from "../images/tpo/search.svg";
import Input from "./Input";
import InputWrapper from "./InputWrapper";

const TextInput = forwardRef(
  (
    {
      label,
      name,
      onChange,
      value,
      placeholder,
      rightIcon,
      height,
      px,
      py,
      error,
      borderRadius,
      bg,
      width,
      maxWidth,
      styles,
      onFocus,
      onBlur,
      minWidth
    },
    ref
  ) => (
    <InputWrapper
      label={label}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      input={
        <Input
          ref={ref}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          rightIcon={rightIcon}
          height={height}
          px={px}
          py={py}
          borderRadius={borderRadius}
          bg={bg}
          onFocus={onFocus}
          onBlur={onBlur}
          {...styles?.input}
        />
      }
      {...styles?.wrapper}
      error={error}
    />
  )
);

TextInput.defaultProps = {
  bg: "white"
};

export default TextInput;

export function Search({ value, onChange, ...props }) {
  return (
    <TextInput
      placeholder="Search"
      onChange={e => onChange(e.target.value)}
      value={value}
      rightIcon={
        value ? (
          <CrossIcon
            fill="black"
            width={17}
            onClick={() => onChange("")}
            style={{
              cursor: "pointer"
            }}
          />
        ) : (
          <SearchIcon fill={theme.colors.dark} />
        )
      }
      bg="white"
      height={55}
      px={30}
      borderRadius={100}
      {...props}
    />
  );
}
