const { default: Box } = require("components/Box");
const { default: Asterisk } = require("tpo/Asterisk");
const { default: Group } = require("tpo/Group");

export default function RequiredFieldsNotice() {
  return (
    <Group fontFamily="gilroyMedium" fontSize={16} justifyContent="flex-end">
      <Box as="span" fontFamily="inherit" fontSize="inherit">
        (
      </Box>
      <Asterisk />
      <Box as="span" fontFamily="inherit" fontSize="inherit">
        Indicates required fields
      </Box>
      <Box as="span" fontFamily="inherit" fontSize="inherit">
        )
      </Box>
    </Group>
  );
}
