import { gql } from "@apollo/client";
import { ChartConfigurationProvider } from "contexts/ChartConfiguration";

import {
  CHART_CONFIGURATION_FIELDS,
  USER_RESULT_FIELDS_TRUNCATED
} from "../graphql/tpo/results/types";
import {
  DiscreteChartCard,
  GenesAllelesChartCard,
  GradientsChartsCard,
  SegmentedChartChard,
  UnknownChartCard
} from "./ChartCards";

export function DatapointCharts({ children, datapoint }) {
  if (datapoint.chartConfiguration?.chartType === "GRADIENT_CHART") {
    return (
      <ChartConfigurationProvider value={datapoint.chartConfiguration}>
        <GradientsChartsCard datapoint={datapoint}>{children}</GradientsChartsCard>
      </ChartConfigurationProvider>
    );
  } else if (datapoint.chartConfiguration?.chartType === "DISCRETE_CHART") {
    return (
      <ChartConfigurationProvider value={datapoint.chartConfiguration}>
        <DiscreteChartCard result={datapoint}>{children}</DiscreteChartCard>
      </ChartConfigurationProvider>
    );
  } else if (datapoint.chartConfiguration?.chartType === "SEGMENTED_CHART") {
    return (
      <ChartConfigurationProvider value={datapoint.chartConfiguration}>
        <SegmentedChartChard datapoint={datapoint}>{children}</SegmentedChartChard>
      </ChartConfigurationProvider>
    );
  } else if (datapoint.datapoint.chartType === "GENES_ALLELES_CHART") {
    return (
      <ChartConfigurationProvider value={datapoint.chartConfiguration}>
        <GenesAllelesChartCard datapoint={datapoint}>{children}</GenesAllelesChartCard>
      </ChartConfigurationProvider>
    );
  }
  return (
    <ChartConfigurationProvider value={datapoint.chartConfiguration}>
      <UnknownChartCard result={datapoint}>{children}</UnknownChartCard>
    </ChartConfigurationProvider>
  );
}

export const RELATED_RESULTS_QUERY = gql`
  query RELATED_RESULTS_QUERY($userDatapointId: ID!, $userId: ID) {
    userDatapoint(userDatapointId: $userDatapointId, userId: $userId) {
      id
      uploaded
      results {
        ...UserResultFieldsTruncated
        isHidden
        resultState {
          id
          name
        }
        chartConfiguration {
          ...ChartConfigurationFields
        }
      }
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
  ${USER_RESULT_FIELDS_TRUNCATED}
`;
