import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import Box from "../components/Box";

export function PanelBox({
  children,
  bg,
  outer,
  inner,
  maxWidth,
  small,
  medium,
  large,
  topPadding,
  bottomPadding,
  verticalPadding,
  nested,
  stacked,
  className
}) {
  outer = {
    bg,
    px: [20, nested ? 0 : 20],
    pt: (verticalPadding || topPadding) && [40, 80],
    pb: (verticalPadding || bottomPadding) && [50, 90],
    mt: stacked && [-40, -80],
    ...outer
  };
  maxWidth =
    (small && [null, 624, 760]) ||
    (medium && [null, 624, 940, 1020]) ||
    (large && [null, 624, 940, 1280]) ||
    maxWidth;
  inner = {
    px: (small && [nested ? 0 : 20, 30, 30, 0]) || 0,
    ...inner
  };

  return (
    <Box className={className} {...outer}>
      <Box maxWidth={maxWidth} mx="auto" {...inner}>
        {children}
      </Box>
    </Box>
  );
}

PanelBox.propTypes = {
  children: PropTypes.node,
  bg: propTypes.color.bg,
  outer: PropTypes.shape({
    mb: propTypes.space.mb
  }),
  inner: PropTypes.shape({
    bg: propTypes.color.bg
  }),
  maxWidth: propTypes.layout.maxWidth,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  topPadding: PropTypes.bool,
  bottomPadding: PropTypes.bool,
  verticalPadding: PropTypes.bool,
  nested: PropTypes.bool,
  stacked: PropTypes.bool
};

export function SpacedContentBox({ children, bg, maxWidth, ...rest }) {
  return (
    <Box bg={bg} {...rest}>
      <Box width="100%" maxWidth={maxWidth} mx="auto">
        {children}
      </Box>
    </Box>
  );
}

SpacedContentBox.propTypes = {
  children: PropTypes.node,
  bg: propTypes.color.bg,
  maxWidth: propTypes.layout.maxWidth
};
