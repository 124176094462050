import Box from "../components/Box";

function Center({ children, stacked, ...rest }) {
  const props = {
    ...rest,
    flexDirection: stacked ? "column" : "row"
  };

  return <Box {...props}>{children}</Box>;
}

Center.defaultProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export default Center;
