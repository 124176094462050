import { useEffect, useState } from "react";

import Loading from "components/Loading";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ORGANISATION_STOCK_DETAILS_QUERY } from "graphql/organisations/queries/stock";
import { useDataLoader } from "hooks/useDataLoader";

import DownloadRequisitionPanel from "./DownloadRequisition";
import RegisterStockPanel from "./RegisterStockPanel";

export default function RegisterStock({ stockId }) {
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [stock, setStock] = useState(null);

  const { organisation } = useOrganisationContext();
  const { data, loading } = useDataLoader({
    query: ORGANISATION_STOCK_DETAILS_QUERY,
    variables: {
      id: stockId,
      organisation: parseInt(organisation?.id)
    },
    skip: !organisation || !stockId
  });

  useEffect(() => {
    if (data?.organisationStockItem) {
      setStock(data.organisationStockItem);
    }
  }, [data]);

  useEffect(() => {
    if (stock?.registeredUser) {
      setRegistrationComplete(true);
    }
  }, [stock]);

  if (loading || !stock) {
    return <Loading />;
  }

  if (registrationComplete) {
    return <DownloadRequisitionPanel stock={stock} />;
  }

  return <RegisterStockPanel stock={stock} onComplete={() => setRegistrationComplete(true)} />;
}
