import { useEffect, useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { getAPIBase } from "core/config";
import { PAYMENT_OBJECT_STATUS_CREATED } from "core/constants";
import Badge from "tpo/Badge";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function OrganisationOrderSummary({ order }) {
  const [stripeDetails, setStripeDetails] = useState();
  const STATUS_OPTIONS = {
    unfilled: {
      color: "black",
      variant: "outline",
      borderRadius: "100px",
      borderWidth: "2px"
    },
    filled: {
      color: "black",
      variant: "pill"
    },
    success: {
      color: "green",
      variant: "pill"
    },
    failure: {
      color: "red",
      variant: "pill"
    },
    notify: {
      color: "purple",
      variant: "pill"
    }
  };

  const STATUSES = {
    "awaiting dispatch": STATUS_OPTIONS["unfilled"],
    dispatched: STATUS_OPTIONS["filled"],
    "at lab": STATUS_OPTIONS["unfilled"],
    failed: STATUS_OPTIONS["failure"],
    cancelled: STATUS_OPTIONS["failure"],
    "review results": STATUS_OPTIONS["notify"],
    "results released": STATUS_OPTIONS["success"],
    invoiced: STATUS_OPTIONS["filled"],
    "awaiting payment": STATUS_OPTIONS["unfilled"],
    "patient to pay": STATUS_OPTIONS["failure"],
    unpaid: STATUS_OPTIONS["failure"],
    "over due": STATUS_OPTIONS["failure"],
    "partial refund": STATUS_OPTIONS["unfilled"],
    refunded: STATUS_OPTIONS["filled"],
    credit: STATUS_OPTIONS["filled"],
    "patient paid": STATUS_OPTIONS["success"],
    paid: STATUS_OPTIONS["success"]
  };

  useEffect(() => {
    if (!order || !order.id) {
      return;
    }

    if (order.sourceBasket.payee === "patient") {
      return setStripeDetails({
        label: "Payment:",
        value: (
          <ExternalTextLink
            onClick={e => {
              e.clickWithinOrganisationOrderSummaryComponent = true;
            }}
            href={`${getAPIBase()}/payments/checkout/${order.id}`}
          >
            Payment Link
          </ExternalTextLink>
        )
      });
    } else if (order.sourceBasket.paymentCheckoutSession) {
      if (order.sourceBasket.paymentCheckoutSession.invoiceUrl) {
        return setStripeDetails({
          label: "Payment:",
          value: (
            <ExternalTextLink
              onClick={e => {
                e.clickWithinOrganisationOrderSummaryComponent = true;
              }}
              href={`${order.sourceBasket.paymentCheckoutSession.invoiceUrl}`}
            >
              View Invoice
            </ExternalTextLink>
          )
        });
      } else {
        return setStripeDetails({
          label: "Payment:",
          value: (
            <ExternalTextLink
              onClick={e => {
                e.clickWithinOrganisationOrderSummaryComponent = true;
              }}
              href={`${order.sourceBasket.paymentCheckoutSession.url}`}
            >
              Payment Link
            </ExternalTextLink>
          )
        });
      }
    } else if (order.sourceBasket.paymentInvoice) {
      return setStripeDetails({
        label: "Payment:",
        value: (
          <ExternalTextLink
            onClick={e => {
              e.clickWithinOrganisationOrderSummaryComponent = true;
            }}
            href={order.sourceBasket.paymentInvoice.url}
          >
            View Invoice
          </ExternalTextLink>
        )
      });
    } else {
      return setStripeDetails({
        label: "Payment:",
        value: "N/A"
      });
    }
  }, [order]);

  let additionalStatus = null;

  if (order.suppliedFromPracticeStock) {
    additionalStatus = "From Stock";
  } else if (order.isStockOrder) {
    additionalStatus = "Stock Order";
  }

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      py={4}
      gap={20}
    >
      <Stack gap={10}>
        {additionalStatus && (
          <Box alignItems="flex-start" fontFamily="gilroyBold" fontSize={16}>
            <Badge bg="black" color="white" ml="auto" py="8px" px="16px" fontSize={10}>
              {additionalStatus}
            </Badge>
          </Box>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16}>
            Order No:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16} data-testid="orderDetails:orderNo">
            {order.id}
          </Box>
        </Group>
        {stripeDetails && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={16} data-testid="stripeDetails:label">
              {stripeDetails.label}
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={16} data-testid="stripeDetails:value">
              {stripeDetails.value}
            </Box>
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16}>
            Order Date:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16} data-testid="orderDate">
            {new Date(order.checkoutDate).toLocaleDateString()}
          </Box>
        </Group>
        {order.sourceBasket &&
        order.sourceBasket.paymentInvoice &&
        order.sourceBasket.paymentInvoice.dateDue &&
        order.sourceBasket.paymentInvoice.paymentStatus === PAYMENT_OBJECT_STATUS_CREATED ? (
          <Group gap={10} alignItems="center">
            <Box fontFamily="gilroyBold" fontSize={16}>
              Payment due:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={16} data-testid="paymentDue">
              {new Date(order.sourceBasket.paymentInvoice.dateDue).toLocaleDateString()}
            </Box>
            {new Date(order.sourceBasket.paymentInvoice.dateDue) > new Date() &&
            order.sourceBasket.paymentInvoice.url ? (
              <ButtonV2
                as="a"
                color="green"
                href={order.sourceBasket.paymentInvoice.url}
                sx={{
                  lineHeight: 1,
                  px: 20,
                  py: 2,
                  cursor: "pointer"
                }}
                data-testid="payNowButton"
                onClick={e => {
                  e.clickWithinOrganisationOrderSummaryComponent = true;
                }}
              >
                pay now
              </ButtonV2>
            ) : null}
          </Group>
        ) : null}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16}>
            Name:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16} data-testid="orderName">
            {order.user.firstName} {order.user.lastName}
          </Box>
        </Group>
      </Stack>
      <Group gap={10} alignSelf="flex-start" flexWrap="wrap">
        <ButtonV2
          key={order.sourceBasket.paymentStatus}
          py="8px"
          px={16}
          fontSize={10}
          {...STATUSES[order.sourceBasket.paymentStatus]}
          style={{
            cursor: "default"
          }}
          data-testid="paymentStatusBadge"
          onClick={e => {
            e.clickWithinOrganisationOrderSummaryComponent = true;
          }}
        >
          {order.sourceBasket.paymentStatus}
        </ButtonV2>
        {/* <ButtonV2
          key={order.sourceBasket.orderStatus}
          py="8px"
          px={16}
          fontSize={10}
          {...STATUSES[order.sourceBasket.orderStatus]}
          style={{
            cursor: "default"
          }}
        >
          {order.sourceBasket.orderStatus}
        </ButtonV2> */}
      </Group>
    </Box>
  );
}
