import React from "react";

import { useHistory } from "react-router-dom";

import { LOGIN_URL } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import { SolidButton } from "./Buttons";
import Text from "./Text";

function SignupCompletePage() {
  useDocTitle("Signup complete");
  const history = useHistory();

  return (
    <AccountPage heading="Success">
      <Text pt={40}>
        Your account has been successfully activated. Please click below to log in to your new
        account.
      </Text>
      <SolidButton
        mt={40}
        maxWidth={300}
        width="100%"
        mx="auto"
        handleClick={() => {
          history.push(LOGIN_URL);
        }}
        data-component-name="Log in button"
      >
        Log in
      </SolidButton>
    </AccountPage>
  );
}

export default SignupCompletePage;
