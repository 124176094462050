import { useState } from "react";

import { useDebounce } from "hooks/useDebounce";
import usePrevious from "hooks/usePrevious";
import { get, isFunction } from "lodash";
import styled from "styled-components";

import Box from "../components/Box";
import Loading from "../components/Loading";
import Center from "./Center";
import Menu, { Button } from "./Menu";
import Stack from "./Stack";

const TableWrapper = styled(Box)`
  position: relative;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table tbody tr td {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  tr:not(.placeholder) {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export default function Table({
  columns,
  rows: newRows,
  loading,
  display,
  pageSize,
  emptyMessage
}) {
  const previousRows = usePrevious(newRows);
  const rows = newRows || previousRows;

  return (
    <TableWrapper display={display}>
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <Box
                as="th"
                key={column.id}
                fontFamily="gilroyBold"
                textAlign={column.align || "left"}
                width={column.width}
                fontSize={18}
                pb={2}
              >
                {column.label}
              </Box>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map(row => (
            <tr key={row.id}>
              {columns.map((col, idx) => (
                <td
                  key={col.id}
                  style={{
                    verticalAlign: columns[idx]?.verticalAlign || "top",
                    textAlign: columns[idx]?.align,
                    width: columns[idx]?.width
                  }}
                >
                  {isFunction(col.accessor) ? col.accessor(row) : get(row, col.accessor)}
                </td>
              ))}
            </tr>
          ))}
          {!loading && !rows?.length && (
            <tr>
              <td
                colSpan={columns.length}
                style={{
                  textAlign: "center"
                }}
              >
                {emptyMessage}
              </td>
            </tr>
          )}
          {loading &&
            !rows?.length &&
            Array.from(Array(pageSize).keys()).map(i => (
              <tr className="placeholder" key={i}>
                <td colSpan={columns.length}></td>
              </tr>
            ))}
        </tbody>
      </table>
      {loading && (
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Stack gap={0} height="100%">
            {/* include the thead here so that the spinner is center of the tbody */}
            <Box visibility="hidden">
              <table>
                <thead>
                  <tr>
                    {columns.map(column => (
                      <th
                        key={column.id}
                        style={{
                          textAlign: column.align,
                          width: column.width
                        }}
                      >
                        {column.label}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </Box>
            <Center flexGrow={1} position="relative">
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="haze"
                opacity={0.5}
              />
              <Loading />
            </Center>
          </Stack>
        </Box>
      )}
    </TableWrapper>
  );
}

Table.defaultProps = {
  emptyMessage: "None found",
  pageSize: 10
};

export function useTableControls({ sortOptions }) {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 200);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [page, setPage] = useState(1);
  return {
    search,
    setSearch,
    debouncedSearch,
    sortMenuOpen,
    setSortMenuOpen,
    sort,
    setSort,
    page,
    setPage
  };
}
