import { useCallback, useEffect, useState } from "react";

import Box from "components/Box";
import { Spinner } from "components/Buttons";
import { FadeTransition } from "components/animations/FadeTransition";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

export default function AcknowledgeAndSubmitPanel({
  title,
  label,
  acknowledgementRequired = true,
  initialValue = false,
  onAcknowledge,
  onSubmit = () => {},
  canSubmit,
  loading = false,
  children,
  ...props
}) {
  const { basket } = useOrganisationBasketContext();
  const [acknowledged, setAcknowledged] = useState(acknowledgementRequired ? initialValue : true);
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    if (!canSubmit) {
      return setAllowSubmit(false);
    }

    if (basket?.shippingAddress?.country?.isoCode === "GB") {
      setAllowSubmit(true);
    } else if (basket?.customsConfirmation === true) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [basket, canSubmit]);

  const handleAcknowledge = useCallback(checked => {
    setAcknowledged(checked);
    onAcknowledge && onAcknowledge(checked);
  });

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit();
  });

  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        bg: "white",
        px: [20, 20, 40],
        pt: [20, 20, 40],
        pb: [50, 50, 80],
        borderRadius: 5
      }}
      {...props}
    >
      <Box fontFamily="gilroyBold" fontSize={28}>
        {title}
      </Box>
      <Spacer py={2} />
      <Box fontFamily="gilroyMedium" fontSize={16}>
        {children}
      </Box>
      {acknowledgementRequired && (
        <>
          <Spacer py={4} />
          <Checkbox
            label={label}
            checked={acknowledged}
            onChange={({ target }) => handleAcknowledge(target.checked)}
          />
        </>
      )}
      <Spacer py={15} />
      <Center>
        <FadeTransition in={loading}>
          <Spinner />
        </FadeTransition>
        <FadeTransition in={!loading}>
          <ButtonV2
            color={acknowledged && allowSubmit ? "green" : "grey"}
            disabled={!acknowledged || !allowSubmit}
            rightIcon={<ChevronComponent />}
            onClick={() => handleSubmit()}
          >
            place order
          </ButtonV2>
        </FadeTransition>
      </Center>
    </PanelBoxV2>
  );
}
