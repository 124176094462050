import { useEffect, useRef, useState } from "react";

import Box from "components/Box";

import ChevronComponent from "./Chevron";
import Menu from "./Menu";
import TextInput from "./TextInput";

export default function Autocomplete({
  placeholder,
  options,
  value,
  onChange,
  maxWidth,
  searchProp = "label"
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [focused, setFocused] = useState(false);
  const [matchedOptions, setMatchedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (!options) {
      return;
    }
    setMatchedOptions(options);
  }, [options, setMatchedOptions]);

  useEffect(() => {
    if (!value) {
      return;
    }
    let foundOption = options.find(option => parseInt(option.value) === parseInt(value));
    if (foundOption) {
      setSelectedOption(foundOption);
      setSearch(foundOption.label);
    }
  }, [value, options, setSelectedOption]);

  useEffect(() => {
    if (!search && focused) {
      setMatchedOptions(options);
      setOpen(true);
    }
    let matched = options.filter(option => {
      return option[searchProp].toLowerCase().includes(search.toLowerCase());
    });
    setMatchedOptions(matched);
  }, [options, search, setMatchedOptions]);

  useEffect(() => {
    if (!selectedOption) {
      return;
    }
    onChange && onChange(selectedOption.value);
    setOpen(false);
  }, [selectedOption, onChange, setOpen]);

  return (
    <>
      <Menu opened={open} onChange={setOpen} trigger="click" yOffset={10}>
        <Menu.Target
          onFocus={() => {
            setFocused(true);
            setOpen(true);
          }}
          onBlur={() => setFocused(false)}
        >
          <TextInput
            placeholder={placeholder}
            value={search || ""}
            onChange={e => {
              setSelectedOption(null);
              setSearch(e.target.value);
            }}
            rightIcon={
              <ChevronComponent
                width={10}
                height={10}
                fill="black"
                direction="bottom"
                style={{
                  margin: 0
                }}
              />
            }
            styles={{
              input: {
                borderColor: focused ? "#228be6" : "haze",
                borderStyle: "solid",
                borderWidth: 1
              }
            }}
            py={15}
            px={20}
            maxWidth={maxWidth}
            borderRadius={5}
          />
        </Menu.Target>
        <Menu.Dropdown maxHeight={300} overflowY="auto" py={2}>
          {matchedOptions?.map(option => (
            <Menu.Item
              key={option.label}
              onClick={() => {
                setSelectedOption(option);
                setSearch(option.label);
              }}
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
