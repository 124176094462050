import Box from "components/Box";
import RichText from "components/RichText";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import AddToBasketButton from "./AddToBasketButton";
import Pills from "./Pills";

export default function MainDetails({
  status,
  name,
  description,
  categories,
  numOfBiomarkersTested,
  processingTime,
  priceElement,
  testType,
  practitionerSupportRequired,
  onAddToBasket,
  options
}) {
  return (
    <>
      <Box px={[0, 40]}>
        {practitionerSupportRequired && (
          <>
            <ButtonV2
              color="partners"
              variant="pill"
              py="8px"
              px={16}
              fontSize={10}
              sx={{
                color: "dark"
              }}
              mr="auto"
            >
              practitioner support required
            </ButtonV2>
            <Spacer py={[20, 20, 2]} />
          </>
        )}
        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="130%">
          {name}
        </Box>
        <Spacer py={[7.5, 7.5, 15]} />
        <RichText fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          {description}
        </RichText>
        <Spacer py={[2, 2, 20]} />
        <Pills.Group>
          {categories.map(c => (
            <Pills.Pill
              key={c}
              color="lifestyle"
              sx={{
                color: "dark"
              }}
            >
              {c}
            </Pills.Pill>
          ))}
        </Pills.Group>
        <Spacer py={[2, 2, 20]} />
        <Stack>
          <Group alignItems="center" gap={10}>
            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
              Sample type:
            </Box>
            <Group alignItems="center" flexWrap="wrap" gap={10}>
              <Pills.Pill color="purple">{testType}</Pills.Pill>
            </Group>
          </Group>
          <Group alignItems="center" gap={10}>
            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
              Biomarkers tested:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={16}>
              {numOfBiomarkersTested}
            </Box>
          </Group>
          <Group alignItems="center" gap={10}>
            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
              Processing time:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={16}>
              {processingTime || "N/A"}
            </Box>
          </Group>
        </Stack>
      </Box>
      <Spacer py={[2, 2, 30]} />
      {options ? (
        options
      ) : (
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
          flexWrap="wrap"
          px={[0, 40]}
          gap={10}
        >
          {priceElement}
          <AddToBasketButton
            status={status}
            practitionerSupportRequired={practitionerSupportRequired}
            onAddToBasket={onAddToBasket}
          />
        </Box>
      )}
    </>
  );
}
