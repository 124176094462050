import { useFormContext } from "react-hook-form";

import ButtonV2 from "v2/Buttons";

export default function ClearAvatar() {
  const { watch, setValue } = useFormContext();

  const avatar = watch("expertAvatar");

  if (!avatar) return null;

  return (
    <ButtonV2
      color="red"
      onClick={() => {
        setValue("expertAvatar", "", { shouldDirty: true });
      }}
      type="button"
    >
      clear
    </ButtonV2>
  );
}
