import Box from "components/Box";

function Badge({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

Badge.defaultProps = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "uppercase",
  borderRadius: 100,
  py: "8px",
  px: 16,
  fontFamily: "gilroyBold",
  letterSpacing: "2.8px",
  fontSize: 10,
  "data-component-name": "Badge"
};

export default Badge;
