import { forwardRef, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PARTNERS_CHECKOUT_URL } from "core/urls";
import ChevronComponent from "tpo/Chevron";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import SegmentedControl from "tpo/SegmentedControl";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import { PAYEE_ORGANISATION, PAYEE_PATIENT } from "../constants";
import { BasketItem } from "./ui/BasketItem";
import { BasketTotals } from "./ui/BasketTotals";
import { OrganisationPayeeOptions } from "./ui/OrganisationPayeeOptions";
import { PatientPayeeOptions } from "./ui/PatientPayeeOptions";

export const OrganisationBasketListing = forwardRef((props, ref) => {
  const [discount, setDiscount] = useState("");

  const { organisation } = useOrganisationContext();
  const {
    basket,
    addProductToBasket,
    removeProductFromBasket,
    addSupplementToBasket,
    removeSupplementFromBasket,
    updateBasketDetails
  } = useOrganisationBasketContext();

  const { setBasketOpen, basketOpen } = useAppState();
  const [onboardingComplete, setOnboardingComplete] = useState(false);

  useEffect(() => {
    if (!organisation) return;

    setOnboardingComplete(organisation?.onboardingStatus === "complete");
  }, [organisation]);

  const lineItems = [];
  const history = useHistory();

  const getItemPrices = item => {
    if (basket.payee == PAYEE_PATIENT) {
      return {
        price: item.fullPricePerUnit * item.quantity
      };
    } else {
      return {
        price: item.pricePerUnit * item.quantity,
        rrpPrice: item.rrpPerUnit * item.quantity
      };
    }
  };

  if (basket) {
    [...basket.productLineItems]
      .sort((a, b) => {
        let sortKeyA = a.productOption
          ? `${a.product.name}-${a.productOption?.name}-${a.clinicLocation?.clinic?.name}`
          : a.product.name;
        let sortKeyB = b.productOption
          ? `${b.product.name}-${b.productOption?.name}-${b.clinicLocation?.clinic?.name}`
          : b.product.name;

        if (sortKeyA < sortKeyB) {
          return -1;
        }
        if (sortKeyA > sortKeyB) {
          return 1;
        }
        return 0;
      })
      .forEach(item => {
        if (item.clinicLocation) {
          lineItems.push(
            <BasketItem
              {...getItemPrices(item)}
              key={item.id}
              name={item.product.name}
              subname={item.productOption?.testProductOptionFamily?.name}
              clinicName={item.clinicLocation.clinic.name}
              address={item.clinicLocation.address}
              fees={item.clinicFeesPerUnit * item.quantity}
              count={item.quantity}
              removeItemFromBasket={() =>
                removeProductFromBasket(
                  item.product.id,
                  item.productOption?.id,
                  item.clinicLocation?.id
                )
              }
              addItemToBasket={() =>
                addProductToBasket(item.product.id, item.productOption?.id, item.clinicLocation?.id)
              }
            />
          );
        } else if (item.productOption) {
          lineItems.push(
            <BasketItem
              {...getItemPrices(item)}
              key={item.id}
              name={item.product.name}
              subname={item.productOption?.testProductOptionFamily?.name}
              count={item.quantity}
              removeItemFromBasket={() =>
                removeProductFromBasket(item.product.id, item.productOption.id)
              }
              addItemToBasket={() => addProductToBasket(item.product.id, item.productOption.id)}
            />
          );
        } else {
          lineItems.push(
            <BasketItem
              {...getItemPrices(item)}
              key={item.id}
              name={item.product.name}
              subname={item.productOption?.testProductOptionFamily?.name}
              count={item.quantity}
              removeItemFromBasket={() => removeProductFromBasket(item.product.id)}
              addItemToBasket={() => addProductToBasket(item.product.id)}
            />
          );
        }
      });
    [...basket.supplementLineItems]
      .sort((a, b) => {
        let sortKeyA = a.supplement.name;
        let sortKeyB = b.supplement.name;

        if (sortKeyA < sortKeyB) {
          return -1;
        }
        if (sortKeyA > sortKeyB) {
          return 1;
        }
        return 0;
      })
      .forEach(item => {
        lineItems.push(
          <BasketItem
            key={item.id}
            name={item.supplement.name}
            price={item.fullPricePerUnit * item.quantity}
            count={item.quantity}
            removeItemFromBasket={() => removeSupplementFromBasket(item.supplement.id)}
            addItemToBasket={() => addSupplementToBasket(item.supplement.id)}
          />
        );
      });
  }

  if (lineItems.length === 0) {
    return (
      <Stack gap={[20, 20, 40]}>
        <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
          Basket summary
        </Box>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Basket empty - add products to continue
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Basket summary
      </Box>
      <Spacer py={[2, 2, 20]} />
      <Stack gap={[10, 10, 20]}>{lineItems}</Stack>
      <Spacer py={[2, 2, 20]} />
      <Box>
        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
          Discount
        </Box>
        <Spacer py={[2, 2, 15]} />
        <Box>
          <FloatingLabelInput
            label="Enter Discount"
            onChange={e => setDiscount(e.target.value)}
            rightIcon={
              <ButtonV2
                sx={{
                  color: "green",
                  fontSize: 18,
                  textTransform: "unset",
                  letterSpacing: "unset",
                  fontFamily: "gilroyMedium",
                  p: 0
                }}
              >
                Apply
              </ButtonV2>
            }
            value={discount}
          />
        </Box>
      </Box>
      <Spacer py={[2, 2, 20]} />
      <Stack alignItems="flex-end" gap={0}>
        {onboardingComplete && (
          <>
            <Group alignItems="center" gap={20} flexWrap="wrap" justifyContent="flex-end">
              <Box fontFamily="gilroyBold" fontSize={16}>
                Who will pay?
              </Box>
              <SegmentedControl
                data={[
                  {
                    label: "patient",
                    value: PAYEE_PATIENT
                  },
                  {
                    label: "business",
                    value: PAYEE_ORGANISATION
                  }
                ]}
                onChange={payee => updateBasketDetails({ payee })}
                value={basket?.payee}
                height={40}
                bg="haze"
              />
            </Group>
            <Spacer py={2} />
          </>
        )}
        {basket?.payee === PAYEE_PATIENT && (
          <PatientPayeeOptions
            basket={basket}
            onBasketUpdate={fields => updateBasketDetails({ ...fields, payee: PAYEE_PATIENT })}
          />
        )}
        {basket?.payee === PAYEE_ORGANISATION && (
          <OrganisationPayeeOptions
            basket={basket}
            onboardingComplete={onboardingComplete}
            onBasketUpdate={fields => updateBasketDetails({ ...fields, payee: PAYEE_ORGANISATION })}
          />
        )}
        <Spacer py={15} />
        <BasketTotals basket={basket} payee={basket?.payee} showShipping={props.showShipping} />
        {props.showCheckoutButton && (
          <Group alignItems="center" gap={20} flexWrap="wrap" justifyContent="flex-end">
            <ButtonV2
              mt={6}
              mb={0}
              onClick={() => {
                setBasketOpen(false);
              }}
              color="black"
              rightIcon={<ChevronComponent />}
              sx={{
                gridColumnStart: ["2", "3"],
                gridColumnEnd: ["3", "4"]
              }}
            >
              Continue Shopping
            </ButtonV2>
            <ButtonV2
              mt={6}
              mb={0}
              onClick={() => {
                history.push(PARTNERS_CHECKOUT_URL);
                setBasketOpen(false);
              }}
              color="green"
              rightIcon={<ChevronComponent />}
              sx={{
                gridColumnStart: ["2", "3"],
                gridColumnEnd: ["3", "4"]
              }}
            >
              Proceed to Checkout
            </ButtonV2>
          </Group>
        )}
      </Stack>
    </>
  );
});
