import { useEffect } from "react";

import { useDataLoader } from "../useDataLoader";

const usePollingRefresh = (PollQuery, shouldRefetch, refetch, on = true, pollInterval = 2000) => {
  const { data, stopPolling } = useDataLoader({
    query: PollQuery,
    pollInterval,
    skip: !on
  });

  const weShouldRefetch = shouldRefetch(data);

  useEffect(() => {
    if (on && weShouldRefetch) {
      stopPolling();
      refetch && refetch();
    }
  }, [on, weShouldRefetch, stopPolling, refetch]);

  return data;
};

export default usePollingRefresh;
