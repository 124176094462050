import { PRACTITIONER } from "core/constants";
import { CREATE_PARTNER_USER_PROFILE_MUTATION } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";

import Common from "./CommonForm";

export default function LoggedInForm({ setFormSubmitted }) {
  const api = useDjangoGraphqlForm({
    transformer: input => ({
      ...input,
      professionalBodies: input.professionalBodies.join(","),
      documents: JSON.stringify(
        input.documents.reduce(
          (acc, doc) => ({
            ...acc,
            [doc.type]: {
              key: doc.key,
              name: doc.name
            }
          }),
          {}
        )
      )
    }),
    mutation: CREATE_PARTNER_USER_PROFILE_MUTATION,
    mutationName: "createPartnerUserProfileMutation",
    defaultValues: {
      tier: PRACTITIONER,
      practitionerType: "",
      professionalBodies: [],
      documents: [],
      businessName: "",
      phoneNumber: "",
      jobRole: "",
      businessLocationCity: "",
      sources: "",
      sourcesOther: "",
      instagram: "",
      linkedin: "",
      youtube: "",
      websiteBlog: "",
      partnerUpdatesConsent: false,
      acceptTerms: false
    },
    handleSuccess: () => {
      setFormSubmitted(true);
    }
  });

  return <Common api={api} />;
}
