import { useHistory } from "react-router-dom";

import Box from "components/Box";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import { CollapseableText } from "tpo/CollapseableText";
import SegmentedControl from "tpo/SegmentedControl";
import Spacer from "tpo/Spacer";

export default function ListPageTemplate({ bg, header, tab, title, subtitle, children, urlMap }) {
  const history = useHistory();

  return (
    <>
      {header}
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: [60, 60, 80],
          pb: 60,
          px: [20, 20, "5.5vw"],
          bg
        }}
        inner={{
          px: 20
        }}
      >
        <Center>
          <SegmentedControl
            data={[
              {
                label: "tests",
                value: "tests"
              },
              {
                label: "supplements",
                value: "supplements"
              }
            ]}
            onChange={v => history.push(urlMap[v])}
            value={tab}
            height={55}
            bg={bg === "white" ? "haze" : "white"}
          />
        </Center>
        <Spacer py={[20]} />
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          {title}
        </Box>
        <Spacer py={[2, 2, 15]} />
        <CollapseableText fontSize={[14, 14, 16]}>{subtitle}</CollapseableText>
      </PanelBoxV2>
      {children}
    </>
  );
}
