import gql from "graphql-tag";

import { ORGANISATION_BASKET_FIELDS, ORGANISATION_FIELDS } from "../shared";

export const ORGANISATION_LIST_QUERY = gql`
  query OrganisationListQuery {
    organisations {
      ...OrganisationFields
    }
  }
  ${ORGANISATION_FIELDS}
`;

export const ORGANISATION_BASKET_DETAIL_QUERY = gql`
  query OrganisationBasketQuery($organisation: Int!) {
    basket(organisation: $organisation) {
      ...OrganisationBasketFields
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const ORGANISATION_ROLES_QUERY = gql`
  query OrganisationRolesQuery {
    organisationRoles {
      id
      name
      permissions
    }
  }
`;
