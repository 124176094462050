export function getAPIBase() {
  return process.env.REACT_APP_API_URL || "http://backend.omnos.local:8000";
}

export function getStripePublishableKey() {
  if (process.env.REACT_APP_ENV === "production") {
    return "pk_live_ygEDoQpl4LjqpeOwkg0esViy009826XNSe";
  } else {
    return "pk_test_tUiZWf5n0ZaDjidgIiuBxNcr00sdCaUnN5";
  }
}
