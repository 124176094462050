import Box from "../components/Box";

function Overlay({ children, ...rest }) {
  return (
    <Box position="absolute" top={0} left={0} right={0} bottom={0} {...rest}>
      {children}
    </Box>
  );
}

export default Overlay;
