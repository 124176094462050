import { useState } from "react";

import Box from "components/Box";
import CloseButton from "components/CloseButton";
import useQueryParams from "hooks/useQueryParams";
import FilterIcon from "images/Filter";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import { startCase } from "lodash";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import Drawer from "tpo/Drawer";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2, { ActionIcon } from "v2/Buttons";

export default function useProductFilters({
  defaultFilters,
  allFilters,
  filtersTitle,
  filterSelected,
  drawerBg,
  color = "white"
}) {
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);

  const { removeFilter, validatedFilters, resetFilters, toggleFilter } = useQueryParams({
    DEFAULT_FILTERS: defaultFilters,
    ALL_FILTERS: allFilters
  });

  const menu = (
    <>
      <ButtonV2
        bg="dark"
        onClick={() => setShowFiltersDrawer(!showFiltersDrawer)}
        size={55}
        leftIcon={<FilterIcon fill="white" />}
        gap={10}
        variant="pill"
        sx={{
          fontSize: 16,
          textTransform: "unset",
          letterSpacing: null
        }}
      >
        Show filters
      </ButtonV2>
      <Drawer.Provider
        position="top"
        opened={showFiltersDrawer}
        close={() => setShowFiltersDrawer(false)}
      >
        <Drawer.Root bg={drawerBg} overflow="auto">
          <PanelBoxV2
            maxWidth={1538}
            outer={{
              px: [20, 20, "5.5vw"],
              pt: 40,
              pb: [60, 60, 120]
            }}
          >
            <Group justifyContent="flex-end" py={[40, 40, 20]}>
              <CloseButton
                height="40px"
                width="40px"
                color={color}
                onClick={() => setShowFiltersDrawer(false)}
                bg={drawerBg}
              />
            </Group>
            <Stack flexDirection={["column", "row"]} gap={[20, 20, 40]}>
              <Group alignItems="center" gap={10}>
                <ActionIcon p={15}>
                  <FilterIcon fill={color} />
                </ActionIcon>
                <Box as="h3" fontSize={36} fontFamily="gilroyBold" lineHeight="130%" color={color}>
                  {filtersTitle}
                </Box>
              </Group>
              <ButtonV2
                color="green"
                rightIcon={<ChevronComponent />}
                mr={["auto"]}
                onClick={() => setShowFiltersDrawer(false)}
              >
                save
              </ButtonV2>
            </Stack>
            <Spacer py={[2, 2, 20]} />
            <Stack gap={[40, 40, 80]}>
              {Object.entries(allFilters).map(([k, values]) => (
                <Stack key={k} gap={[20, 20, 40]}>
                  <Box
                    fontFamily="gilroyBold"
                    fontSize={14}
                    letterSpacing="2.8px"
                    textTransform="uppercase"
                    color={color}
                  >
                    {startCase(k)}
                  </Box>
                  <Group flexWrap="wrap" gap={[10, 10, 20]}>
                    {values.map(val => (
                      <ButtonV2
                        leftIcon={
                          validatedFilters[k].includes(val) ? (
                            <CrossIcon fill="white" width={10} />
                          ) : null
                        }
                        color={validatedFilters[k].includes(val) ? "green" : color}
                        variant={validatedFilters[k].includes(val) ? "filled" : "outline"}
                        py={[2, 2, 16]}
                        px={[16, 16, 20]}
                        fontSize={[10, 10, 12]}
                        borderRadius="100px"
                        key={val}
                        borderWidth="2px"
                        gap={10}
                        onClick={() => {
                          toggleFilter(k, val);
                        }}
                        height={[27, 27, 40]}
                      >
                        {val}
                      </ButtonV2>
                    ))}
                  </Group>
                </Stack>
              ))}
            </Stack>
          </PanelBoxV2>
        </Drawer.Root>
      </Drawer.Provider>
    </>
  );

  const hasSelectedFilters = Object.entries(validatedFilters).some(([groupName, values]) => {
    const filteredValues =
      filterSelected && groupName in filterSelected
        ? values.filter(val => filterSelected[groupName].includes(val))
        : values;
    return filteredValues.length;
  });

  const selectedFilters = hasSelectedFilters ? (
    <Group alignItems="center" flexWrap="wrap" gap={10}>
      <Group flexWrap="wrap" gap={10}>
        {Object.entries(validatedFilters).map(([groupName, values]) => {
          return values
            .filter(val => {
              if (filterSelected && groupName in filterSelected) {
                return filterSelected[groupName].includes(val);
              }
              return true;
            })
            .map(val => (
              <ButtonV2
                leftIcon={<CrossIcon fill="white" width={10} />}
                color="green"
                key={`${groupName}:${val}`}
                py={16}
                px={20}
                borderRadius="100px"
                onClick={() => removeFilter(groupName, val)}
                gap={10}
                fontSize={12}
                height={40}
              >
                {val}
              </ButtonV2>
            ));
        })}
      </Group>
      {hasSelectedFilters && (
        <ButtonV2
          onClick={() => resetFilters()}
          variant="transparent"
          sx={{
            letterSpacing: null,
            fontSize: 16,
            textTransform: "unset",
            fontFamily: "gilroyMedium",
            textDecoration: "underline",
            p: 0
          }}
        >
          Clear all filters
        </ButtonV2>
      )}
    </Group>
  ) : null;

  return {
    menu,
    selectedFilters,
    validatedFilters
  };
}
