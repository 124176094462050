import Box from "components/Box";
import { useBasketContext } from "contexts/BasketContext";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import { ThermostatButton } from "v2/Buttons";

function BasketItemUI({
  count,
  name,
  subname,
  price,
  previousPrice,
  rrpPrice,
  addItemToBasket,
  removeItemFromBasket
}) {
  const { isSubmitting } = useBasketContext();

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      alignItems={["unset", "unset", "center"]}
      gap={10}
      data-component-name="BasketItemUI"
    >
      <ThermostatButton
        onDecrease={removeItemFromBasket}
        onIncrease={addItemToBasket}
        value={count}
        disabled={isSubmitting}
        sx={{
          mr: "auto"
        }}
      />
      <Group alignItems="flex-start" gap={10} justifyContent="center" flexGrow={1}>
        <Group
          alignItems="flex-start"
          justifyContent="space-between"
          flexGrow={1}
          flexDirection="column"
          m="auto"
        >
          <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} data-testid="basket-item-name">
            {name}
          </Box>
          {subname && (
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              color="grey"
              data-testid="basket-item-subname"
            >
              {subname}
            </Box>
          )}
        </Group>
        <Group
          alignItems="flex-end"
          justifyContent="space-between"
          flexGrow={1}
          flexDirection="column"
          style={{ minWidth: "80px" }}
        >
          <Group alignItems="flex-start" gap={4} justifyContent="space-between" flexGrow={1} my={1}>
            {previousPrice && previousPrice > price && (
              <Currency
                fontSize={28}
                value={previousPrice}
                color="black"
                strikethrough
                data-testid="basket-item-previous-price"
              />
            )}
            <Currency
              fontSize={28}
              value={price}
              color={previousPrice && previousPrice > price ? "red" : "black"}
              data-testid="basket-item-price"
            />
          </Group>
          {rrpPrice && (
            <Currency
              fontSize={18}
              value={rrpPrice}
              prefix="RRP"
              color="midGrey"
              strikethrough
              data-testid="basket-item-rrpprice"
            />
          )}
        </Group>
      </Group>
    </Box>
  );
}

export function BasketItem({
  count,
  name,
  subname,
  price,
  previousPrice,
  rrpPrice,
  clinicName,
  address,
  fees,
  addItemToBasket,
  removeItemFromBasket
}) {
  if (clinicName) {
    return (
      <Stack gap={20}>
        <BasketItemUI
          count={count}
          name={name}
          subname={subname}
          price={price}
          previousPrice={previousPrice}
          addItemToBasket={addItemToBasket}
          removeItemFromBasket={removeItemFromBasket}
        />
        <Box>
          <Box fontFamily="gilroyBold" fontSize={16}>
            {clinicName}
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={14}>
            {address}
          </Box>
          <Group alignItems="center" justifyContent="space-between">
            <Box fontFamily="gilroyBold" fontSize={16}>
              Clinic fee
            </Box>
            <Currency fontSize={18} value={fees} />
          </Group>
        </Box>
      </Stack>
    );
  } else {
    return (
      <BasketItemUI
        count={count}
        name={name}
        subname={subname}
        price={price}
        rrpPrice={rrpPrice}
        previousPrice={previousPrice}
        addItemToBasket={addItemToBasket}
        removeItemFromBasket={removeItemFromBasket}
      />
    );
  }
}
