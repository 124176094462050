import { useEffect } from "react";

import { useMutation } from "@apollo/client";
import { InternalTextLink } from "components/Links";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import RefetchEvent from "events/refetch";
import { CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION } from "graphql/organisations/mutations";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import { ORGANISATION_ORDERS_LIST_QUERY } from "graphql/organisations/queries/orders";
import { ORGANISATION_BASKET_DETAIL_QUERY } from "graphql/organisations/queries/organisation";
import { ORGANISATION_STOCK_LIST_QUERY } from "graphql/organisations/queries/stock";
import Spacer from "tpo/Spacer";

import AcknowledgeAndSubmitPanel from "../ui/AcknowledgeAndSubmitPanel";

export default function PayOrderNowForm({ onSubmit, canSubmit }) {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  const [createPaymentCheckoutSessionMutation, { data, loading, error }] = useMutation(
    CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION,
    {
      variables: {
        input: {
          basket: basket.id,
          organisation: organisation.id
        }
      },
      refetchQueries: [
        {
          query: ORGANISATION_BASKET_DETAIL_QUERY,
          variables: {
            organisation: parseInt(organisation?.id)
          }
        }
      ]
    }
  );

  useEffect(() => {
    if (data?.createPaymentCheckoutSessionMutation?.paymentCheckoutSession?.url) {
      RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
      window.location.href = data.createPaymentCheckoutSessionMutation.paymentCheckoutSession.url;
    }
  }, [data]);

  return (
    <AcknowledgeAndSubmitPanel
      title="Payment Details"
      acknowledgementRequired={false}
      onSubmit={() => {
        createPaymentCheckoutSessionMutation();
        onSubmit && onSubmit();
      }}
      canSubmit={canSubmit}
      loading={loading}
    >
      <p>
        You still need to{" "}
        <InternalTextLink href="/partners/dashboard/business">
          link your business bank account
        </InternalTextLink>{" "}
        to enable invoice payments.
      </p>
      <Spacer py={2} />
      <p>Please pay now to place this order.</p>
    </AcknowledgeAndSubmitPanel>
  );
}
