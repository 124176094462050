import { useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import StripeCalloutBanner from "components/organisations/business/ui/StripeCalloutBanner";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import Danger from "images/Danger";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function LinkYourBankAccount() {
  const { organisation } = useOrganisationContext();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  return (
    <Box px={[40]} pb={80}>
      <Stack
        alignItems={["flex-start", "flex-start", "center"]}
        maxWidth={760}
        stacked
        mx="auto"
        gap={[20, 20, 40]}
      >
        <Group alignItems="center" gap={[10, 10, 20]}>
          <Danger size={[22, 22, 40]} />
          <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 44]}>
            Link your bank account
          </Box>
        </Group>
        <Box
          fontFamily="gilroyMedium"
          fontSize={[14, 14, 16]}
          textAlign={["left", "left", "center"]}
        >
          Before you can add clients or receive commissions you’ll need to link a bank account for
          payment. Once completed you will automatically receive commission payouts to your bank
          account on a monthly basis as well as be able to order stock tests.
        </Box>
        <Box>
          <Box fontFamily="gilroyBold" fontSize={18}>
            Payment provider
          </Box>
          <Spacer py={[2, 2, 30 / 2]} />
          <StripeCalloutBanner fontSize={[14, 14, 16]} mr={[30, 30, 15]} />
        </Box>
        <ExternalTextLink
          href={organisation?.onboardingUrl}
          target="_self"
          rel="noopener noreferrer"
        >
          <ButtonV2
            color="green"
            rightIcon={<ChevronComponent />}
            mx="auto"
            onClick={() => setButtonDisabled(true)}
            disabled={buttonDisabled}
          >
            complete set up
          </ButtonV2>
        </ExternalTextLink>
      </Stack>
    </Box>
  );
}
