import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import { PRACTITIONER } from "core/constants";
import { ERROR_404_URL, getPartnerShopTabUrl } from "core/urls";

import SupplementDetailPage from "./SupplementDetailPage";
import SupplementListPage from "./SupplementListPage";
import TestProductDetailPage from "./TestProductDetailPage";
import TestProductListPage from "./TestProductListPage";

function RedirectPractitioners({ children, tab }) {
  const { userInfo } = useAppState();

  if (userInfo?.user?.partnerUserProfile?.tier === PRACTITIONER) {
    return <Redirect to={getPartnerShopTabUrl(tab)} />;
  }

  return children;
}

export default function Routes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={`${match.path}/tests`} />
      </Route>
      <Route path={`${match.path}/supplements`} exact>
        <RedirectPractitioners tab="supplements">
          <SupplementListPage />
        </RedirectPractitioners>
      </Route>
      <Route path={`${match.path}/supplements/:slug`} exact>
        <RedirectPractitioners tab="supplements">
          <SupplementDetailPage />
        </RedirectPractitioners>
      </Route>
      <Route path={`${match.path}/tests`} exact>
        <RedirectPractitioners tab="tests">
          <TestProductListPage />
        </RedirectPractitioners>
      </Route>
      <Route path={`${match.path}/tests/:slug`} exact>
        <RedirectPractitioners tab="tests">
          <TestProductDetailPage />
        </RedirectPractitioners>
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
