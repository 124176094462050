import Box from "components/Box";
import Page from "components/Page";
import Jumbotron from "tpo/Jumbotron";
import Stack from "tpo/Stack";

export default function OrderDetailTemplate({ title, children, footer, gap }) {
  return (
    <Page bg="haze">
      <Jumbotron title={title} />
      <Box pt={[30, 30, 60]} pb={[50, 50, 80]} px={20}>
        <Box
          bg="white"
          borderRadius={5}
          maxWidth={1020}
          mx="auto"
          pt={[30, 30, 60]}
          pb={[50, 50, 80]}
          px={[20, 20, 40]}
        >
          <Stack gap={gap} maxWidth={760} mx="auto">
            {children}
          </Stack>
        </Box>
      </Box>
      {footer}
    </Page>
  );
}

OrderDetailTemplate.defaultProps = {
  gap: [20, 20, 40]
};
