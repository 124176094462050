import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";

import CreateOrderInvoiceForm from "../forms/CreateOrderInvoiceForm";
import PayOrderLaterForm from "../forms/PayOrderLaterForm";
import PayOrderNowForm from "../forms/PayOrderNowForm";

export default function FinaliseBasketPanel({ canSubmit = false }) {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  let PaymentForm = PayOrderNowForm;

  if (basket?.payee === "organisation") {
    if (organisation?.chargesEnabled) {
      PaymentForm = CreateOrderInvoiceForm;
    }
  } else {
    PaymentForm = PayOrderLaterForm;
  }

  return <PaymentForm canSubmit={canSubmit} />;
}
