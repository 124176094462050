import React from "react";

import { theme } from "../core/theme";
import Box from "./Box";
import DashboardHeader from "./DashboardHeader";
import Page from "./Page";

function BasicPage({ children, heading }) {
  return (
    <Page header={<DashboardHeader>{heading}</DashboardHeader>}>
      <Box px={20} py={theme.spacing.large}>
        <Box maxWidth={800} mx="auto">
          {children}
        </Box>
      </Box>
    </Page>
  );
}

export default BasicPage;
