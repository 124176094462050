import Autocomplete from "./Autocomplete";

const formatPatient = patient => {
  if (!patient) {
    return null;
  }
  let user = patient.platformUserProfile.user;
  return `${user.firstName} ${user.lastName} (${user.email})`;
};

export default function SelectPatient({ patients, value, onChange }) {
  const patientsById = (patients || []).reduce(
    (acc, patient) => ({
      ...acc,
      [patient.id]: patient
    }),
    {}
  );

  // TODO:
  // What if there are no patients?
  // Should we show a message?

  return (
    Object.keys(patients).length > 0 && (
      <Autocomplete
        options={Object.keys(patientsById).map(id => ({
          label: formatPatient(patientsById[id]),
          value: id
        }))}
        placeholder="Select patient"
        value={value}
        onChange={onChange}
        maxWidth={310}
      />
    )
  );
}
