import OrganisationDashboard from "components/organisations/OrganisationDashboard";
import { useUserProfileContext } from "contexts/UserProfileContext";

import AffiliateDashboard from "./affiliate";

function PartnerDashboard() {
  const { isPartnerUser, partnerUserProfile } = useUserProfileContext();
  if (!isPartnerUser) {
    return null;
  }

  if (partnerUserProfile.tier === "Practitioner") {
    return <OrganisationDashboard />;
  } else if (partnerUserProfile.tier === "Affiliate") {
    return <AffiliateDashboard />;
  }
  return null;
}

export default PartnerDashboard;
