import { useState } from "react";

import Box from "components/Box";
import Grid from "components/Grid";
import Center from "tpo/Center";
import SegmentedControl from "tpo/SegmentedControl";
import Spacer from "tpo/Spacer";

export default function BiomarkersAndSymptomsList({ biomarkersTested, relatedSymptoms }) {
  const [biomarkerSymptomsSwitch, setBiomarkerSymptomsSwitch] = useState("biomarkers");

  const biomarkersTestedElement = (
    <>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Tested Biomarkers
      </Box>
      <Spacer py={[20]} />
      <Grid
        as="ul"
        gridColumnGap={40}
        gridTemplateColumns={["auto", "auto", "auto auto auto"]}
        style={{
          listStylePosition: "inside",
          listStyleType: "disc",
          textIndent: "-13px",
          paddingLeft: "13px"
        }}
      >
        {biomarkersTested.map(bm => (
          <Box as="li" key={bm}>
            {bm}
          </Box>
        ))}
      </Grid>
    </>
  );

  const relatedSymptomsElement = (
    <>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Symptoms
      </Box>
      <Spacer py={[20]} />
      <Grid
        as="ul"
        gridColumnGap={40}
        gridTemplateColumns={["auto", "auto", "auto auto auto"]}
        style={{
          listStylePosition: "inside",
          listStyleType: "disc",
          textIndent: "-13px",
          paddingLeft: "13px"
        }}
      >
        {relatedSymptoms.map(rs => (
          <Box as="li" key={rs}>
            {rs}
          </Box>
        ))}
      </Grid>
    </>
  );

  if (biomarkersTested?.length && relatedSymptoms?.length) {
    return (
      <>
        <Center>
          <SegmentedControl
            data={[
              {
                label: "biomarkers",
                value: "biomarkers"
              },
              {
                label: "symptoms",
                value: "symptoms"
              }
            ]}
            onChange={setBiomarkerSymptomsSwitch}
            value={biomarkerSymptomsSwitch}
            height={40}
          />
        </Center>
        <Spacer py={[15, 15, 30]} />
        {biomarkerSymptomsSwitch === "biomarkers"
          ? biomarkersTestedElement
          : relatedSymptomsElement}
      </>
    );
  } else if (biomarkersTested?.length) {
    return biomarkersTestedElement;
  } else if (relatedSymptoms?.length) {
    return relatedSymptomsElement;
  }

  return null;
}
