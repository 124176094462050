import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { GENERIC_USER_PROFILE_QUERY } from "graphql/accounts";

export default function useUserProfile() {
  const [isPlatformUser, setIsPlatformUser] = useState(undefined);
  const [isPartnerUser, setIsPartnerUser] = useState(undefined);

  const { data, loading } = useQuery(GENERIC_USER_PROFILE_QUERY);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data) {
      setIsPlatformUser(!!data?.platformUserProfile?.id);
      setIsPartnerUser(!!data?.partnerUserProfile?.id && data?.partnerUserProfile?.approved);
    }
  }, [data, loading, setIsPlatformUser, setIsPartnerUser]);

  return {
    loading,
    isPlatformUser,
    isPartnerUser,
    platformUserProfile: data?.platformUserProfile,
    partnerUserProfile: data?.partnerUserProfile
  };
}
