import { forwardRef } from "react";

import { useQuery } from "@apollo/client";
import { SHIPPING_COUNTRIES_QUERY } from "graphql/shop";

import NativeSelect from "./NativeSelect";

const ShippingCountrySelect = forwardRef(
  ({ onChange, value, error, label, name, getOptions }, ref) => {
    const { data, loading } = useQuery(SHIPPING_COUNTRIES_QUERY);

    if (loading) {
      return (
        <NativeSelect
          name={name}
          ref={ref}
          onChange={onChange}
          value={value}
          error={error}
          label={label}
        />
      );
    }

    let options;
    if (getOptions) {
      options = getOptions(data?.shippingCountries);
    } else {
      const shippingCountries = data?.shippingCountries.map(item => ({
        value: item.name,
        label: item.name
      }));

      const ukOnly = shippingCountries.filter(item => item.value === "United Kingdom");
      const rest = shippingCountries.filter(item => item.value !== "United Kingdom");

      options = [...ukOnly, ...rest.sort(({ value: v1 }, { value: v2 }) => v1.localeCompare(v2))];
    }

    return (
      <NativeSelect
        name={name}
        ref={ref}
        onChange={onChange}
        value={value}
        error={error}
        label={label}
      >
        <option value="">Select Country</option>
        {options?.map((item, idx) => (
          <option key={`${item.value}-${idx}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </NativeSelect>
    );
  }
);

export default ShippingCountrySelect;
