import { useContext } from "react";

import { useParams } from "react-router-dom";

import { gql } from "@apollo/client";

import Box from "../components/Box";
import CenteredHeader from "../components/CenteredHeader";
import DataLoader from "../components/DataLoader";
import ImageAndIconHeader from "../components/ImageAndIconHeader";
import IntroBlock from "../components/IntroBlock";
import { ListBox } from "../components/List";
import Page from "../components/Page";
import Text from "../components/Text";
import { FITNESS, LIFESTYLE, NUTRITION } from "../core/constants";
import { SpacedContentBox } from "../deprecated/Boxes";
import useDocTitle from "../hooks/use-doc-title";
import { HeadingExtraLarge } from "./Headings";
import RecommendationIcon from "./RecommendationIcon";
import RecommendationWhy from "./RecommendationWhy";
import { ViewerContext } from "./Viewer";

function getBackgroundColor(category) {
  if (category === NUTRITION) {
    return "nutrition";
  } else if (category === LIFESTYLE) {
    return "lifestyle";
  } else if (category === FITNESS) {
    return "fitness";
  } else {
    console.error("Unrecognised background color");
    return "white";
  }
}

export const RECOMMENDATION_QUERY = gql`
  query RecommendationQuery($recommendationId: ID, $userId: ID) {
    recommendation(recommendationId: $recommendationId, userId: $userId) {
      name
      category
      verb
      id
      description
      examples {
        name
      }
      relatedUserResults(userId: $userId) {
        id
        result {
          name
          id
        }
      }
    }
  }
`;

export function RecommendationHeader({ name }) {
  return (
    <SpacedContentBox maxWidth={1040} mb={[20, 20, 80]} my={[20, 20, 60]} px={20}>
      <Box px={20}>
        <HeadingExtraLarge fontSize={[24, 34, 34, 44]}>{name}</HeadingExtraLarge>
      </Box>
    </SpacedContentBox>
  );
}

function RecommendationPageContent({ recommendation }) {
  useDocTitle(recommendation.name);

  const hasExamples = !!recommendation?.examples.length;

  return (
    <Page backgroundColor="haze">
      <Box maxWidth={1200} mx="auto" width="100%">
        <ImageAndIconHeader
          backgroundColor="white"
          icon={
            <RecommendationIcon category={recommendation.category} width="100%" height="100%" />
          }
          heading={recommendation.category}
        />
        <IntroBlock
          heading={recommendation.name}
          text={recommendation.description}
          backgroundColor={getBackgroundColor(recommendation.category)}
        />
        {hasExamples && (
          <ListBox
            heading="Examples"
            items={recommendation.examples.map(example => {
              return example.name;
            })}
          />
        )}
      </Box>
      {!!recommendation.relatedUserResults?.length && (
        <RecommendationWhy
          backgroundColor={hasExamples ? "white" : "haze"}
          relatedUserResults={recommendation.relatedUserResults}
        />
      )}
    </Page>
  );
}

export default function RecommendationPage() {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;
  const { recommendationId } = useParams();

  return (
    <DataLoader
      query={RECOMMENDATION_QUERY}
      variables={{ recommendationId, userId: clientId }}
      render={({ recommendation }) => {
        if (recommendation) {
          return <RecommendationPageContent recommendation={recommendation} />;
        } else {
          return (
            <Page
              backgroundColor="haze"
              header={<CenteredHeader heading="Recommendation" backgroundColor="haze" />}
            >
              <Box px={20}>
                <Text textAlign="center">I'm sorry this recommendation cannot be found</Text>
              </Box>
            </Page>
          );
        }
      }}
    />
  );
}
