import { useAppState } from "components/AppStateProvider";
import { AuthContext } from "components/AuthProvider";
import Box from "components/Box";
import Loading from "components/Loading";
import { OrganisationBasketListing } from "components/organisations/checkout/OrganisationBasketListing";
import { BasketContext, useBasketContext } from "contexts/BasketContext";
import { LOGIN_URL, getOrderUrl } from "core/urls";
import usePartnerPageCheck from "hooks/partner/usePartnerPageCheck";
import Center from "tpo/Center";
import Currency from "tpo/Currency";
import { Error } from "tpo/InputWrapper";
import LinkWrapper from "tpo/LinkWrapper";
import ButtonV2, { ThermostatButton } from "v2/Buttons";

import FloatingLabelInput from "../FloatingLabelInput";
import Group from "../Group";
import Modal from "../Modal";
import Spacer from "../Spacer";
import Stack from "../Stack";
import getClinicFees from "./utils/getClinicFees";
import groupItemsByName from "./utils/groupItemByName";

const PAYEE_PATIENT = "patient";

function BasketItem({
  count,
  name,
  price,
  previousPrice,
  addItemToBasket,
  removeItemFromBasket,
  "data-testid": dataTestId
}) {
  const { isSubmitting } = useBasketContext();

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      alignItems={["unset", "unset", "center"]}
      gap={10}
      data-component-name="BasketItem"
      data-testid={dataTestId || "lineInBasket"}
    >
      <ThermostatButton
        onDecrease={removeItemFromBasket}
        onIncrease={addItemToBasket}
        value={count}
        disabled={isSubmitting}
        sx={{
          mr: "auto"
        }}
      />
      <Group alignItems="flex-start" gap={10} justifyContent="space-between" flexGrow={1}>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} mr="auto" data-testid="name">
          {name}
        </Box>
        {previousPrice && previousPrice > price && (
          <Currency
            fontSize={28}
            value={previousPrice}
            color="#e44c4b"
            strikethrough
            data-testid="previousPrice"
          />
        )}
        <Currency fontSize={28} value={price} data-testid="currentPrice" />
      </Group>
    </Box>
  );
}

function BasketItemWithClinic({
  count,
  name,
  price,
  previousPrice,
  clinicName,
  address,
  fees,
  addItemToBasket,
  removeItemFromBasket
}) {
  return (
    <Stack gap={20} data-component-name="BasketItemWithClinic" data-testid="lineInBasket">
      <BasketItem
        count={count}
        name={name}
        price={price}
        previousPrice={previousPrice}
        addItemToBasket={addItemToBasket}
        removeItemFromBasket={removeItemFromBasket}
        data-testid="BasketItem"
      />
      <Box>
        <Box fontFamily="gilroyBold" fontSize={16} data-testid="clinicName">
          {clinicName}
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={14} data-testid="clinicAddress">
          {address}
        </Box>
        <Group alignItems="center" justifyContent="space-between" data-testid="clinicFee">
          <Box fontFamily="gilroyBold" fontSize={16}>
            Clinic fee
          </Box>
          <Currency fontSize={18} value={fees} />
        </Group>
      </Box>
    </Stack>
  );
}

// Whereas this assumes only one clinic location
export function getPrice(lineSummary) {
  let count = lineSummary.count;
  let linePrice = lineSummary.linePrice;
  return (linePrice * count).toFixed(2);
}

function Discount() {
  const {
    openOrder,
    setDiscountCode,
    discountCode,
    discountCodeError,
    updateDiscount,
    removeDiscount
  } = useBasketContext();

  const hasError =
    (openOrder.discountMeetsRequirements && discountCodeError) ||
    !openOrder.discountMeetsRequirements;

  const discountIsApplied =
    openOrder.discountCode !== "" && openOrder.discountCode === discountCode.toLowerCase();

  return (
    <>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Discount
      </Box>
      <Spacer py={[2, 2, 15]} />
      <Box>
        <FloatingLabelInput
          name="discountCode"
          label="Enter Discount"
          onChange={e => setDiscountCode(e.target.value)}
          rightIcon={
            discountIsApplied ? (
              <ButtonV2
                // NB: successfully removing the discount will result in the local state being reset to ""
                onClick={removeDiscount}
                sx={{
                  color: "red",
                  fontSize: 18,
                  textTransform: "unset",
                  letterSpacing: "unset",
                  fontFamily: "gilroyMedium",
                  p: 0
                }}
                data-testid="removeDiscountCode"
              >
                Remove
              </ButtonV2>
            ) : (
              <ButtonV2
                onClick={updateDiscount}
                sx={{
                  color: "green",
                  fontSize: 18,
                  textTransform: "unset",
                  letterSpacing: "unset",
                  fontFamily: "gilroyMedium",
                  p: 0
                }}
                disabled={discountCode === ""}
                data-testid="applyDiscountCode"
              >
                Apply
              </ButtonV2>
            )
          }
          value={discountCode}
          editable={!discountIsApplied}
        />
      </Box>
      {hasError && (
        <Error
          data-testid="discountCodeError"
          error={
            openOrder.discountMeetsRequirements
              ? discountCodeError
              : "Basket does not meet discount requirements."
          }
        />
      )}
    </>
  );
}

export function UserBasketSummaryContent({ order, beforeTotals, afterTotals, footer }) {
  const { removeItemFromBasket, addItemToBasket, discountCode } = useBasketContext();

  const allOrderItems = [...order.testItems, ...order.supplementItems];
  const orderItemsByName = groupItemsByName(allOrderItems);

  return (
    <>
      <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Basket summary
      </Box>
      <Stack gap={[10, 10, 20]}>
        {Object.keys(orderItemsByName).map(orderItem => {
          const lineSummary = orderItemsByName[orderItem];
          let clinic = null;
          if (lineSummary.clinicBookings.length > 0) {
            clinic = lineSummary.clinicBookings[0].location;
          }
          return clinic ? (
            <BasketItemWithClinic
              key={orderItem}
              name={lineSummary.nameInBasket}
              price={getPrice(lineSummary)}
              previousPrice={
                lineSummary.consumerFullPrice === undefined
                  ? undefined
                  : lineSummary.consumerFullPrice * lineSummary["count"]
              }
              clinicName={clinic.name || clinic.clinic?.name || "Clinic"}
              address={clinic.address}
              fees={getClinicFees(lineSummary.clinicBookings)}
              count={lineSummary.count}
              removeItemFromBasket={() => {
                removeItemFromBasket({
                  clinicLocationId: clinic?.id,
                  compositeId: lineSummary.compositeId
                });
              }}
              addItemToBasket={() => {
                addItemToBasket({
                  clinicLocationId: clinic?.id,
                  compositeId: lineSummary.compositeId
                });
              }}
            />
          ) : (
            <BasketItem
              key={orderItem}
              name={lineSummary.nameInBasket}
              price={getPrice(lineSummary)}
              previousPrice={
                lineSummary.consumerFullPrice === undefined
                  ? undefined
                  : lineSummary.consumerFullPrice * lineSummary["count"]
              }
              count={lineSummary.count}
              removeItemFromBasket={() => {
                removeItemFromBasket({
                  clinicLocationId: clinic?.id,
                  compositeId: lineSummary.compositeId
                });
              }}
              addItemToBasket={() => {
                addItemToBasket({
                  clinicLocationId: clinic?.id,
                  compositeId: lineSummary.compositeId
                });
              }}
            />
          );
        })}
      </Stack>
      {discountCode !== undefined && (
        // i.e. wait for discount code state to be initialised
        <Box>
          <Discount />
        </Box>
      )}
      <Stack alignItems="flex-end" gap={20}>
        {beforeTotals}
        {afterTotals}
      </Stack>
      {footer}
    </>
  );
}

export function UserBasketSummary({ footer, beforeTotals, afterTotals }) {
  const { orderError, orderLoading, openOrder } = useBasketContext();

  if (orderError) return orderError;

  if (orderLoading)
    return (
      <Center>
        <Loading />
      </Center>
    );

  if (!openOrder || (openOrder.testItems.length === 0 && openOrder.supplementItems.length === 0))
    return (
      <>
        <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
          Basket summary
        </Box>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Basket empty - add products to continue
        </Box>
      </>
    );

  return (
    <UserBasketSummaryContent
      order={openOrder}
      footer={footer}
      beforeTotals={beforeTotals}
      afterTotals={afterTotals}
    />
  );
}

export function Totals({ basket, payee }) {
  return (
    <>
      <Currency
        prefix="RRP"
        value={basket?.basketRrpTotalPrice}
        strikethrough
        color="midGrey"
        fontSize={28}
      />
      <Spacer py={1} />
      <Currency
        prefix="BASKET"
        value={basket?.basketTotalPrice - basket?.basketShippingCost}
        fontSize={28}
      />
      <Spacer py={1} />
      <Currency prefix="SHIPPING" value={basket?.basketShippingCost} fontSize={28} />
      <Spacer py={1} />
      <Currency prefix="TOTAL" value={basket?.basketTotalPrice} color="dark" fontSize={44} />
      <Spacer py={1} />
      {payee === PAYEE_PATIENT && (
        <Currency
          prefix="COMMISSION"
          value={basket?.basketTotalCommission}
          color="dark"
          fontSize={28}
        />
      )}
    </>
  );
}

/**
 * This just looks at the url to check which basket to show: UserBasket or PartnerBasket.
 * Partner routes are protected to ensure only a partner has access.
 */

export default function Basket() {
  const { basketOpen, setBasketOpen } = useAppState();
  const isPartnerPage = usePartnerPageCheck();

  return (
    <Modal
      maxWidth={"min(calc(100% - 20px - 5.5vw), 1020px)"}
      closeButton
      headerProps={{
        pt: [30, 30, 60],
        px: [20, 20, 40],
        pb: 20
      }}
      bg="white"
      show={basketOpen}
      onClose={() => setBasketOpen(false)}
      close={() => setBasketOpen(false)}
      mode={[
        "fullScreen",
        "fullScreen",
        {
          topRightPosition: {
            x: [20, 20, "5.5vw"],
            y: 80
          }
        }
      ]}
      maxHeight="calc(100vh - 80px - 20px)"
      // 80px is height from top, 20px gap to bottom
      borderRadius={[0, 0, 5]}
      data-component-name={isPartnerPage ? "PractitionerBasket" : "ConsumerBasket"}
    >
      <Box maxWidth={760} mx="auto" pb={[60, 60, 120]} px={[20, 20, 40]}>
        {isPartnerPage ? (
          <OrganisationBasketListing showCheckoutButton={true} showShipping={false} />
        ) : (
          <Stack gap={[20, 20, 40]}>
            <UserBasketSummary
              afterTotals={
                <BasketContext.Consumer>
                  {({ openOrder }) =>
                    openOrder === undefined ? null : (
                      <Currency
                        prefix="TOTAL"
                        value={openOrder.discountedTotal.toFixed(2)}
                        fontSize={44}
                        data-testid="BasketTotal"
                      />
                    )
                  }
                </BasketContext.Consumer>
              }
              footer={
                <AuthContext.Consumer>
                  {({ user }) => {
                    return user ? (
                      <Box
                        display="flex"
                        flexDirection={["column", "column", "row"]}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        gap={20}
                      >
                        <ButtonV2 color="dark" onClick={() => setBasketOpen(false)}>
                          continue shopping
                        </ButtonV2>
                        <BasketContext.Consumer>
                          {({ openOrder }) => (
                            <ButtonV2
                              as={LinkWrapper}
                              color="green"
                              to={getOrderUrl(openOrder.id)}
                              onClick={() => setBasketOpen(false)}
                            >
                              proceed to checkout
                            </ButtonV2>
                          )}
                        </BasketContext.Consumer>
                      </Box>
                    ) : (
                      <Stack gap={20} pt={20}>
                        <Box>
                          <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
                            Choose checkout option
                          </Box>
                          <Spacer py={2} />
                          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                            We can see that you're not currently logged in. If you have an account,
                            please sign in to continue your purchase, otherwise you can checkout as
                            a guest now and create an account later
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection={["column", "column", "row"]}
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          gap={20}
                        >
                          <BasketContext.Consumer>
                            {({ openOrder }) => (
                              <ButtonV2
                                as={LinkWrapper}
                                color="dark"
                                to={getOrderUrl(openOrder.id)}
                                onClick={() => setBasketOpen(false)}
                              >
                                check out as guest
                              </ButtonV2>
                            )}
                          </BasketContext.Consumer>
                          <ButtonV2
                            as={LinkWrapper}
                            color="green"
                            to={LOGIN_URL}
                            onClick={() => setBasketOpen(false)}
                          >
                            sign in to your account
                          </ButtonV2>
                        </Box>
                      </Stack>
                    );
                  }}
                </AuthContext.Consumer>
              }
            />
          </Stack>
        )}
      </Box>
    </Modal>
  );
}
