import { round } from "lodash";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
}

export const TERMINOLOGY_TO_GRADIENT = {
  "low,below optimal,optimal,above optimal,high": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FF5D6D")],
      [18.87, hexToRgb("#FCCE57")],
      [50.2, hexToRgb("#00BF86")],
      [81.25, hexToRgb("#FFD558")],
      [100, hexToRgb("#FF5D6D")]
    ]
  },
  "optimal,above optimal,high": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#00BF86")],
      [51.04, hexToRgb("#FFD558")],
      [100, hexToRgb("#FF5D6D")]
    ]
  },
  "low,below optimal,optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FF5D6D")],
      [50, hexToRgb("#FCCE57")],
      [75, hexToRgb("#00BF86")]
    ]
  },
  "below optimal,optimal,above optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FCA557")],
      [25, hexToRgb("#FCCE57")],
      [50, hexToRgb("#00BF86")],
      [75, hexToRgb("#FCCE57")],
      [100, hexToRgb("#FCA557")]
    ]
  },
  "below optimal,optimal,above optimal,high": {
    angle: "90deg",
    gradient: [
      [25, hexToRgb("#FCCE57")],
      [40, hexToRgb("#00BF86")],
      [55, hexToRgb("#FCCE57")],
      [100, hexToRgb("#FF5D6D")]
    ]
  },
  "low,below optimal,optimal,above optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FF5D6D")],
      [35.85, hexToRgb("#FCCE57")],
      [63.92, hexToRgb("#00BF86")],
      [87.5, hexToRgb("#FFD558")]
    ]
  },
  "below optimal,optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FCA557")],
      [50, hexToRgb("#FCCE57")],
      [100, hexToRgb("#00BF86")]
    ]
  },
  "optimal,above optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#00BF86")],
      [51.04, hexToRgb("#FFD558")],
      [98.96, hexToRgb("#FCA557")]
    ]
  }
};

export function rgbArrayToString(rgbArr) {
  return `rgb(${rgbArr.join(",")})`;
}

export function getInterpretationColor(interpretation) {
  let color = "#818181";
  if (interpretation?.toLowerCase() === "optimal") {
    color = "#00BF86";
  } else if (
    interpretation?.toLowerCase() === "below optimal" ||
    interpretation?.toLowerCase() === "above optimal"
  ) {
    color = "#FCA557";
  } else if (interpretation?.toLowerCase() === "low" || interpretation?.toLowerCase() === "high") {
    color = "#FF5D6D";
  }
  return color;
}

export function getInterpretationColorFromChartConfiguration({
  interpretation,
  terminology,
  colours
}) {
  let interpretationIndex = terminology.indexOf(interpretation.toLowerCase());
  let intIdxAsFloat = interpretationIndex / (terminology.length - 1);
  let colourIndex = Math.ceil(intIdxAsFloat * (colours.length - 1));
  if (interpretationIndex === -1 || !colours[colourIndex]) {
    return "#818181";
  } else {
    return colours[colourIndex];
  }
}

export function getChartRanges({ range, normalisedRange, precision = 2, withLabels = false }) {
  const { minimum, maximum } = normalisedRange;

  const fullRange = normalisedRange.range.map((point, index) => ({
    value: precision ? round(point, precision) : point,
    label: withLabels ? round(range[index], 2) : undefined
  }));

  // if the first does not equal the minimum, add it
  if (fullRange[0].value !== minimum) {
    fullRange.unshift({
      value: minimum
    });
  }

  // if the last does not equal the maximum, add it
  if (fullRange[fullRange.length - 1].value !== maximum) {
    fullRange.push({
      value: maximum
    });
  }

  return {
    minimum,
    maximum,
    fullRange
  };
}

export function constructGradientFromTerms(terms, deg) {
  if (terms in TERMINOLOGY_TO_GRADIENT) {
    const bits = TERMINOLOGY_TO_GRADIENT[terms];
    const gradient = bits.gradient.map(
      ([percent, colour]) => `${rgbArrayToString(colour)} ${percent}%`
    );
    return `linear-gradient(${deg || bits.angle}, ${gradient.join(",")})`;
  }
  return null;
}

export function bindValueToSegment(value, segmentCount) {
  // handle binary datapoints like absent
  if (segmentCount === 1) {
    segmentCount = 2;
  }
  const halfSegmentHeight = 0.5 / segmentCount;
  const adjustedValue = Math.floor(value * segmentCount) / segmentCount - halfSegmentHeight;

  return Math.max(halfSegmentHeight, Math.min(1 - halfSegmentHeight, adjustedValue));
}

export function roundTimestampToDay(timestamp) {
  let date = new Date(timestamp);
  date.setUTCHours(0, 0, 0, 0);
  return date.getTime();
}

export function dueForRetestOrExpired(dueForRetest, dateExpired) {
  dueForRetest = dueForRetest ? new Date(dueForRetest).getTime() : 0;
  dateExpired = dateExpired ? new Date(dateExpired).getTime() : 0;

  if (dueForRetest) {
    return dueForRetest <= new Date().getTime();
  } else {
    return dateExpired <= new Date().getTime();
  }
}
