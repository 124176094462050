import ConnectedUsers from "tpo/ConnectedUsers";

import InviteYourPatients from "./ui/InviteYourPatients";
import PatientsTemplate from "./ui/Template";

export default function ManagePatients() {
  return (
    <PatientsTemplate active="manage_patients">
      <InviteYourPatients />
      <ConnectedUsers />
    </PatientsTemplate>
  );
}
