import { isValidElement } from "react";

import Box from "components/Box";
import RichText from "components/RichText";
import Currency from "tpo/Currency";
import Stack from "tpo/Stack";

import AddToBasketButton from "./AddToBasketButton";

function Option({
  name,
  description,
  price,
  previousPrice,
  practitionerSupportRequired,
  lastOption,
  hasClinics,
  onFindNearestClinic,
  onAddToBasket,
  status,
  disabled = false,
  disabledMessage = ""
}) {
  console.log({ disabled, disabledMessage });
  return (
    <Stack
      borderBottomColor="haze"
      borderBottomStyle={!lastOption ? "solid" : null}
      borderBottomWidth={1}
      gap={20}
      pb={20}
      data-component-name="TestProductOptions.Option"
    >
      <Stack gap={20}>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} data-testid="name">
          {name}
        </Box>
        <RichText>{description}</RichText>
      </Stack>
      <Stack
        flexDirection={["column", "column", "row"]}
        justifyContent={[null, null, "space-between"]}
        alignItems={["flex-start"]}
      >
        <Stack>
          {isValidElement(price) ? (
            price
          ) : (
            <Currency fontSize={[24, 24, 44]} value={price} data-testid="price" />
          )}
          {previousPrice !== null && previousPrice > price && (
            <Currency
              color="midGrey"
              fontSize={18}
              value={previousPrice}
              prefix="RRP"
              data-testid="rrpprice"
            />
          )}
        </Stack>
        <AddToBasketButton
          practitionerSupportRequired={practitionerSupportRequired}
          hasClinics={hasClinics}
          onFindNearestClinic={onFindNearestClinic}
          onAddToBasket={onAddToBasket}
          status={status}
          disabled={disabled}
          disabledMessage={disabledMessage}
        />
      </Stack>
    </Stack>
  );
}

export default function TestProductOptions({ bg, children }) {
  return (
    <Stack
      gap={20}
      px={[20, 20, 40]}
      py={40}
      bg={bg}
      borderRadius={5}
      data-component-name="TestProductOptions"
    >
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Purchase options
      </Box>
      {children}
    </Stack>
  );
}

TestProductOptions.defaultProps = {
  bg: "white"
};

TestProductOptions.Option = Option;
