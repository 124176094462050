import { useLocation, useHistory } from "react-router-dom";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { useHeaderContext } from "contexts/HeaderContext";
import {
  BLOG_URL,
  CONTACT_US_URL,
  EXPERTS_URL,
  HOW_IT_WORKS_URL,
  LOGIN_URL,
  MEET_THE_TEAM_URL,
  OUR_MISSION_URL,
  PARTNERS_EXTERNAL_URL,
  PARTNERS_PRACTITIONER_URL,
  SIGNUP_URL,
  TESTS_URL,
  THE_SCIENCE_URL
} from "core/urls";
import ChevronComponent from "tpo/Chevron";
import Drawer from "tpo/Drawer";
import Group from "tpo/Group";
import LinkWrapper from "tpo/LinkWrapper";
import NavLink from "tpo/NavLink";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

export default function LoggedOutMenuDrawer({ bg }) {
  const { opened, close, onClick } = useHeaderContext();
  const location = useLocation();
  const history = useHistory();

  return (
    <Drawer.Provider maxWidth={700} opened={opened} close={close}>
      <Drawer.Root bg={bg}>
        <Group height={60}>
          <Drawer.CloseButton color="white" position="fixed" right={[20, 20, "5.5vw"]} top={20} />
        </Group>
        <Box
          pt={[30, 30, 60]}
          pb={[50, 50, 80]}
          pl={[30, 30, 60]}
          pr={[20, 20, "5.5vw"]}
          overflow="auto"
        >
          <ButtonV2
            as={LinkWrapper}
            color="green"
            rightIcon={<ChevronComponent />}
            to={LOGIN_URL}
            style={{
              display: "inline-flex"
            }}
            onClick={onClick}
          >
            Sign in
          </ButtonV2>
          <Spacer py={2} />
          <NavLink
            color="green"
            label="Sign up"
            fontSize={24}
            onClick={() => {
              onClick();
              history.push(SIGNUP_URL);
            }}
          />
          {/* <NavLink
            color="white"
            label="Find a practitioner"
            fontSize={24}
            active={location.pathname === EXPERTS_URL}
            onClick={() => {
              onClick();
              history.push(EXPERTS_URL);
            }}
          /> */}
          <NavLink
            color="white"
            label="Shop"
            fontSize={24}
            active={location.pathname === TESTS_URL}
            onClick={() => {
              onClick();
              history.push(TESTS_URL);
            }}
          />
          <NavLink color="white" label="About">
            <NavLink
              color="white"
              label="How it works"
              as={ExternalTextLink}
              href={HOW_IT_WORKS_URL}
              underline={false}
            />
            <NavLink
              color="white"
              label="Our Mission"
              as={ExternalTextLink}
              href={OUR_MISSION_URL}
              underline={false}
            />
            <NavLink
              color="white"
              label="The science"
              as={ExternalTextLink}
              href={THE_SCIENCE_URL}
              underline={false}
            />
            <NavLink
              color="white"
              label="Meet the team"
              as={ExternalTextLink}
              href={MEET_THE_TEAM_URL}
              underline={false}
            />
            <NavLink
              color="white"
              label="Blog"
              as={ExternalTextLink}
              href={BLOG_URL}
              underline={false}
            />
            <NavLink
              color="white"
              label="Contact us"
              as={ExternalTextLink}
              href={CONTACT_US_URL}
              underline={false}
            />
          </NavLink>
          <NavLink color="white" label="Partners">
            <NavLink
              color="white"
              label="Omnos Partner programme"
              as={ExternalTextLink}
              href={PARTNERS_EXTERNAL_URL}
              underline={false}
            />
            <NavLink
              color="white"
              label="Practitioner application"
              onClick={() => {
                onClick();
                history.push(PARTNERS_PRACTITIONER_URL);
              }}
            />
          </NavLink>
        </Box>
      </Drawer.Root>
    </Drawer.Provider>
  );
}
