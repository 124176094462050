import Danger from "images/Danger";
import Center from "tpo/Center";
import Group from "tpo/Group";

import Box from "./Box";
import { ExternalTextLink } from "./Links";

export default function CommissionNotice() {
  return (
    <Center>
      <Center
        pt={[20, 20, 40]}
        pb={[50, 50, 80]}
        px={[20, 20, 40]}
        stacked
        gap={[20, 20, 40]}
        maxWidth={1200}
        bg="white"
        width="100%"
      >
        <Group alignItems="center" gap={[10, 10, 20]} mr={["auto", 0]}>
          <Danger size={40} />
          <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]}>
            Commission balance
          </Box>
        </Group>
        <Box maxWidth={760} mx="auto" fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          Please be aware that as of March 1st 2024, we will be discontinuing the Omnos affiliate
          program in its current format.{" "}
          <Box as="span" fontFamily="gilroyBold">
            Due to these changes any commissions earned via the Omnos affiliate program will not be
            listed on this page.
          </Box>
          <br />
          <br />
          To find out your available balance please message{" "}
          <ExternalTextLink
            href={`mailto:finance@omnos.me`}
            target="_blank"
            rel="noopener noreferrer"
          >
            finance@omnos.me
          </ExternalTextLink>
          .
          <br />
          <br />
          Please ensure you submit all commission invoices by 31 March 2024. Any invoices received
          after 31 March 2024 will not be processed for payment.
        </Box>
      </Center>
    </Center>
  );
}
