import { gql } from "@apollo/client";

export const USER_NOTIFICATION_FIELDS = gql`
  fragment UserNotificationFields on UserNotificationType {
    id
    notification {
      id
      type
    }
  }
`;
