import { useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Loading from "components/Loading";
import Page from "components/Page";
import { useBasketContext } from "contexts/BasketContext";
import { SHOP_SUPPLEMENTS_URL, SHOP_TESTS_URL } from "core/urls";
import {
  SHOP_LISTING_FILTERS_QUERY,
  SUPPLEMENT_CONNECTIONS_QUERY,
  SUPPLEMENT_DETAIL_QUERY
} from "graphql/shop";
import { useDataLoader } from "hooks/useDataLoader";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import { PanelBoxV2 } from "tpo/Boxes";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import Modal from "tpo/Modal";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";
import useProductFilters from "tpo/shop/hooks/useProductFilters";

import ListPageTemplate from "./ListPageTemplate";
import OmnosHeader from "./OmnosHeader";
import Pills from "./Pills";
import SupplementCard from "./SupplementCard";
import { SupplementDetailContent } from "./SupplementDetailPage";

export function SupplementListContent({
  bg,
  dietryRestrictions,
  types,
  brands,
  header,
  urlMap,
  title,
  subtitle,
  onAddToBasket,
  productFiltersDrawerBg = "blue"
}) {
  const [selectedSupplement, setSelectedSupplement] = useState();

  const history = useHistory();
  const location = useLocation();

  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "A - Z",
        value: "name"
      },
      {
        label: "Z - A",
        value: "-name"
      },
      {
        label: "Price Asc",
        value: "price"
      },
      {
        label: "Price Desc",
        value: "-price"
      }
    ]
  });

  const { menu, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      brands: [],
      dietryRestrictions: [],
      types: []
    },
    allFilters: {
      brands: [...brands].sort(),
      dietryRestrictions: dietryRestrictions.map(dr => dr.name).sort(),
      types: [...types].sort()
    },
    filtersTitle: "Product Filters",
    drawerBg: productFiltersDrawerBg
  });

  const { data, loading, fetchMore } = useDataLoader({
    query: SUPPLEMENT_CONNECTIONS_QUERY,
    variables: {
      orderBy: controls.sort,
      search: debouncedSearch,
      first: 10,
      after: "",
      brandNames: validatedFilters.brands.join(","),
      dietryRestrictions: validatedFilters.dietryRestrictions.join(","),
      doseTypes: validatedFilters.types.join(",")
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  const supplements = data?.supplementConnections?.edges.map(edge => edge.node);
  const hasMore = data?.supplementConnections?.pageInfo?.hasNextPage;
  const endCursor = data?.supplementConnections?.pageInfo?.endCursor;

  return (
    <ListPageTemplate
      bg={bg}
      urlMap={urlMap}
      header={header}
      tab="supplements"
      title={title}
      subtitle={subtitle}
    >
      <PanelBoxV2
        maxWidth={1220}
        outer={{
          bg: "haze",
          pt: [30, 30, 40],
          pb: [30, 30, 100],
          px: [20, 20, "5.5vw"]
        }}
      >
        <Box
          display="flex"
          flexDirection={["column", "column", "row"]}
          gap={20}
          justifyContent={[null, null, "space-between"]}
          flexWrap="wrap"
        >
          <Group gap={10}>{menu}</Group>
          <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
            <Search
              value={search}
              onChange={s => {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set("search", s);
                history.push({
                  path: location.path,
                  search: searchParams.toString()
                });
              }}
              maxWidth={[null, 350]}
              minWidth={310}
              width="100%"
            />
            <Box display="flex" justifyContent="flex-end">
              <SortMenu
                value={controls.sort}
                setValue={controls.setSort}
                open={controls.sortMenuOpen}
                setOpen={controls.setSortMenuOpen}
                options={controls.sortOptions}
                placeholder="Sort By"
              />
            </Box>
          </Box>
          <Box display={["none", "none", "block"]} width="100%" />
          {selectedFilters}
          <Box display={["none", "none", "block"]} width="100%" />
          <Spacer py={20} />
        </Box>
        <InfiniteScroll
          loader={<Loading />}
          hasMore={hasMore}
          loading={loading}
          next={() => {
            if (endCursor) {
              fetchMore({
                variables: {
                  after: endCursor,
                  first: 10
                }
              });
            }
          }}
          items={supplements}
        >
          <Stack>
            <InfiniteScrollContext.Consumer>
              {({ itemsList, setBottomElement }) => (
                <>
                  {itemsList.map((supplement, idx) => (
                    <SupplementCard
                      title={supplement.name}
                      addText="Add"
                      status={supplement.status}
                      subtitle={
                        <>
                          <Box color="midGrey" fontFamily="gilroyMedium" fontSize={14}>
                            {supplement.brandName}
                          </Box>
                          {!!supplement.size && (
                            <Box color="midGrey" fontFamily="gilroyMedium" fontSize={14}>
                              Size: {supplement.size}
                            </Box>
                          )}
                        </>
                      }
                      pills={
                        <>
                          {!!supplement.doseType && (
                            <Pills.Pill color="purple">{supplement.doseType}</Pills.Pill>
                          )}
                          {supplement.dietryRestrictions.map(dr => (
                            <Pills.Pill
                              key={dr.id}
                              color="haze"
                              sx={{
                                color: "dark"
                              }}
                            >
                              {dr.name}
                            </Pills.Pill>
                          ))}
                        </>
                      }
                      price={supplement.price}
                      key={supplement.id}
                      onAdd={e => {
                        e.onAdd = true;
                        onAddToBasket({ supplement });
                      }}
                      onSelect={e => {
                        if (e.onAdd) return;
                        setSelectedSupplement(supplement);
                      }}
                      ref={itemsList.length - 1 === idx ? setBottomElement : null}
                    />
                  ))}
                </>
              )}
            </InfiniteScrollContext.Consumer>
          </Stack>
        </InfiniteScroll>
      </PanelBoxV2>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [2, 2, 20],
          pb: 20
        }}
        bg="haze"
        show={!!selectedSupplement}
        close={() => setSelectedSupplement()}
        mode={["fullScreen", "fullScreen", "centered"]}
        data-component-name="SupplementDetailModal"
      >
        {selectedSupplement && (
          <DataLoader
            query={SUPPLEMENT_DETAIL_QUERY}
            variables={{
              slug: selectedSupplement.slug
            }}
            render={({ supplement }) => (
              <SupplementDetailContent
                onAddToBasket={() => {
                  setSelectedSupplement();
                  onAddToBasket({ supplement });
                }}
                description={supplement.description}
                name={supplement.name}
                productImage={supplement.productImage}
                dietryRestrictions={supplement.dietryRestrictions}
                price={supplement.price}
                directions={supplement.directions}
                relatedUserResults={supplement.relatedUserResults}
                dimensions={{
                  columnWidth: 600
                }}
                status={supplement.status}
              />
            )}
          />
        )}
      </Modal>
    </ListPageTemplate>
  );
}

export default function SupplementListPage() {
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  return (
    <DataLoader
      query={SHOP_LISTING_FILTERS_QUERY}
      render={({ dietryRestrictions, types, brands }) => (
        <Page>
          <SupplementListContent
            brands={brands}
            dietryRestrictions={dietryRestrictions}
            types={types}
            urlMap={{
              supplements: SHOP_SUPPLEMENTS_URL,
              tests: SHOP_TESTS_URL
            }}
            header={<OmnosHeader />}
            bg="white"
            title="Supplements are important"
            subtitle="We can get a huge amount of benefit from small changes to our diets. Alongside our nutritional advice we offer more specific, targeted support, in the form of carefully selected supplements. These supplements have been designed to be specific rather than general."
            onAddToBasket={({ supplement }) => {
              addItemToBasket({
                compositeId: supplement.compositeId
              });
              setBasketOpen(true);
            }}
          />
        </Page>
      )}
    />
  );
}
