import { forwardRef, useCallback, useState } from "react";

import { theme } from "core/theme";
import { ReactComponent as EyeWithStrikeThrough } from "images/eye-with-strikethrough.svg";
import { ReactComponent as Eye } from "images/eye.svg";
import { ReactComponent as Pencil } from "images/pencil.svg";
import { ActionIcon } from "v2/Buttons";

import FloatingLabelInput from "./FloatingLabelInput";
import Group from "./Group";

const FloatingLabelPasswordInput = forwardRef(({ error, ...rest }, ref) => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  const PasswordVisibilityIcon = visible ? Eye : EyeWithStrikeThrough;

  return (
    <FloatingLabelInput
      type={visible ? "text" : "password"}
      rightIcon={
        <Group gap={10}>
          <ActionIcon onClick={toggleVisibility}>
            <PasswordVisibilityIcon width={20} color={error ? "red" : theme.colors.slate} />
          </ActionIcon>
          <Pencil fill={error ? "red" : theme.colors.slate} width={16} />
        </Group>
      }
      error={error}
      {...rest}
      ref={ref}
    />
  );
});

export default FloatingLabelPasswordInput;
